import RiseModeZ_01 from '../../../img/Produtos/memoria/rise-mode-z/1.jpg';
import RiseModeZ_02 from '../../../img/Produtos/memoria/rise-mode-z/2.jpg';
import RiseModeZ_Banner from '../../../img/Produtos/memoria/rise-mode-z/banner.jpg';

import RiseModeZDDR5_02 from '../../../img/Produtos/memoria/rise-mode-z-ddr5/2.jpg';

import DiamondSeries_01 from '../../../img/Produtos/memoria/diamond-series/1.jpg';
import DiamondSeries_02 from '../../../img/Produtos/memoria/diamond-series/2.jpg';
import DiamondSeries_Banner from '../../../img/Produtos/memoria/diamond-series/banner.jpg';

import DiamondRGB_01 from '../../../img/Produtos/memoria/diamond-rgb/1.jpg';
import DiamondRGB_02 from '../../../img/Produtos/memoria/diamond-rgb/2.jpg';
import DiamondRGB_Banner from '../../../img/Produtos/memoria/diamond-rgb/banner.jpg';

import VenonRGB_01 from '../../../img/Produtos/memoria/venon-rgb/1.jpg';
import VenonRGB_02 from '../../../img/Produtos/memoria/venon-rgb/2.jpg';
import VenonRGB_Banner from '../../../img/Produtos/memoria/venon-rgb/banner.jpg';

import Value_01 from '../../../img/Produtos/memoria/value/1.jpg';
import Value_02 from '../../../img/Produtos/memoria/value/2.jpg';
import Value_Banner from '../../../img/Produtos/memoria/value/banner.jpg';

import ValueNotebook_01 from '../../../img/Produtos/memoria/value-notebook/1.jpg';
import ValueNotebook_02 from '../../../img/Produtos/memoria/value-notebook/2.jpg';
import ValueNotebook_Banner from '../../../img/Produtos/memoria/value-notebook/banner.jpg';

const products = [
  {
    id: 1,
    name: 'Rise Mode Z SERIES DDR4',
    category: 'memoria',
    descriptionP: `Uma das peças mais importantes para tornar o seu computador rápido. A Memória RAM Rise Mode Z é o que você precisa para evitar que seu PC trave enquanto usa seus programas favoritos.<br>
    <br>
    Especificações:<br/>

    Capacidade: 8GB / 16GB / 32GB<br/>
    3200Mhz<br/>
    CL16–16-18-18 1.2V<br/>
    DDR4-3200<br/>
    Latência: 16<br/>
    288-Pin DIMM
    `,


    descriptionE: `One of the most important components to make your computer fast. The Rise Mode Z RAM Memory is what you need to prevent your PC from freezing while using your favorite programs.<br>
    <br>
    
    Specifications:<br/>

    Capacity: 8GB / 16GB / 32GB<br/>
    3200Mhz<br/>
    CL16–16-18-18 1.2V<br/>
    DDR4-3200<br/>
    Latency: 16<br/>
    288-Pin DIMM
    `,
      
    productId: 'rise-mode-z-ddr4',
    banner: RiseModeZ_Banner,
    youtubeUrl: '',
    images: [RiseModeZ_01, RiseModeZ_02],
    pageURL: 'rise-mode-z-ddr4',
  },
  {
    id: 7,
    name: 'Rise Mode Z SERIES DDR5',
    category: 'memoria',
    descriptionP: `Uma das peças mais importantes para tornar o seu computador rápido. A Memória RAM Rise Mode Z é o que você precisa para evitar que seu PC trave enquanto usa seus programas favoritos.<br>
    <br>
    Especificações:<br/>

    Capacidade: 16GB / 32GB<br/>
    4800Mhz<br/>
    CL40-39-39 at 1.1V<br/>
    DDR5-4800<br/>
    Latência: 40<br/>
    288-Pin DIMM<br/>
    On-Die ECC
    `,


    descriptionE: `One of the most important components to make your computer fast. The Rise Mode Z RAM Memory is what you need to prevent your PC from freezing while using your favorite programs.<br>
    <br>
    
    Specifications:<br/>

   
    Capacity: 16GB / 32GB<br/>
    4800Mhz<br/>
    CL40-39-39 at 1.1V<br/>
    DDR5-4800<br/>
    Latency: 40<br/>
    288-Pin DIMM<br/>
    On-Die ECC
    `,
      
    productId: 'rise-mode-z-ddr5',
    banner: RiseModeZ_Banner,
    youtubeUrl: '',
    images: [RiseModeZ_01, RiseModeZDDR5_02],
    pageURL: 'rise-mode-z-ddr5',
  },
  {
    id: 2,
    name: 'Diamond series',
    category: 'memoria',
    descriptionP: "Construída com chip de alta velocidade, as memórias Rise Mode proporcionam segurança e desempenho para o seu setup, mantendo o estilo Rise no seu PC. Ganhe mais velocidade e utilize aplicativos pesados sem dor de cabeça. Realize diversas tarefas ao mesmo tempo com maior largura de banda. E tenha uma resposta mais rápida com menor latência e tempo de carregamento.",

    descriptionE: "Built with high-speed chips, Rise Mode memories provide security and performance for your setup, maintaining the Rise style in your PC. Gain more speed and use resource-intensive applications without headaches. Perform multiple tasks at the same time with greater bandwidth. And respond faster with lower latency and loading times.",
      
    productId: 'diamond-series',
    banner: DiamondSeries_Banner,
    youtubeUrl: '',
    images: [DiamondSeries_01, DiamondSeries_02],
    pageURL: 'diamond-series',
  },

  {
    id: 3,
    name: 'DIAMOND SERIES RGB',
    category: 'memoria',
    descriptionP: "Construída com chip de alta velocidade, as memórias Rise Mode proporcionam segurança e desempenho para o seu setup, mantendo o estilo Rise no seu PC. Ganhe mais velocidade e utilize aplicativos pesados sem dor de cabeça. Realize diversas tarefas ao mesmo tempo com maior largura de banda. E tenha uma resposta mais rápida com menor latência e tempo de carregamento.",

    descriptionE: "Built with high-speed chips, Rise Mode memories provide security and performance for your setup, maintaining the Rise style in your PC. Gain more speed and use resource-intensive applications without headaches. Perform multiple tasks at the same time with greater bandwidth. And respond faster with lower latency and loading times.",
    productId: 'diamond-rgb',
    banner: DiamondRGB_Banner,
    youtubeUrl: '3hzVuWzXQ5E',
    images: [DiamondRGB_01, DiamondRGB_02],
    pageURL: 'diamond-rgb',
  },
  {
    id: 4,
    name: 'Venon RGB',
    category: 'memoria',
    descriptionP:`Construída com chip de alta velocidade, as memórias Rise Mode proporcionam segurança e desempenho para o seu setup, mantendo o estilo Rise no seu PC. Ganhe mais velocidade e utilize aplicativos pesados sem dor de cabeça. Realize diversas tarefas ao mesmo tempo com maior largura de banda. E tenha uma resposta mais rápida com menor latência e tempo de carregamento.<br>
    <br/> 
    Especificações:<br/>
    Capacidade: 8GB / 16GB<br/>
    3200Mhz<br/>
    CL16–16-16-16 1.2V<br/>
    DDR4-3200<br/>
    Latência: 16<br/>
    288-Pin DIMM
    `,

    descriptionE: `Built with high-speed chips, Rise Mode memories provide security and performance for your setup, maintaining the Rise style in your PC. Gain more speed and use resource-intensive applications without headaches. Perform multiple tasks at the same time with greater bandwidth. And respond faster with lower latency and loading times.<br>
    <br/>
    Specifications:<br/>

    Capacity: 8GB / 16GB<br/>
    3200Mhz<br/>
    CL16–16-16-16 1.2V<br/>
    DDR4-3200<br/>
    Latency: 16<br/>
    288-Pin DIMM`,
    productId: 'memoria-venon-rgb',
    banner: '',
    youtubeUrl: '',
    images: [VenonRGB_01, VenonRGB_02],
    pageURL: 'memoria-venon-rgb',
  },
  {
    id: 5,
    name: 'Value Desktop',
    category: 'memoria',
    descriptionP: "Construída com chip de alta velocidade, as memórias Rise Mode proporcionam segurança e desempenho para o seu setup, mantendo o estilo Rise no seu PC. Ganhe mais velocidade e utilize aplicativos pesados sem dor de cabeça. Realize diversas tarefas ao mesmo tempo com maior largura de banda. E tenha uma resposta mais rápida com menor latência e tempo de carregamento.",

    descriptionE: "Built with high-speed chips, Rise Mode memories provide security and performance for your setup, maintaining the Rise style in your PC. Gain more speed and use resource-intensive applications without headaches. Perform multiple tasks at the same time with greater bandwidth. And respond faster with lower latency and loading times.",
    productId: 'value',
    banner: Value_Banner,
    youtubeUrl: 'Rj5kTyUTjmA',
    images: [Value_01, Value_02],
    pageURL: 'value',
  },
  {
    id: 6,
    name: 'Value Notebook',
    category: 'memoria',
    descriptionP: "Construída com chip de alta velocidade, as memórias Rise Mode proporcionam segurança e desempenho para o seu setup, mantendo o estilo Rise no seu PC. Ganhe mais velocidade e utilize aplicativos pesados sem dor de cabeça. Realize diversas tarefas ao mesmo tempo com maior largura de banda. E tenha uma resposta mais rápida com menor latência e tempo de carregamento.",

    descriptionE: "Built with high-speed chips, Rise Mode memories provide security and performance for your setup, maintaining the Rise style in your PC. Gain more speed and use resource-intensive applications without headaches. Perform multiple tasks at the same time with greater bandwidth. And respond faster with lower latency and loading times.",
    productId: 'value-notebook',
    banner: ValueNotebook_Banner,
    youtubeUrl: '3vsGRL5zqZM',
    images: [ValueNotebook_01, ValueNotebook_02],
    pageURL: 'value-notebook',
  },
];

export default products;
