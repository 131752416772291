import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import Modal from 'react-modal';
import { motion } from 'framer-motion'; 

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background: #161616;
  border-radius: 0rem 1rem 1rem 0rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h1{
    background: none;
    color: #4491FA;
    margin-bottom: 0.5rem;
  }
`;

const SearchInput = styled.input`
  width: 100%;
  max-width: 600px;
  padding: 10px;
  margin-bottom: 20px;
  background: #343333;
  border: 1px solid #8d8d8d;
  border-radius: 4px;
  height: 50px;
  font-size: 1.2rem;
`;

const Controle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
`;

const ProductList = styled.div`
  width: 100%;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  background: none;
`;

const ProductItem = styled.li`
  display: flex;
  align-items: center;
  margin: 0.8rem;
  padding: 1rem;
  border-radius: 4px;
  background: #101010;
  border: 1px solid #8d8d8d;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: 300ms;

    :hover{
      background: #343333;
    }

    
`;

const ProductThumbnail = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 4px;
  
  @media screen and (max-width: 1300px) {
    width: 30px;
    height: 30px;
  }
  
`;

const ProductInfo = styled.div`
  flex: 1;
  background: none;
  
`;

const ProductName = styled.span`
  font-weight: bold;
  display: flex;
  align-items: center;
  background: none;
  
  span{
    font-size: 1.5rem;
    background: none;
  }
`;

const ProductCategory = styled.span`
  color: #ff0000;
  background: none;
`;

const DeleteButton = styled.button`
  background-color: #ff0000;
  color: #fff;
  border: none;
  padding: 12px 20px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 1rem;
  font-family: 'inter';
  font-weight: 800;

  :hover{
    filter: brightness(0.8);
  }

  @media screen and (max-width: 1300px) {
    padding: 5px 10px;
    font-size: 0.8rem;
  }
`;

const SuccessMessage = styled.div`
  /* Estilos para a mensagem de sucesso */
  background-color: #4caf50;
  color: #fff;
  padding: 10px;
  margin-top: 20px;
  font-size: 1.3rem;
  width: 300px;
  margin: 0 auto;
  padding: 1rem;
  border-radius: 4px;
  display: block;
  text-align: center;
`;

const CustomModal = styled(Modal)`
  /* Estilos para o container do modal */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #161616;
  padding: 80px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
`;

const Overlay = styled.div`
  /* Estilos para o overlay */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;


const ModalTitle = styled.h2`
  /* Estilos para o título do modal */
  margin-bottom: 4px;
  background: none;
  font-size: 2.5rem;
`;

const ModalContent = styled.div`
  /* Estilos para o conteúdo do modal */
  margin-bottom: 20px;
  background: none;

  p{
    font: 1rem;
    background: none;
    color: #c3c3c3;
    font-family: 'Inter';
  }
`;

const ModalActions = styled.div`
  /* Estilos para as ações do modal */
  display: flex;
  justify-content: flex-end;
  background: none;

  button {
    margin-left: 10px;
  }

  #cancelar{
    color: white;
    border: 1px solid white;
    width: 150px;
    height: 50px;
    background:none;
    cursor: pointer;
    font-size: 1rem;
    font-family: 'inter';
    font-weight: 800;
    border-radius: 4px;

    :hover{
      filter: brightness(0.8);
    }
  }

  #excluir{
    color: white;
    border: none;
    width: 150px;
    height: 50px;
    background: #ff0000;
    cursor: pointer;
    font-size: 1rem;
    font-family: 'inter';
    font-weight: 800;
    border-radius: 4px;

    :hover{
      filter: brightness(0.8);
    }
  }
`;

const firebaseConfig = {
  apiKey: "AIzaSyBliTfTPbSU4caIdCdMh_Xs5f_-95ISq28",
  authDomain: "produtos-list.firebaseapp.com",
  databaseURL: "https://produtos-list-default-rtdb.firebaseio.com",
  projectId: "produtos-list",
  storageBucket: "produtos-list.appspot.com",
  messagingSenderId: "308827988350",
  appId: "1:308827988350:web:a635c2b1508599bfff132f"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const database = firebase.database();

Modal.setAppElement('#root'); // Define o elemento raiz do React Modal

const App = () => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [deleteProductId, setDeleteProductId] = useState(null); // ID do produto a ser excluído
  const [isModalOpen, setIsModalOpen] = useState(false); // Estado do modal de confirmação
  const [showSuccessMessage, setShowSuccessMessage] = useState(false); // Novo estado para exibir a mensagem

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await database.ref('products').once('value');
        const data = snapshot.val();
        if (data) {
          const productsArray = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));
          setProducts(productsArray);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleDeleteProduct = async (pageURL) => {
    try {
      await database.ref(`products/${pageURL}`).remove();
      setProducts((prevProducts) =>
        prevProducts.filter((product) => product.id !== pageURL)
      );
      setDeleteProductId(null); // Limpa o ID do produto a ser excluído
      setIsModalOpen(false); // Fecha o modal de confirmação
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const handleOpenModal = (pageURL) => {
    setDeleteProductId(pageURL);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setDeleteProductId(null);
    setIsModalOpen(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredProducts = products.filter((product) =>
  product.pageURL && product.pageURL.toLowerCase().includes(searchTerm.toLowerCase())
);

  return (
    <motion.div
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.3 }}
    >
    <Container>
      <h1>Remover Páginas</h1>
      <SearchInput
        type="text"
        placeholder="Pesquisar produto"
        value={searchTerm}
        onChange={handleSearch}
      />
       {showSuccessMessage && (
        <SuccessMessage>Produto excluído com sucesso!</SuccessMessage>
      )}
      <Controle>
      <ProductList>
      {filteredProducts.map((product) => (
        <ProductItem key={product.id}>
          <ProductInfo>
            <ProductName>
              {product.additionalImageURL && product.additionalImageURL.length > 0 && (
                <ProductThumbnail src={product.additionalImageURL} alt="Product Thumbnail" />
              )}
              <span>{product.headerTitle}</span>
            </ProductName>
            <ProductCategory>Categoria: {product.pageURL}</ProductCategory>
          </ProductInfo>
          <DeleteButton onClick={() => handleOpenModal(product.id)}>
            Remover
          </DeleteButton>
        </ProductItem>
      ))}
      </ProductList>
      </Controle>           
      <CustomModal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Confirmar exclusão"
        overlayClassName="modal-overlay"
      >
        <ModalTitle>Confirmar Exclusão</ModalTitle>
        <ModalContent>
          <p>Deseja realmente excluir esta página?</p>
        </ModalContent>
        <ModalActions>
          <button id='cancelar' onClick={handleCloseModal}>Cancelar</button>
          <button id='excluir' onClick={() => handleDeleteProduct(deleteProductId)}>
            Excluir
          </button>
        </ModalActions>
      </CustomModal>
    </Container>
    </motion.div>
  );
};

export default App;
