import Frost120mm_01 from '../../../img/Produtos/water_cooler/frost-120mm/1.jpg';
import Frost120mm_02 from '../../../img/Produtos/water_cooler/frost-120mm/2.jpg';
import Frost120mm_03 from '../../../img/Produtos/water_cooler/frost-120mm/3.jpg';
import Frost120mm_04 from '../../../img/Produtos/water_cooler/frost-120mm/4.jpg';
import Frost120mm_Banner from '../../../img/Produtos/water_cooler/frost-120mm/banner.jpg';


import Frost240mm_01 from '../../../img/Produtos/water_cooler/frost-240mm/1.jpg';
import Frost240mm_02 from '../../../img/Produtos/water_cooler/frost-240mm/2.jpg';
import Frost240mm_03 from '../../../img/Produtos/water_cooler/frost-240mm/3.jpg';
import Frost240mm_04 from '../../../img/Produtos/water_cooler/frost-240mm/4.jpg';
import Frost240mm_05 from '../../../img/Produtos/water_cooler/frost-240mm/5.jpg';
import Frost240mm_Banner from '../../../img/Produtos/water_cooler/frost-240mm/banner.jpg';

import Frost360mm_01 from '../../../img/Produtos/water_cooler/frost-360mm-rgb/1.jpg';
import Frost360mm_02 from '../../../img/Produtos/water_cooler/frost-360mm-rgb/2.jpg';
import Frost360mm_03 from '../../../img/Produtos/water_cooler/frost-360mm-rgb/3.jpg';
import Frost360mm_04 from '../../../img/Produtos/water_cooler/frost-360mm-rgb/4.jpg';
import Frost360mm_05 from '../../../img/Produtos/water_cooler/frost-360mm-rgb/5.jpg';
import Frost360mm_Banner from '../../../img/Produtos/water_cooler/frost-360mm-rgb/banner.jpg';

import Black120mm_01 from '../../../img/Produtos/water_cooler/black-120mm-rgb/1.jpg';
import Black120mm_02 from '../../../img/Produtos/water_cooler/black-120mm-rgb/2.jpg';
import Black120mm_03 from '../../../img/Produtos/water_cooler/black-120mm-rgb/3.jpg';
import Black120mm_04 from '../../../img/Produtos/water_cooler/black-120mm-rgb/4.jpg';
import Black120mm_Banner from '../../../img/Produtos/water_cooler/black-120mm-rgb/banner.jpg';

import Black240mm_01 from '../../../img/Produtos/water_cooler/black-240mm-rgb/1.jpg';
import Black240mm_02 from '../../../img/Produtos/water_cooler/black-240mm-rgb/2.jpg';
import Black240mm_03 from '../../../img/Produtos/water_cooler/black-240mm-rgb/3.jpg';
import Black240mm_04 from '../../../img/Produtos/water_cooler/black-240mm-rgb/4.jpg';
import Black240mm_Banner from '../../../img/Produtos/water_cooler/black-240mm-rgb/banner.jpg';

import Black120AR_01 from '../../../img/Produtos/water_cooler/black-120mm-argb/1.png';

import Black240AR_01 from '../../../img/Produtos/water_cooler/black-240mm-argb/1.jpg';
import Black240AR_02 from '../../../img/Produtos/water_cooler/black-240mm-argb/2.jpg';
import Black240AR_03 from '../../../img/Produtos/water_cooler/black-240mm-argb/3.jpg';
import Black240AR_04 from '../../../img/Produtos/water_cooler/black-240mm-argb/4.jpg';
import Black240AR_05 from '../../../img/Produtos/water_cooler/black-240mm-argb/5.jpg';
import Black240AR_Banner from '../../../img/Produtos/water_cooler/black-240mm-argb/banner.jpg';

import Black360AR_01 from '../../../img/Produtos/water_cooler/black-360mm-argb/1.png';

import Black360mm_01 from '../../../img/Produtos/water_cooler/black-360mm-rgb/1.jpg';
import Black360mm_02 from '../../../img/Produtos/water_cooler/black-360mm-rgb/2.jpg';
import Black360mm_03 from '../../../img/Produtos/water_cooler/black-360mm-rgb/3.jpg';
import Black360mm_04 from '../../../img/Produtos/water_cooler/black-360mm-rgb/4.jpg';
import Black360mm_Banner from '../../../img/Produtos/water_cooler/black-360mm-rgb/banner.jpg';


import AuraFrost120_01 from '../../../img/Produtos/water_cooler/aura-frost-120/1.jpg';
import AuraFrost120_02 from '../../../img/Produtos/water_cooler/aura-frost-120/2.jpg';
import AuraFrost120_03 from '../../../img/Produtos/water_cooler/aura-frost-120/3.jpg';
import AuraFrost120_04 from '../../../img/Produtos/water_cooler/aura-frost-120/4.jpg';
import AuraFrost120_05 from '../../../img/Produtos/water_cooler/aura-frost-120/5.jpg';
import AuraFrost120_Banner from '../../../img/Produtos/water_cooler/aura-frost-120/banner.jpg';

import AuraFrost240_01 from '../../../img/Produtos/water_cooler/aura-frost-240/1.jpg';
import AuraFrost240_02 from '../../../img/Produtos/water_cooler/aura-frost-240/2.jpg';
import AuraFrost240_03 from '../../../img/Produtos/water_cooler/aura-frost-240/3.jpg';
import AuraFrost240_04 from '../../../img/Produtos/water_cooler/aura-frost-240/4.jpg';
import AuraFrost240_05 from '../../../img/Produtos/water_cooler/aura-frost-240/5.jpg';
import AuraFrost240_Banner from '../../../img/Produtos/water_cooler/aura-frost-240/banner.jpg';


import AuraFrost360_01 from '../../../img/Produtos/water_cooler/aura-frost-360/1.jpg';
import AuraFrost360_02 from '../../../img/Produtos/water_cooler/aura-frost-360/2.jpg';
import AuraFrost360_03 from '../../../img/Produtos/water_cooler/aura-frost-360/3.jpg';
import AuraFrost360_04 from '../../../img/Produtos/water_cooler/aura-frost-360/4.jpg';
import AuraFrost360_05 from '../../../img/Produtos/water_cooler/aura-frost-360/5.jpg';
import AuraFrost360_Banner from '../../../img/Produtos/water_cooler/aura-frost-360/banner.jpg';

import AuraIcet120_01 from '../../../img/Produtos/water_cooler/aura-ice-120/1.jpg';
import AuraIcet120_02 from '../../../img/Produtos/water_cooler/aura-ice-120/2.jpg';
import AuraIcet120_03 from '../../../img/Produtos/water_cooler/aura-ice-120/3.jpg';
import AuraIcet120_04 from '../../../img/Produtos/water_cooler/aura-ice-120/4.jpg';
import AuraIcet120_05 from '../../../img/Produtos/water_cooler/aura-ice-120/5.jpg';
import AuraIcet120_06 from '../../../img/Produtos/water_cooler/aura-ice-120/6.jpg';
import AuraIcet120_Banner from '../../../img/Produtos/water_cooler/aura-ice-120/banner.jpg';

import AuraIcet240_01 from '../../../img/Produtos/water_cooler/aura-ice-240/1.jpg';
import AuraIcet240_02 from '../../../img/Produtos/water_cooler/aura-ice-240/2.jpg';
import AuraIcet240_03 from '../../../img/Produtos/water_cooler/aura-ice-240/3.jpg';
import AuraIcet240_04 from '../../../img/Produtos/water_cooler/aura-ice-240/4.jpg';
import AuraIcet240_05 from '../../../img/Produtos/water_cooler/aura-ice-240/5.jpg';
import AuraIcet240_06 from '../../../img/Produtos/water_cooler/aura-ice-240/6.jpg';
import AuraIcet240_Banner from '../../../img/Produtos/water_cooler/aura-ice-240/banner.jpg';

import AuraIcet360_01 from '../../../img/Produtos/water_cooler/aura-ice-360/1.jpg';
import AuraIcet360_02 from '../../../img/Produtos/water_cooler/aura-ice-360/2.jpg';
import AuraIcet360_03 from '../../../img/Produtos/water_cooler/aura-ice-360/3.jpg';
import AuraIcet360_04 from '../../../img/Produtos/water_cooler/aura-ice-360/4.jpg';
import AuraIcet360_05 from '../../../img/Produtos/water_cooler/aura-ice-360/5.jpg';
import AuraIcet360_06 from '../../../img/Produtos/water_cooler/aura-ice-360/6.jpg';
import AuraIcet360_Banner from '../../../img/Produtos/water_cooler/aura-ice-360/banner.jpg';

import AuraIceBlack120_1 from '../../../img/Produtos/water_cooler/aura-ice-black- 120mm-argb/120 black ARGB.png';
import AuraIceBlack240_1 from '../../../img/Produtos/water_cooler/aura-ice-black- 240mm-argb/1.png';
import AuraIceBlack360_1 from '../../../img/Produtos/water_cooler/aura-ice-black- 360mm-argb/1.png';

import BannerFrost from '../../../img/Produtos/water_cooler/banner_frost.png';
import BannerIce from '../../../img/Produtos/water_cooler/banner_ice.png';

const products = [
  {
    id: 1,
    name: 'Frost 120mm',
    category: 'watercooler',
    descriptionP: `Water Cooler Gamer Rise Mode Frost 120mm<br>
    TDP: 200W<br>
    Part Number: RM-WCZ-01-RGB<br>
    Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br>
    AMD Socket -FM2+/FM1/AM3+/AM3/AM2/AM4<br>
    Tamanho do fan: 120x120x25mm<br>
    Velocidade do fan: 1200-2000RPM<br>
    Tipo de rolamento: Rolamento hidráulico<br>
    RGB - 12v motherboard<br>
    (Compatível apenas com placas mãe com entrada RGB 12v)<br>
    Tensão: 12v<br>
    Ruído do fan: 20dB(A)<br>
    Fluxo de ar: 56.3CFM (MAX)<br>
    Vida útil do fan: 40000 Horas`,


    descriptionE: `Water Cooler Gamer Rise Mode Frost 120mm<br>
    TDP: 200W<br>
    Part Number: RM-WCZ-01-RGB<br>
    Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br>
    AMD Socket -FM2+/FM1/AM3+/AM3/AM2/AM4<br>
    Fan Size: 120x120x25mm<br>
    Fan Speed: 1200-2000RPM<br>
    Bearing Type: Hydraulic Bearing<br>
    RGB - 12v motherboard<br>
    (Compatible only with motherboards with 12v RGB header)<br>
    Voltage: 12v<br>
    Fan Noise: 20dB(A)<br>
    Airflow: 56.3CFM (MAX)<br>
    Fan Lifespan: 40000 Hours`,
      
    productId: 'frost-120mm',
    banner: Frost120mm_Banner,
    youtubeUrl: '',
    images: [Frost120mm_01, Frost120mm_02, Frost120mm_03, Frost120mm_04],
    pageURL: 'frost-120mm',
  },
  {
    id: 2,
    name: 'Frost 240mm',
    category: 'watercooler',
    descriptionP: `Water Cooler Gamer Rise Mode Frost 240mm<br>
    TDP: 220W<br>
    Part Number: RM-WCZ-02-RGB<br>
    Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br>
    AMD Socket -FM2+/FM1/AM3+/AM3/AM2/AM4<br>
    Tamanho do fan: 120x120x25mm (2x)<br>
    RGB - 12v motherboard<br>
    (Compatível apenas com placas mãe com entrada RGB 12v)<br>
    Velocidade do fan: 1200-2000RPM<br>
    Tipo de rolamento: Rolamento hidráulico<br>
    Tensão: 12v<br>
    Ruído do fan: 20dB(A)<br>
    Fluxo de ar: 56.3CFM (MAX)<br>
    Vida útil do fan: 40000 Horas<br>
    EAN: 6015033498490`,


    descriptionE: `Water Cooler Gamer Rise Mode Frost 240mm<br>
    TDP: 220W<br>
    Part Number: RM-WCZ-02-RGB<br>
    Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br>
    AMD Socket -FM2+/FM1/AM3+/AM3/AM2/AM4<br>
    Fan Size: 120x120x25mm (2x)<br>
    RGB - 12v motherboard<br>
    (Compatible only with motherboards with 12v RGB header)<br>
    Fan Speed: 1200-2000RPM<br>
    Bearing Type: Hydraulic Bearing<br>
    Voltage: 12v<br>
    Fan Noise: 20dB(A)<br>
    Airflow: 56.3CFM (MAX)<br>
    Fan Lifespan: 40000 Hours<br>
    EAN: 6015033498490`,
      
    productId: 'frost-240mm',
    banner: Frost240mm_Banner,
    youtubeUrl: '',
    images: [Frost240mm_01, Frost240mm_02, Frost240mm_03, Frost240mm_04, Frost240mm_05],
    pageURL: 'frost-240mm',
  },
  {
    id: 3,
    name: 'Frost 360mm RGB',
    category: 'watercooler',
    descriptionP: `Water Cooler Gamer Rise Mode Frost 360mm<br>
    TDP: 250W<br>
    Part Number: RM-WCF-04-RGB<br>
    Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br>
    AMD Socket -FM2+/FM1/AM3+/AM3/AM2/AM4<br>
    Tamanho do fan: 120x120x25mm (3x)<br>
    RGB - 12v motherboard<br>
    (Compatível apenas com placas mãe com entrada RGB 12v)<br>
    Velocidade do fan: 1200-2000RPM<br>
    Tipo de rolamento: Rolamento hidráulico<br>
    Tensão: 12v<br>
    Ruído do fan: 20dB(A)<br>
    Fluxo de ar: 56.3CFM (MAX)<br>
    Vida útil do fan: 40000 Horas<br>
    EAN: 6015033496472`,


    descriptionE: `Water Cooler Gamer Rise Mode Frost 360mm<br>
    TDP: 250W<br>
    Part Number: RM-WCF-04-RGB<br>
    Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br>
    AMD Socket -FM2+/FM1/AM3+/AM3/AM2/AM4<br>
    Fan Size: 120x120x25mm (3x)<br>
    RGB - 12v motherboard<br>
    (Compatible only with motherboards with 12v RGB header)<br>
    Fan Speed: 1200-2000RPM<br>
    Bearing Type: Hydraulic Bearing<br>
    Voltage: 12v<br>
    Fan Noise: 20dB(A)<br>
    Airflow: 56.3CFM (MAX)<br>
    Fan Lifespan: 40000 Hours<br>
    EAN: 6015033496472`,
      
    productId: 'frost-360mm-rgb',
    banner: Frost360mm_Banner,
    youtubeUrl: 'UXBu4X6MCJ8',
    images: [Frost360mm_01, Frost360mm_02, Frost360mm_03, Frost360mm_04, Frost360mm_05],
    pageURL: 'frost-360mm-rgb',
  },
  {
    id: 4,
    name: 'Black 120mm RGB',
    category: 'watercooler',
    descriptionP: `TDP: 200W<br>
    Part Number: RM-WCB-01-RGB<br>
    Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br>
    AMD Socket -FM2+/FM1/AM3+/AM3/AM2/AM4<br>
    Tamanho do fan: 120x120x25mm<br>
    Velocidade do fan: 1200-2000RPM<br>
    Tipo de rolamento: Rolamento hidráulico<br>
    RGB - 12v motherboard<br>
    (Compatível apenas com placas mãe com entrada RGB 12v)<br>
    Tensão: 12v<br>
    Ruído do fan: 20dB(A)<br>
    Fluxo de ar: 56.3CFM (MAX)<br>
    Vida útil do fan: 40000 Horas`,


    descriptionE: `TDP: 200W<br>
    Part Number: RM-WCB-01-RGB<br>
    Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br>
    AMD Socket -FM2+/FM1/AM3+/AM3/AM2/AM4<br>
    Fan Size: 120x120x25mm<br>
    Fan Speed: 1200-2000RPM<br>
    Bearing Type: Hydraulic Bearing<br>
    RGB - 12v motherboard<br>
    (Compatible only with motherboards with 12v RGB header)<br>
    Voltage: 12v<br>
    Fan Noise: 20dB(A)<br>
    Airflow: 56.3CFM (MAX)<br>
    Fan Lifespan: 40000 Hours`,
      
    productId: 'black-120mm-rgb',
    banner: Black120mm_Banner,
    youtubeUrl: 'UXBu4X6MCJ8',
    images: [Black120mm_01, Black120mm_02,Black120mm_03, Black120mm_04],
    pageURL: 'black-120mm-rgb',
  },
  {
    id: 5,
    name: 'Black 240mm RGB',
    category: 'watercooler',
    descriptionP: `Water Cooler Gamer Rise Mode Black 240mm<br>
    TDP: 220W<br>
    Part Number: RM-WCB-02-RGB<br>
    Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br>
    AMD Socket -FM2+/FM1/AM3+/AM3/AM2/AM4<br>
    Tamanho do fan: 120x120x25mm (2x)<br>
    RGB - 12v motherboard<br>
    (Compatível apenas com placas mãe com entrada RGB 12v)<br>
    Velocidade do fan: 1200-2000RPM<br>
    Tipo de rolamento: Rolamento hidráulico<br>
    Tensão: 12v<br>
    Ruído do fan: 20dB(A)<br>
    Fluxo de ar: 56.3CFM (MAX)<br>
    Vida útil do fan: 40000 Horas`,


    descriptionE: `Water Cooler Gamer Rise Mode Black 240mm<br>
    TDP: 220W<br>
    Part Number: RM-WCB-02-RGB<br>
    Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br>
    AMD Socket -FM2+/FM1/AM3+/AM3/AM2/AM4<br>
    Fan Size: 120x120x25mm (2x)<br>
    RGB - 12v motherboard<br>
    (Compatible only with motherboards with 12v RGB header)<br>
    Fan Speed: 1200-2000RPM<br>
    Bearing Type: Hydraulic Bearing<br>
    Voltage: 12v<br>
    Fan Noise: 20dB(A)<br>
    Airflow: 56.3CFM (MAX)<br>
    Fan Lifespan: 40000 Hours`,
      
    productId: 'black-240mm-rgb',
    banner: Black240mm_Banner,
    youtubeUrl: 'UXBu4X6MCJ8',
    images: [Black240mm_01, Black240mm_02,Black240mm_03, Black240mm_04],
    pageURL: 'black-240mm-rgb',
  },
  {
    id: 7,
    name: 'Black 360mm RGB',
    category: 'watercooler',
    descriptionP: `Water Cooler Gamer Rise Mode Black 360mm<br>
    TDP: 250W<br>
    Part Number: RM-WCB-03-RGB<br>
    Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br>
    AMD Socket -FM2+/FM1/AM3+/AM3/AM2/AM4<br>
    Tamanho do fan: 120x120x25mm (3x)<br>
    RGB - 12v motherboard<br>
    (Compatível apenas com placas mãe com entrada RGB 12v)<br>
    Velocidade do fan: 1200-2000RPM<br>
    Tipo de rolamento: Rolamento hidráulico<br>
    Tensão: 12v<br>
    Ruído do fan: 20dB(A)<br>
    Fluxo de ar: 56.3CFM (MAX)<br>
    Vida útil do fan: 40000 Horas<br>
    EAN: 6015033726753`,


    descriptionE: `Water Cooler Gamer Rise Mode Black 360mm<br>
    TDP: 250W<br>
    Part Number: RM-WCB-03-RGB<br>
    Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br>
    AMD Socket -FM2+/FM1/AM3+/AM3/AM2/AM4<br>
    Fan Size: 120x120x25mm (3x)<br>
    RGB - 12v motherboard<br>
    (Compatible only with motherboards with 12v RGB header)<br>
    Fan Speed: 1200-2000RPM<br>
    Bearing Type: Hydraulic Bearing<br>
    Voltage: 12v<br>
    Fan Noise: 20dB(A)<br>
    Airflow: 56.3CFM (MAX)<br>
    Fan Lifespan: 40000 Hours<br>
    EAN: 6015033726753`,
      
    productId: 'black-360mm-rgb',
    banner: Black360mm_Banner,
    youtubeUrl: 'UXBu4X6MCJ8',
    images: [Black360mm_01, Black360mm_02, Black360mm_03,Black360mm_04],
    pageURL: 'black-360mm-rgb',
  },
  {
    id: 17,
    name: 'Black 120mm ARGB',
    category: 'watercooler',
    descriptionP: `
    Water Cooler Gamer Rise Mode Black 120mm ARGB<br/>TDP: 200W<br/>Part Number: RM-WCB-05-ARGB<br/>Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br/>AMD Socket - FM2+/FM1/AM3+/AM3/AM2/AM4/AM5<br/>Tamanho do fan: 120x120x25mm<br/>Velocidade do fan: 1200-2000RPM<br/>Tipo de rolamento: Rolamento hidráulico<br/>ARGB - 5v motherboard<br/>Tensão: 12v<br/>Ruído do fan: 20dB(A)<br/>Fluxo de ar: 56.3CFM (MAX)<br/>Vida útil do fan: 80000 Horas<br/>`,


    descriptionE: `
    Water Cooler Gamer Rise Mode Black 120mm ARGB<br/>TDP: 200W<br/>Part Number: RM-WCB-05-ARGB<br/>Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br/>AMD Socket - FM2+/FM1/AM3+/AM3/AM2/AM4/AM5<br/>Fan Size: 120x120x25mm<br/>Fan Speed: 1200-2000RPM<br/>Bearing Type: Hydraulic Bearing<br/>ARGB - 5v motherboard<br/>Voltage: 12v<br/>Fan Noise: 20dB(A)<br/>Airflow: 56.3CFM (MAX)<br/>Fan Lifespan: 80000 Hours<br/>`,
      
    productId: 'black-120mm-Argb',
    banner: '',
    youtubeUrl: '',
    images: [Black120AR_01],
    pageURL: 'black-120mm-Argb',
  },
  {
    id: 6,
    name: 'Black 240mm ARGB',
    category: 'watercooler',
    descriptionP: `Water Cooler Gamer Rise Mode Black 240mm ARGB<br>
    TDP: 220W<br>
    Part Number: RM-WCB-04-ARGB<br>
    Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br>
    AMD Socket -FM2+/FM1/AM3+/AM3/AM2/AM4<br>
    Tamanho do fan: 120x120x25mm (2x)<br>
    ARGB - 5v motherboard<br>
    (Compatível apenas com placas mãe com entrada ARGB 5v)<br>
    Velocidade do fan: 1200-2000RPM<br>
    Tipo de rolamento: Rolamento hidráulico<br>
    Tensão: 12v<br>
    Ruído do fan: 20dB(A)<br>
    Fluxo de ar: 56.3CFM (MAX)<br>
    Vida útil do fan: 40000 Horas`,


    descriptionE: `Water Cooler Gamer Rise Mode Black 240mm ARGB<br>
    TDP: 220W<br>
    Part Number: RM-WCB-04-ARGB<br>
    Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br>
    AMD Socket -FM2+/FM1/AM3+/AM3/AM2/AM4<br>
    Fan Size: 120x120x25mm (2x)<br>
    ARGB - 5v motherboard<br>
    (Compatible only with motherboards with 5v ARGB header)<br>
    Fan Speed: 1200-2000RPM<br>
    Bearing Type: Hydraulic Bearing<br>
    Voltage: 12v<br>
    Fan Noise: 20dB(A)<br>
    Airflow: 56.3CFM (MAX)<br>
    Fan Lifespan: 40000 Hours`,
      
    productId: 'black-240mm-Argb',
    banner: Black240AR_Banner,
    youtubeUrl: 'eCC3G3wKMO0',
    images: [Black240AR_01, Black240AR_02, Black240AR_03, Black240AR_04, Black240AR_05],
    pageURL: 'black-240mm-Argb',
  },
  {
    id: 18,
    name: 'Black 360mm ARGB',
    category: 'watercooler',
    descriptionP: `
    Water Cooler Gamer Rise Mode Black 360mm ARGB<br/>TDP: 250W<br/>Part Number: RM-WCB-07-ARGB<br/>Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br/>AMD Socket - FM2+/FM1/AM3+/AM3/AM2/AM4/AM5<br/>Tamanho do fan: 120x120x25mm (3x)<br/>Velocidade do fan: 1200-2000RPM<br/>Tipo de rolamento: Rolamento hidráulico<br/>ARGB - 5v motherboard<br/>Tensão: 12v<br/>Ruído do fan: 20dB(A)<br/>Fluxo de ar: 56,3CFM (MAX)<br/>Vida útil do fan: 80000 Horas<br/>`,


    descriptionE: `
    Water Cooler Gamer Rise Mode Black 360mm ARGB<br/>TDP: 250W<br/>Part Number: RM-WCB-07-ARGB<br/>Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br/>AMD Socket - FM2+/FM1/AM3+/AM3/AM2/AM4/AM5<br/>Fan Size: 120x120x25mm (3x)<br/>Fan Speed: 1200-2000RPM<br/>Bearing Type: Hydraulic Bearing<br/>ARGB - 5v motherboard<br/>Voltage: 12v<br/>Fan Noise: 20dB(A)<br/>Airflow: 56.3CFM (MAX)<br/>Fan Lifespan: 80000 Hours<br/>`,
      
    productId: 'black-360mm-Argb',
    banner: '',
    youtubeUrl: '',
    images: [Black360AR_01],
    pageURL: 'black-360mm-Argb',
  },
  {
    id: 8,
    name: 'AURA FROST PRO 120MM ARGB',
    category: 'watercooler',
    descriptionP: `Water Cooler Gamer Rise Mode Frost 120mm ARGB<br>
    TDP: 200W<br>
    Part Number: RM-WCZ-05-ARGB<br>
    Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br>
    AMD Socket -FM2+/FM1/AM3+/AM3/AM2/AM4<br>
    Tamanho do fan: 120x120x25mm<br>
    Velocidade do fan: 1200-2000RPM<br>
    Tipo de rolamento: Rolamento hidráulico<br>
    ARGB - 5v motherboard<br>
    (Compatível apenas com placas mãe com entrada ARGB 5v)<br>
    Tensão: 12v<br>
    Ruído do fan: 20dB(A)<br>
    Fluxo de ar: 56.3CFM (MAX)<br>
    Vida útil do fan: 40000 Horas3`,


    descriptionE: `Water Cooler Gamer Rise Mode Frost 120mm ARGB<br>
    TDP: 200W<br>
    Part Number: RM-WCZ-05-ARGB<br>
    Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br>
    AMD Socket -FM2+/FM1/AM3+/AM3/AM2/AM4<br>
    Fan Size: 120x120x25mm<br>
    Fan Speed: 1200-2000RPM<br>
    Bearing Type: Hydraulic Bearing<br>
    ARGB - 5v motherboard<br>
    (Compatible only with motherboards with 5v ARGB header)<br>
    Voltage: 12v<br>
    Fan Noise: 20dB(A)<br>
    Airflow: 56.3CFM (MAX)<br>
    Fan Lifespan: 40000 Hours`,
      
    productId: 'aura-frost-120',
    banner: BannerFrost,
    youtubeUrl: '04WCPlnAaLc?si=dv-S5G6zy7q9D8Gn',
    images: [AuraFrost120_01, AuraFrost120_02, AuraFrost120_03, AuraFrost120_04, AuraFrost120_05],
    pageURL: 'aura-frost-120',
  },
  {
    id: 9,
    name: 'AURA FROST PRO 240MM ARGB',
    category: 'watercooler',
    descriptionP: `Water Cooler Gamer Rise Mode Frost 240mm ARGB<br>
    TDP: 220W<br>
    Part Number: RM-WCZ-06-ARGB<br>
    Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br>
    AMD Socket -FM2+/FM1/AM3+/AM3/AM2/AM4<br>
    Tamanho do fan: 120x120x25mm (2x)<br>
    ARGB - 5v motherboard<br>
    (Compatível apenas com placas mãe com entrada RGB 5v)<br>
    Velocidade do fan: 1200-2000RPM<br>
    Tipo de rolamento: Rolamento hidráulico<br>
    Tensão: 12v<br>
    Ruído do fan: 20dB(A)<br>
    Fluxo de ar: 56.3CFM (MAX)<br>
    Vida útil do fan: 40000 Horas`,


    descriptionE: `Water Cooler Gamer Rise Mode Frost 240mm ARGB<br>
    TDP: 220W<br>
    Part Number: RM-WCZ-06-ARGB<br>
    Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br>
    AMD Socket -FM2+/FM1/AM3+/AM3/AM2/AM4<br>
    Fan Size: 120x120x25mm (2x)<br>
    ARGB - 5v motherboard<br>
    (Compatible only with motherboards with 5v RGB header)<br>
    Fan Speed: 1200-2000RPM<br>
    Bearing Type: Hydraulic Bearing<br>
    Voltage: 12v<br>
    Fan Noise: 20dB(A)<br>
    Airflow: 56.3CFM (MAX)<br>
    Fan Lifespan: 40000 Hours`,
      
    productId: 'aura-frost-240',
    banner: BannerFrost,
    youtubeUrl: '04WCPlnAaLc?si=dv-S5G6zy7q9D8Gn',
    images: [AuraFrost240_01, AuraFrost240_02, AuraFrost240_03, AuraFrost240_04, AuraFrost240_05],
    pageURL: 'aura-frost-240',
  },
  {
    id: 10,
    name: 'AURA FROST PRO 360MM ARGB',
    category: 'watercooler',
    descriptionP: `Water Cooler Gamer Rise Mode Frost 360mm ARGB<br>
    TDP: 250W<br>
    Part Number: RM-WCF-07-ARGB<br>
    Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br>
    AMD Socket -FM2+/FM1/AM3+/AM3/AM2/AM4<br>
    Tamanho do fan: 120x120x25mm (3x)<br>
    ARGB - 5v motherboard<br>
    (Compatível apenas com placas mãe com entrada RGB 12v)<br>
    Velocidade do fan: 1200-2000RPM<br>
    Tipo de rolamento: Rolamento hidráulico<br>
    Tensão: 12v<br>
    Ruído do fan: 20dB(A)<br>
    Fluxo de ar: 56.3CFM (MAX)<br>
    Vida útil do fan: 40000 Horas`,


    descriptionE: `Water Cooler Gamer Rise Mode Frost 360mm ARGB<br>
    TDP: 250W<br>
    Part Number: RM-WCF-07-ARGB<br>
    Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br>
    AMD Socket -FM2+/FM1/AM3+/AM3/AM2/AM4<br>
    Fan Size: 120x120x25mm (3x)<br>
    ARGB - 5v motherboard<br>
    (Compatible only with motherboards with 12v RGB header)<br>
    Fan Speed: 1200-2000RPM<br>
    Bearing Type: Hydraulic Bearing<br>
    Voltage: 12v<br>
    Fan Noise: 20dB(A)<br>
    Airflow: 56.3CFM (MAX)<br>
    Fan Lifespan: 40000 Hours`,
      
    productId: 'aura-frost-360',
    banner: BannerFrost,
    youtubeUrl: '04WCPlnAaLc?si=dv-S5G6zy7q9D8Gn',
    images: [AuraFrost360_01, AuraFrost360_02, AuraFrost360_03, AuraFrost360_04, AuraFrost360_05],
    pageURL: 'aura-frost-360',
  },
  {
    id: 11,
    name: 'aura ice white 120MM ARGB',
    category: 'watercooler',
    descriptionP: `Water Cooler Gamer Rise Mode Aura Ice 120mm ARGB<br>
    TDP: 200W<br>
    Part Number: RM-WAI-01-ARGB<br>
    Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br>
    AMD Socket -FM2+/FM1/AM3+/AM3/AM2/AM4/AM5<br>
    Tamanho do fan: 120x120x25mm<br>
    Velocidade do fan: 800~1800±10% RPM<br>
    Tipo de rolamento: Rolamento hidráulico<br>
    ARGB - 5v motherboard<br>
    Tensão: 12v<br>
    Ruído do fan: 23dB(A)<br>
    Fluxo de ar: 68CFM (MAX)<br>
    Pressão estática: 2.5 mmH2O max<br>
    Vida útil do fan: 40000 Horas`,


    descriptionE: `Water Cooler Gamer Rise Mode Aura Ice 120mm ARGB<br>
    TDP: 200W<br>
    Part Number: RM-WAI-01-ARGB<br>
    Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br>
    AMD Socket -FM2+/FM1/AM3+/AM3/AM2/AM4/AM5<br>
    Fan Size: 120x120x25mm<br>
    Fan Speed: 800~1800±10% RPM<br>
    Bearing Type: Hydraulic Bearing<br>
    ARGB - 5v motherboard<br>
    Voltage: 12v<br>
    Fan Noise: 23dB(A)<br>
    Airflow: 68CFM (MAX)<br>
    Static Pressure: 2.5 mmH2O max<br>
    Fan Lifespan: 40000 Hours`,
      
    productId: 'aura-ice-120',
    banner: BannerIce,
    youtubeUrl: '47VDb0Pdwic?si=3-A0fZFyCPeDPw31',
    images: [AuraIcet120_01, AuraIcet120_02, AuraIcet120_03, AuraIcet120_04, AuraIcet120_05, AuraIcet120_06],
    pageURL: 'aura-ice-120',
  },
  {
    id: 12,
    name: 'aura ice white 240mm argb',
    category: 'watercooler',
    descriptionP: `Water Cooler Gamer Rise Mode Aura Ice 240mm ARGB<br>
    TDP: 250W<br>
    Part Number: RM-WAI-02-ARGB<br>
    Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br>
    AMD Socket -FM2+/FM1/AM3+/AM3/AM2/AM4/AM5<br>
    Fan Size: 120x120x25mm (2x)<br>
    Fan Speed: 800~1800±10% RPM<br>
    Bearing Type: Hydraulic Bearing<br>
    ARGB - 5v motherboard<br>
    Voltage: 12v<br>
    Fan Noise: 23dB(A)<br>
    Airflow: 68CFM (MAX)<br>
    Static Pressure: 2.5 mmH2O max<br>
    Fan Lifespan: 40000 Hours`,


    descriptionE: `Water Cooler Gamer Rise Mode Aura Ice 240mm ARGB<br>
    TDP: 250W<br>
    Part Number: RM-WAI-02-ARGB<br>
    Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br>
    AMD Socket -FM2+/FM1/AM3+/AM3/AM2/AM4/AM5<br>
    Fan Size: 120x120x25mm (2x)<br>
    Fan Speed: 800~1800±10% RPM<br>
    Bearing Type: Hydraulic Bearing<br>
    ARGB - 5v motherboard<br>
    Voltage: 12v<br>
    Fan Noise: 23dB(A)<br>
    Airflow: 68CFM (MAX)<br>
    Static Pressure: 2.5 mmH2O max<br>
    Fan Lifespan: 40000 Hours`,
      
    productId: 'aura-ice-240',
    banner: BannerIce,
    youtubeUrl: '47VDb0Pdwic?si=3-A0fZFyCPeDPw31',
    images: [AuraIcet240_01, AuraIcet240_02, AuraIcet240_03, AuraIcet240_04, AuraIcet240_05, AuraIcet240_06],
    pageURL: 'aura-ice-240',
  },
  {
    id: 13,
    name: 'aura ice white 360mm argb',
    category: 'watercooler',
    descriptionP: `
    Water Cooler Gamer Rise Mode Aura Ice 360mm ARGB<br>
    TDP: 300W<br>
    Part Number: RM-WAI-03-ARGB<br>
    Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br>
    AMD Socket -FM2+/FM1/AM3+/AM3/AM2/AM4/AM5<br>
    Fan Size: 120x120x25mm (3x)<br>
    Fan Speed: 800~1800±10% RPM<br>
    Bearing Type: Hydraulic Bearing<br>
    ARGB - 5v motherboard<br>
    Voltage: 12v<br>
    Fan Noise: 23dB(A)<br>
    Airflow: 68CFM (MAX)<br>
    Static Pressure: 2.5 mmH2O max<br>
    Fan Lifespan: 40000 Hours`,


    descriptionE: `Water Cooler Gamer Rise Mode Aura Ice 360mm ARGB<br>
    TDP: 300W<br>
    Part Number: RM-WAI-03-ARGB<br>
    Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br>
    AMD Socket -FM2+/FM1/AM3+/AM3/AM2/AM4/AM5<br>
    Fan Size: 120x120x25mm (3x)<br>
    Fan Speed: 800~1800±10% RPM<br>
    Bearing Type: Hydraulic Bearing<br>
    ARGB - 5v motherboard<br>
    Voltage: 12v<br>
    Fan Noise: 23dB(A)<br>
    Airflow: 68CFM (MAX)<br>
    Static Pressure: 2.5 mmH2O max<br>
    Fan Lifespan: 40000 Hours`,
      
    productId: 'aura-ice-360',
    banner: BannerIce,
    youtubeUrl: '47VDb0Pdwic?si=3-A0fZFyCPeDPw31',
    images: [AuraIcet360_01, AuraIcet360_02, AuraIcet360_03, AuraIcet360_04, AuraIcet360_05, AuraIcet360_06],
    pageURL: 'aura-ice-360',
  },
  {
    id: 14,
    name: 'aura ice black 120mm argb',
    category: 'watercooler',
    descriptionP: `Water Cooler Gamer Rise Mode Aura Ice Black 120mm ARGB<br/>TDP: 200W<br/>Part Number: RM-WAIB-04-ARGB<br/>Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br/>AMD Socket - FM2+/FM1/AM3+/AM3/AM2/AM4/AM5<br/>Tamanho do fan: 120x120x25mm<br/>Velocidade do fan: 800~1800±10% RPM<br/>Tipo de rolamento: Rolamento hidráulico<br/>ARGB - 5v motherboard<br/>Tensão: 12v<br/>Ruído do fan: 23dB(A)<br/>Fluxo de ar: 68CFM (MAX)<br/>Pressão estática: 2.5 mmH2O max<br/>Vida útil do fan: 40000 Horas<br/>`,


    descriptionE: `Water Cooler Gamer Rise Mode Aura Ice Black 120mm ARGB<br/>TDP: 200W<br/>Part Number: RM-WAIB-04-ARGB<br/>Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br/>AMD Socket - FM2+/FM1/AM3+/AM3/AM2/AM4/AM5<br/>Fan Size: 120x120x25mm<br/>Fan Speed: 800~1800±10% RPM<br/>Bearing Type: Hydraulic Bearing<br/>ARGB - 5v motherboard<br/>Voltage: 12v<br/>Fan Noise: 23dB(A)<br/>Airflow: 68CFM (MAX)<br/>Static Pressure: 2.5 mmH2O max<br/>Fan Lifespan: 40000 Hours<br/>`,
      
    productId: 'aura-ice-black-120',
    banner: '',
    youtubeUrl: '',
    images: [AuraIceBlack120_1 ],
    pageURL: 'aura-ice-black-120',
  },
  {
    id: 15,
    name: 'aura ice black 240mm argb',
    category: 'watercooler',
    descriptionP: `
   
Water Cooler Gamer Rise Mode Aura Ice Black 240mm ARGB<br/>TDP: 250W<br/>Part Number: RM-WAIB-05-ARGB<br/>Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br/>AMD Socket - FM2+/FM1/AM3+/AM3/AM2/AM4/AM5<br/>Tamanho do fan: 120x120x25mm (2x)<br/>Velocidade do fan: 800~1800±10% RPM<br/>Tipo de rolamento: Rolamento hidráulico<br/>ARGB - 5v motherboard<br/>Tensão: 12v<br/>Ruído do fan: 23dB(A)<br/>Fluxo de ar: 68CFM (MAX)<br/>Pressão estática: 2.5 mmH2O max<br/>Vida útil do fan: 40000 Horas<br/>`,


    descriptionE: `

    Water Cooler Gamer Rise Mode Aura Ice Black 240mm ARGB<br/>TDP: 250W<br/>Part Number: RM-WAIB-05-ARGB<br/>Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br/>AMD Socket - FM2+/FM1/AM3+/AM3/AM2/AM4/AM5<br/>Fan Size: 120x120x25mm (2x)<br/>Fan Speed: 800~1800±10% RPM<br/>Bearing Type: Hydraulic Bearing<br/>ARGB - 5v motherboard<br/>Voltage: 12v<br/>Fan Noise: 23dB(A)<br/>Airflow: 68CFM (MAX)<br/>Static Pressure: 2.5 mmH2O max<br/>Fan Lifespan: 40000 Hours<br/>`,
      
    productId: 'aura-ice-black-240',
    banner: '',
    youtubeUrl: '',
    images: [AuraIceBlack240_1 ],
    pageURL: 'aura-ice-black-240',
  },
  {
    id: 16,
    name: 'aura ice black 360mm argb',
    category: 'watercooler',
    descriptionP: `
    Water Cooler Gamer Rise Mode Aura Ice Black 360mm ARGB<br/>TDP: 300W<br/>Part Number: RM-WAIB-06-ARGB<br/>Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br/>AMD Socket - FM2+/FM1/AM3+/AM3/AM2/AM4/AM5<br/>Tamanho do fan: 120x120x25mm (3x)<br/>Velocidade do fan: 800~1800±10% RPM<br/>Tipo de rolamento: Rolamento hidráulico<br/>ARGB - 5v motherboard<br/>Tensão: 12v<br/>Ruído do fan: 23dB(A)<br/>Fluxo de ar: 68CFM (MAX)<br/>Pressão estática: 2.5 mmH2O max<br/>Vida útil do fan: 40000 Horas<br/>`,


    descriptionE: `
    Water Cooler Gamer Rise Mode Aura Ice Black 360mm ARGB<br/>TDP: 300W<br/>Part Number: RM-WAIB-06-ARGB<br/>Socket: Intel-LGA1156/1155/1151/1150/1366/2011/1200/1700<br/>AMD Socket - FM2+/FM1/AM3+/AM3/AM2/AM4/AM5<br/>Fan Size: 120x120x25mm (3x)<br/>Fan Speed: 800~1800±10% RPM<br/>Bearing Type: Hydraulic Bearing<br/>ARGB - 5v motherboard<br/>Voltage: 12v<br/>Fan Noise: 23dB(A)<br/>Airflow: 68CFM (MAX)<br/>Static Pressure: 2.5 mmH2O max<br/>Fan Lifespan: 40000 Hours<br/>`,
      
      
    productId: 'aura-ice-black-360',
    banner: '',
    youtubeUrl: '',
    images: [AuraIceBlack360_1 ],
    pageURL: 'aura-ice-black-360',
  },
];

export default products;
