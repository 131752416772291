import Cabosleeved_01 from '../../../img/Produtos/cabos_sleeved/1.jpg';
import Cabosleeved_02 from '../../../img/Produtos/cabos_sleeved/2.jpg';
import Cabosleeved_03 from '../../../img/Produtos/cabos_sleeved/3.jpg';
import Cabosleeved_04 from '../../../img/Produtos/cabos_sleeved/4.jpg';
import Cabosleeved_05 from '../../../img/Produtos/cabos_sleeved/5.jpg';

import CabosleevedGamerRGB_01 from '../../../img/Produtos/cabos_sleeved/cabo-sleeved-gamer-aura-rgb/1.jpg';
import CabosleevedGamerRGB_02 from '../../../img/Produtos/cabos_sleeved/cabo-sleeved-gamer-aura-rgb/2.jpg';
import CabosleevedGamerRGB_Banner from '../../../img/Produtos/cabos_sleeved/cabo-sleeved-gamer-aura-rgb/banner.jpg';

const products = [
  {
    id: 1,
    name: 'CABOS SLEEVED',
    category: 'cabosleeved',
    descriptionP: `Marca: Rise Mode<br>
    Cores: AZUL, BRANCO, BRANCO E VERMELHO, CINZA, PRETO, PRETO E AMARELO, PRETO E AZUL, PRETO E BRANCO, PRETO E VERDE, PRETO E VERMELHO, VERDE, VERMELHO<br>
    Material dos Conectores: BANHADO A OURO<br>
    Quantidade de Extensor: (1 para Placa Mãe) - (1 para CPU 4+4) - (2 para PCI-E 6+2)<br>
    Compatibilidade: ATX<br>
    Dimensões do Produto: 30CM DE COMPRIMENTO<br>
    Dimensões da Caixa: 22CM X 19CM X 5CM<br>
    Peso: 294 GRAMAS (BRUTO SEM EMBALAGEM)<br>
    Peso com Caixa: 371 GRAMAS (BRUTO COM EMBALAGEM)<br>`,

    descriptionE: `Brand: Rise Mode<br>
    Colors: Blue, White, White and Red, Gray, Black, Black and Yellow, Black and Blue, Black and White, Black and Green, Black and Red, Green, Red<br>
    Connector Material: Gold-plated<br>
    Number of Extenders: (1 for Motherboard) - (1 for CPU 4+4) - (2 for PCI-E 6+2)<br>
    Compatibility: ATX<br>
    Product Dimensions: 30cm in length<br>
    Box Dimensions: 22cm x 19cm x 5cm<br>
    Weight: 294 grams (gross weight without packaging)<br>
    Weight with Box: 371 grams (gross weight with packaging)<br>`,
      
    productId: 'cabos-sleeved',
    banner: '',
    youtubeUrl: '',
    images: [Cabosleeved_01, Cabosleeved_02, Cabosleeved_03, Cabosleeved_04, Cabosleeved_05],
    pageURL: 'cabos-sleeved',
  },
  {
    id: 2,
    name: 'Cabo Sleeved Rise Mode Gamer 24p Aura RGB',
    category: 'cabosleeved',
    descriptionP: `Características:<br>
    Marca: Rise Mode<br>
    Modelo: Aura Rgb 24p<br>
    Part Number: RM-SL-RGB<br>
    Tamanho: 240mmx50mm<br>
    Led RGB: 5v 3 pin (5V-D-0-G)<br>
    Informações adicionais:<br>
    
    Fibra óptica brilhante com ótima iluminação<br>
    Conexão rgb endereçável compatível com placas Mãe 5v 3p<br>
    Sincronização com outros componentes rgb<br>
    Cabo padrão AWG18<br>`,

    descriptionE: `Features:<br>
    Brand: Rise Mode<br>
    Model: Aura Rgb 24p<br>
    Part Number: RM-SL-RGB<br>
    Size: 240mmx50mm<br>
    RGB LED: 5v 3 pin (5V-D-0-G)<br>
    Additional Information:<br>
    
    Bright optical fiber with excellent illumination<br>
    Addressable RGB connection compatible with 5v 3-pin Motherboards<br>
    Synchronization with other RGB components<br>
    Standard AWG18 cable<br>`,
      
    productId: 'cabo-sleeved-gamer-rgb',
    banner: CabosleevedGamerRGB_Banner,
    youtubeUrl: '',
    images: [CabosleevedGamerRGB_01, CabosleevedGamerRGB_02],
    pageURL: 'cabo-sleeved-gamer-rgb',
  },
];

export default products;
