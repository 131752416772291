import DryfitRed_01 from '../../../img/Produtos/camiseta/1.jpg';
import DryfitRed_02 from '../../../img/Produtos/camiseta/2.jpg';

import DryfitBlue_01 from '../../../img/Produtos/camiseta/3.jpg';
import DryfitBlue_02 from '../../../img/Produtos/camiseta/4.jpg';

const products = [
  {
    id: 1,
    name: 'Gaming Dryfit RED',
    category: 'suportecontrole',
    descriptionP:`Nome do produto: Camiseta Rise Mode Gaming Dryfit - Vermelha P<br><br>
    CAMISETA RISE GAMING! RISE PENSANDO SEMPRE EM SUA MELHOR APARÊNCIA!<br><br>
    A Rise agora, com camisetas estilo Player, oferece material em tecido Dry fit.<br><br>
    Temos em tamanho P, M e G nas cores Azul Vermelha.<br><br>
    Ótima para locomoção e principalmente para você fazer sua jogada ser ainda melhor!!!<br><br>
    Especificações:<br><br>
    
    Marca: Rise Mode<br><br>
    Modelo: Gaming Dry fit - Vermelha P<br><br>
    Part number: RG-UN-01-RDP<br><br>
    Cor: Vermelha<br><br>
    Tecido: DRY FIT<br><br>
    Tamanho: P<br><br>
    EAN: 7895099202243<br><br>
    Nome do produto: Camiseta Rise Mode Gaming Dryfit - Vermelha M<br><br>
    CAMISETA RISE GAMING! RISE PENSANDO SEMPRE EM SUA MELHOR APARÊNCIA!<br><br>
    A Rise agora, com camisetas estilo Player, oferece material em tecido Dry fit.<br><br>
    Temos em tamanho P, M e G nas cores Azul Vermelha.<br><br>
    Ótima para locomoção e principalmente para você fazer sua jogada ser ainda melhor!!!<br><br>
    Especificações:<br><br>
    
    Marca: Rise Mode<br><br>
    Modelo: Gaming Dry fit - Vermelha M<br><br>
    Part number: RG-UN-01-RDM<br><br>
    Cor: Vermelha<br><br>
    Tecido: DRY FIT<br><br>
    Tamanho: M<br><br>
    EAN: 7895099202236<br><br>
    Nome do produto: Camiseta Rise Mode Gaming Dryfit - Vermelha G<br><br>
    CAMISETA RISE GAMING! RISE PENSANDO SEMPRE EM SUA MELHOR APARÊNCIA!<br><br>
    A Rise agora, com camisetas estilo Player, oferece material em tecido Dry fit.<br><br>
    Temos em tamanho P, M e G nas cores Azul Vermelha.<br><br>
    Ótima para locomoção e principalmente para você fazer sua jogada ser ainda melhor!!!<br><br>
    Especificações:<br><br>
    
    Marca: Rise Mode<br><br>
    Modelo: Gaming Dry fit - Vermelha G<br><br>
    Part number: RG-UN-01-RDG<br><br>
    Cor: Vermelha<br><br>
    Tecido: DRY FIT<br><br>
    Tamanho: G<br><br>
    EAN: 7895099202229<br><br>`,
      
    descriptionE:"",
  
    productId: 'gaming-dryfit-red',
    banner: '',
    youtubeUrl: '',
    images: [DryfitRed_01, DryfitRed_02],
    pageURL: 'gaming-dryfit-red',
  },
  {
    id: 2,
    name: 'Gaming Dryfit RED',
    category: 'suportecontrole',
    descriptionP:`
    Nome do produto: Camiseta Rise Mode Gaming Dryfit - Azul P<br><br>
    CAMISETA RISE GAMING! RISE PENSANDO SEMPRE EM SUA MELHOR APARÊNCIA!<br><br>
    A Rise agora, com camisetas estilo Player, oferece material em tecido Dry fit.<br><br>
    Temos em tamanho P, M e G nas cores Azul Vermelha.<br><br>
    Ótima para locomoção e principalmente para você fazer sua jogada ser ainda melhor!!!<br><br>
    Especificações:<br><br>
    
    Marca: Rise Mode<br><br>
    Modelo: Gaming Dryfit - Azul P<br><br>
    Part number: RG-UN-01-BLP<br><br>
    Cor: AZUL<br><br>
    Tecido: DRY FIT<br><br>
    Tamanho: P<br><br>
    EAN: 7895099202212<br><br>
    Nome do produto: Camiseta Rise Mode Gaming Dryfit - Azul M<br><br>
    CAMISETA RISE GAMING! RISE PENSANDO SEMPRE EM SUA MELHOR APARÊNCIA!<br><br>
    A Rise agora, com camisetas estilo Player, oferece material em tecido Dry fit.<br><br>
    Temos em tamanho P, M e G nas cores Azul Vermelha.<br><br>
    Ótima para locomoção e principalmente para você fazer sua jogada ser ainda melhor!!!<br><br>
    Especificações:<br><br>
    
    Marca: Rise Mode<br><br>
    Modelo: Gaming Dryfit - Azul M<br><br>
    Part number: RG-UN-01-BLM<br><br>
    Cor: AZUL<br><br>
    Tecido: DRY FIT<br><br>
    Tamanho: M<br><br>
    EAN: 7895099202205<br><br>
    Nome do produto: Camiseta Rise Mode Gaming Dryfit - Azul G<br><br>
    CAMISETA RISE GAMING! RISE PENSANDO SEMPRE EM SUA MELHOR APARÊNCIA!<br><br>
    A Rise agora, com camisetas estilo Player, oferece material em tecido Dry fit.<br><br>
    Temos em tamanho P, M e G nas cores Azul Vermelha.<br><br>
    Ótima para locomoção e principalmente para você fazer sua jogada ser ainda melhor!!!<br><br>
    Especificações:<br><br>
    
    Marca: Rise Mode<br><br>
    Modelo: Gaming Dryfit - Azul G<br><br>
    Part number: RG-UN-01-BLG<br><br>
    Cor: AZUL<br><br>
    Tecido: DRY FIT<br><br>
    Tamanho: G<br><br>
    EAN: 7895099202199<br><br>`,
      
    descriptionE:"",
  
    productId: 'gaming-dryfit-blue',
    banner: '',
    youtubeUrl: '',
    images: [DryfitBlue_01, DryfitBlue_02],
    pageURL: 'gaming-dryfit-blue',
  },
];

export default products;
