import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import ImageProduct from './image/product.jpg';
import ImageProduct2 from './image/prduct1.jpg';
import IconDownload from './image/transferir.png';
import Logo from '../../img/logo.png';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Alterado para flex-start */
  padding: 5px;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: none;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const Division = styled.div`
  background: linear-gradient(to right, #ff20cd, #1294ff,#58ffb4,#1294ff,#ff20cd);
	background-size: 400% 400%;
	animation: gradient 6s ease infinite;
	height: 8px;

	@keyframes gradient {
		0% {
			background-position: 0% 50%;
		}
		50% {
			background-position: 100% 50%;
		}
		100% {
			background-position: 0% 50%;
		}
	}
`;

const Card = styled.a`
  max-width: 850px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #181818;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin: 20px;
  text-decoration: none;


  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const CardImage = styled.img`
  width: 150px;
  height: 150px;
  height: auto;
  border-radius: 8px;
  margin: 16px;
`;

const ControlSoftware = styled.div`
  background: none;
  margin-right: 14px;
`;

const NameSoftware = styled.h2`
  background: none;
  color: white;
  font-size: 30px;
  text-align: left;
  margin-bottom: 4px;
`;

const OSSoftware = styled.p`
  background: none;
  text-align: left;
  font-family: 'Inter';
  color: #d7d7d7;
  margin-bottom: 4px;
`;

const VersionSoftware = styled.p`
  background: none;
  text-align: left;
  color: #d7d7d7;
  font-family: 'Inter';
  margin-bottom: 4px;

  @media (max-width: 1000px) {
    margin-bottom: 20px;
  }
`;

const Link = styled.a`
  font-style: none;
  list-style: none;
  text-decoration: none;
`;

const BotaoDownload = styled.button`
  margin: 10px;
  border: none;
  background-color: ${props => (props.disabled ? '#c3c3c3' : 'white')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const DownloadSoftware = styled.div`
  width: 160px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  transition: 0.5ms;
  background: none;

:hover{
  filter: brightness(0.9);
}
`;

const DownloadSoftwareTitle = styled.h2`
  list-style: none;
  background: none;
  color: black;
`;

const DownloadSoftwareImage = styled.img`
  width: 20px;
  height: 20px;
  background: none;
`;

const Topo = styled.div`
  width: 100vw;
  background: #161616;
`;

const ControlTopo = styled.div`
  background: #161616;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
`;

const Imagem = styled.img`
  background: none;
`;

const Title = styled.h1`
  background: none;
  margin-bottom: 1.2rem;
  font-family: 'Bebas Neue', sans-serif;
  background: none;
  font-size: 4rem;
`;

const SubTitle = styled.p`
  background: none;
  margin-bottom: 1.2rem;
  font-family: 'Inter', sans-serif;
  background: none;
  font-size: 1rem;
  color: #c3c3c3;
  text-align: center;
  display: none;
`;

const Pesquisa = styled.input`
  margin-bottom: 0.4rem;
  max-width: 550px;
  width: 100%;
  height: 60px;
  padding: 10px;
  background: #343333;
  border-radius: 4px;
  border: 1px solid #8D8D8D;
  font-family: 'Inter', sans-serif;
  color: white;
  display: none;
`;

const Downloads = () => {
  const [botaoTravado, setBotaoTravado] = useState(false);
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  
  const produtos = [
    {
      id: 1,
      nome: "Aura Ice ARGB Driver Setup 6.0",
      sistemaOperacional: "WIN 10 | WIN 11",
      versao: "6.0",
      imagem: ImageProduct, // Substitua ImageProduct1 pela referência à imagem do produto
    },
  ];

  // Função para atualizar o estado de pesquisa
  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
  };

  // Filtrar produtos com base no texto de pesquisa
  const filteredProdutos = produtos.filter((produto) =>
    produto.nome.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <motion.div
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.3 }}
  >   
      <Division></Division>  
      <Container>
        <Topo>
          <ControlTopo>
            <Imagem src={Logo} alt="Rise Mode" title="Rise Mode"/>
            <Title alt="Downloads">DOWNLOADS</Title>
            <SubTitle alt="Descrição">{t('downloads.sub')}</SubTitle>
            <Pesquisa    
              type="text"
              placeholder="Digite o nome do produto"
              value={searchText}
              onChange={handleSearchInputChange} // Atualize o estado de pesquisa quando o usuário digitar
            />
          </ControlTopo>
        </Topo>
        <CardContainer>

        {filteredProdutos.map((produto) => (
        <Card key={produto.id}>
          <CardImage src={produto.imagem} alt={produto.nome} title="Aura Ice ARGB"/>
          <ControlSoftware>
            <NameSoftware alt="Nome do Software">{produto.nome}</NameSoftware>
            <OSSoftware alt="Sistema OS">{produto.sistemaOperacional}</OSSoftware>
            <VersionSoftware alt="Versão">{produto.versao}</VersionSoftware>
          </ControlSoftware>

              <BotaoDownload
              disabled={botaoTravado}>
                    <Link href="/donwload-software-aura-ice"
                    >
                      <DownloadSoftware alt="Download" title="Download">
                          <DownloadSoftwareTitle alt="Download">DOWNLOAD</DownloadSoftwareTitle>
                          <DownloadSoftwareImage src={IconDownload} alt="Download" title="Download"/>
                      </DownloadSoftware>
                    </Link>
              </BotaoDownload>
        </Card>
      ))}
      </CardContainer>
      </Container>
  </motion.div>
  );
};

export default Downloads;