import A1BlackRed_01 from '../../../img/Produtos/cadeira/a1-black-red/1.jpg';
import A1BlackRed_02 from '../../../img/Produtos/cadeira/a1-black-red/2.jpg';
import A1BlackRed_03 from '../../../img/Produtos/cadeira/a1-black-red/3.jpg';
import A1BlackRed_04 from '../../../img/Produtos/cadeira/a1-black-red/4.jpg';
import A1BlackRed_Banner from '../../../img/Produtos/cadeira/a1-black-red/banner.jpg';

import A1Black_01 from '../../../img/Produtos/cadeira/a1-black/1.jpg';
import A1Black_02 from '../../../img/Produtos/cadeira/a1-black/2.jpg';
import A1Black_03 from '../../../img/Produtos/cadeira/a1-black/3.jpg';
import A1Black_04 from '../../../img/Produtos/cadeira/a1-black/4.jpg';
import A1Black_Banner from '../../../img/Produtos/cadeira/a1-black/banner.jpg';

const products = [
  {
    id: 1,
    name: 'A1 Black and Red',
    category: 'cadeira_gamer',
    descriptionP: `Tamanho: 69 x 75 x 124-132cm<br>
    Material da Superfície: Couro Pvc<br>
    Material de acolchoamento do assento: Espuma Moldada<br>
    Apoio de braço: Apoio de braço 2D<br>
    Mecanismo: mecanismo borboleta<br>
    Pistão a Gás: Pistão a Gás classe 3 80mm<br>
    Base: Base de Metal Alargada<br>
    Rodízio: Rodízio PU<br>
    Massagem: Função de Massagem no Encosto<br>
    Suporte Máximo: 150kg`,

    descriptionE: `Size: 69 x 75 x 124-132cm<br>
    Surface Material: PVC Leather<br>
    Seat Padding Material: Molded Foam<br>
    Armrest: 2D Armrest<br>
    Mechanism: Butterfly Mechanism<br>
    Gas Lift: Class 3 80mm Gas Lift<br>
    Base: Enlarged Metal Base<br>
    Casters: PU Casters<br>
    Massage: Backrest Massage Function<br>
    Maximum Weight Capacity: 150kg`,
      
    productId: 'a1-black-red',
    banner: A1BlackRed_Banner,
    youtubeUrl: '',
    images: [A1BlackRed_01, A1BlackRed_02, A1BlackRed_03, A1BlackRed_04],
    pageURL: 'a1-black-red',
  },
  {
    id: 2,
    name: 'A1 Black',
    category: 'cadeira_gamer',
    descriptionP: `Tamanho: 69 x 75 x 124-132cm<br>
    Material da Superfície: Couro Pvc<br>
    Material de acolchoamento do assento: Espuma Moldada<br>
    Apoio de braço: Apoio de braço 2D<br>
    Mecanismo: mecanismo borboleta<br>
    Pistão a Gás: Pistão a Gás classe 3 80mm<br>
    Base: Base de Metal Alargada<br>
    Rodízio: Rodízio PU<br>
    Massagem: Função de Massagem no Encosto<br>
    Suporte Máximo: 150kg`,

    descriptionE: `Size: 69 x 75 x 124-132cm<br>
    Surface Material: PVC Leather<br>
    Seat Padding Material: Molded Foam<br>
    Armrest: 2D Armrest<br>
    Mechanism: Butterfly Mechanism<br>
    Gas Lift: Class 3 80mm Gas Lift<br>
    Base: Enlarged Metal Base<br>
    Casters: PU Casters<br>
    Massage: Backrest Massage Function<br>
    Maximum Weight Capacity: 150kg`,
      
    productId: 'a1-black',
    banner: A1Black_Banner,
    youtubeUrl: '',
    images: [A1Black_01, A1Black_02, A1Black_03, A1Black_04],
    pageURL: 'a1-black',
  },
];

export default products;
