import React, { useState } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    background: none;
    color: #4491fa;
  }
`;

const Box = styled.div`
  text-align: center;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: none;
  cursor: pointer;

  label {
    width: 400px;
    padding: 1rem;
    font-size: 1rem;
    background: none;
    font-family: 'Inter';
    color: red;
  }

  input {
    background: #4491fa;
    cursor: pointer;
    font-size: 1.2rem;

    @media screen and (max-width: 1000px) {
      font-size: 0.8rem;
  }
  }
`;

const Content = styled.div`
  text-align: center;
  width: 1200px;
  height: 100vh;
  padding: 1rem;
  background: #161616;
  margin: 0 auto;
  border-radius: 0rem 1rem 1rem 0rem;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;

const Button = styled.button`
  background-color: #4491fa;
  color: #fff;
  border: none;
  padding: 15px 60px;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 1rem;
  font-family: 'inter';
  font-weight: 800;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  :hover {
    filter: brightness(0.8);
  }
`;

const UploadForm = () => {
  const [desktopImages, setDesktopImages] = useState([]);
  const [mobileImages, setMobileImages] = useState([]);
  const [message, setMessage] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [inputEmpty, setInputEmpty] = useState(false);
  const [tamanho, setTamanho] = useState(false);

  const handleDesktopFileChange = (event) => {
    setDesktopImages(event.target.files);
  };

  const handleMobileFileChange = (event) => {
    setMobileImages(event.target.files);
  };

  const handleSubmit = async (event) => {
    setButtonDisabled(true);
    event.preventDefault();

    if (desktopImages.length === 0) {
      setInputEmpty(true);
      setButtonDisabled(false);
      return;
    }

    try {
      setInputEmpty(false);
      setMessage('Enviando banner...');

      let uploadFailed = false; // Variável para verificar se houve falha no upload

      // Verificar o tamanho dos arquivos
      for (let i = 0; i < desktopImages.length; i++) {
        const image = desktopImages[i];

        if (image.size > 1024 * 1024) {
          setMessage('');
          setTamanho(true);
          setTimeout(() => {
            setTamanho(false);
          }, 3000);
          uploadFailed = true;
          break;
        }
      }

      if (!uploadFailed) {
        const desktopResponses = await Promise.all(
          Array.from(desktopImages).map(async (image) => {
            const formData = new FormData();
            formData.append('images', image);

            const response = await axios.post('https://api-banners.vercel.app/upload', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            });

            return response.data.imageUrl;
          })
        );
        
          setMessage('Banner enviado com sucesso!');
 
        // Limpar os campos após 3 segundos
        setTimeout(() => {
          setDesktopImages([]);
          setMobileImages([]);
          setMessage('');
          setButtonDisabled(false);
        }, 3000);
      } else {
        setButtonDisabled(true);
        setTimeout(() => {
          setButtonDisabled(false);
        }, 3000);
      }
    } catch (error) {
      console.error(error);
      setMessage('Erro ao enviar o banner.');

      // Limpar a mensagem de erro após 3 segundos
      setTimeout(() => {
        setMessage('');
      }, 3000);
    }
  };

  return (
    <motion.div initial={{ opacity: 0, scale: 0.5 }} animate={{ opacity: 1, scale: 1 }} transition={{ duration: 0.3 }}>
      <Form onSubmit={handleSubmit} enctype="multipart/form-data">
        <Content>
          <h1>BANNERS HOME</h1>
          <Box>
            <label htmlFor="desktopImage1">Antes de enviar os banners <br/> verificar o nome e o numero, exemplo se for trocar o segundo banner: banner_02_desktop e no mobile: banner_02_mobile, serão aceitas apenas imagens JPG abaixo de 1MB! Tamanho Desktop: 1300x670 | Tamanho Mobile:700x700</label>
            <input type="file" id="desktopImage1" name="images" onChange={handleDesktopFileChange} />
          </Box>
          <Button disabled={buttonDisabled} type="submit">Enviar</Button>
          {tamanho && <p className="error-message">A imagem excede 1MB!</p>}
          {inputEmpty && <p className="error-message">Preencha o campo de imagem</p>}
          {message && <p className="success-message">{message}</p>}
        </Content>
      </Form>
    </motion.div>
  );
};


export default UploadForm;
