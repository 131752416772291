import React from 'react';
import styled from 'styled-components';
import imagem1 from '../../img/BANNER MOUSE.png';
import imagem2 from '../../img/BANNER TECLADO.png';
import Banner from '../../img/location.mp4';
import { useTranslation } from 'react-i18next';

const Video = styled.video`
  width: 100%;
  max-width: 700px;
  pointer-events: none; /* Impede a interação com o vídeo */
  display: block; 
  margin: 0 auto;
  border-radius: 6px;   
`;  

const Local = styled.div`
  padding: 1rem;
`;

const TitleLocal = styled.h1`
  text-align: center;
  font-size: 2rem;
  font-family: 'Bebas Neue';
  margin-top: 1rem;
  margin-bottom: 4px;
  background: none;
  color: white;
`;


const Endereco = styled.h2`
  text-align: center;
  font-size: 1.4rem;
  font-family: 'Bebas Neue';
  margin-top: 1rem;
  background: none;
  color: #c3c3c3;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
`;

const Title = styled.h1`
  text-align: center;
  font-size: 1.8rem;
  font-family: 'Bebas Neue';
  margin-top: 1.5rem;
`;

const ImageWrapper = styled.a`
  display: flex;
  align-items: center;
  width: 50%;
  padding: 5px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  text-decoration: none;

  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.1);
  }
`;

const ContentWrapper = styled.div`
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  background: none;
  margin-left: 1rem;
`;

const Titulo = styled.h1`
  background: none;
  font-size: 3rem;
  @media screen and (max-width: 1440px) {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 1024px) {
    font-size: 2rem;
  }
`;

const Text = styled.p`
  width: 360px;
  font-family: 'Inter';
  color: #c3c3c3;
  margin-bottom: 20px;
  font-size: 1rem;
  background: none;

  @media screen and (max-width: 1440px) {
    font-size: 1rem;
    width: 320px;
    font-weight: 800;
  }

  @media screen and (max-width: 1000px) {
    font-size: 0.6rem;
    width: 130px;
    font-weight: 800;
  }

  @media screen and (max-width: 1024px) {
    width: 200px;
  }
`;

const Button = styled.button`
  background-color: #4491fa;
  color: #fff;
  border: none;
  padding: 15px 40px;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: 500ms;
  font-weight: 500;

  :hover {
    filter: brightness(0.8);
  }

  @media screen and (max-width: 900px) {
    padding: 10px 15px;
    font-size: 1rem;
  }
`;

const ImageGrid = () => {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <ImageWrapper href="/watercooler" rel="noopener noreferrer">
          <Image src={imagem1} alt="Water Cooler" title="Water Cooler"/>
          <ContentWrapper>
            <Titulo alt="Titulo">{t('card.carrosel.1')}</Titulo>
            <Text alt="Desc">{t('finalBanner.mouse.description')}</Text>
            <Button alt="Ver mais!" title="Ver mais!">{t('button.banner.p')}</Button>
          </ContentWrapper>
        </ImageWrapper>
        <ImageWrapper href="/teclado-mouse" rel="noopener noreferrer">
          <Image src={imagem2} alt="Teclado Gamer" title="Teclado Gamer"/>
          <ContentWrapper>
            <Titulo alt="Titulo">{t('card.carrosel.6T')}</Titulo>
            <Text alt="Desc">{t('finalBanner.teclado.description')}</Text>
            <Button alt="Ver mais!" title="Ver mais!">{t('button.banner.p')}</Button>
          </ContentWrapper>
        </ImageWrapper>
      </Container>
      <Local>
        <Video src={Banner} autoPlay loop muted alt="Localização" title="Localização"/>
        <Endereco alt="Localização" title="Localização" >230 Sunport Ln, unit 500. Orlando. Fl. 32809 - United States</Endereco>
      </Local>
    </>
  );
};

export default ImageGrid;
