import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Menu = styled.header`
  position: relative;
  padding: 0%;
  margin: 6px auto;
  max-width: 1380px;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  z-index: 9999;

  .menu-icon {
  display: none;
}



  @media screen and (max-width: 900px) {
    .menu-icon {
      display: block;
      margin-left: auto; /* Alinha à direita */
      cursor: pointer;
    }

    nav {
      display: flex;
      align-items: center; /* Centraliza verticalmente */
    }

    nav ul {
      display: none;
    }

    nav.open ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.9);
      z-index: 2000;
    }

    .close-icon {
      position: absolute;
      top: 1rem;
      right: 1rem;
      color: white;
      font-size: 1.2rem;
      cursor: pointer;
      z-index: 9999;
    }

    li {
      margin-top: 2rem;
      text-align: center;
    }
  }

  img {
    display: inline;
    width: 70px;
    height: 70px;
    margin-right: 1rem;
  }

  #imgButton:after {
    content: '';
    position: absolute;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #4491FA;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  #produto {
    border: none;
    background: none;
    font-size: 1.2rem;
    cursor: pointer;
    font-family: 'Bebas Neue', sans-serif;
  }

  #produto:after {
    content: '';
    position: absolute;
    width: 70px;
    transform: scaleX(0);
    height: 2px;
    bottom: 20px;
    left: 260px;
    background-color: #4491FA;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  #produto:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    margin-top: 1.5rem;
    display: inline-block;
    padding-left: 3rem;
    text-align: center;
    background: none;
  }

  a.active {
  color: #4491FA;
  /* Outros estilos que você desejar aplicar ao item ativo */
}

  a {
    display: inline-block;
    position: relative;
    color: white;
    text-decoration: none;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 1.2rem;
    background: none;
  }

  @media screen and (max-width: 900px) {

    li{
      margin: 4px;
      right: 25px;
    }

    #menumb{
      margin: 4px;
      right: 25px;
    }
    }
  
  a:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #4491FA;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  a:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`;

export const CloseIcon = styled(FontAwesomeIcon)`
  position: absolute;

  cursor: pointer;
  z-index: 10000;
  pointer-events: auto;
`;
