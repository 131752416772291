import React from 'react';
import { motion } from 'framer-motion'; 
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Banner1 from '../../img/bannersobre.mp4';
import Banner2 from '../../img/location.mp4';
import Produtos from '../../img/sobre.mp4';

const Video1 = styled.video`
  width: 100%;
  max-width: 1800px;
  pointer-events: none; /* Impede a interação com o vídeo */
`;  


const Video2 = styled.video`
  width: 100%;
  max-width: 700px;
  pointer-events: none; /* Impede a interação com o vídeo */
  display: block;
  margin: 0 auto;
  border-radius: 6px;
`;  

const Video3 = styled.video`
  width: 100%;
  max-width: 1920px;
  pointer-events: none; /* Impede a interação com o vídeo */
  display: block;
  margin: 0 auto;
`;  

const Local = styled.div`
  padding: 1rem;
`;

const TitleLocal = styled.h1`
  text-align: center;
  font-size: 2rem;
  font-family: 'Bebas Neue';
  margin-top: 4px;
  margin-bottom: 4px;
  background: none;
  color: white;
`;


const Endereco = styled.h2`
  text-align: center;
  font-size: 1.4rem;
  font-family: 'Bebas Neue';
  margin-top: 1rem;
  background: none;
  color: #c3c3c3;
`;

const Division = styled.div`
  background: linear-gradient(to right, #ff20cd, #1294ff,#58ffb4,#1294ff,#ff20cd);
	background-size: 400% 400%;
	animation: gradient 6s ease infinite;
	height: 8px;

	@keyframes gradient {
		0% {
			background-position: 0% 50%;
		}
		50% {
			background-position: 100% 50%;
		}
		100% {
			background-position: 0% 50%;
		}
	}
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem;
`;

const Box1 = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  text-align: center;

  h1{
    background: none;
    font-size: 5rem;
    color: #4491fa;
    height: 80px;
    margin-bottom: 8px;

    @media screen and (max-width: 1000px) {
    font-size: 3.5rem;
    margin-bottom: 2px;
    }
  }

  h2{
    font-size: 1.4rem;
    background: none;
    font-family: 'Inter';
    margin-bottom: 1rem;
    font-weight: 700;
  }

  p{
    width: 100%;
    max-width: 900px;
    background: none;
    font-family: 'Inter';
    color: #c3c3c3;
    font-size: 1.2rem;
  }
  
  img{
    padding: 1rem;
    width: 100%;
    max-width: 1900px;

    @media screen and (max-width: 1000px) {
    width: 600px;
    }
    }
`;


function Sobre() {
  const { t, i18n } = useTranslation();

  return (
    <motion.div
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.3 }}
    >
    <Division></Division>   
    <Container>
      <Video1 src={Banner1} autoPlay loop muted alt="Welcome" title="Welcome"/>
      <Box1>
        <h1 alt="Sobre Rise">{t('sobre.title')}</h1>
        <h2 alt="Rise Your Life">Rise your life</h2>
        <p alt="Descrição">{t('sobre.desc.box1')}</p>
        <Video3 src={Produtos} autoPlay loop muted alt="Produtos" title="Produtos"/>
      </Box1>
    </Container>
       <Local>
        <Video2 src={Banner2} autoPlay loop muted alt="Localização" title="Localização"/>
        <Endereco alt="Localização" title="Localização">230 Sunport Ln, unit 500. Orlando. Fl. 32809 - United States</Endereco>
      </Local>
     </motion.div>
  );
}

export default Sobre;
