import Microsd64 from '../../../img/Produtos/microsd/64.jpg';
import Microsd128 from '../../../img/Produtos/microsd/128.jpg';
import Microsd256 from '../../../img/Produtos/microsd/256.jpg';


const products = [
  {
    id: 1,
    name: 'MICRO SD CARD 64GB',
    category: 'microsd',
    descriptionP: `Especificações: <br>

    - Capacidade: 64GB <br>
    
    - Velocidade de leitura de até 90 MB/s <br>
    
    - Velocidade de gravação de até 30 MB/s <br>
    
    - Velocidade de Vídeo: U3 <br>
    
    - Formato: Micro SD + Adaptador SD <br>`,

    descriptionE: ``,
      
    productId: 'microsd-64gb',
    banner: '',
    youtubeUrl: '',
    images: [Microsd64],
    pageURL: 'microsd-64gb',
  },
  {
    id: 2,
    name: 'MICRO SD CARD 128GB',
    category: 'microsd',
    descriptionP: `Especificações: <br>

    - Capacidade: 128GB <br>
    
    - Velocidade de leitura de até 90 MB/s <br>
    
    - Velocidade de gravação de até 30 MB/s <br>
    
    - Velocidade de Vídeo: U3 <br>
    
    - Formato: Micro SD + Adaptador SD <br>`,

    descriptionE: ``,
      
    productId: 'microsd-128gb',
    banner: '',
    youtubeUrl: '',
    images: [Microsd128],
    pageURL: 'microsd-128gb',
  },
  {
    id: 3,
    name: 'MICRO SD CARD 256GB',
    category: 'microsd',
    descriptionP: `Especificações: <br>

    - Capacidade: 256GB <br>
    
    - Velocidade de leitura de até 90 MB/s <br>
    
    - Velocidade de gravação de até 30 MB/s <br>
    
    - Velocidade de Vídeo: U3 <br>
    
    - Formato: Micro SD + Adaptador SD <br>`,

    descriptionE: ``,
      
    productId: 'microsd-256gb',
    banner: '',
    youtubeUrl: '',
    images: [Microsd256],
    pageURL: 'microsd-256gb',
  },
];

export default products;
