import React from 'react';
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Banners from "./components/Banners/Banners";
import Contato from "./components/Contato/Contato";
import Ticketrma from "./components/Ticketrma/Ticketrma";
import Revenda from "./components/Revenda/Revenda";
import Produtos from "./components/Produtos/Produtos";
import Ondecomprar from './components/Ondecomprar/Ondecomprar';
import Suporteheadset from './components/Produtos/Suporteheadset/Suporteheadset';
import Memoria from './components/Produtos/Memoria/memoria';
import MemoriaProduct from './components/Produtos/Memoria/ProductPage';
import Fan from './components/Produtos/Fan/Fan';
import Watercooler from './components/Produtos/Watercooler/Watercooler';
import Suportevga from './components/Produtos/Suportevga/Suportevga';
import Coverpsu from './components/Produtos/Coverpsu/Coverpsu';
import Cadeiragamer from './components/Produtos/Cadeiragamer/Cadeiragamer';
import Mousepad from './components/Produtos/Mousepad/Mousepad';
import Gabinete from './components/Produtos/Gabinete/Gabinete';
import Cooler from './components/Produtos/Cooler/Cooler';
import Fonte from './components/Produtos/Fonte/Fonte';
import Teclado from './components/Produtos/Teclado/Teclado';
import Ssdm2 from './components/Produtos/Ssdm2/Ssdm2';
import Ssd from './components/Produtos/Ssd/Ssd'
import Pcie from './components/Produtos/Pcie/Pcie';
import Cabosleeved from './components/Produtos/Cabosleeved/Cabosleeved';
import Microsd from './components/Produtos/Microsd/Microsd';
import Camiseta from './components/Produtos/Camiseta/Camiseta';
import Pastatermica from './components/Produtos/Pastatermica/Pastatermica';
import Suportecontrole from './components/Produtos/Suportecontrole/Suportecontrole';
import Suportenotebook from './components/Produtos/Suportenotebook/Suportenotebook';
import CaixadeSom from './components/Produtos/CaixaSom/CaixaSom.js';
import DinamicPage from './components/Produtos/DinamicPage/DinamicPage';
import ProductComp from './components/Produtos/Memoria/ProductComp';
import Admin from "./components/Addproduct/Admin";
import Sobre from "./components/Sobre/Sobre";
import PageSoftware from "./components/Software/PageSoftware.js";
import Downloads from "./components/Software/Downloads.js";


//Suporte Headset
import VenonV3 from './components/Produtos/Suporteheadset/products/VenonV3';
import Alien from './components/Produtos/Suporteheadset/products/Alien';
import Z from './components/Produtos/Suporteheadset/products/Z';
import VenonRGB from './components/Produtos/Suporteheadset/products/VenonRGB';

//fan
import FanEnergyArgb from './components/Produtos/Fan/products/FanEnergyArgb';
import FanLaserArgb from './components/Produtos/Fan/products/FanLaserArgb';
import FanBrancoRGB from './components/Produtos/Fan/products/FanBrancoRGB';
import AuraProBlack from './components/Produtos/Fan/products/AuraProBlack.js';
import CoolMotherboard from './components/Produtos/Fan/products/CoolMotherboard.js';
import FanAuraRGB from './components/Produtos/Fan/products/FanAuraRGB';
import TurbineBlack from './components/Produtos/Fan/products/TurbineBlack.js';
import Black80mm from './components/Produtos/Fan/products/Black80mm.js';
import BlackSLed from './components/Produtos/Fan/products/BlackSLed';
import GalaxyLed from './components/Produtos/Fan/products/GalaxyLed';
import WindLed from './components/Produtos/Fan/products/WindLed';
import WindRgbRainbow from './components/Produtos/Fan/products/WindRgbRainbow';
import GalaxyRgbMotherboard5v120 from './components/Produtos/Fan/products/GalaxyRgbMotherboard5v120.js';
import GalaxyRgbMotherboard12v120 from './components/Produtos/Fan/products/GalaxyRgbMotherboard12v120.js';
import GalaxyRgbRainbow from './components/Produtos/Fan/products/GalaxyRgbRainbow';
import ForceRgb from './components/Produtos/Fan/products/ForceRgb';
import HexMotherboardArgb from './components/Produtos/Fan/products/HexMotherboardArgb';
import VibeRgbMotherboard from './components/Produtos/Fan/products/VibeRgbMotherboard';
import AquaRgbMotherboard from './components/Produtos/Fan/products/AquaRgbMotherboard';
import GalaxyMotherBoardRgb from './components/Produtos/Fan/products/GalaxyMotherBoardRgb';
import GalaxyMotherboard140 from './components/Produtos/Fan/products/GalaxyMotherboard140';
import FanBrancoMotherboard5v from './components/Produtos/Fan/products/FanBrancoMotherboard5v';
import FanBrancoMotherboard12v from './components/Produtos/Fan/products/FanBrancoMotherboard12v';
import AquaMotherboard5v from './components/Produtos/Fan/products/AquaMotherboard5v';
import AquaMotherboard12v from './components/Produtos/Fan/products/AquaMotherboard12v';
import FanSmartRgb from './components/Produtos/Fan/products/FanSmartRgb';
import FrostProArgb from './components/Produtos/Fan/products/FrostProArgb';
import CrystalSoundArgb from './components/Produtos/Fan/products/CrystalSoundArgb';
import MagnetoBlackArgb from './components/Produtos/Fan/products/MagnetoBlackArgb';
import MagnetoWhiteArgb from './components/Produtos/Fan/products/MagnetoWhiteArgb';
import FanSmartRGB4P12V from './components/Produtos/Fan/products/SmartRGB4p12v.js';
import Aquamotherboardargb5v120 from './components/Produtos/Fan/products/AquaMotherboardARGB5V120.js';

//Gabinete
import X5GlassBlack from './components/Produtos/Gabinete/products/X5GlassBlack';
import X4GlassBlack from './components/Produtos/Gabinete/products/X4GlassBlack';
import GalaxyGlassSoundB from './components/Produtos/Gabinete/products/GalaxyGlassSoundB';
import GalaxyGlassSoundW from './components/Produtos/Gabinete/products/GalaxyGlassSoundW';
import GalaxyGlassSoundP from './components/Produtos/Gabinete/products/GalaxyGlassSoundP';
import GalaxyGlassBlack from './components/Produtos/Gabinete/products/GalaxyGlassBlack';
import GalaxyGlassWhite from './components/Produtos/Gabinete/products/GalaxyGlassWhite';
import GalaxyGlassPink from './components/Produtos/Gabinete/products/GalaxyGlassPink';
import GalaxyGlassStandardB from './components/Produtos/Gabinete/products/GalaxyGlassStandardB';
import GalaxyGlassStandardW from './components/Produtos/Gabinete/products/GalaxyGlassStandardW';
import GalaxyGlassMBlack from './components/Produtos/Gabinete/products/GalaxyGlassMBlack';
import GalaxyGlassMwhite from './components/Produtos/Gabinete/products/GalaxyGlassMwhite';
import Glass06x from './components/Produtos/Gabinete/products/Glass06x';
import Glass06xBlack from './components/Produtos/Gabinete/products/Glass06xBlack';
import Z01 from './components/Produtos/Gabinete/products/Z01';
import Z02 from './components/Produtos/Gabinete/products/Z02';
import Z03 from './components/Produtos/Gabinete/products/Z03';
import Glass06Rgb from './components/Produtos/Gabinete/products/Glass06Rgb';
import Glass06Rgb6fans from './components/Produtos/Gabinete/products/Glass06Rgb6fans';
import Glass06semfans from './components/Produtos/Gabinete/products/Glass06semfans';
import Office01 from './components/Produtos/Gabinete/products/office01';
import Gabinete22 from './components/Produtos/Gabinete/products/22.js';
import Gabinete23 from './components/Produtos/Gabinete/products/23.js';
import Gabinete24 from './components/Produtos/Gabinete/products/24.js';
import Gabinete25 from './components/Produtos/Gabinete/products/25.js';
import Gabinete26 from './components/Produtos/Gabinete/products/26.js';
import Gabinete27 from './components/Produtos/Gabinete/products/27.js';

//MemoriaRAm
import RiseModeZ from './components/Produtos/Memoria/products/RiseModeZ';
import RiseModeZDDR5 from './components/Produtos/Memoria/products/RiseModeZDDR5.js';
import DiamondSeries from './components/Produtos/Memoria/products/DiamondSeries';
import DiamondSeriesRGB from './components/Produtos/Memoria/products/DiamondSeriesRGB';
import MemoriaVenonRgb from './components/Produtos/Memoria/products/VenonRgb';
import Value from './components/Produtos/Memoria/products/Value';
import ValueNotebook from './components/Produtos/Memoria/products/ValueNotebook';

 //Water Cooler
 import Frost120MM from './components/Produtos/Watercooler/products/Frost120MM';
 import Frost240mm from './components/Produtos/Watercooler/products/Frost240mm';
 import Frost360MM from './components/Produtos/Watercooler/products/Frost360MM';
 import Black120MM from './components/Produtos/Watercooler/products/Black120MM';
 import Black240mmRGB from './components/Produtos/Watercooler/products/Black240mmRGB'; 
 import Black120mmARGB from './components/Produtos/Watercooler/products/Black120mmARGB'; 
 import Black240mmARGB from './components/Produtos/Watercooler/products/Black240mmARGB'; 
 import Black360mmARGB from './components/Produtos/Watercooler/products/Black360mmARGB.js'; 
 import Black360mmRGB from './components/Produtos/Watercooler/products/Black360mmRGB';  
 import AuraFrost120 from './components/Produtos/Watercooler/products/AuraFrost120';
 import AuraFrost240 from './components/Produtos/Watercooler/products/AuraFrost240';
 import AuraFrost360 from './components/Produtos/Watercooler/products/AuraFrost360';
 import AuraIce120 from './components/Produtos/Watercooler/products/AuraIce120';
 import AuraIce240 from './components/Produtos/Watercooler/products/AuraIce240';
 import AuraIce360 from './components/Produtos/Watercooler/products/AuraIce360';
 import AuraIceBlack120 from './components/Produtos/Watercooler/products/AuraIce120 Black.js';
 import AuraIceBlack240 from './components/Produtos/Watercooler/products/AuraIce240 Black.js';
 import AuraIceBlack360 from './components/Produtos/Watercooler/products/AuraIce360 Black.js';
 

//Cadeira Gamer
import A1BlackRed from './components/Produtos/Cadeiragamer/products/A1BlackRed';
import A1Black from './components/Produtos/Cadeiragamer/products/A1Black';

//Suporte VGA
import Lv1 from './components/Produtos/Suportevga/products/Lv1';
import Lv2 from './components/Produtos/Suportevga/products/Lv2';
import Lv3 from './components/Produtos/Suportevga/products/Lv3';

//Cooler
import WinterBlackAR from './components/Produtos/Cooler/products/WinterBlackAR';
import G800RGB from './components/Produtos/Cooler/products/G800RGB';
import G700 from './components/Produtos/Cooler/products/G700';
import Z4White from './components/Produtos/Cooler/products/Z4White';
import X1Cooler from './components/Produtos/Cooler/products/X1Cooler';
import X2Cooler from './components/Produtos/Cooler/products/X2Cooler';
import X3Cooler from './components/Produtos/Cooler/products/X3Cooler';
import X4Cooler from './components/Produtos/Cooler/products/X4Cooler';
import X4RGBCooler from './components/Produtos/Cooler/products/X4RGBCooler';
import X5BlueLedCooler from './components/Produtos/Cooler/products/X5BlueLedCooler';
import X5WhiteCooler from './components/Produtos/Cooler/products/X5WhiteCooler';
import X5RGB from './components/Produtos/Cooler/products/X5RGB.js';
import Z4RGB from './components/Produtos/Cooler/products/Z4RGB';
import G200 from './components/Produtos/Cooler/products/G200';
import Z1Cooler from './components/Produtos/Cooler/products/Z1Cooler';
import Z2Cooler from './components/Produtos/Cooler/products/Z2Cooler';
import Z3Cooler from './components/Produtos/Cooler/products/Z3Cooler';
import Z5Cooler from './components/Produtos/Cooler/products/Z5RGB';


//Mousepad
import NaoFume from './components/Produtos/Mousepad/products/NaoFumeRam';
import GalaxyRGBEX from './components/Produtos/Mousepad/products/GalaxyRGBEX';
import GamingEsqueleto from './components/Produtos/Mousepad/products/GamingEsqueleto';
import GamingNinja from './components/Produtos/Mousepad/products/Gamingninja';
import GamingPlanetas from './components/Produtos/Mousepad/products/GamingPlanetas';
import GamingFloresta from './components/Produtos/Mousepad/products/GamingFloresta';
import GamingNeon from './components/Produtos/Mousepad/products/GamingNeon';
import GamingAk47 from './components/Produtos/Mousepad/products/GamingAk47';
import GamingBlackMode from './components/Produtos/Mousepad/products/GamingBlackMode';
import GamingHacker from './components/Produtos/Mousepad/products/GamingHacker';
import GamingScorpionB from './components/Produtos/Mousepad/products/GamingScorpionB';
import GamingScorpionR from './components/Produtos/Mousepad/products/GamingScorpionR';
import GamingDesert from './components/Produtos/Mousepad/products/GamingDesert';
import GamingAkRed from './components/Produtos/Mousepad/products/GamingAkRed';
import GamingSniper from './components/Produtos/Mousepad/products/GamingSniper';
import GamingSniperG from './components/Produtos/Mousepad/products/GamingSniperG';
import GamingSnake from './components/Produtos/Mousepad/products/GamingSnake';

//Fonte
import Atomic400 from './components/Produtos/Fonte/products/Atomic400';
import Atomic500 from './components/Produtos/Fonte/products/Atomic500';
import Atomic600 from './components/Produtos/Fonte/products/Atomic600';
import Zeus500 from './components/Produtos/Fonte/products/Zeus500';
import Zeus650 from './components/Produtos/Fonte/products/Zeus650';
import Zeus850 from './components/Produtos/Fonte/products/Zeus850';

//Teclado
import G1BlackWhite from './components/Produtos/Teclado/products/G1BlackWhite';
import G1Black from './components/Produtos/Teclado/products/G1Black';
import G1White from './components/Produtos/Teclado/products/G1White';
import TOffice01 from './components/Produtos/Teclado/products/Office-01.js';
import TOffice02 from './components/Produtos/Teclado/products/Office-02.js';

//Cabo Sleeved
import CaboSleeved from './components/Produtos/Cabosleeved/products/CaboSleveed';
import CaboSleevedGamerRGB from './components/Produtos/Cabosleeved/products/CaboSleveedGamerRGB';

//Mouse


//Coverpsu
import Coverpsu1 from './components/Produtos/Coverpsu/products/1';
import Coverpsu2 from './components/Produtos/Coverpsu/products/2';
import Coverpsu3 from './components/Produtos/Coverpsu/products/3';
import Coverpsu4 from './components/Produtos/Coverpsu/products/4';
import Coverpsu5 from './components/Produtos/Coverpsu/products/5';
import Coverpsu6 from './components/Produtos/Coverpsu/products/6';
import Coverpsu7 from './components/Produtos/Coverpsu/products/7';
import Coverpsu8 from './components/Produtos/Coverpsu/products/8';
import Coverpsu9 from './components/Produtos/Coverpsu/products/9';
import Coverpsu10 from './components/Produtos/Coverpsu/products/10';
import Coverpsu11 from './components/Produtos/Coverpsu/products/11';
import Coverpsu12 from './components/Produtos/Coverpsu/products/12';
import Coverpsu13 from './components/Produtos/Coverpsu/products/13';
import Coverpsu14 from './components/Produtos/Coverpsu/products/14';

//Pcie
import PcieExpress from './components/Produtos/Pcie/products/1';

//Ssd m2
import Ssdm2Diamond from './components/Produtos/Ssdm2/products/1';

//Ssd 
import Ssd120gb from './components/Produtos/Ssd/products/1';
import Ssd240gb from './components/Produtos/Ssd/products/2';
import Ssd480gb from './components/Produtos/Ssd/products/3';
import Ssd960gb from './components/Produtos/Ssd/products/4';

//Microsd
import Microsd64 from './components/Produtos/Microsd/products/1';
import Microsd128 from './components/Produtos/Microsd/products/2';
import Microsd256 from './components/Produtos/Microsd/products/3';

//Pasta Termica
import SilverCold from './components/Produtos/Pastatermica/products/1';
import SilverFrost from './components/Produtos/Pastatermica/products/2';
import Cold10W from './components/Produtos/Pastatermica/products/3.js';
import Cold17W from './components/Produtos/Pastatermica/products/4.js';

//Suporte Controle
import SuporteControleVenonv3 from './components/Produtos/Suportecontrole/products/VenonRGB';
import SuporteControleAlien from './components/Produtos/Suportecontrole/products/Alien';

//Camiseta
import GamingRedDryfit from './components/Produtos/Camiseta/products/1';
import GamingBlueDryfit from './components/Produtos/Camiseta/products/2';

//Base Notebook
import BaseNotebookCooler from './components/Produtos/Suportenotebook/products/1';

//Caixa de Som
import SomAuraS1 from './components/Produtos/CaixaSom/products/1.js';
import SomAuraS4 from './components/Produtos/CaixaSom/products/2.js';
import SomAuraS5 from './components/Produtos/CaixaSom/products/3.js';
import SomAuraS6 from './components/Produtos/CaixaSom/products/4.js';


import { Global } from "./Global";
import "./Style.css";
import Footer from "./components/Footer/Footer";
import { AnimatePresence } from "framer-motion";
import { useParams } from 'react-router-dom';




export default function App() {

  return (
    <div>
       <Header />
    <Global>
      <AnimatePresence mode="wait">
      <Routes>
            <Route path="/" element={<Banners />} />
            <Route path="/produtos" element={<Produtos />} />
            <Route path="/contato" element={<Contato />} />
            <Route path="/ticketrma" element={<Ticketrma />} />
            <Route path="/revenda" element={<Revenda />} />
            <Route path="/ondecomprar" element={<Ondecomprar />} />
            //Route path="/suporteheadset" 
            <Route path="/productPage/:productPage" element={<ProductComp />} />
            <Route path="/:productId" element={<MemoriaProduct />} />
            <Route path="/:pageURL" element={<DinamicPage />}/>
            <Route path="/memoria" element={<Memoria />} />
            <Route path="/fan" element={<Fan />} />
            <Route path="/watercooler" element={<Watercooler />} />
            <Route path="/cadeiragamer" element={<Cadeiragamer />} />
            <Route path="/suportevga" element={<Suportevga />} />
            //Route path="/coverpsu"
            <Route path="/mousepad" element={<Mousepad />} />
            <Route path="/gabinete" element={<Gabinete />} />
            <Route path="/aircooler" element={<Cooler />} />
            <Route path="/fonte" element={<Fonte />} />
            <Route path="/teclado-mouse" element={<Teclado />} />
            <Route path="/pcie" element={<Pcie />} />
            <Route path="/ssdm2" element={<Ssdm2 />} />
            <Route path="/ssd" element={<Ssd />} />
            <Route path="/microsd" element={<Microsd />} />
            <Route path="/pastatermica" element={<Pastatermica />} />
            <Route path="/cabosleeved" element={<Cabosleeved />} />
            //Route path="/camiseta"
            //Route path="/suportecontrole"
            <Route path="/suportenotebook" element={<Suportenotebook />} />
            <Route path="/caixadesom" element={<CaixadeSom />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/sobre" element={<Sobre />} />
            <Route path="/donwload-software-aura-ice" element={<PageSoftware />} />
            <Route path="/downloads" element={<Downloads />} />

            //ROTAS DOS PRODUTOS

            //Suporte Headeset  
            <Route path="/venonv3" element={<VenonV3 />} /> 
            <Route path="/alien" element={<Alien />} />
            <Route path="/suporte-headset-z" element={<Z />} /> 
            <Route path="/venonrgb" element={<VenonRGB />} /> 

            //FAN
            <Route path="/kit-fan-energy-argb" element={<FanEnergyArgb  />} /> 
            <Route path="/kit-fan-laser-argb" element={<FanLaserArgb  />} /> 
            <Route path="kit-fan-branco-rgb" element={<FanBrancoRGB  />} />
            <Route path="turbine-black-120mm" element={<TurbineBlack />} />
            <Route path="kit-fan-aura-rgb" element={<FanAuraRGB  />} />
            <Route path="cool-motherboard-120mm-RGB-5v" element={<CoolMotherboard  />} />
            <Route path="fan-black-80mm" element={<Black80mm  />} />
            <Route path="black-s-led" element={<BlackSLed  />} />
            <Route path="galaxy-led" element={<GalaxyLed  />} />
            <Route path="aura-pro-black" element={<AuraProBlack  />} />
            <Route path="wind-led" element={<WindLed />} />
            <Route path="wind-rgb-rainbow" element={<WindRgbRainbow />} />
            <Route path="galaxy-motherboard-120-5v" element={<GalaxyRgbMotherboard5v120 />} />
            <Route path="galaxy-motherboard-120-12v" element={<GalaxyRgbMotherboard12v120 />} />
            <Route path="galaxy-rgb-rainbow" element={<GalaxyRgbRainbow />} />
            <Route path="force-rgb" element={<ForceRgb />} />
            <Route path="hex-motherboard-argb" element={<HexMotherboardArgb />} />
            <Route path="vibe-rgb-motherboard" element={<VibeRgbMotherboard />} />
            <Route path="aqua-rgb-motherboard" element={<AquaRgbMotherboard />} />
            <Route path="galaxy-motherboard-140-12v" element={<GalaxyMotherBoardRgb />} />
            <Route path="galaxy-motherboard-140-5v" element={<GalaxyMotherboard140/>} />
            <Route path="fan-branco-motherboard-5v" element={<FanBrancoMotherboard5v />} />
            <Route path="fan-branco-motherboard-12v" element={<FanBrancoMotherboard12v />} />
            <Route path="aqua-motherboard-5v" element={<AquaMotherboard5v />} />
            <Route path="aqua-motherboard-12v" element={<AquaMotherboard12v />} />
            <Route path="fan-smart-rgb" element={<FanSmartRgb />} />
            <Route path="frost-pro-argb" element={<FrostProArgb />} />
            <Route path="crystal-sound-argb" element={<CrystalSoundArgb />} />
            <Route path="magneto-black-argb" element={<MagnetoBlackArgb />} />
            <Route path="magneto-white-argb" element={<MagnetoWhiteArgb />} />
            <Route path="smart-individual-12v" element={<FanSmartRGB4P12V />} />
            <Route path="aqua-motherboard-120-5v" element={<Aquamotherboardargb5v120 />} />

            //GABINETE
            <Route path="x5-glass-black" element={<X5GlassBlack />} />
            <Route path="x4-glass-black" element={<X4GlassBlack />} />
            <Route path="galaxy-glass-sound-black" element={<GalaxyGlassSoundB />} />
            <Route path="galaxy-glass-sound-white" element={<GalaxyGlassSoundW />} />
            <Route path="galaxy-glass-sound-pink" element={<GalaxyGlassSoundP />} />
            <Route path="galaxy-glass-black" element={<GalaxyGlassBlack />} />
            <Route path="galaxy-glass-white" element={<GalaxyGlassWhite />} />
            <Route path="galaxy-glass-pink" element={<GalaxyGlassPink />} />
            <Route path="galaxy-glass-standard-black" element={<GalaxyGlassStandardB />} />
            <Route path="galaxy-glass-standard-white" element={<GalaxyGlassStandardW />} />
            <Route path="galaxy-glass-m-black" element={<GalaxyGlassMBlack />} />
            <Route path="galaxy-glass-m-white" element={<GalaxyGlassMwhite />} />
            <Route path="glass-06x-frost-white" element={<Glass06x />} />
            <Route path="glass-06x-black" element={<Glass06xBlack/>} />
            <Route path="z01-black" element={<Z01 />} />
            <Route path="z02-black" element={<Z02 />} />
            <Route path="z03-black" element={<Z03 />} />
            <Route path="glass-06" element={<Glass06Rgb />} />
            <Route path="glass-06-rgb-6-fans" element={<Glass06Rgb6fans />} />
            <Route path="glass-06-sem-fans" element={<Glass06semfans />} />
            <Route path="office-01" element={<Office01/>} />
            <Route path="glass-06x-white-6-fans-argb" element={<Gabinete22/>} />
            <Route path="glass-06x-black-6-fans-argb" element={<Gabinete27/>} />
            <Route path="galaxy-glass-standard-black-10-fans-argb" element={<Gabinete23/>} />
            <Route path="galaxy-glass-standard-white-10-fans-argb" element={<Gabinete24/>} />
            <Route path="wave-white-3-fans-argb" element={<Gabinete25/>} />
            <Route path="wave-black-3-fans-argb" element={<Gabinete26/>} />

            //Memoria
            <Route path="rise-mode-z-ddr4" element={<RiseModeZ />} />
            <Route path="rise-mode-z-ddr5" element={<RiseModeZDDR5 />} />
            <Route path="diamond-series" element={<DiamondSeries />} />
            <Route path="diamond-rgb" element={<DiamondSeriesRGB />} />
            <Route path="memoria-venon-rgb" element={<MemoriaVenonRgb />} />
            <Route path="value" element={<Value/>} />
            <Route path="value-notebook" element={<ValueNotebook/>} />

            //Water Cooler
            <Route path="frost-120mm" element={<Frost120MM />} />
            <Route path="frost-240mm" element={<Frost240mm />} />
            <Route path="frost-360mm-rgb" element={<Frost360MM />} />
            <Route path="black-120mm-rgb" element={<Black120MM />} />
            <Route path="black-240mm-rgb" element={<Black240mmRGB/>} />
            <Route path="black-120mm-Argb" element={<Black120mmARGB/>} />
            <Route path="black-240mm-Argb" element={<Black240mmARGB/>} />
            <Route path="black-360mm-Argb" element={<Black360mmARGB/>} />
            <Route path="black-360mm-rgb" element={<Black360mmRGB/>} />
            <Route path="aura-frost-120" element={<AuraFrost120 />} />
            <Route path="aura-frost-240" element={<AuraFrost240 />} />
            <Route path="aura-frost-360" element={<AuraFrost360 />} />
            <Route path="aura-ice-120" element={<AuraIce120 />} />
            <Route path="aura-ice-240" element={<AuraIce240 />} />
            <Route path="aura-ice-360" element={<AuraIce360 />} />
            <Route path="aura-ice-black-120" element={<AuraIceBlack120 />} />
            <Route path="aura-ice-black-240" element={<AuraIceBlack240 />} />
            <Route path="aura-ice-black-360" element={<AuraIceBlack360 />} />

            //Cadeira Gamer
            <Route path="a1-black-red" element={<A1BlackRed />} />
            <Route path="a1-black" element={<A1Black/>} />

            //Suporte VGA
            <Route path="suporte-vga-lv1" element={<Lv1 />} />
            <Route path="suporte-vga-lv2" element={<Lv2/>} />
            <Route path="suporte-vga-lv3" element={<Lv3/>} />  

            //Cooler
            <Route path="winter-black-argb" element={<WinterBlackAR />} />
            <Route path="g800-rgb" element={<G800RGB />} />
            <Route path="g700" element={<G700 />} />
            <Route path="z4-white" element={<Z4White />} />
            <Route path="z4-rgb" element={<Z4RGB />} />
            <Route path="x1-cooler" element={<X1Cooler />} />
            <Route path="x2-cooler" element={<X2Cooler />} />
            <Route path="x3-cooler" element={<X3Cooler />} />
            <Route path="x4-cooler" element={<X4Cooler />} />
            <Route path="x4-rgb-cooler" element={<X4RGBCooler />} />
            <Route path="x5-blue-led-cooler" element={<X5BlueLedCooler />} />
            <Route path="x5-white-cooler" element={<X5WhiteCooler />} />
            <Route path="x5-rgb-cooler" element={<X5RGB />} />
            <Route path="g200-cooler" element={<G200 />} />
            <Route path="z1-cooler" element={<Z1Cooler />} />
            <Route path="z2-cooler" element={<Z2Cooler />} />
            <Route path="z3-cooler" element={<Z3Cooler />} />
            <Route path="z5-rgb-cooler" element={<Z5Cooler />} />
            
            //Mousepad
            <Route path="nao-fume-ram" element={<NaoFume />} />
            <Route path="galaxy-rgb-mousepad" element={<GalaxyRGBEX />} />
            <Route path="gaming-esqueleto-mousepad" element={<GamingEsqueleto />} />
            <Route path="gaming-ninja-mousepad" element={<GamingNinja />} />
            <Route path="gaming-planetas-mousepad" element={<GamingPlanetas />} />
            <Route path="gaming-floresta-mousepad" element={<GamingFloresta />} />
            <Route path="gaming-neon-mousepad" element={<GamingNeon/>} />
            <Route path="gaming-ak47-mousepad" element={<GamingAk47 />} />
            <Route path="gaming-black-mode" element={<GamingBlackMode />} />
            <Route path="gaming-hacker-mousepad" element={<GamingHacker />} />
            <Route path="gaming-scorpion-mousepad" element={<GamingScorpionB />} />
            <Route path="gaming-scorpion-red-mousepad" element={<GamingScorpionR />} />
            <Route path="gaming-desert-mousepad" element={<GamingDesert />} />
            <Route path="gaming-ak47-red-mousepad" element={<GamingAkRed/>} />
            <Route path="gaming-sniper-mousepad" element={<GamingSniper />} />  
            <Route path="gaming-sniper-grey" element={<GamingSniperG />} />
            <Route path="gaming-snake-mousepad" element={<GamingSnake />} />

            //Fonte
            <Route path="atomic-400w-led" element={<Atomic400/>} />
            <Route path="atomic-500w-led" element={<Atomic500/>} />
            <Route path="atomic-600w-led" element={<Atomic600/>} /> 
            <Route path="zeus-500w" element={<Zeus500 />} />  
            <Route path="zeus-650w" element={<Zeus650 />} />  
            <Route path="zeus-850w" element={<Zeus850 />} />  

            //Teclado
            <Route path="g1-black-white-teclado" element={<G1BlackWhite/>} />  
            <Route path="g1-full-black-teclado" element={<G1Black />} />  
            <Route path="g1-full-white-teclado" element={<G1White />} />  
            <Route path="office-of-01-black" element={<TOffice01 />} />
            <Route path="office-of-02-black" element={<TOffice02 />} />   

            //Cabo Sleeved
            <Route path="cabos-sleeved" element={<CaboSleeved />} />
            <Route path="cabo-sleeved-gamer-rgb" element={<CaboSleevedGamerRGB />} />

            //Mouse

            //Coverpsu
            <Route path="cover-psu-rs1-rgb" element={<Coverpsu1 />} />
            <Route path="cover-psu-rs1-white" element={<Coverpsu2 />} />
            <Route path="dragon-rgb-molex-cr-black" element={<Coverpsu3 />} />
            <Route path="dragon-rgb-motherboard-black" element={<Coverpsu4 />} />
            <Route path="dragon-rgb-molex-cr-white" element={<Coverpsu5 />} />
            <Route path="dragon-rgb-motherboard-white" element={<Coverpsu6 />} />
            <Route path="dragon-black" element={<Coverpsu7 />} />
            <Route path="dragon-white" element={<Coverpsu8 />} />
            <Route path="alien-rgb-controle-molex-black" element={<Coverpsu9 />} />
            <Route path="alien-rgb-motherboard-black" element={<Coverpsu10 />} />
            <Route path="alien-rgb-molex-cr-white" element={<Coverpsu11 />} />  
            <Route path="alien-rgb-motherboard-white" element={<Coverpsu12 />} /> 
            <Route path="rgb-controle-molex-motherboard-black" element={<Coverpsu13 />} /> 
            <Route path="rs1-rgb-molex-white" element={<Coverpsu14 />} /> 

            //Pcie
            <Route path="extensor-pci-express" element={<PcieExpress />} /> 

            //Ssd m2
            <Route path="ssd-m2-diamond-series" element={<Ssdm2Diamond />} /> 


            //Ssd
            <Route path="ssd-120gb-gamer-line" element={<Ssd120gb />} /> 
            <Route path="ssd-240gb-gamer-line" element={<Ssd240gb />} />
            <Route path="ssd-480gb-gamer-line" element={<Ssd480gb />} />
            <Route path="ssd-960gb-gamer-line" element={<Ssd960gb />} />

            //Microsd
            <Route path="microsd-64gb" element={<Microsd64 />} />
            <Route path="microsd-128gb" element={<Microsd128 />} />
            <Route path="microsd-256gb" element={<Microsd256 />} />

            //Pasta Termica  
            <Route path="pasta-termica-silver-cold" element={<SilverCold />} />
            <Route path="pasta-termica-silver-frost" element={<SilverFrost />} />
            <Route path="pasta-termica-silver-cold-10w" element={<Cold10W />} />
            <Route path="pasta-termica-silver-cold-17w" element={<Cold17W />} />

             //Suporte Controle
            <Route path="suporte-controle-venonv3" element={<SuporteControleVenonv3 />} />  
            <Route path="suporte-controle-alien" element={<SuporteControleAlien />} />  

            //Camiseta
            <Route path="gaming-dryfit-red" element={<GamingRedDryfit />} />  
            <Route path="gaming-dryfit-blue" element={<GamingBlueDryfit />} />  

            //Base Notebook
            <Route path="base-com-cooler-para-notebook" element={<BaseNotebookCooler />} /> 

            //Som Caixa
            <Route path="aura-sound-s1-rgb" element={<SomAuraS1 />} /> 
            <Route path="aura-sound-s4-rgb" element={<SomAuraS4  />} /> 
            <Route path="aura-sound-s5-rgb" element={<SomAuraS5 />} /> 
            <Route path="aura-sound-s6-rgb-bluetooh" element={<SomAuraS6  />} /> 

          </Routes>
        </AnimatePresence>
      </Global>
    </div>
  );
}
