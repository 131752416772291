import React, { useState, useEffect} from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import Loading from '../../../Loading/Loading';
import YouTube from 'react-youtube';
import { useTranslation } from 'react-i18next';
import BotaoComprar from '../../BotaoComprar/BotaoComprar';
import Venon01 from '../../../../img/Produtos/Suporte_headset/Venon/venon.jpg';
import VenonBanner from '../../../../img/Produtos/Suporte_headset/Venon/venon_banner.jpg';
import products from '../DataSuporteHeadset';
import ImageProduct from './product.jpg';
import IconDownload from './download.png';

const Paragraph = styled.p`
    padding: 0.2rem;
    color: #c3c3c3;
    font-size: 1rem;
    background: none;
    font-family: "Inter";
    @media screen and (max-width: 1031px) {
    font-size: 0.6rem;
    width: 100%;
    letter-spacing: 1px !important;
  }
`;

const ControleDesc = styled.div`
  display: block;
  margin: 0 auto;
  width: 400px;
`;

const TituloDesc = styled.h2`
  width: 100%;
  margin-top: 1rem;
  background: none;
`;

const Principal = styled.div`
  background-color: black;
  padding: 1rem;
  border-radius: 4px;
  width: 100%;
  max-width: 1200px;

  position: relative;
  margin: 0 auto;
`;

const ControleAll = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Conteudo = styled.div`
  width: 800px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background: white;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 8px;

  @media screen and (max-width: 1000px) {
    width: 420px;
  }

  img {
    width: 350px;
    margin: 4px;

    @media screen and (max-width: 1000px) {
      width: 150px;
      margin: 4px;
    }
  }
`;

const TituloModal = styled.h1`
  background: none;
  color: white;
  text-align: center;
`;

const BannerProduto = styled.img`
  width: 100%;
  max-width: 680px;
  border-radius: 10px;
  margin: 1rem;
`;

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
`;

const CarouselContainer = styled.div`
  width: 50%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  padding: 1rem;
  background: white;
  border-radius: 1rem;
  border: none;

  @media screen and (max-width: 1000px) {
    width: 100% ;
  }
`;

const CarouselWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
  border: none;
  background: none;
`;

const CarouselItem = styled.img`
  width: 100%;
  height: auto;
  border: none;
  background: none;
`;

const NavButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #4491FA;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: white;
  width: 40px;
  border-radius: 4px;
  margin: 8px;

  &:hover{
    filter: brightness(0.8);
  }
`;

const PrevButton = styled(NavButton)`
  left: 0;
`;

const NextButton = styled(NavButton)`
  right: 0;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const Card = styled.a`
  max-width: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #202020;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin: 20px;
  text-decoration: none;
  background: #161616;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const CardImage = styled.img`
  width: 150px;
  height: 150px;
  height: auto;
  border-radius: 8px;
  margin: 16px;
`;

const ControlSoftware = styled.div`
  background: none;
  margin-right: 14px;
`;

const NameSoftware = styled.h2`
  background: none;
  color: white;
  font-size: 30px;
  text-align: center;
  margin-bottom: 4px;
`;

const OSSoftware = styled.p`
  background: none;
  text-align: left;
  font-family: 'Inter';
  color: #d7d7d7;
  margin-bottom: 4px;
`;

const VersionSoftware = styled.p`
  background: none;
  text-align: left;
  color: #d7d7d7;
  font-family: 'Inter';
  margin-bottom: 4px;

  @media (max-width: 1000px) {
    margin-bottom: 20px;
  }
`;

const Link = styled.a`
  font-style: none;
  list-style: none;
`;

const BotaoDownload = styled.div`
  background: none;
  margin: 10px;
`;

const DownloadSoftware = styled.div`
  width: 120px;
  padding: 10px;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-radius: 10px;
  transition: 0.5ms;

  :hover{
    filter: brightness(0.9);
  }
`;

const DownloadSoftwareTitle = styled.h2`
  list-style: none;
  background: none;
  color: black;
`;

const DownloadSoftwareImage = styled.img`
  width: 20px;
  height: 20px;
  background: none;
`;


const ProductPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [productsData, setProductsData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const images = [
    'url_da_imagem_1.jpg',
    'url_da_imagem_2.jpg',
    'url_da_imagem_3.jpg',
    'url_da_imagem_4.jpg',
    'url_da_imagem_5.jpg',
  ];

  useEffect(() => {
    // Simulando uma chamada assíncrona para buscar os dados dos produtos
    setTimeout(() => {
      setProductsData(products);
      setIsLoading(false);
    }, 2000);
  }, []);
  
  const WithLoading = ({ isLoading, children }) => {
    return <>{isLoading ? <Loading /> : <>{children}</>}</>;
  };
  
  const { t } = useTranslation();
  
  return (
    <WithLoading isLoading={isLoading}>
       {products
      .filter((product) => product.id === 13) // Filtra apenas o produto com ID 1
      .map((product) => (
        <Principal>
          <VideoContainer>
            {product.youtubeUrl ? (
              <YouTube videoId={product.youtubeUrl} id="youtube-container" />
            ) : (
              <div></div>
            )}
          </VideoContainer>
          <BotaoComprar/>
          <ControleAll>
          
          <CarouselContainer>
              <CarouselWrapper
                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
              >
                {product.images.slice(0, 5).map((image, index) => (
                  <CarouselItem key={index} src={image} alt={`slide-${index}`} />
                ))}
              </CarouselWrapper>
              <PrevButton onClick={prevSlide}>{'<'}</PrevButton>
              <NextButton onClick={nextSlide}>{'>'}</NextButton>
            </CarouselContainer>


            <BannerProduto src={product.banner} />
            <TituloModal>{product.name}</TituloModal>
          </ControleAll>
          <ControleDesc>
            <Paragraph dangerouslySetInnerHTML={{ __html: t('watercooler.aura-ice-360mm') }} />          
          </ControleDesc> 
                  
      <CardContainer>
        <Card>
          <CardImage src={ImageProduct} alt="Product" />
          <ControlSoftware>
            <NameSoftware>Download Software </NameSoftware>
            <OSSoftware>Water Cooler Aura Ice ARGB</OSSoftware>    
          </ControlSoftware>

          <BotaoDownload>
            <Link href='/donwload-software-aura-ice'>
              <DownloadSoftware>
                  <DownloadSoftwareImage src={IconDownload}/>
              </DownloadSoftware>
            </Link>
          </BotaoDownload>
        </Card>
      </CardContainer>         



        </Principal>
       ))}
    </WithLoading>
  );
  };
  
  export default ProductPage;
  
  
