import FanEnergyArgb_01 from '../../../img/Produtos/fan/kit-fan-energy-argb/foto_01.png';
import FanEnergyArgb_02 from '../../../img/Produtos/fan/kit-fan-energy-argb/foto_02.jpg';
import FanEnergyArgb_03 from '../../../img/Produtos/fan/kit-fan-energy-argb/foto_03.jpg';
import FanEnergyArgb_Banner from '../../../img/Produtos/fan/kit-fan-energy-argb/foto_banner.jpg';

import FanLaserArgb_01 from '../../../img/Produtos/fan/kit-fan-laser-argb/foto_01.png';
import FanLaserArgb_02 from '../../../img/Produtos/fan/kit-fan-laser-argb/foto_02.jpg';
import FanLaserArgb_03 from '../../../img/Produtos/fan/kit-fan-laser-argb/foto_03.jpg';
import FanLaserArgb_Banner from '../../../img/Produtos/fan/kit-fan-laser-argb/foto_banner.jpg';

import FanBrancoRGB_01 from '../../../img/Produtos/fan/kit-fan-branco-rgb/foto_01.jpg'
import FanBrancoRGB_02 from '../../../img/Produtos/fan/kit-fan-branco-rgb/foto_01.png'
import FanBrancoRGB_03 from '../../../img/Produtos/fan/kit-fan-branco-rgb/foto_03.jpg'
import FanBrancoRGB_Banner from '../../../img/Produtos/fan/kit-fan-branco-rgb/foto_banner.jpg'

import FanAuraRGB_01 from '../../../img/Produtos/fan/kit-fan-aura-rgb/foto_01.png'
import FanAuraRGB_02 from '../../../img/Produtos/fan/kit-fan-aura-rgb/foto_01.jpg'
import FanAuraRGB_03 from '../../../img/Produtos/fan/kit-fan-aura-rgb/foto_03.jpg'
import FanAuraRGB_04 from '../../../img/Produtos/fan/kit-fan-aura-rgb/foto_04.jpg'
import FanAuraRGB_05 from '../../../img/Produtos/fan/kit-fan-aura-rgb/foto_05.jpg'
import FanAuraRGB_Banner from '../../../img/Produtos/fan/kit-fan-aura-rgb/foto_banner.jpg'

import BlackSLed_01 from '../../../img/Produtos/fan/Black-S-Led/foto_01.png'
import BlackSLed_02 from '../../../img/Produtos/fan/Black-S-Led/foto_02.jpg'
import BlackSLed_03 from '../../../img/Produtos/fan/Black-S-Led/foto_03.png'
import BlackSLed_04 from '../../../img/Produtos/fan/Black-S-Led/foto_04.png'
import BlackSLed_Banner from '../../../img/Produtos/fan/Black-S-Led/banner.png'

import Black80_01 from '../../../img/Produtos/fan/fan-black-80mm/foto_01.png'
import Black80_02 from '../../../img/Produtos/fan/fan-black-80mm/foto_02.jpg'
import Black80_03 from '../../../img/Produtos/fan/fan-black-80mm/foto_03.jpg'
import Black80_04 from '../../../img/Produtos/fan/fan-black-80mm/foto_04.jpg'

import GalaxyLed_01 from '../../../img/Produtos/fan/galaxy-led/foto_01.png'
import GalaxyLed_02 from '../../../img/Produtos/fan/galaxy-led/foto_02.png'
import GalaxyLed_03 from '../../../img/Produtos/fan/galaxy-led/foto_03.png'
import GalaxyLed_04 from '../../../img/Produtos/fan/galaxy-led/foto_04.png'
import GalaxyLed_Banner from '../../../img/Produtos/fan/galaxy-led/foto_banner.jpg'

import WindLed_01 from '../../../img/Produtos/fan/wind-led/foto_01.png'
import WindLed_02 from '../../../img/Produtos/fan/wind-led/foto_02.png'
import WindLed_03 from '../../../img/Produtos/fan/wind-led/foto_03.png'
import WindLed_04 from '../../../img/Produtos/fan/wind-led/foto_04.png'
import WindLed_Banner from '../../../img/Produtos/fan/wind-led/foto_banner.jpg'

import WindRgbRainbow_01 from '../../../img/Produtos/fan/wind-rgb-rainbow/foto_01.png'
import WindRgbRainbow_02 from '../../../img/Produtos/fan/wind-rgb-rainbow/foto_02.jpg'
import WindRgbRainbow_03 from '../../../img/Produtos/fan/wind-rgb-rainbow/foto_03.jpg'
import WindRgbRainbow_04 from '../../../img/Produtos/fan/wind-rgb-rainbow/foto_04.jpg'
import WindRgbRainbow_Banner from '../../../img/Produtos/fan/wind-rgb-rainbow/banner.png'

import GalaxyRgbMotherboard_01 from '../../../img/Produtos/fan/galaxy-rgb-motherboard-5v-120mm/foto_01.jpg'
import GalaxyRgbMotherboard_02 from '../../../img/Produtos/fan/galaxy-rgb-motherboard-5v-120mm/foto_02.jpg'
import GalaxyRgbMotherboard_03 from '../../../img/Produtos/fan/galaxy-rgb-motherboard-5v-120mm/foto_03.jpg'
import GalaxyRgbMotherboard_Banner from '../../../img/Produtos/fan/galaxy-rgb-motherboard-5v-120mm/foto_banner.jpg'


import GalaxyRgbMotherboard12v120mm_01 from '../../../img/Produtos/fan/galaxy-rgb-motherboard-12v-120mm/1.jpg'
import GalaxyRgbMotherboard12v120mm_02 from '../../../img/Produtos/fan/galaxy-rgb-motherboard-12v-120mm/2.jpg'
import GalaxyRgbMotherboard12v120mm_03 from '../../../img/Produtos/fan/galaxy-rgb-motherboard-12v-120mm/3.jpg'
import GalaxyRgbMotherboard12v120mm_04 from '../../../img/Produtos/fan/galaxy-rgb-motherboard-12v-120mm/4.jpg'

import SmartRGB4p12v_01 from '../../../img/Produtos/fan/Smart-RGB4p-12v/1.jpg'
import SmartRGB4p12v_02 from '../../../img/Produtos/fan/Smart-RGB4p-12v/2.jpg'
import SmartRGB4p12v_03 from '../../../img/Produtos/fan/Smart-RGB4p-12v/3.jpg'
import SmartRGB4p12v_04 from '../../../img/Produtos/fan/Smart-RGB4p-12v/4.jpg'

import GalaxyRgbRainbow_01 from '../../../img/Produtos/fan/galaxy-rgb-rainbow/foto_01.png'
import GalaxyRgbRainbow_02 from '../../../img/Produtos/fan/galaxy-rgb-rainbow/foto_02.jpg'
import GalaxyRgbRainbow_03 from '../../../img/Produtos/fan/galaxy-rgb-rainbow/foto_03.jpg'
import GalaxyRgbRainbow_04 from '../../../img/Produtos/fan/galaxy-rgb-rainbow/foto_04.jpg'
import GalaxyRgbRainbow_Banner from '../../../img/Produtos/fan/galaxy-rgb-rainbow/banner.png'

import ForceRgb_01 from '../../../img/Produtos/fan/force-rgb/foto_01.png'
import ForceRgb_02 from '../../../img/Produtos/fan/force-rgb/foto_02.jpg'
import ForceRgb_03 from '../../../img/Produtos/fan/force-rgb/foto_03.jpg'
import ForceRgb_04 from '../../../img/Produtos/fan/force-rgb/foto_04.jpg'
import ForceRgb_Banner from '../../../img/Produtos/fan/force-rgb/banner.png'

import HexMotherboard_01 from '../../../img/Produtos/fan/hex-motherboard-argb/foto_01.png'
import HexMotherboard_02 from '../../../img/Produtos/fan/hex-motherboard-argb/foto_02.jpg'
import HexMotherboard_03 from '../../../img/Produtos/fan/hex-motherboard-argb/foto_03.jpg'
import HexMotherboard_Banner from '../../../img/Produtos/fan/hex-motherboard-argb/banner.png'

import VibeRgbMotherboard_01 from '../../../img/Produtos/fan/vibe-rgb-motherboard/foto_01.png'
import VibeRgbMotherboard_02 from '../../../img/Produtos/fan/vibe-rgb-motherboard/foto_02.jpg'
import VibeRgbMotherboard_03 from '../../../img/Produtos/fan/vibe-rgb-motherboard/foto_03.jpg'
import VibeRgbMotherboard_04 from '../../../img/Produtos/fan/vibe-rgb-motherboard/foto_04.jpg'
import VibeRgbMotherboard_Banner from '../../../img/Produtos/fan/vibe-rgb-motherboard/banner.png'

import AquaRgbMotherboard_01 from '../../../img/Produtos/fan/aqua-rgb-motherboard/foto_01.png'
import AquaRgbMotherboard_02 from '../../../img/Produtos/fan/aqua-rgb-motherboard/foto_02.jpg'
import AquaRgbMotherboard_03 from '../../../img/Produtos/fan/aqua-rgb-motherboard/foto_03.jpg'
import AquaRgbMotherboard_04 from '../../../img/Produtos/fan/aqua-rgb-motherboard/foto_04.jpg'
import AquaRgbMotherboard_Banner from '../../../img/Produtos/fan/aqua-rgb-motherboard/banner.png'

import GalaxyMotherboardRgb_01 from '../../../img/Produtos/fan/galaxy-motherboard-rgb/foto_01.png'
import GalaxyMotherboardRgb_02 from '../../../img/Produtos/fan/galaxy-motherboard-rgb/foto_02.jpg'
import GalaxyMotherboardRgb_03 from '../../../img/Produtos/fan/galaxy-motherboard-rgb/foto_03.jpg'
import GalaxyMotherboardRgb_04 from '../../../img/Produtos/fan/galaxy-motherboard-rgb/foto_04.jpg'
import GalaxyMotherboardRgb_Banner from '../../../img/Produtos/fan/galaxy-motherboard-rgb/banner.png'

import GalaxyMotherboard140_01 from '../../../img/Produtos/fan/galaxy-motherboard-140/foto_01.png'
import GalaxyMotherboard140_02 from '../../../img/Produtos/fan/galaxy-motherboard-140/foto_02.jpg'
import GalaxyMotherboard140_03 from '../../../img/Produtos/fan/galaxy-motherboard-140/foto_03.jpg'
import GalaxyMotherboard140_04 from '../../../img/Produtos/fan/galaxy-motherboard-140/foto_04.jpg'
import GalaxyMotherboard140_Banner from '../../../img/Produtos/fan/galaxy-motherboard-140/banner.png'

import BrancoMotherboard5v_01 from '../../../img/Produtos/fan/fan-branco-motherboard-5v/foto_01.png'
import BrancoMotherboard5v_02 from '../../../img/Produtos/fan/fan-branco-motherboard-5v/foto_02.jpg'
import BrancoMotherboard5v_03 from '../../../img/Produtos/fan/fan-branco-motherboard-5v/foto_03.jpg'
import BrancoMotherboard5v_04 from '../../../img/Produtos/fan/fan-branco-motherboard-5v/foto_04.jpg'
import BrancoMotherboard5v_Banner from '../../../img/Produtos/fan/fan-branco-motherboard-5v/banner.png'

import BrancoMotherboard12v_01 from '../../../img/Produtos/fan/fan-branco-motherboard-12v/foto_01.png'
import BrancoMotherboard12v_02 from '../../../img/Produtos/fan/fan-branco-motherboard-12v/foto_02.jpg'
import BrancoMotherboard12v_03 from '../../../img/Produtos/fan/fan-branco-motherboard-12v/foto_03.jpg'
import BrancoMotherboard12v_04 from '../../../img/Produtos/fan/fan-branco-motherboard-12v/foto_04.jpg'
import BrancoMotherboard12v_banner from '../../../img/Produtos/fan/fan-branco-motherboard-12v/foto_banner.jpg'

import AquaMotherboard5v_01 from '../../../img/Produtos/fan/aqua-motherboard-5v/foto_01.png'
import AquaMotherboard5v_02 from '../../../img/Produtos/fan/aqua-motherboard-5v/foto_02.jpg'
import AquaMotherboard5v_03 from '../../../img/Produtos/fan/aqua-motherboard-5v/foto_03.jpg'

import AquaMotherboard12v_01 from '../../../img/Produtos/fan/aqua-motherboard-12v/foto_01.png'
import AquaMotherboard12v_02 from '../../../img/Produtos/fan/aqua-motherboard-12v/foto_02.jpg'
import AquaMotherboard12v_03 from '../../../img/Produtos/fan/aqua-motherboard-12v/foto_03.jpg'
import AquaMotherboard12v_Banner from '../../../img/Produtos/fan/aqua-motherboard-12v/foto_banner.jpg'

import FanSmart_01 from '../../../img/Produtos/fan/fan-smart-rgb/foto_01.jpg'
import FanSmart_02 from '../../../img/Produtos/fan/fan-smart-rgb/foto_01.png'
import FanSmart_03 from '../../../img/Produtos/fan/fan-smart-rgb/foto_03.jpg'
import FanSmart_04 from '../../../img/Produtos/fan/fan-smart-rgb/foto_04.jpg'
import FanSmart_Banner from '../../../img/Produtos/fan/fan-smart-rgb/banner.png'

import FrostPro_01 from '../../../img/Produtos/fan/frost-pro-argb/foto_01.png'
import FrostPro_02 from '../../../img/Produtos/fan/frost-pro-argb/foto_02.png'
import FrostPro_03 from '../../../img/Produtos/fan/frost-pro-argb/foto_03.png'
import FrostPro_04 from '../../../img/Produtos/fan/frost-pro-argb/foto_04.png'
import FrostPro_05 from '../../../img/Produtos/fan/frost-pro-argb/foto_04.png'
import FrostPro_Banner from '../../../img/Produtos/fan/frost-pro-argb/banner.png'

import CrystalSound_01 from '../../../img/Produtos/fan/crystal-sound-argb/foto_01.png'
import CrystalSound_02 from '../../../img/Produtos/fan/crystal-sound-argb/foto_02.png'
import CrystalSound_03 from '../../../img/Produtos/fan/crystal-sound-argb/foto_03.png'
import CrystalSound_04 from '../../../img/Produtos/fan/crystal-sound-argb/foto_04.png'
import CrystalSound_05 from '../../../img/Produtos/fan/crystal-sound-argb/foto_05.jpg'
import CrystalSound_Banner from '../../../img/Produtos/fan/crystal-sound-argb/banner.png'

import MagnetoBlack_01 from '../../../img/Produtos/fan/magneto-black-argb/foto_01.png'
import MagnetoBlack_02 from '../../../img/Produtos/fan/magneto-black-argb/foto_02.png'
import MagnetoBlack_03 from '../../../img/Produtos/fan/magneto-black-argb/foto_03.png'
import MagnetoBlack_04 from '../../../img/Produtos/fan/magneto-black-argb/foto_04.png'
import MagnetoBlack_Banner from '../../../img/Produtos/fan/magneto-black-argb/banner.png'

import MagnetoWhite_01 from '../../../img/Produtos/fan/magneto-white-argb/foto_01.png'
import MagnetoWhite_02 from '../../../img/Produtos/fan/magneto-white-argb/foto_02.png'
import MagnetoWhite_03 from '../../../img/Produtos/fan/magneto-white-argb/foto_03.png'
import MagnetoWhite_04 from '../../../img/Produtos/fan/magneto-white-argb/foto_04.png'
import MagnetoWhite_Banner from '../../../img/Produtos/fan/magneto-white-argb/banner.png'

import TurbineBlack_01 from '../../../img/Produtos/fan/turbine-black-120mm/1.png'
import TurbineBlack_02 from '../../../img/Produtos/fan/turbine-black-120mm/2.jpg'
import TurbineBlack_03 from '../../../img/Produtos/fan/turbine-black-120mm/3.jpg'
import TurbineBlack_04 from '../../../img/Produtos/fan/turbine-black-120mm/4.png'

import AuraProBlack_01 from '../../../img/Produtos/fan/kit-fan-aura-pro-black/1.png'
import AuraProBlack_02 from '../../../img/Produtos/fan/kit-fan-aura-pro-black/2.jpg'
import AuraProBlack_03 from '../../../img/Produtos/fan/kit-fan-aura-pro-black/3.png'
import AuraProBlack_04 from '../../../img/Produtos/fan/kit-fan-aura-pro-black/4.jpg'

import CoolMotherboard_01 from '../../../img/Produtos/fan/cool-motherboard-120mm/1.png'
import CoolMotherboard_02 from '../../../img/Produtos/fan/cool-motherboard-120mm/2.png'
import CoolMotherboard_03 from '../../../img/Produtos/fan/cool-motherboard-120mm/3.png'
import CoolMotherboard_04 from '../../../img/Produtos/fan/cool-motherboard-120mm/4.png'


import AquaMotherboardARGB5v120mm_01 from  '../../../img/Produtos/fan/aqua-motherboard-argb-5v/1.jpg'



const products = [
  {
    id: 6,
    name: 'Galaxy led',
    category: 'fan',
    descriptionP: "FAN Rise Mode GALAXY G1 S-LED<br/><br/>" +
    "ESPECIFICAÇÕES:<br/><br/>" +
    "Modelo: GALAXY G1 S-LED<br/>" +
    "Cores: azul, branco, vermelho e verde<br/>" +
    "Part Number branco: RM-FN-01-BW<br/>" +
    "Part Number azul: RM-FN-01-BB<br/>" +
    "Part Number vermelho: RM-FN-01-BR<br/>" +
    "Part Number verde: RM-FN-01-BG<br/>" +
    "Dimensões do Fan: 120mm x 120mm x 25mm<br/>" +
    "Fluxo de ar do Fan: 50 CFM<br/>" +
    "Nível sonoro: 28 DB<br/>" +
    "Velocidade: 1500 RPM<br/>" +
    "Conector: 3 pin e Molex",
      
    descriptionE: "Rise Mode GALAXY G1 S-LED FAN<br/><br/>" +
    "SPECIFICATIONS:<br/><br/>" +
    "Model: GALAXY G1 S-LED<br/>" +
    "Colors: blue, white, red, and green<br/>" +
    "White Part Number: RM-FN-01-BW<br/>" +
    "Blue Part Number: RM-FN-01-BB<br/>" +
    "Red Part Number: RM-FN-01-BR<br/>" +
    "Green Part Number: RM-FN-01-BG<br/>" +
    "Fan Dimensions: 120mm x 120mm x 25mm<br/>" +
    "Fan Airflow: 50 CFM<br/>" +
    "Noise Level: 28 DB<br/>" +
    "Speed: 1500 RPM<br/>" +
    "Connector: 3 pin and Molex",
  
    productId: 'galaxy-led',
    banner: GalaxyLed_Banner,
    youtubeUrl: 'ulJFBS0bXj0',
    images: [GalaxyLed_01, GalaxyLed_02, GalaxyLed_03, GalaxyLed_04],
    pageURL: 'galaxy-led',
  },
  {
    id: 7,
    name: 'Wind led',
    category: 'fan',
    descriptionP: "Especificações:<br/><br/>" +
    "- Marca: Rise Mode<br/>" +
    "- Modelo: WIND LED<br/>" +
    "- Cores: Branco, Azul, Vermelho, Verde<br/>" +
    "- Part Number Branco: RM-WN-01-BW<br/>" +
    "- Part Number Azul: RM-WN-01-BB<br/>" +
    "- Part Number Vermelho: WM-FN-01-BR<br/>" +
    "- Part Number Verde: RM-WN-01-BG<br/>" +
    "- Dimensões do Fan: 120mm x 120mm x 25mm<br/>" +
    "- Cor do Led: Azul<br/>" +
    "- Fluxo de ar do Fan: 50 CFM<br/>" +
    "- Nível sonoro: 25 DB<br/>" +
    "- Velocidade: 1500 RPM<br/>" +
    "- Conector: 3 pin e Molex",
      
    descriptionE: "Specifications:<br/><br/>" +
    "- Brand: Rise Mode<br/>" +
    "- Model: WIND LED<br/>" +
    "- Colors: White, Blue, Red, Green<br/>" +
    "- White Part Number: RM-WN-01-BW<br/>" +
    "- Blue Part Number: RM-WN-01-BB<br/>" +
    "- Red Part Number: WM-FN-01-BR<br/>" +
    "- Green Part Number: RM-WN-01-BG<br/>" +
    "- Fan Dimensions: 120mm x 120mm x 25mm<br/>" +
    "- Led Color: Blue<br/>" +
    "- Fan Airflow: 50 CFM<br/>" +
    "- Noise Level: 25 DB<br/>" +
    "- Speed: 1500 RPM<br/>" +
    "- Connector: 3 pin and Molex",
  
    productId: 'wind-led',
    banner: WindLed_Banner,
    youtubeUrl: 'Nd7pxSzbnVk',
    images: [WindLed_01, WindLed_02, WindLed_03, WindLed_04],
    pageURL: 'wind-led',
  },
  {
    id: 26,
    name: 'Black 80mm',
    category: 'fan',
    descriptionP: " Fan Individual Rise Mode Black 80mm <br/>" +

    " Características: <br/>" +
    
    " - Marca: Rise Mode <br/>" +
    
    " - Modelo: RM-BK-01-FB <br/>" +
     
    " Especificações: <br/>" +
    
    " - Fluxo de ar do fan: 50 CFM <br/>" +
    
    " - Velocidade do fan: 1500 RPM <br/>" +
    
    " - Voltagem: 12v <br/>" +
    
    " - Conexão fan: Molex <br/>" +
    
    " Dimensões: - 80mm x 80mm x 25mm <br/>" +"",
      
    descriptionE: "Features: <br/>" +

    " - Brand: Rise Mode <br/>" +
    
    " - Model: RM-BK-01-FB <br/>" +
     
    "Specifications: <br/>" +
    
    " - Fan airflow: 50 CFM <br/>" +
    
    " - Fan speed: 1500 RPM <br/>" +
    
    " - Voltage: 12v <br/>" +
    
    " - Fan connection: Molex <br/>" +
    
    "Dimensions: - 80mm x 80mm x 25mm <br/>" +""
    ,
  
    productId: 'fan-black-80mm',
    banner: '',
    youtubeUrl: '',
    images: [Black80_01, Black80_02, Black80_03, Black80_04 ],
    pageURL: 'fan-black-80mm',
  },
  {
    id: 5,
    name: 'Black 120mm',
    category: 'fan',
    descriptionP: "Fan Individual Gamer Rise Mode Black Sem Led 120mm<br/><br/>" +
    "ESPECIFICAÇÕES:<br/><br/>" +
    "- Part Number: RM-BK-01-FB<br/>" +
    "- Dimensões do fan: 120mm x 120mm x 25mm<br/>" +
    "- Fluxo de ar do fan: 50 CFM<br/>" +
    "- Velocidade do fan: 1500 RPM<br/>" +
    "- Voltagem: 12v<br/>" +
    "- Conexão fan: Molex<br/><br/>" +
    "EAN: 6015033513537",
      
    descriptionE: "Individual Gamer Fan Rise Mode Black Without LED 120mm<br/><br/>" +
    "SPECIFICATIONS:<br/><br/>" +
    "- Part Number: RM-BK-01-FB<br/>" +
    "- Fan Dimensions: 120mm x 120mm x 25mm<br/>" +
    "- Fan Airflow: 50 CFM<br/>" +
    "- Fan Speed: 1500 RPM<br/>" +
    "- Voltage: 12v<br/>" +
    "- Fan Connection: Molex<br/><br/>" +
    "EAN: 6015033513537",
  
    productId: 'black-s-led',
    banner: BlackSLed_Banner,
    youtubeUrl: '',
    images: [BlackSLed_01, BlackSLed_02, BlackSLed_03, BlackSLed_04],
    pageURL: 'black-s-led',
  },
  {
    id: 8,
    name: 'Wind RGB Rainbow',
    category: 'fan',
    descriptionP: "Fan Individual Rise Mode Wind Rainbow<br/><br/>" +
    "Características:<br/><br/>" +
    "- Marca: Rise Mode<br/>" +
    "- Modelo: RM-WN-02-RGB<br/><br/>" +
    "Especificações:<br/><br/>" +
    "- Fluxo de ar do fan: 50 CFM<br/>" +
    "- Velocidade do fan: 1500 RPM<br/>" +
    "- Cor Led: Rainbow<br/>" +
    "- Voltagem: 12v<br/>" +
    "- Conexão fan:3p + Molex<br/><br/>" +
    "Dimensões:<br/><br/>" +
    "- 120mm x 120mm x 25mm<br/><br/>" +
    "** Leds Rainbow não podem ser controlados<br/><br/>" +
    "Ean: 6015033498407",
      
    descriptionE: "Individual Fan Rise Mode Wind Rainbow<br/><br/>" +
    "Features:<br/><br/>" +
    "- Brand: Rise Mode<br/>" +
    "- Model: RM-WN-02-RGB<br/><br/>" +
    "Specifications:<br/><br/>" +
    "- Fan Airflow: 50 CFM<br/>" +
    "- Fan Speed: 1500 RPM<br/>" +
    "- Led Color: Rainbow<br/>" +
    "- Voltage: 12v<br/>" +
    "- Fan Connection: 3p + Molex<br/><br/>" +
    "Dimensions:<br/><br/>" +
    "- 120mm x 120mm x 25mm<br/><br/>" +
    "** Rainbow LEDs cannot be controlled<br/><br/>" +
    "Ean: 6015033498407",
  
    productId: 'wind-rgb-rainbow',
    banner: WindRgbRainbow_Banner,
    youtubeUrl: '',
    images: [WindRgbRainbow_01, WindRgbRainbow_02, WindRgbRainbow_03, WindRgbRainbow_04],
    pageURL: 'wind-rgb-rainbow',
  },
  {
    id: 9,
    name: 'Galaxy Motherboard ARGB 5V 120mm',
    category: 'fan',
    descriptionP: "",
      
    descriptionE: "",
  
    productId: 'galaxy-motherboard-120-5v',
    banner: GalaxyRgbMotherboard_Banner,
    youtubeUrl: '',
    images: [GalaxyRgbMotherboard_03, GalaxyRgbMotherboard_02, GalaxyRgbMotherboard_01],
    pageURL: 'galaxy-motherboard-120-5v',
  },
  {
    id: 30,
    name: 'Galaxy Motherboard RGB 12V 120mm',
    category: 'fan',
    descriptionP: "",
      
    descriptionE: "",
  
    productId: 'galaxy-motherboard-120-12v',
    banner: '',
    youtubeUrl: '',
    images: [GalaxyRgbMotherboard12v120mm_01, GalaxyRgbMotherboard12v120mm_02, GalaxyRgbMotherboard12v120mm_03, GalaxyRgbMotherboard12v120mm_04],
    pageURL: 'galaxy-motherboard-120-12v',
  },
  {
    id: 10,
    name: 'Galaxy rgb rainbow',
    category: 'fan',
    descriptionP: "O Cooler Fan Rise Mode Galaxy da marca Rise Mode, modelo RM-FRM-02-RGB, possui as seguintes especificações:<br/><br/>" +
    "- Fluxo de ar do ventilador: 50 CFM<br/>" +
    "- Velocidade do ventilador: 1500 RPM<br/>" +
    "- Cor do LED: Rainbow<br/>" +
    "- Voltagem: 12v<br/>" +
    "- Conexão do ventilador: 3 pinos + Molex<br/>" +
    "- Dimensões: 120mm x 120mm x 25mm<br/>" +
    "- * Os LEDs Rainbow não podem ser controlados.<br/><br/>" +
    "O conteúdo da embalagem inclui o Cooler Fan Rise Mode Galaxy.<br/><br/>" +
    "Informações complementares:<br/><br/>" +
    "Tamanho do ventilador: 120 mm",
      
    descriptionE: "The Rise Mode Cooler Fan Galaxy, model RM-FRM-02-RGB, features the following specifications:<br/><br/>" +
    "- Fan airflow: 50 CFM<br/>" +
    "- Fan speed: 1500 RPM<br/>" +
    "- LED color: Rainbow<br/>" +
    "- Voltage: 12v<br/>" +
    "- Fan connection: 3-pin + Molex<br/>" +
    "- Dimensions: 120mm x 120mm x 25mm<br/>" +
    "- * Rainbow LEDs cannot be controlled.<br/><br/>" +
    "The package includes the Rise Mode Cooler Fan Galaxy.<br/><br/>" +
    "Additional information:<br/><br/>" +
    "Fan size: 120mm",
  
    productId: 'galaxy-rgb-rainbow',
    banner: GalaxyRgbRainbow_Banner,
    youtubeUrl: '',
    images: [GalaxyRgbRainbow_01, GalaxyRgbRainbow_02, GalaxyRgbRainbow_03, GalaxyRgbRainbow_04],
    pageURL: 'galaxy-rgb-rainbow',
  },
  {
    id: 11,
    name: 'Force Motherboard RGB',
    category: 'fan',
    descriptionP: "O Fan Individual Rise Mode Force RGB 4p 12v possui as seguintes especificações:<br/><br/>" +
    "- Part Number: RM-FC-04-12V<br/>" +
    "- Dimensões do Fan: 120mm x 120mm x 25mm<br/>" +
    "- Fluxo de ar do Fan: 50 CFM<br/>" +
    "- Velocidade do fan: 1500 RPM<br/>" +
    "- Tipo de rolamento: Rolamento de fluído<br/>" +
    "- Voltagem: 12v<br/>" +
    "- Conexão Fan: 4 pinos + Molex<br/>" +
    "- Cor do Led: LED RGB<br/>" +
    "- Conexão Led: 4 pinos (12V-G-R-B)<br/>" +
    "- Compatível com Aura RGB, Mystic Light, RGB Fusion<br/>(Compatível apenas com placas mãe com entrada RGB 12v)<br/>" +
    "* Produto não acompanha controlador RGB<br/><br/>" +
    "Informações adicionais:<br/><br/>" +
    "- O controle dos fans RGB é realizado via software em placas mãe com conexão RGB 12v 4 pinos<br/>" +
    "- Fan RGB com LEDs de alto brilho de iluminação<br/><br/>" +
    "EAN: 6015033498476",
      
    descriptionE: "The Rise Mode Individual Fan Force RGB 4p 12v features the following specifications:<br/><br/>" +
    "- Part Number: RM-FC-04-12V<br/>" +
    "- Fan Dimensions: 120mm x 120mm x 25mm<br/>" +
    "- Fan Airflow: 50 CFM<br/>" +
    "- Fan Speed: 1500 RPM<br/>" +
    "- Bearing Type: Fluid Bearing<br/>" +
    "- Voltage: 12v<br/>" +
    "- Fan Connection: 4-pin + Molex<br/>" +
    "- LED Color: RGB LED<br/>" +
    "- LED Connection: 4-pin (12V-G-R-B)<br/>" +
    "- Compatible with Aura RGB, Mystic Light, RGB Fusion<br/>(Only compatible with motherboards with 12v RGB input)<br/>" +
    "* Product does not include RGB controller<br/><br/>" +
    "Additional Information:<br/><br/>" +
    "- RGB fan control is only available via software on motherboards with 12v 4-pin RGB connection<br/>" +
    "- RGB fan with high brightness illumination LEDs<br/><br/>" +
    "EAN: 6015033498476",
  
    productId: 'force-rgb',
    banner: ForceRgb_Banner,
    youtubeUrl: '',
    images: [ForceRgb_01, ForceRgb_02, ForceRgb_03, ForceRgb_04],
    pageURL: 'force-rgb',
  },
  {
    id: 12,
    name: 'Hex Motherboard ARGB',
    category: 'fan',
    descriptionP: "ESPECIFICAÇÕES:<br/><br/>- Part Number: RM-FC-01-5V<br/>- Dimensões do Fan: 120mm x 120mm x 25mm<br/>- Fluxo de ar do Fan: 50 CFM<br/>- Velocidade do fan: 1500 RPM<br/>- Tipo de rolamento: Rolamento de fluido<br/>- Voltagem: 5v (Led)<br/>- Conexão Fan: Molex<br/>- Cor do Led: RGB Led<br/>- Conexão Led: 3 pin (5V-D-0-G)<br/>- Compatível com Aura RGB, Mystic Light, RGB Fusion<br/>(Compatível apenas com placas mãe com entrada rgb 5v digital) *Produto não acompanha controlador rgb<br/><br/>Informações adicionais:<br/>- Controle dos fans rgb por via software em placas mãe com conexão rgb 5v digital<br/>- Fan Rgb com leds de alto brilho de iluminação<br/><br/>Ean: 6015033498438",
      
    descriptionE: "SPECIFICATIONS:<br/><br/>- Part Number: RM-FC-01-5V<br/>- Fan Dimensions: 120mm x 120mm x 25mm<br/>- Fan Airflow: 50 CFM<br/>- Fan Speed: 1500 RPM<br/>- Bearing Type: Fluid Bearing<br/>- Voltage: 5v (LED)<br/>- Fan Connection: Molex<br/>- LED Color: RGB LED<br/>- LED Connection: 3 pin (5V-D-0-G)<br/>- Compatible with Aura RGB, Mystic Light, RGB Fusion<br/>(Compatible only with motherboards with 5v digital RGB input) *Product does not include RGB controller<br/><br/>Additional Information:<br/>- RGB fan control via software on motherboards with 5v digital RGB connection<br/>- RGB fan with high brightness illumination LEDs<br/><br/>EAN: 6015033498438",
  
    productId: 'hex-motherboard-argb',
    banner: HexMotherboard_Banner,
    youtubeUrl: '',
    images: [HexMotherboard_01, HexMotherboard_02, HexMotherboard_03],
    pageURL: 'hex-motherboard-argb',
  },
  {
    id: 13,
    name: 'Vibe RGB Motherboard',
    category: 'fan',
    descriptionP: "ESPECIFICAÇÕES:<br/><br/>- Part Number: RM-FC-03-12V<br/>- Dimensões do Fan: 120mm x 120mm x 25mm<br/>- Fluxo de ar do Fan: 50 CFM<br/>- Velocidade do fan: 1500 RPM<br/>- Tipo de rolamento: Rolamento de fluido<br/>- Voltagem: 12v<br/>- Conexão Fan: 4 pin + Molex<br/>- Cor do Led: RGB Led<br/>- Conexão Led: 4 pin (12V-G-R-B)<br/>- Compatível com Aura RGB, Mystic Light, RGB Fusion<br/>(Compatível apenas com placas mãe com entrada rgb 12v)<br/>*Produto não acompanha controlador rgb<br/><br/>Informações adicionais:<br/>- Controle dos fans rgb por via software em placas mãe com conexão rgb 12v 4 pin<br/>- Fan Rgb com leds de alto brilho de iluminação<br/><br/>Ean: 6015033498452",
      
    descriptionE: "SPECIFICATIONS:<br/><br/>- Part Number: RM-FC-03-12V<br/>- Fan Dimensions: 120mm x 120mm x 25mm<br/>- Fan Airflow: 50 CFM<br/>- Fan Speed: 1500 RPM<br/>- Bearing Type: Fluid Bearing<br/>- Voltage: 12v<br/>- Fan Connection: 4 pin + Molex<br/>- LED Color: RGB LED<br/>- LED Connection: 4 pin (12V-G-R-B)<br/>- Compatible with Aura RGB, Mystic Light, RGB Fusion<br/>(Compatible only with motherboards with 12v RGB input)<br/>*Product does not include RGB controller<br/><br/>Additional Information:<br/>- RGB fan control via software on motherboards with 12v 4 pin RGB connection<br/>- RGB fan with high brightness illumination LEDs<br/><br/>EAN: 6015033498452",
  
    productId: 'vibe-rgb-motherboard',
    banner: VibeRgbMotherboard_Banner,
    youtubeUrl: '',
    images: [VibeRgbMotherboard_01, VibeRgbMotherboard_02, VibeRgbMotherboard_03, VibeRgbMotherboard_04],
    pageURL: 'vibe-rgb-motherboard',
  },
  {
    id: 14,
    name: 'Aqua RGB Motherboard',
    category: 'fan',
    descriptionP: "ESPECIFICAÇÕES:<br/><br/>- Part Number: RM-FC-02-12V<br/>- Dimensões do Fan: 120mm x 120mm x 25mm<br/>- Fluxo de ar do Fan: 50 CFM<br/>- Velocidade do fan: 1500 RPM<br/>- Tipo de rolamento: Rolamento de fluido<br/>- Voltagem: 12v<br/>- Conexão Fan: 4 pin + Molex<br/>- Cor do Led: RGB Led<br/>- Conexão Led: 4 pin (12V-G-R-B)<br/>- Compatível com Aura RGB, Mystic Light, RGB Fusion<br/>(Compatível apenas com placas mãe com entrada rgb 12v)<br/>*Produto não acompanha controlador rgb<br/><br/>Informações adicionais:<br/>- Controle dos fans rgb por via software em placas mãe com conexão rgb 12v 4 pin<br/>- Fan Rgb com leds de alto brilho de iluminação<br/><br/>Ean: 6015033498445",
      
    descriptionE: "SPECIFICATIONS:<br/><br/>- Part Number: RM-FC-02-12V<br/>- Fan Dimensions: 120mm x 120mm x 25mm<br/>- Fan Airflow: 50 CFM<br/>- Fan Speed: 1500 RPM<br/>- Bearing Type: Fluid Bearing<br/>- Voltage: 12v<br/>- Fan Connection: 4 pin + Molex<br/>- LED Color: RGB LED<br/>- LED Connection: 4 pin (12V-G-R-B)<br/>- Compatible with Aura RGB, Mystic Light, RGB Fusion<br/>(Compatible only with motherboards with 12v RGB input)<br/>*Product does not include RGB controller<br/><br/>Additional Information:<br/>- RGB fan control via software on motherboards with 12v 4 pin RGB connection<br/>- RGB fan with high brightness illumination LEDs<br/><br/>EAN: 6015033498445",
  
    productId: 'aqua-rgb-motherboard',
    banner: AquaRgbMotherboard_Banner,
    youtubeUrl: '',
    images: [AquaRgbMotherboard_01, AquaRgbMotherboard_02, AquaRgbMotherboard_03, AquaRgbMotherboard_04],
    pageURL: 'aqua-rgb-motherboard',
  },
  {
    id: 16,
    name: 'Galaxy Motherboard ARGB 5V 140mm',
    category: 'fan',
    descriptionP: "ESPECIFICAÇÕES:<br/><br/>- Part Number: RM-MB-04-5V<br/>- Dimensões do Fan: 140mm x 140mm x 25mm<br/>- Fluxo de ar do Fan: 50 CFM<br/>- Velocidade do fan: 1500 RPM<br/>- Tipo de rolamento: Rolamento de fluido<br/>- Voltagem: 5v (Led)<br/>- Conexão Fan: 3 pin + Molex<br/>- Cor do Led: RGB Led<br/>- Conexão Led: 3 pin (5V-D-0-G)<br/>- Compatível com Aura RGB, Mystic Light, RGB Fusion<br/>(Compatível apenas com placas mãe com entrada rgb 5v digital)<br/>*Produto não acompanha controlador rgb<br/><br/>Informações adicionais:<br/>- Controle dos fans rgb por via software em placas mãe com conexão rgb 5v digital<br/>- Fan Rgb com leds de alto brilho de iluminação",
      
    descriptionE: "SPECIFICATIONS:<br/><br/>- Part Number: RM-MB-04-5V<br/>- Fan Dimensions: 140mm x 140mm x 25mm<br/>- Fan Airflow: 50 CFM<br/>- Fan Speed: 1500 RPM<br/>- Bearing Type: Fluid Bearing<br/>- Voltage: 5v (LED)<br/>- Fan Connection: 3 pin + Molex<br/>- LED Color: RGB LED<br/>- LED Connection: 3 pin (5V-D-0-G)<br/>- Compatible with Aura RGB, Mystic Light, RGB Fusion<br/>(Compatible only with motherboards with 5v digital RGB input)<br/>*Product does not include RGB controller<br/><br/>Additional Information:<br/>- RGB fan control via software on motherboards with 5v digital RGB connection<br/>- RGB fan with high brightness illumination LEDs",
  
    productId: 'galaxy-motherboard-140-5v',
    banner: GalaxyMotherboard140_Banner,
    youtubeUrl: '',
    images: [GalaxyMotherboard140_01, GalaxyMotherboard140_02, GalaxyMotherboard140_03, GalaxyMotherboard140_04],
    pageURL: 'galaxy-motherboard-140-5v',
  },
  {
    id: 15,
    name: 'Galaxy Motherboard RGB 12V 140MM',
    category: 'fan',
    descriptionP: "ESPECIFICAÇÕES:<br/><br/>- Part Number: RM-MB-04-12V<br/>- Dimensões do Fan: 140mm x 140mm x 25mm<br/>- Fluxo de ar do Fan: 50 CFM<br/>- Velocidade do fan: 1500 RPM<br/>- Tipo de rolamento: Rolamento de fluido<br/>- Voltagem: 12v<br/>- Conexão Fan: 4 pin + Molex<br/>- Cor do Led: RGB Led<br/>- Conexão Led: 4 pin (12V-G-R-B)<br/>- Compatível com Aura RGB, Mystic Light, RGB Fusion<br/>(Compatível apenas com placas mãe com entrada rgb 12v)<br/>*Produto não acompanha controlador rgb<br/><br/>Informações adicionais:<br/>- Controle dos fans rgb por via software em placas mãe com conexão rgb 12v 4 pin<br/>- Fan Rgb com leds de alto brilho de iluminação",
      
    descriptionE: "SPECIFICATIONS:<br/><br/>- Part Number: RM-MB-04-12V<br/>- Fan Dimensions: 140mm x 140mm x 25mm<br/>- Fan Airflow: 50 CFM<br/>- Fan Speed: 1500 RPM<br/>- Bearing Type: Fluid Bearing<br/>- Voltage: 12v<br/>- Fan Connection: 4 pin + Molex<br/>- LED Color: RGB LED<br/>- LED Connection: 4 pin (12V-G-R-B)<br/>- Compatible with Aura RGB, Mystic Light, RGB Fusion<br/>(Compatible only with motherboards with 12v RGB input)<br/>*Product does not include RGB controller<br/><br/>Additional Information:<br/>- RGB fan control via software on motherboards with 12v 4 pin RGB connection<br/>- RGB fan with high brightness illumination LEDs",
  
    productId: 'galaxy-motherboard-140-12v',
    banner: GalaxyMotherboardRgb_Banner,
    youtubeUrl: '',
    images: [GalaxyMotherboardRgb_01, GalaxyMotherboardRgb_02, GalaxyMotherboardRgb_03, GalaxyMotherboardRgb_04],
    pageURL: 'galaxy-motherboard-140-12v',
  },
  {
    id: 17,
    name: 'Fan Branco Motherboard ARGB 5V 120mm',
    category: 'fan',
    descriptionP: "ESPECIFICAÇÕES:<br/><br/>- Part Number: RM-MB-02-5V<br/>- Dimensões do fan: 120mm x 120mm x 25mm<br/>- Fluxo de ar do fan: 50 CFM<br/>- Velocidade do fan: 1500 RPM<br/>- Tipo de rolamento: Rolamento de fluido<br/>- Voltagem: 5v (Led)<br/>- Conexão fan: Molex<br/>- Cor do Led: RGB Led<br/>- Conexão Led: 3 pin (5V-D-0-G)<br/>- Compatível com Aura RGB, Mystic Light, RGB Fusion<br/>(Compatível apenas com placas mãe com entrada rgb 5v digital)<br/><br/>Informações adicionais:<br/>- Controle dos fans rgb por via software em placas mãe com conexão rgb 5v digital<br/>- Fan Rgb com leds de alto brilho de iluminação<br/><br/>EAN: 6015033497424",
      
    descriptionE: "SPECIFICATIONS:<br/><br/>- Part Number: RM-MB-02-5V<br/>- Fan Dimensions: 120mm x 120mm x 25mm<br/>- Fan Airflow: 50 CFM<br/>- Fan Speed: 1500 RPM<br/>- Bearing Type: Fluid Bearing<br/>- Voltage: 5v (Led)<br/>- Fan Connection: Molex<br/>- LED Color: RGB Led<br/>- LED Connection: 3 pin (5V-D-0-G)<br/>- Compatible with Aura RGB, Mystic Light, RGB Fusion<br/>(Only compatible with motherboards with 5v digital RGB input)<br/><br/>Additional Information:<br/>- RGB fan control is only available via software on motherboards with 5v digital RGB connection<br/>- Individual RGB fan with high brightness illumination LEDs<br/><br/>EAN: 6015033497424",
  
    productId: 'fan-branco-motherboard-5v',
    banner: BrancoMotherboard5v_Banner,
    youtubeUrl: '',
    images: [BrancoMotherboard5v_01, BrancoMotherboard5v_02, BrancoMotherboard5v_03, BrancoMotherboard5v_04],
    pageURL: 'fan-branco-motherboard-5v',
  },
  {
    id: 18,
    name: 'FAN Branco Motherboard RGB 12V 120mm',
    category: 'fan',
    descriptionP: "ESPECIFICAÇÕES:<br/><br/>- Part Number: RM-MB-02-12V<br/>- Dimensões do fan: 120mm x 120mm x 25mm<br/>- Fluxo de ar do fan: 50 CFM<br/>- Velocidade do fan: 1500 RPM<br/>- Tipo de rolamento: Rolamento de fluído<br/>- Voltagem: 12v<br/>- Conexão fan: Molex<br/>- Cor do Led: RGB Led<br/>- Conexão Led: 4 pin (12V-G-R-B)<br/>- Compatível com Aura RGB, Mystic Light, RGB Fusion<br/>(Compatível apenas com placas mãe com entrada rgb 12v)<br/><br/>Informações adicionais:<br/>- Controle dos fans rgb por via software em placas mãe com conexão rgb 12v 4 pin<br/>- Fan Rgb com leds de alto brilho de iluminação<br/><br/>EAN: 6015033497431",
      
    descriptionE: "SPECIFICATIONS:<br/><br/>- Part Number: RM-MB-02-12V<br/>- Fan Dimensions: 120mm x 120mm x 25mm<br/>- Fan Airflow: 50 CFM<br/>- Fan Speed: 1500 RPM<br/>- Bearing Type: Fluid Bearing<br/>- Voltage: 12v<br/>- Fan Connection: Molex<br/>- LED Color: RGB Led<br/>- LED Connection: 4 pin (12V-G-R-B)<br/>- Compatible with Aura RGB, Mystic Light, RGB Fusion<br/>(Only compatible with motherboards with 12v RGB input)<br/><br/>Additional Information:<br/>- RGB fan control is only available via software on motherboards with 12v RGB connection<br/>- Individual RGB fan with high brightness illumination LEDs<br/><br/>EAN: 6015033497431",
  
    productId: 'fan-branco-motherboard-12v',
    banner: BrancoMotherboard12v_banner,
    youtubeUrl: '',
    images: [BrancoMotherboard12v_01, BrancoMotherboard12v_02, BrancoMotherboard12v_03, BrancoMotherboard12v_04],
    pageURL: 'fan-branco-motherboard-12v',
  },
  {
    id: 20,
    name: 'Aqua Motherboard RGB 12V 120mm',
    category: 'fan',
    descriptionP: "ESPECIFICAÇÕES:<br/><br/>- Part Number: RM-MB-03-12V<br/>- Dimensões do fan: 120mm x 120mm x 25mm<br/>- Fluxo de ar do fan: 50 CFM<br/>- Velocidade do fan: 1500 RPM<br/>- Tipo de rolamento: Rolamento de fluído<br/>- Voltagem: 12v<br/>- Conexão fan: Molex<br/>- Cor do Led: RGB Led<br/>- Conexão Led: 4 pin (12V-G-R-B)<br/>- Compatível com Aura RGB, Mystic Light, RGB Fusion<br/>(Compatível apenas com placas mãe com entrada rgb 12v)<br/><br/>Informações adicionais:<br/>- Controle dos fans rgb por via software em placas mãe com conexão rgb 12v 4 pin<br/>- Fan Rgb com leds de alto brilho de iluminação<br/><br/>EAN: 6015033511502",
      
    descriptionE: "SPECIFICATIONS:<br/><br/>- Part Number: RM-MB-03-12V<br/>- Fan Dimensions: 120mm x 120mm x 25mm<br/>- Fan Airflow: 50 CFM<br/>- Fan Speed: 1500 RPM<br/>- Bearing Type: Fluid Bearing<br/>- Voltage: 12v<br/>- Fan Connection: Molex<br/>- LED Color: RGB Led<br/>- LED Connection: 4 pin (12V-G-R-B)<br/>- Compatible with Aura RGB, Mystic Light, RGB Fusion<br/>(Only compatible with motherboards with 12v RGB input)<br/><br/>Additional Information:<br/>- RGB fan control is only available via software on motherboards with 12v RGB connection<br/>- Individual RGB fan with high brightness illumination LEDs<br/><br/>EAN: 6015033511502",
  
    productId: 'aqua-motherboard-12v',
    banner: AquaMotherboard12v_Banner,
    youtubeUrl: '',
    images: [AquaMotherboard12v_01, AquaMotherboard12v_02, AquaMotherboard12v_03],
    pageURL: 'aqua-motherboard-12v',
  },
  {
    id: 32,
    name: 'Aqua Motherboard ARGB 5V 120mm',
    category: 'fan',
    descriptionP: "",
      
    descriptionE: "",
  
    productId: 'aqua-motherboard-120-5v',
    banner: '',
    youtubeUrl: '',
    images: [AquaMotherboardARGB5v120mm_01],
    pageURL: 'aqua-motherboard-120-5v',
  },
  {
    id: 29,
    name: 'Cool Motherboard 120mm ARGB 5v',
    category: 'fan',
    descriptionP: "ESPECIFICAÇÕES:<br/>- Part Number: RM-ARGB-05-5V<br/>- Dimensões do Fan: 120mm x 120mm x 25mm<br/>- Fluxo de ar do Fan: 50 CFM<br/>- Velocidade do fan: 1500 RPM<br/>- Tipo de rolamento: Rolamento de fluido<br/>- Voltagem: 5v (Led)<br/>- Conexão Fan: 3 pin + Molex<br/>- Cor do Led: RGB Led<br/>- Conexão Led: 3 pin (5V-D-0-G)<br/>- Compatível com Aura RGB, Mystic Light, RGB Fusion<br/>(Compatível apenas com placas mãe com entrada rgb 5v digital)<br/>*Produto não acompanha controlador rgb<br/><br/>Informações adicionais:<br/>- Controle dos fans rgb por via software em placas mãe com conexão rgb 5v digital<br/>- Fan Rgb com leds de alto brilho de iluminação<br/>",
      
    descriptionE: "SPECIFICATIONS:<br/>- Part Number: RM-ARGB-05-5V<br/>- Fan Dimensions: 120mm x 120mm x 25mm<br/>- Fan Airflow: 50 CFM<br/>- Fan Speed: 1500 RPM<br/>- Bearing Type: Fluid Bearing<br/>- Voltage: 5v (Led)<br/>- Fan Connection: 3 pin + Molex<br/>- Led Color: RGB Led<br/>- Led Connection: 3 pin (5V-D-0-G)<br/>- Compatible with Aura RGB, Mystic Light, RGB Fusion<br/>(Compatible only with motherboards with 5v digital rgb input)<br/>*Product does not include RGB controller<br/><br/>Additional Information:<br/>- RGB fan control via software on motherboards with 5v digital rgb connection<br/>- RGB Fan with high-brightness lighting LEDs<br/>",
  
    productId: 'cool-motherboard-120mm-RGB-5v',
    banner: '',
    youtubeUrl: '',
    images: [CoolMotherboard_01, CoolMotherboard_02, CoolMotherboard_03, CoolMotherboard_04],
    pageURL: 'cool-motherboard-120mm-RGB-5v',
  },
  {
    id: 27,
    name: 'Turbine Black 120mm',
    category: 'fan',
    descriptionP: "Fan Individual Rise Mode Turbine Black 120mm<br/>Características:<br/>- Marca: Rise Mode<br/>- Modelo: RM-GT-01-FB<br/>Especificações:<br/>- Fluxo de ar do fan: 50 CFM<br/>- Velocidade do fan: 1500 RPM<br/>- Voltagem: 12v<br/>- Conexão fan: Molex<br/>Dimensões:<br/>- 120mm x 120mm x 25mm<br/>",
      
    descriptionE: "Fan Individual Rise Mode Turbine Black 120mm<br/>Features:<br/>- Brand: Rise Mode<br/>- Model: RM-GT-01-FB<br/>Specifications:<br/>- Fan airflow: 50 CFM<br/>- Fan speed: 1500 RPM<br/>- Voltage: 12v<br/>- Fan connection: Molex<br/>Dimensions:<br/>- 120mm x 120mm x 25mm<br/>",
  
    productId: 'turbine-black-120mm',
    banner: '',
    youtubeUrl: '',
    images: [TurbineBlack_01, TurbineBlack_02, TurbineBlack_03, TurbineBlack_04],
    pageURL: 'turbine-black-120mm',
  },
  {
    id: 31,
    name: 'Smart RGB 4p 12v',
    category: 'fan',
    descriptionP: "",
      
    descriptionE: "",
  
    productId: 'smart-individual-12v',
    banner: '',
    youtubeUrl: '',
    images: [SmartRGB4p12v_01, SmartRGB4p12v_02, SmartRGB4p12v_03, SmartRGB4p12v_04],
    pageURL: 'smart-individual-12v',
  },
  {
    id: 21,
    name: 'Kit Fan smart RGB',
    category: 'fan',
    descriptionP: "ESPECIFICAÇÕES:<br/><br/>Part Number: RM-FN-02-RGB<br/>- Dimensões do Fan: (3x) 120 mm x 120 mm x 25 mm<br/>- RGB com controle remoto / 12v motherboard<br/>- Compatível com RGB placa mãe 12v<br/>- Entrada para tira led RGB 12v<br/>- Fluxo de ar do Fan: 50 CFM<br/>- Velocidade do Fan: 1650 RPM<br/>- Tipo de rolamento: Rolamento de fluído<br/>- Nível de Ruido: 25dba (MAX)",
      
    descriptionE: "SPECIFICATIONS:<br/><br/>Part Number: RM-FN-02-RGB<br/>- Fan Dimensions: (3x) 120 mm x 120 mm x 25 mm<br/>- RGB with remote control / 12v motherboard<br/>- Compatible with 12v RGB motherboards<br/>- Input for 12v RGB LED strip<br/>- Fan Airflow: 50 CFM<br/>- Fan Speed: 1650 RPM<br/>- Bearing Type: Fluid Bearing<br/>- Noise Level: 25dba (MAX)",
  
    productId: 'fan-smart-rgb',
    banner: FanSmart_Banner,
    youtubeUrl: '',
    images: [FanSmart_02, FanSmart_01, FanSmart_03, FanSmart_04],
    pageURL: 'fan-smart-rgb',
  },
  {
    id: 22,
    name: 'KIT FAN AURA FROST PRO ARGB',
    category: 'fan',
    descriptionP: "Kit Fan Gamer Rise Mode Frost Pro ARGB<br/><br/>Part Number: RM-CF-02-ARGB<br/>Dimensões do Fan: (3x) 120mm x 120mm x 25mm<br/>ARGB com controle remoto / 5v motherboard<br/>Fluxo de ar do fan: 50 CFM<br/>Velocidade do fan: 1650 RPM<br/>Nível de Ruído: 25 DB (MAX)<br/>Tipo de rolamento: Rolamento de fluido<br/>Controle de velocidade do fan e troca de led",
      
    descriptionE: "Gamer Rise Mode Frost Pro ARGB Fan Kit<br/><br/>Part Number: RM-CF-02-ARGB<br/>Fan Dimensions: (3x) 120mm x 120mm x 25mm<br/>ARGB with remote control / 5v motherboard<br/>Fan Airflow: 50 CFM<br/>Fan Speed: 1650 RPM<br/>Noise Level: 25 DB (MAX)<br/>Bearing Type: Fluid Bearing<br/>Fan speed control and LED customization",
  
    productId: 'frost-pro-argb',
    banner: FrostPro_Banner,
    youtubeUrl: '',
    images: [FrostPro_01, FrostPro_02, FrostPro_03, FrostPro_04, FrostPro_05],
    pageURL: 'frost-pro-argb',
  },
  {
    id: 28,
    name: 'KIT FAN Aura Pro Black ARGB',
    category: 'fan',
    descriptionP: "Kit 3 Fans Gamer Aura Pro Black ARGB<br/>Número da Peça: RM-AUB-02-ARGB<br/>Dimensões do Fan: (3x) 120mm x 120mm x 25mm<br/>ARGB com controle remoto / placa-mãe 5v<br/>Fluxo de ar do fan: 50 CFM<br/>Velocidade do fan: 1650 RPM<br/>Nível de Ruído: 25 DB (MÁX)<br/>Tipo de Rolamento: Rolamento de Fluido<br/>Controle de velocidade do fan e mudança de LED<br/>",
      
    descriptionE: "Kit 3 Fans Gamer Aura Pro Black ARGB<br/>Part Number: RM-AUB-02-ARGB<br/>Fan Dimensions: (3x) 120mm x 120mm x 25mm<br/>ARGB with remote control / 5v motherboard<br/>Fan airflow: 50 CFM<br/>Fan speed: 1650 RPM<br/>Noise Level: 25 DB (MAX)<br/>Bearing Type: Fluid Bearing<br/>Fan speed control and LED change<br/>",
  
    productId: 'aura-pro-black',
    banner: '',
    youtubeUrl: '',
    images: [AuraProBlack_01, AuraProBlack_02, AuraProBlack_03, AuraProBlack_04],
    pageURL: 'aura-pro-black',
  },
  {
    id: 23,
    name: 'Kit Fan Crystal Sound ARGB',
    category: 'fan',
    descriptionP: "- Part Number: RM-CS-02-ARGB<br/>- Dimensões do Fan: (5x) 120mm x 120mm x 25mm<br/>- ARGB com controle remoto / 5v motherboard<br/>- Fluxo de ar do fan: 50 CFM<br/>- Velocidade do fan: 1650 RPM<br/>- Nível de Ruído: 25 DB (MAX)<br/>- Tipo de rolamento: Rolamento de fluido<br/>- Controle de velocidade do fan e troca de led<br/>- ARGB com suporte a led rítmico (pisca no ritmo da música)",
      
    descriptionE: "- Part Number: RM-CS-02-ARGB<br/>- Fan Dimensions: (5x) 120mm x 120mm x 25mm<br/>- ARGB with remote control / 5v motherboard<br/>- Fan Airflow: 50 CFM<br/>- Fan Speed: 1650 RPM<br/>- Noise Level: 25 DB (MAX)<br/>- Bearing Type: Fluid Bearing<br/>- Fan speed control and LED customization<br/>- ARGB with support for rhythmic LED (blinks to the beat of the music)",
  
    productId: 'crystal-sound-argb',
    banner: CrystalSound_Banner,
    youtubeUrl: '',
    images: [CrystalSound_01, CrystalSound_02, CrystalSound_03, CrystalSound_04, CrystalSound_05],
    pageURL: 'crystal-sound-argb',
  },
  {
    id: 1,
    name: 'Kit Fan Energy ARGB',
    category: 'fan',
    descriptionP: "ESPECIFICAÇÕES:<br/><br/>" +
    "Part Number: FN-02-RGB-5V<br/>" +
    "Dimensões do Fan: (3x) 120mm x 120mm x 25mm<br/>" +
    "Cor do Led: ARGB<br/>" +
    "Fluxo de ar do Fan: 50 CFM<br/>" +
    "Velocidade do fan: 1650 RPM<br/>" +
    "Nível de Ruído: 25 DB (MAX)<br/>" +
    "Tipo de rolamento: Rolamento de fluido<br/>" +
    "Controle Remoto: Diversos efeitos<br/><br/>" +
    "Informações adicionais:<br/>" +
    "- Compatível com placas mãe rgb padrão 3p 5v<br/>" +
    "- Efeito rainbow de iluminação<br/>" +
    "- Alto Brilho de Iluminação<br/>" +
    "- Vários efeitos e cores ajustáveis<br/>" +
    "- Leds Endereçáveis",
      
    descriptionE: "SPECIFICATIONS:<br/><br/>" +
    "Part Number: FN-02-RGB-5V<br/>" +
    "Fan Dimensions: (3x) 120mm x 120mm x 25mm<br/>" +
    "LED Color: ARGB<br/>" +
    "Fan Airflow: 50 CFM<br/>" +
    "Fan Speed: 1650 RPM<br/>" +
    "Noise Level: 25 DB (MAX)<br/>" +
    "Bearing Type: Fluid bearing<br/>" +
    "Remote Control: Various effects<br/><br/>" +
    "Additional Information:<br/>" +
    "- Compatible with standard 3-pin 5V RGB motherboards<br/>" +
    "- Rainbow lighting effect<br/>" +
    "- High illumination brightness<br/>" +
    "- Multiple adjustable effects and colors<br/>" +
    "- Addressable LEDs",
  
    productId: 'kit-fan-energy-argb',
    banner: FanEnergyArgb_Banner,
    youtubeUrl: '',
    images: [FanEnergyArgb_01, FanEnergyArgb_02, FanEnergyArgb_03],
    pageURL: 'kit-fan-energy-argb',
  },
  {
    id: 2,
    name: 'Kit Fan Laser ARGB',
    category: 'fan',
    descriptionP: "ESPECIFICAÇÕES:<br/><br/>" +
    "Part Number: RM-RGB-05-5V<br/>" +
    "Dimensões do Fan: (3x) 120mm x 120mm x 25mm<br/>" +
    "Cor do Led: ARGB<br/>" +
    "Fluxo de ar do Fan: 50 CFM<br/>" +
    "Velocidade do fan: 1650 RPM<br/>" +
    "Nível de Ruído: 25 DB (MAX)<br/>" +
    "Tipo de rolamento: Rolamento de fluido<br/>" +
    "Controle Remoto: Diversos efeitos<br/><br/>" +
    "Informações adicionais:<br/>" +
    "- Compatível com placas mãe rgb padrão 3p 5v<br/>" +
    "- Efeito rainbow de iluminação<br/>" +
    "- Alto Brilho de Iluminação<br/>" +
    "- Vários efeitos e cores ajustáveis<br/>" +
    "- Leds Endereçáveis<br/><br/>" +
    "EAN: 6015033513599",
      
    descriptionE: "SPECIFICATIONS:<br/><br/>" +
    "Part Number: RM-RGB-05-5V<br/>" +
    "Fan Dimensions: (3x) 120mm x 120mm x 25mm<br/>" +
    "LED Color: ARGB<br/>" +
    "Fan Airflow: 50 CFM<br/>" +
    "Fan Speed: 1650 RPM<br/>" +
    "Noise Level: 25 DB (MAX)<br/>" +
    "Bearing Type: Fluid bearing<br/>" +
    "Remote Control: Various effects<br/><br/>" +
    "Additional Information:<br/>" +
    "- Compatible with standard 3-pin 5V RGB motherboards<br/>" +
    "- Rainbow lighting effect<br/>" +
    "- High illumination brightness<br/>" +
    "- Multiple adjustable effects and colors<br/>" +
    "- Addressable LEDs<br/><br/>" +
    "EAN: 6015033513599",
  
    productId: 'kit-fan-laser-argb',
    banner: FanLaserArgb_Banner,
    youtubeUrl: '',
    images: [FanLaserArgb_01, FanLaserArgb_02, FanLaserArgb_03],
    pageURL: 'kit-fan-laser-argb',
  },
  {
    id: 3,
    name: 'Kit Fan Branco RGB',
    category: 'fan',
    descriptionP: "ESPECIFICAÇÕES:<br/><br/>" +
    "Part Number: RM-CF-02-RGB<br/>" +
    "Dimensões do Fan: (3x) 120mm x 120mm x 25mm<br/>" +
    "RGB com controle remoto / 12v motherboard<br/>" +
    "Fluxo de ar do fan: 50 CFM<br/>" +
    "Velocidade do fan: 1650 RPM<br/>" +
    "Nível de Ruído: 25 DB (MAX)<br/>" +
    "Tipo de rolamento: Rolamento de ﬂuído<br/>" +
    "Controle de velocidade do fan e troca de led<br/><br/>" +
    "EAN: 6015033497417",
      
    descriptionE: "SPECIFICATIONS:<br/><br/>" +
    "Part Number: RM-CF-02-RGB<br/>" +
    "Fan Dimensions: (3x) 120mm x 120mm x 25mm<br/>" +
    "RGB with remote control / 12v motherboard<br/>" +
    "Fan Airflow: 50 CFM<br/>" +
    "Fan Speed: 1650 RPM<br/>" +
    "Noise Level: 25 DB (MAX)<br/>" +
    "Bearing Type: Fluid bearing<br/>" +
    "Fan speed control and LED color change<br/><br/>" +
    "EAN: 6015033497417",
  
    productId: 'kit-fan-branco-rgb',
    banner: FanBrancoRGB_Banner,
    youtubeUrl: '',
    images: [FanBrancoRGB_02, FanBrancoRGB_01, FanBrancoRGB_03],
    pageURL: 'kit-fan-branco-rgb',
  },
  {
    id: 4,
    name: 'Kit Fan Aura RGB',
    category: 'fan',
    descriptionP: "ESPECIFICAÇÕES:<br/><br/>" +
    "Part Number: RM-AU-02-RGB<br/>" +
    "Dimensões do Fan: (3x) 120mm x 120mm x 25mm<br/>" +
    "RGB com controle remoto / 12v motherboard<br/>" +
    "Fluxo de ar do Fan: 50 CFM<br/>" +
    "Velocidade do fan: 1650 RPM<br/>" +
    "Nível de Ruído: 25 DB (MAX)<br/>" +
    "Tipo de rolamento: Rolamento de ﬂuído<br/>" +
    "Controle de velocidade do fan e troca de led<br/><br/>" +
    "EAN: 7895099519686",
      
    descriptionE: "SPECIFICATIONS:<br/><br/>" +
    "Part Number: RM-AU-02-RGB<br/>" +
    "Fan Dimensions: (3x) 120mm x 120mm x 25mm<br/>" +
    "RGB with remote control / 12v motherboard<br/>" +
    "Fan Airflow: 50 CFM<br/>" +
    "Fan Speed: 1650 RPM<br/>" +
    "Noise Level: 25 DB (MAX)<br/>" +
    "Bearing Type: Fluid bearing<br/>" +
    "Fan speed control and LED color change<br/><br/>" +
    "EAN: 7895099519686",
  
    productId: 'kit-fan-aura-rgb',
    banner: FanAuraRGB_Banner,
    youtubeUrl: 'RSZnxhVC8GA',
    images: [FanAuraRGB_01, FanAuraRGB_02, FanAuraRGB_03, FanAuraRGB_04, FanAuraRGB_05],
    pageURL: 'kit-fan-aura-rgb',
  },
  {
    id: 24,
    name: 'Kit Fan Magneto Black ARGB ',
    category: 'fan',
    descriptionP: "- Part Number: RM-MA-01-ARGB<br/>- Dimensões do Fan: (3x) 120mm x 120mm x 25mm<br/>- ARGB com controle / 5v motherboard<br/>- Fluxo de ar do fan: 68,5 CFM<br/>- Velocidade do fan: 800-2000±10% RPM<br/>- Nível de Ruído: 29 DB (MAX)<br/>- Conexão Fan: 4p PWM<br/>- Tipo de rolamento: Rolamento de fluído<br/>- Controle de velocidade do fan e troca de led<br/>EAN: 7898687653892",
      
    descriptionE: "- Part Number: RM-MA-01-ARGB<br/>- Fan Dimensions: (3x) 120mm x 120mm x 25mm<br/>- ARGB with control / 5v motherboard<br/>- Fan Airflow: 68.5 CFM<br/>- Fan Speed: 800-2000±10% RPM<br/>- Noise Level: 29 DB (MAX)<br/>- Fan Connection: 4p PWM<br/>- Bearing Type: Fluid Bearing<br/>- Fan speed control and LED customization<br/>EAN: 7898687653892",
  
    productId: 'magneto-black-argb',
    banner: MagnetoBlack_Banner,
    youtubeUrl: '',
    images: [MagnetoBlack_01, MagnetoBlack_02, MagnetoBlack_03, MagnetoBlack_04],
    pageURL: 'magneto-black-argb',
  },
  {
    id: 25,
    name: 'Kit Fan Magneto White ARGB',
    category: 'fan',
    descriptionP: "- Part Number: RM-MA-02-ARGB<br/>- Dimensões do Fan: (3x) 120mm x 120mm x 25mm<br/>- ARGB com controle / 5v motherboard<br/>- Fluxo de ar do fan: 68,5 CFM<br/>- Velocidade do fan: 800-2000±10% RPM<br/>- Nível de Ruído: 29 DB (MAX)<br/>- Conexão Fan: 4p PWM<br/>- Tipo de rolamento: Rolamento de fluído<br/>- Controle de velocidade do fan e troca de led<br/>EAN: 7894984377998",
      
    descriptionE: "- Part Number: RM-MA-02-ARGB<br/>- Fan Dimensions: (3x) 120mm x 120mm x 25mm<br/>- ARGB with control / 5v motherboard<br/>- Fan Airflow: 68.5 CFM<br/>- Fan Speed: 800-2000±10% RPM<br/>- Noise Level: 29 DB (MAX)<br/>- Fan Connection: 4p PWM<br/>- Bearing Type: Fluid Bearing<br/>- Fan speed control and LED customization<br/>EAN: 7894984377998",
  
    productId: 'magneto-white-argb',
    banner: MagnetoWhite_Banner,
    youtubeUrl: '',
    images: [MagnetoWhite_01, MagnetoWhite_02, MagnetoWhite_03, MagnetoWhite_04],
    pageURL: 'magneto-white-argb',
  },
];

export default products;
