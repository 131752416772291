import React, { useState, useEffect, useLayoutEffect } from 'react';
import { motion } from 'framer-motion'; 
import styled from "styled-components";
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import imagem1 from "../../img/fotos_produtos/suporteheadset.jpg";
import imagem2 from "../../img/fotos_produtos/memoria.jpg";
import imagem3 from "../../img/fotos_produtos/watercooler.jpg";
import imagem4 from "../../img/fotos_produtos/cadeiragamer.jpg";
import imagem5 from "../../img/fotos_produtos/gabinete.jpg";
import imagem6 from "../../img/fotos_produtos/mousepad.jpg";
import imagem7 from "../../img/fotos_produtos/fan.jpg";
import imagem8 from "../../img/fotos_produtos/psucover.jpg";
import imagem9 from "../../img/fotos_produtos/suportevga.jpg";
import imagem10 from "../../img/fotos_produtos/cooler.jpg";
import imagem11 from "../../img/fotos_produtos/fonte.jpg";
import imagem12 from "../../img/fotos_produtos/extensorpcie.jpg";
import imagem13 from "../../img/fotos_produtos/m.2.jpg";
import imagem14 from "../../img/fotos_produtos/ssd.jpg";
import imagem15 from "../../img/fotos_produtos/microsd.jpg";
import imagem16 from "../../img/fotos_produtos/pastatermica.jpg";
import imagem17 from "../../img/fotos_produtos/cabosleeved.jpg";
import imagem18 from "../../img/fotos_produtos/suportecontrole.jpg";
import imagem19 from "../../img/fotos_produtos/camiseta.jpg";
import imagem20 from "../../img/fotos_produtos/mouse.jpg";
import imagem21 from "../../img/fotos_produtos/teclado.jpg";
import imagem23 from "../../img/fotos_produtos/suportenotebook.jpg";
import imagem24 from "../../img/fotos_produtos/caixa_som.png";
import { useTranslation } from 'react-i18next';
import { fetchProducts } from '../../components/List.js/List';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

const firebaseConfig = {
  apiKey: "AIzaSyBliTfTPbSU4caIdCdMh_Xs5f_-95ISq28",
  authDomain: "produtos-list.firebaseapp.com",
  databaseURL: "https://produtos-list-default-rtdb.firebaseio.com",
  projectId: "produtos-list",
  storageBucket: "produtos-list.appspot.com",
  messagingSenderId: "308827988350",
  appId: "1:308827988350:web:a635c2b1508599bfff132f"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const Container = styled.div`
  text-align: center;
  background: 232323;
  padding: 1rem;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0;
  flex-wrap: wrap;

  h1{
    color: white;
    font-size: 2.5rem;
  }
`;

const Gray = styled.div`
  background: #101010;
  padding: 2rem;
  border-radius: 4px;
`;

const Division = styled.div`
  background: linear-gradient(to right, #ff20cd, #1294ff,#58ffb4,#1294ff,#ff20cd);
	background-size: 400% 400%;
	animation: gradient 6s ease infinite;
	height: 8px;


@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
`;

const Title = styled.h1`
  border-radius: 4px;
  font-size: 1.2rem;
  font-weight: 300;
  background: none;
  font-weight: 600;
  color: white;
`;

const Subtitle = styled.p`
  border-radius: 4px;
  font-size: 1rem;
  background: none;
  font-family: 'Inter';
  color: #c3c3c3;
  margin-bottom: 0.3rem;
  padding: 0rem 1rem 1rem 1rem;
`;

const CardContainer = styled.div`
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #161616;
  flex-wrap: wrap; /* Add this line */
  padding: 1rem;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const Card = styled.a`
  width: 300px;
  border-radius: 8px;
  padding: 16px;
  margin: 14px;
  text-decoration: none;
  background: #101010;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: transform .2s;

  :hover{
    transform: scale(1.1);
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const CardTitle = styled.h2`
  padding: 6px;

  width: 80%;
  font-size: 1.3rem;
  margin-bottom: 8px;
  border-radius: 2px;
  background: #4491FA;
  text-rendering: optimizeLegibility;
`;


export default function Produtos() {
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [modalUrl, setModalUrl] = useState('');
  const { id } = useParams();


  useEffect(() => {
    if (id) {
      openModal(id);
    }
  }, [id]);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allProductsData = await fetchProducts(); // Busca todos os produtos
        const fonteProducts = allProductsData.filter(product => product.pageURL); // Filtra os produtos da categoria 'fonte'
        setProducts(fonteProducts);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
  
    fetchData();
  }, []);
  
  const navigate = useNavigate();
  
  const openModal = (product) => {
    // Gerando a URL com o nome do produto
    const modalUrl = `${product.pageURL}`;

     window.history.replaceState(null, '', modalUrl);

    setSelectedProduct(product);
  };

  
    const closeModal = () => {
      setSelectedProduct(null);
    };
  
    const handleOverlayClick = (event) => {
      if (event.target === event.currentTarget) {
        closeModal();
      }
    };

  const { t } = useTranslation();

  return (
        <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
        >
      <Container>
      <Gray>  
      <Title alt="Titulo">{t('title.products')}</Title>
      <Subtitle alt="Subtitle">{t('description.products')}</Subtitle>
      
      </Gray>
      <Division></Division>    
      <CardContainer>
        <Card href='/memoria' alt="Memória RAM" title="Memória RAM">
          <CardImage src={imagem2} alt="Card 2" />
          <CardTitle>{t('card.carrosel.2')}</CardTitle>
        </Card>
        <Card href='/fan' alt="Fan" title="Fan">
          <CardImage src={imagem7} alt="Card 7" />
          <CardTitle>Fan</CardTitle>
        </Card>
        <Card href='/watercooler' alt="Water Cooler" title="Water Cooler">
          <CardImage src={imagem3} alt="Card 3" />
          <CardTitle>{t('card.carrosel.1')}</CardTitle>
        </Card>
        <Card href='/cadeiragamer' alt="Cadeira Gamer" title="Cadeira Gamer">
          <CardImage src={imagem4} alt="Card 4" />
          <CardTitle>{t('card.carrosel.5')}</CardTitle>
        </Card>
        <Card href='/aircooler' alt="Cooler" title="Cooler">
          <CardImage src={imagem10} alt="Card 10" />
          <CardTitle>COOLER</CardTitle>
        </Card>
        <Card href='/gabinete' alt="Gabinete" title="Gabinete">
          <CardImage src={imagem5} alt="Card 5" />
          <CardTitle>{t('card.carrosel.3')}</CardTitle>
        </Card>
        <Card href='/mousepad' alt="Mousepad" title="Mousepad">
          <CardImage src={imagem6} alt="Card 6" />
          <CardTitle>{t('card.carrosel.19')}</CardTitle>
        </Card>
        <Card href='/suportevga' alt="Suporte VGA" title="Suporte VGA">
          <CardImage src={imagem9} alt="Card 9" />
          <CardTitle>{t('card.carrosel.9')}</CardTitle>
        </Card>
        <Card href='/fonte' alt="Fonte" title="Fonte">
          <CardImage src={imagem11} alt="Card 11" />
          <CardTitle>{t('card.carrosel.8')}</CardTitle>
        </Card>
        <Card href='/teclado-mouse' alt="Teclado" title="Teclado">
          <CardImage src={imagem21} alt="Card 11" />
          <CardTitle>{t('card.carrosel.6')}</CardTitle>
        </Card>
        <Card href='/pcie' alt="Extersor PCIE" title="Extersor PCIE">
          <CardImage src={imagem12} alt="Card 12" />
          <CardTitle>{t('card.carrosel.10')}</CardTitle>
        </Card>
        <Card href='/ssdm2' alt="SSD M.2" title="SSD M.2">
          <CardImage src={imagem13} alt="Card 13" />
          <CardTitle>SSD M.2 PCIE</CardTitle>
        </Card>
        <Card href='/ssd' alt="SSD" title="SSD">
          <CardImage src={imagem14} alt="Card 14" />
          <CardTitle>SSD</CardTitle>
        </Card>
        <Card href='/microsd' alt="Micro SD" title="Micro SD">
          <CardImage src={imagem15} alt="Card 15" />
          <CardTitle>MICRO SD CARD</CardTitle>
        </Card>
        <Card href='/pastatermica' alt="Pasta Térmica" title="Pasta Térmica">
          <CardImage src={imagem16} alt="Card 16" />
          <CardTitle>{t('card.carrosel.11')}</CardTitle>
        </Card>
        <Card href='/cabosleeved' alt="Cabo Sleeved" title="Cabo Sleeved">
          <CardImage src={imagem17} alt="Card 17" />
          <CardTitle>{t('card.carrosel.12')}</CardTitle>
        </Card>
        <Card href='/suportenotebook' alt="Base Para Notebook" title="Base Para Notebook">
          <CardImage src={imagem23} alt="Card 23" />
          <CardTitle>{t('card.carrosel.23')}</CardTitle>
        </Card>
        <Card href='/caixadesom' alt="Caixa de Som" title="Caixa de Som">
          <CardImage src={imagem24} alt="Card 24" />
          <CardTitle>{t('card.carrosel.24')}</CardTitle>
        </Card>
            {products.map((product) => (
                <Card href={`/${product.pageURL}`} key={product.id} onClick={() => openModal(product)}>
                  <CardImage src={product.additionalImageURL} alt="Product" />
                  <CardTitle>{product.headerTitle}</CardTitle>
                </Card>
            ))}
      </CardContainer>
    </Container>
    </motion.div>
  );
}


