import Venon01 from '../../../img/Produtos/suporte-controle/venon-v3/1.jpg';
import Venon02 from '../../../img/Produtos/suporte-controle/venon-v3/2.jpg';
import Venon03 from '../../../img/Produtos/suporte-controle/venon-v3/3.jpg';
import Venon04 from '../../../img/Produtos/suporte-controle/venon-v3/4.jpg';
import VenonBanner from '../../../img/Produtos/suporte-controle/venon-v3/banner.jpg';

import Alien01 from '../../../img/Produtos/suporte-controle/alien/1.jpg';
import Alien02 from '../../../img/Produtos/suporte-controle/alien/2.jpg';
import Alien03 from '../../../img/Produtos/suporte-controle/alien/3.jpg';
import Alien04 from '../../../img/Produtos/suporte-controle/alien/4.jpg';
import AlienBanner from '../../../img/Produtos/suporte-controle/alien/banner.jpg';

const products = [
  {
    id: 1,
    name: 'VENON V3',
    category: 'suportecontrole',
    descriptionP:`O MELHOR SUPORTE PARA O SEU JOYSTICK! <br>

    Suporte Controller Rise Gaming é extremamente atrativo e conta com um design Gamer, projetado para suportar e guardar 2 joystick após o uso, o suporte é produzido com dupla camada de acrílico da mais alta qualidade, proporcionando robustez e durabilidade. <br>
    
    Por ser um suporte com uma base firme e regular ele se torna estável na mesa evitando a queda dos controles caso aconteça algum impacto, evitando também que seu joystick seja riscado quando fica solto na mesa, e é claro contando sempre com o acabamento profissional cortado a laser que so a RISE oferece. <br>
    
    --------------------------------------------<br>
    
    Especificações: <br>
     
    - Modelo: RISE GAMING VENON V3 <br>
    
    - Cores: VERMELHO, AZUL, BRANCO, PRETO <br>
    
    - Material: ACRÍLICO <br>
    
    - Acabamento: CORTADO A LASER <br>
    
    - Compatibilidade: PS4/PS3/XBOX/XBOX360 <br>
    
    - Suporta: 2 JOYSTICK <br>
    
    - Dimensões do produto: L - 15,9 - A 19,5 - C 19,8 <br>
    
    - Dimensões da caixa: 16,5X20X21CM <br>
    
    - Peso: 224 GRAMAS (BRUTO SEM EMBALAGEM) <br>
    
    - Peso com caixa: 294 GRAMAS (BRUTO COM EMBALAGEM) <br>`,
      
    descriptionE:"",
  
    productId: 'suporte-controle-venonv3',
    banner: VenonBanner,
    youtubeUrl: 'v0ccZLKABt4',
    images: [Venon01, Venon02, Venon03, Venon04],
    pageURL: 'suporte-controle-venonv3',
  },
  {
    id: 2,
    name: 'ALIEN',
    category: 'suportecontrole',
    descriptionP:`O MELHOR SUPORTE PARA O SEU JOYSTICK! <br>

    Suporte Controller Rise Gaming é extremamente atrativo e conta com um design Gamer, projetado para suportar e guardar 2 joystick após o uso, o suporte é produzido com dupla camada de acrílico da mais alta qualidade, proporcionando robustez e durabilidade. <br>
    
    Por ser um suporte com uma base firme e regular ele se torna estável na mesa evitando a queda dos controles caso aconteça algum impacto, evitando também que seu joystick seja riscado quando fica solto na mesa, e é claro contando sempre com o acabamento profissional cortado a laser que so a RISE oferece. <br>
    
    --------------------------------------------
    
    Especificações: <br>
    
    - Modelo: RISE GAMING ALIEN <br>
    
    - Cor: PRETO, BRANCO, VERMELHO, AZUL <br>
    
    - Material: ACRÍLICO <br>
     
    - Acabamento: CORTADO A LASER <br>
    
    - Compatibilidade: PS4/PS3/XBOX/XBOX360 <br>
    
    - Suporta: 2 JOYSTICK <br>
    
    - Dimensões do produto: L - 15,9 - A 19,5 - C 19,8 <br>
     
    - Dimensões da caixa: 16,5X20X21CM <br>
    
    - Peso: 224 GRAMAS (BRUTO SEM EMBALAGEM) <br>
     
    - Peso com caixa: 294 GRAMAS (BRUTO COM EMBALAGEM) <br>`,
      
    descriptionE:"",
  
    productId: 'suporte-controle-alien',
    banner: AlienBanner,
    youtubeUrl: 'v0ccZLKABt4',
    images: [Alien04, Alien01, Alien03, Alien02],
    pageURL: 'suporte-controle-alien',
  },
];

export default products;
