import BaseNotebook_01 from '../../../img/Produtos/base-notebook/1.jpg';
import BaseNotebook_02 from '../../../img/Produtos/base-notebook/2.jpg';
import BaseNotebook_03 from '../../../img/Produtos/base-notebook/3.jpg';
import BaseNotebook_04 from '../../../img/Produtos/base-notebook/4.jpg';
import BaseNotebook_Banner from '../../../img/Produtos/base-notebook/banner.jpg';

const products = [
  {
    id: 1,
    name: 'Base com Cooler para Notebook',
    category: 'basenotebook',
    descriptionP:`

    Tenha segurança, comodidade e praticidade enquanto utiliza o seu notebook com a base refrigerada da Rise Mode,  <br>Galaxy Black X6. Conta com 1 cooler fan de 14cm que mantem o seu notebook na temperatura ideal, evitando  <br>superaquecimento, prolongando a vida útil de seu equipamento. <br>
    <br>
    English: <br>
    
    Enjoy safety, convenience, and practicality while using your notebook with the Rise Mode Galaxy Black X6 cooling <br>
    pad. It features a 14cm cooler fan that keeps your notebook at the optimal temperature, preventing overheating and extending the lifespan of your device.<br>`,
      
    descriptionE:"",
  
    productId: 'base-com-cooler-para-notebook',
    banner: BaseNotebook_Banner,
    youtubeUrl: '',
    images: [BaseNotebook_01, BaseNotebook_02, BaseNotebook_03, BaseNotebook_04],
    pageURL: 'base-com-cooler-para-notebook',
  },
];

export default products;
