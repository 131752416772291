import React, { useState, useEffect } from "react";
import { BrowserRouter} from "react-router-dom";
import Rotas from "./Rotas";
import styled from "styled-components";
import Octadesk from "./OctadeskConfig";
// const ZENDESK_KEY = "56503979-e426-4978-8a22-348f653b6db2";
// import Zendesk, { ZendeskAPI } from "./ZendexConfig";

export default function App() {

  // const handleLoaded = () => {
  //   ZendeskAPI("messenger", "open");
  // };

  return (
    <div className="App">
      <BrowserRouter>
        <Rotas />
      </BrowserRouter>

      <Octadesk />

    </div>
  );
}

