import React from 'react';
import styled from 'styled-components';

const DescriptionContainer = styled.div`
  /* Estilos do container da descrição */
`;

const Paragraph = styled.p`
    padding: 0.2rem;
    color: #c3c3c3;
    font-size: 1rem;
    background: none;
    font-family: "Inter";
    @media screen and (max-width: 1031px) {
    font-size: 0.6rem;
    width: 300px;
    letter-spacing: 1px !important;
  }
`;

function ProductDescription({ description }) {
  // Divida a descrição em parágrafos, se necessário
  const paragraphs = description.split('\n'); // Supondo que a quebra de linha seja usada para separar os parágrafos

  return (
    <DescriptionContainer>
      {paragraphs.map((paragraph, index) => (
        <Paragraph key={index}>{paragraph}</Paragraph>
      ))}
    </DescriptionContainer>
  );
}

export default ProductDescription;
