import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ImageProduct from './image/product.jpg';
import SetupSoftware from './image/software.exe';
import IconDownload from './image/download.png';
import Print1 from './image/1.png';
import Print2 from './image/2.png';
import Print3 from './image/3.png';
import Print4 from './image/4.png';
import Print5 from './image/5.png';
import BannerAura from './image/banner_pagina_aura.png';
import { motion } from 'framer-motion'; 
import { useTranslation } from 'react-i18next';
/* eslint-disable */


const BannerPrincipal = styled.img`
  width: 100%;
  max-width: 1920px;
`;

const Container = styled.div`
  text-align: center;
  background: 232323;
  padding: 1rem;

  h1{
    margin-bottom: 1rem;
    font-size: 2.5rem;
  }
`;

const Division = styled.div`
  background: linear-gradient(to right, #ff20cd, #1294ff,#58ffb4,#1294ff,#ff20cd);
	background-size: 400% 400%;
	animation: gradient 6s ease infinite;
	height: 8px;

	@keyframes gradient {
		0% {
			background-position: 0% 50%;
		}
		50% {
			background-position: 100% 50%;
		}
		100% {
			background-position: 0% 50%;
		}
	}
`;

const Title = styled.h2`
  margin-top: 20px;
  background: none;
  color: white;
  font-size: 36px;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const Card = styled.a`
  max-width: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #181818;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin: 20px;
  text-decoration: none;


  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const CardImage = styled.img`
  width: 150px;
  height: 150px;
  height: auto;
  border-radius: 8px;
  margin: 16px;
`;

const ControlSoftware = styled.div`
  background: none;
  margin-right: 14px;
`;

const NameSoftware = styled.h2`
  background: none;
  color: white;
  font-size: 30px;
  text-align: left;
  margin-bottom: 4px;
`;

const OSSoftware = styled.p`
  background: none;
  text-align: left;
  font-family: 'Inter';
  color: #d7d7d7;
  margin-bottom: 4px;
`;

const VersionSoftware = styled.p`
  background: none;
  text-align: left;
  color: #d7d7d7;
  font-family: 'Inter';
  margin-bottom: 4px;

  @media (max-width: 1000px) {
    margin-bottom: 20px;
  }
`;

const Link = styled.a`
  font-style: none;
  list-style: none;
  text-decoration: none;
`;

const BotaoDownload = styled.button`
  margin: 10px;
  border: none;
  background-color: ${props => (props.disabled ? '#c3c3c3' : 'white')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const DownloadSoftware = styled.div`
  width: 160px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  transition: 0.5ms;
  background: none;

:hover{
  filter: brightness(0.9);
}
`;

const DownloadSoftwareTitle = styled.h2`
  list-style: none;
  background: none;
  color: black;
`;

const DownloadSoftwareImage = styled.img`
  width: 30px;
  height: 30px;
  background: none;
`;

const TituloPrincipal = styled.h1`
  text-align: center;
  width: 100%;
  max-width: 550px;
  margin-top: 20px;
  background: none;
`;

const SubPrincipal = styled.p`
  text-align: center;
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
  color: #c3c3c3;
  font-size: 18px;
  background: none;
  font-family: "Inter";

  @media screen and (max-width: 1031px) {
    font-size: 13px;
  }
`;

const DescPrincipal = styled.p`
  text-align: center;
  width: 100%;
  max-width: 550px;
  color: #c3c3c3;
  font-size: 18px;
  background: none;
  font-family: "Inter";
  margin-bottom: 20px;

  @media screen and (max-width: 1031px) {
    font-size: 13px;
  }
`;

const Guide =styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: none;
  margin: 20px;
`;

const GuideCard =styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: none;
  margin: 14px;
`;

const GuideTitle =styled.h2`
  margin-bottom: 10px;
`;

const GuideImage =styled.img`
  width: 500px;
  margin: 0 auto;

  @media screen and (max-width: 1000px) {
    width: 300px;
  }
`;

const TopoControll = styled.div`
    background: #111111;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

function App() {
  const [botaoTravado, setBotaoTravado] = useState(false);
  const { t } = useTranslation();

  const lidarComClique = () => {
    if (!botaoTravado) {
      setBotaoTravado(true);
      setTimeout(() => {
        setBotaoTravado(false);
      }, 2000);
    }
  };
  
  return (
    <motion.div
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.3 }}
    >
    <Container>
    <Division></Division> 
      <TopoControll>
        <BannerPrincipal src={BannerAura}/>
        <TituloPrincipal>{t('aura.ice.software.title')}</TituloPrincipal>
        <SubPrincipal>{t('aura.ice.software.sub1')}</SubPrincipal>
      <CardContainer>
        <Card>
          <CardImage src={ImageProduct} alt="Product" />
          <ControlSoftware>
            <NameSoftware>Aura Ice ARGB Driver Setup 6.0</NameSoftware>
            <OSSoftware>WIN 10 | WIN 11</OSSoftware>
            <VersionSoftware>6.0</VersionSoftware>
          </ControlSoftware>

          <BotaoDownload
           onClick={lidarComClique}
           disabled={botaoTravado}>
                <Link href={SetupSoftware}
                download="Software-CPU-Temp-Aura-Ice-ARGB-Rise-Mode.exe"
                >
                  <DownloadSoftware>
                      <DownloadSoftwareTitle>DOWNLOAD</DownloadSoftwareTitle>
                      <DownloadSoftwareImage src={IconDownload}/>
                  </DownloadSoftware>
                </Link>
          </BotaoDownload>
        </Card>
      </CardContainer>
      <DescPrincipal>{t('aura.ice.software.desc')}</DescPrincipal>
      </TopoControll>
      <Title>{t('aura.ice.software.title2')}:</Title>
      <Guide>
        <GuideCard>
          <GuideTitle>{t('aura.ice.software.print1')}</GuideTitle>
        </GuideCard>

        <GuideCard>
          <GuideTitle>{t('aura.ice.software.print2')}</GuideTitle>
          <GuideImage src={Print1}/>
        </GuideCard>

        <GuideCard>
          <GuideTitle>{t('aura.ice.software.print3')}</GuideTitle>
          <GuideImage src={Print2}/>
        </GuideCard>

        <GuideCard>
          <GuideTitle>{t('aura.ice.software.print4')}</GuideTitle>
          <GuideImage src={Print3}/>
        </GuideCard>

        <GuideCard>
          <GuideTitle>{t('aura.ice.software.print5')}</GuideTitle>
          <GuideImage src={Print4}/>
        </GuideCard>
        
        <GuideCard>
          <GuideTitle>{t('aura.ice.software.print6')}</GuideTitle>
          <GuideImage src={Print5}/>
        </GuideCard>

        <GuideCard>
          <GuideTitle>{t('aura.ice.software.print7')}</GuideTitle>
        </GuideCard>

      </Guide>
    
    </Container>
    </motion.div>
  );
}

export default App;
