import React from 'react';
import styled, { keyframes } from 'styled-components';
import Gif from '../../img/loading.gif';
import { useTranslation } from 'react-i18next';

// Estilize o componente de carregamento
const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
  background: #030303;

  p{
    position: absolute;
    color: #c3c3c3;
    font-size: 1.3rem;
    z-index: 2;
    top: 50px;
  }

  img {
    position: absolute;
    width: 600px;
    top: 50px;
    z-index: 1;
  }
`;

const Loading = () => {
  const { t } = useTranslation();

  return (
    <LoadingContainer>
      <p>{t('loading.text')}</p>
      <img src={Gif} alt="Loading" />
    </LoadingContainer>
  );
};

export default Loading;
