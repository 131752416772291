import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { motion } from 'framer-motion'; 
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/storage';
import ExemploTopo from '../../img/exemple_topo.jpg';
import ExemploMeio from '../../img/exemple_meio.jpg';

// Sua configuração do Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBliTfTPbSU4caIdCdMh_Xs5f_-95ISq28",
  authDomain: "produtos-list.firebaseapp.com",
  databaseURL: "https://produtos-list-default-rtdb.firebaseio.com",
  projectId: "produtos-list",
  storageBucket: "produtos-list.appspot.com",
  messagingSenderId: "308827988350",
  appId: "1:308827988350:web:a635c2b1508599bfff132f"
};

// Inicializa o Firebase
firebase.initializeApp(firebaseConfig);

const database = firebase.database();


const Container = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #161616;
  width: 1200px;
  height: 100%;
  max-height: 1050px;
  border-radius: 0rem 1rem 1rem 0rem;
  padding: 2rem;

  @media screen and (max-width: 1000px) {
      width:100%;
  }

  p {
    font-size: 1.2rem;
    color: white;
  }

  input[type=file] {
  width: 350px;
  max-width: 100%;
  color: #444;
  padding: 5px;
  background: #fff;
  border-radius: 10px;
}

  input[type=file]::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #4491FA;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background .2s ease-in-out;
  }

  input[type=file]::file-selector-button:hover {
    filter: brightness(0.9);
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 1rem;
  background: black;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    background: none;
    height: 100%;
  }

`;

const SectionDiv1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background: black;
  border-radius: 1rem;
  margin: 4px;
`;

const Image = styled.img`
  margin-top: 1rem;
  width: 100%;
  max-width: 400px;
  border-radius: 1rem;
`;

const SectionDiv2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 1rem;
  background: black;

  img{
    width: 400px;
    border-radius: 1rem;
  }
`;

const Title = styled.h1`
  text-align: center;
  color: #4491FA;
  margin-bottom: 0.5rem;
  background: none;
`;

const SubtitleP = styled.h1`
  text-align: center;
  color: #4491FA;
  margin-top: 1rem;
  background: none;
`;

const Subtitle = styled.h2`
  text-align: center;
  color: white;
  margin-top: 1rem;
  background: none;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  background: none;
`;

const Thumbnail = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 10px 0 0 10px;
  background: none;
`;

const ImagesWrapper = styled.div`
  display: flex;
  background: none;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 1000px;
`;

const ImageInput = styled.input`
background: none;
`;

const EditButton = styled.button`
  padding: 5px 10px;
  background-color: #4491fa;
  border: none;
  color: #fff;
  cursor: pointer;
  margin-right: 10px;
  height: 100%;
  border-radius: 0 10px 10px 0;
  font-size: 1.4rem;
`;

const NameInput = styled.input`
  margin: 5px;
  width: 350px;
  height: 40px;
  border-radius: 4px;
  padding: 10px;
  background: #343333;
  border: 1px solid #8d8d8d;
  font-size: 1.2rem;
`;

const NameInput2 = styled.input`
  margin: 5px;
  width: 350px;
  height: 40px;
  border-radius: 4px;
  padding: 10px;
  background: #343333;
  border: 1px solid #8d8d8d;
  font-size: 1.2rem;
`;

const NameInputUrl = styled.input`
  margin: 5px;
  width: 350px;
  height: 40px;
  border-radius: 4px;
  padding: 10px;
  background: #343333;
  border: 1px solid #8d8d8d;
  font-size: 1.1rem;
  text-transform: lowercase; 
  font-family: 'Inter';
`;

const DescriptionInput = styled.textarea`
  margin: 5px;
  padding: 10px;
  width: 350px;
  height: 100px;
  border-radius: 4px;
  background: #343333;
  border: 1px solid #8d8d8d;
  font-size: 1rem;
  font-family: 'Inter';
`;

const DescriptionInput2 = styled.textarea`
  margin: 5px;
  padding: 10px;
  width: 350px;
  height: 100px;
  border-radius: 4px;
  background: #343333;
  border: 1px solid #8d8d8d;
  font-size: 1rem;
  font-family: 'Inter';
`;

const CategorySelect = styled.select`
  margin: 5px;
  padding: 10px;
  width: 350px;
  height: 40px;
  border-radius: 4px;
  background: #343333;
  border: 1px solid #8d8d8d;
  color: #ffffff;
  cursor: pointer;
  font-size: 1.2rem;

  option {
    font-size: 1.1rem;
    cursor: pointer;
  }
`;

const BannerInput = styled.input`
  margin: 5px;
  cursor: pointer;
`;

const YoutubeUrlInput = styled.input`
  margin: 5px;
  padding: 10px;
  width: 350px;
  height: 40px;
  border-radius: 4px;
  background: #343333;
  border: 1px solid #8d8d8d;
  color: #ffffff;
  font-size: 1.2rem;
`;


const SubmitButton = styled.button`
  width: 350px;
  margin: 10px;
  padding: 15px 60px;
  background-color: #4491fa;
  color: #ffff;
  cursor: pointer;
  border: none;
  font-size: 1.1rem;
  border-radius: 4px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  :hover {
    filter: brightness(0.8);
  }
`;

const UploadPage = () => {
  const [images, setImages] = useState([]);
  const [pageURL, setPageURL] = useState('');
  const [headerTitle, setHeaderTitle] = useState('');
  const [headerDescription, setHeaderDescription] = useState('');
  const [headerBanner, setHeaderBanner] = useState(null);
  const [cardImage, setCardImage] = useState(null);
  const [additionalImage, setAdditionalImage] = useState(null);
  const [cardTitle, setCardTitle] = useState('');
  const [cardDescription, setCardDescription] = useState('');
  const fileInputRef = useRef(null);
  const [currentEditIndex, setCurrentEditIndex] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showSizeWarning, setShowSizeWarning] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [imageError, setImageError] = useState(false);

  const handleHeaderBannerChange = (e) => {
    const file = e.target.files[0];
    setHeaderBanner(file);
  };

  const handleCardImageChange = (e) => {
    const file = e.target.files[0];
    setCardImage(file);
  };

  const handleAdditionalImageChange = (e) => {
    const file = e.target.files[0];
    setAdditionalImage(file);
  };

  const handleSubmit = async () => {
    setButtonDisabled(true);
    const totalSize = images.reduce((size, image) => size + image.size, 0);
  
    if (totalSize > 1024 * 1024) {
      setShowSizeWarning(true);
      setTimeout(() => {
        setShowSizeWarning(false);
        setButtonDisabled(false);
      }, 3000);
      return;
    }
  
    if (
      pageURL === '' ||
      headerTitle === '' ||
      headerDescription === '' ||
      headerBanner === null ||
      cardImage === null ||
      cardTitle === '' ||
      cardDescription === ''
    ) {
      // Verifica se os campos estão vazios
      setShowErrorMessage(true);
      setTimeout(() => {
        setShowErrorMessage(false);
        setButtonDisabled(false);
      }, 3000);
      return;
    }
  
    try {
      // Crie uma referência para cada imagem no Firebase Storage
      const headerBannerRef = firebase.storage().ref().child('headerBanner.jpg');
      const cardImageRef = firebase.storage().ref().child('cardImage.jpg');
      const additionalImageRef = firebase.storage().ref().child('additionalImage.jpg');
  
      // Faça o upload das imagens para o Firebase Storage
      await headerBannerRef.put(headerBanner);
      await cardImageRef.put(cardImage);
      await additionalImageRef.put(additionalImage);
  
      // Obtenha as URLs de download das imagens
      const headerBannerURL = await headerBannerRef.getDownloadURL();
      const cardImageURL = await cardImageRef.getDownloadURL();
      const additionalImageURL = await additionalImageRef.getDownloadURL();
  
      // Resto do código para salvar as URLs das imagens e outros dados no Realtime Database
      const newProductRef = firebase.database().ref('products').push();
      const newProductKey = newProductRef.key;
  
      const newProductData = {
        pageURL,
        headerTitle,
        headerDescription,
        headerBannerURL,
        cardTitle,
        cardDescription,
        cardImageURL,
        additionalImageURL,
      };
  
      await newProductRef.set(newProductData);
  
      // Salve as URLs das imagens no estado
      setImages((prevImages) => [
        ...prevImages,
        {
          headerBannerURL,
          cardImageURL,
          additionalImageURL,
        },
      ]);
  
      // Redefinir campos e exibir mensagem de sucesso
      setPageURL('');
      setHeaderTitle('');
      setHeaderDescription('');
      setHeaderBanner(null);
      setCardTitle('');
      setCardDescription('');
      setCardImage(null);
      setAdditionalImage(null);
      setCurrentEditIndex(null);
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
        setButtonDisabled(false);
      }, 3000);
    } catch (error) {
      // Lidar com o erro aqui
      console.error(error);
      setButtonDisabled(false);
    }
  };
  
  return (
    <motion.div
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.3 }}
    >
    <Container>
      <Title>CRIAR NOVA PÁGINA</Title>
      <Section>
      <SectionDiv1>
          <SubtitleP>THUMB DO PRODUTO:</SubtitleP>
          <ImageInput
            className="custom-file-input" 
            id="additionalImageInput"
            type="file"
            onChange={handleAdditionalImageChange}
          />
          <Subtitle>Nome da página:</Subtitle>
          <p>Aqui vai definir a categoria e a URL da página</p>
          <NameInputUrl
            type="text"
            placeholder="memoria"
            value={pageURL}
            onChange={(e) => setPageURL(e.target.value)}
          />
           
          <Image src={ExemploTopo}/>
          <SubtitleP>TOPO DA PÁGINA:</SubtitleP>
          <NameInput
            type="text"
            placeholder="NOME DO PRODUTO"
            value={headerTitle}
            onChange={(e) => setHeaderTitle(e.target.value)}
          />
          <DescriptionInput
            placeholder="Descrição"
            value={headerDescription}
            onChange={(e) => setHeaderDescription(e.target.value)}
          />
          <Subtitle>BANNER PRINCIPAL:</Subtitle>
          <ImageInput
            className="custom-file-input" 
            id="headerBannerInput"
            type="file"
            onChange={handleHeaderBannerChange}
          />
      </SectionDiv1>
      
      <SectionDiv2>
        <img src={ExemploMeio}/>
        <SubtitleP>Informações secundárias:</SubtitleP>
        <NameInput2
          type="text"
          placeholder="Título"
          value={cardTitle}
          onChange={(e) => setCardTitle(e.target.value)}
        />
        <DescriptionInput2
          placeholder="Descrição"
          value={cardDescription}
          onChange={(e) => setCardDescription(e.target.value)}
        />
        <Subtitle>BANNER:</Subtitle>
        <ImageInput
          className="custom-file-input" 
          id="cardImageInput"
          type="file"
          onChange={handleCardImageChange}
        />
      </SectionDiv2>
      </Section>    
      {showSizeWarning && <p style={{ color: 'yellow' }}>O tamanho total das imagens não pode exceder 1MB!</p>}
      {showErrorMessage && <p style={{ color: 'red' }}>Por favor, preencha todos os campos!</p>}
      {showSuccessMessage && <p style={{ color: 'green' }}>Arquivo adicionado com sucesso!</p>}
      {imageError && <p style={{ color: 'red' }}>Por favor, preencha o campo da imagem</p>}
      <SubmitButton disabled={buttonDisabled} onClick={handleSubmit}>CRIAR PÁGINA</SubmitButton>
    </Container>
  </motion.div>
  );
};

export default UploadPage;
