import SilverCold from '../../../img/Produtos/pasta-termica/1.jpg';
import SilverColdBanner from '../../../img/Produtos/pasta-termica/banner.jpg';


import SilverFrost from '../../../img/Produtos/pasta-termica/frost.jpg';
import SilverFrostdBanner from '../../../img/Produtos/pasta-termica/bannerFrost.jpg';

import Cold10W from '../../../img/Produtos/pasta-termica/COLD-10W/1.jpg';
import Cold17W from '../../../img/Produtos/pasta-termica/COLD-17W/1.jpg';

const products = [
  {
    id: 1,
    name: 'PASTA TÉRMICA - SILVER COLD',
    category: 'pastatermica',
    descriptionP: `PASTA TÉRMICA - SILVER COLD <br>

    Marca: Rise Mode <br>
    
    Modelo: Pasta térmica Rise Mode Silver Cold <br>
    
    ESPECIFICAÇÕES: <br>
    
    Conteúdo: 5g <br>
    
    Cor: Cinza <br>
    
    Composição: Silicone e Prata <br>
    
    Condutividade Térmica: > 7,5 W / mK <br>
    
    Resistencia à Temperatura: -50 ° C - 220 ° C <br>
    
    Alta condutividade Térmica <br>
      
    Não condutivo <br>
    
    Fácil aplicação <br>
    
    Resistência à corrosão <br>
    PN: RM-TG-01-CLD <br>`,

    descriptionE: ``,
      
    productId: 'pasta-termica-silver-cold',
    banner: SilverColdBanner,
    youtubeUrl: '',
    images: [SilverCold],
    pageURL: 'pasta-termica-silver-cold',
  },
  {
    id: 2,
    name: 'PASTA TÉRMICA - SILVER FROST',
    category: 'pastatermica',
    descriptionP: `PASTA TÉRMICA - SILVER FROST <br>

    Marca: Rise Mode <br>
    
    Modelo: Pasta térmica Rise Mode Silver Frost<br>
    
    ESPECIFICAÇÕES: <br>
    
    Conteúdo: 5g <br>
    
    Cor: Cinza <br>
    
    Composição: Silicone e Prata <br>
    
    Condutividade Térmica: > 5,5 W / mK <br>
    
    Resistencia à Temperatura: -50 ° C - 220 ° C <br>
    
    Alta condutividade Térmica <br>
      
    Não condutivo <br>
    
    Fácil aplicação <br>
    
    Resistência à corrosão <br>
    PN: RM-TG-01-FRT <br>`,

    descriptionE: ``,
      
    productId: 'pasta-termica-silver-frost',
    banner: SilverFrostdBanner,
    youtubeUrl: '',
    images: [SilverFrost],
    pageURL: 'pasta-termica-silver-frost',
  },
  {
    id: 3,
    name: 'PASTA TÉRMICA - COLD 10W',
    category: 'pastatermica',
    descriptionP: `ESPECIFICAÇÕES:<br/>Part Number: RM-TG-10-CLD<br/>Conteúdo: 5g<br/>Cor: Cinza<br/>Composição: Silicone e Prata<br/>Condutividade Térmica: > 10 W / mK<br/>Resistência à Temperatura: -50 ° C - 220 ° C<br/>Alta Condutividade Térmica<br/>Não Condutivo<br/>Fácil Aplicação<br/>Resistência à Corrosão<br/>`,

    descriptionE: `SPECIFICATIONS:<br/>Part Number: RM-TG-10-CLD<br/>Content: 5g<br/>Color: Gray<br/>Composition: Silicone and Silver<br/>Thermal Conductivity: > 10 W / mK<br/>Temperature Resistance: -50 ° C - 220 ° C<br/>High Thermal Conductivity<br/>Non-conductive<br/>Easy Application<br/>Corrosion Resistance<br/>`,
      
    productId: 'pasta-termica-silver-cold-10w',
    banner: '',
    youtubeUrl: '',
    images: [Cold10W],
    pageURL: 'pasta-termica-silver-cold-10w',
  },
  {
    id: 4,
    name: 'PASTA TÉRMICA - COLD 17W',
    category: 'pastatermica',
    descriptionP: `
    ESPECIFICAÇÕES:<br/>Part Number: RM-TG-17-CLD<br/>Conteúdo: 5g<br/>Cor: Cinza<br/>Composição: Silicone e Prata<br/>Condutividade Térmica: > 17 W / mK<br/>Resistência à Temperatura: -50 ° C - 220 ° C<br/>Alta Condutividade Térmica<br/>Não Condutivo<br/>Fácil Aplicação<br/>Resistência à Corrosão<br/>`,

    descriptionE: `
    SPECIFICATIONS:<br/>Part Number: RM-TG-17-CLD<br/>Content: 5g<br/>Color: Gray<br/>Composition: Silicone and Silver<br/>Thermal Conductivity: > 17 W / mK<br/>Temperature Resistance: -50 ° C - 220 ° C<br/>High Thermal Conductivity<br/>Non-conductive<br/>Easy Application<br/>Corrosion Resistance<br/>`,
      
    productId: 'pasta-termica-silver-cold-17w',
    banner: '',
    youtubeUrl: '',
    images: [Cold17W],
    pageURL: 'pasta-termica-silver-cold-17w',
  },
];

export default products;
