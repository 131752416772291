import Atomic400_01 from '../../../img/Produtos/fonte/atomic-fonte/400.jpg';
import Atomic400_02 from '../../../img/Produtos/fonte/atomic-fonte/2.jpg';
import Atomic400_03 from '../../../img/Produtos/fonte/atomic-fonte/3.jpg';

import Atomic500_01 from '../../../img/Produtos/fonte/atomic-fonte/500.jpg';
import Atomic500_02 from '../../../img/Produtos/fonte/atomic-fonte/2.jpg';
import Atomic500_03 from '../../../img/Produtos/fonte/atomic-fonte/3.jpg';

import Atomic600_01 from '../../../img/Produtos/fonte/atomic-fonte/600.jpg';
import Atomic600_02 from '../../../img/Produtos/fonte/atomic-fonte/2.jpg';
import Atomic600_03 from '../../../img/Produtos/fonte/atomic-fonte/3.jpg';

import Zeus500_01 from '../../../img/Produtos/fonte/zeus-fonte/500w.jpg';
import Zeus650_01 from '../../../img/Produtos/fonte/zeus-fonte/650w.jpg';
import Zeus850_01 from '../../../img/Produtos/fonte/zeus-fonte/850w.jpg';
import ZeusBanner from '../../../img/Produtos/fonte/zeus-fonte/banner.jpg';

const products = [
  {
    id: 1,
    name: 'Atomic 400w Led WHITE',
    category: 'Fonte',
    descriptionP: `Marca: Rise Mode<br>
    Modelo: Fonte Rise Mode Atomic 400W
    Especificações:
    
    Part number: RM-FE-02-AT<br>
    Potência: 400W<br>
    Eficiência de 82%<br>
    PFC: ATIVO<br>
    Entrada AC, Certificação CE<br>
    Tamanho do ventilador: 120mm<br>
    Cor do LED: Branco<br>
    Conectores:
    
    Conector 24p: 1x<br>
    Conector 4+4p: 1x<br>
    Conector Floppy: 1x<br>
    Conector 4-Pin: 3x<br>
    Conector PCIe 6+2p: 2x<br>
    Conector SATA: 5x<br>
    EAN: 6015033513544`,

    descriptionE: `Brand: Rise Mode<br>
    Model: Rise Mode Atomic 400W<br>
    Specifications:<br>
    
    Part number: RM-FE-02-AT<br>
    Power: 400W<br>
    Efficiency: 82%<br>
    PFC: Active<br>
    AC Input: CE certified<br>
    Fan Size: 120mm<br>
    LED Color: White<br>
    Connectors:<br>
    
    24-pin Connector: 1x<br>
    4+4-pin Connector: 1x<br>
    Floppy Connector: 1x<br>
    4-Pin Connector: 3x<br>
    PCIe 6+2-pin Connector: 2x<br>
    SATA Connector: 5x<br>
    EAN: 6015033513544`,
      
    productId: 'atomic-400w-led',
    banner: '',
    youtubeUrl: '',
    images: [Atomic400_01, Atomic400_02, Atomic400_03],
    pageURL: 'atomic-400w-led',
  },
  {
    id: 2,
    name: 'Atomic 500w Led WHITE',
    category: 'Fonte',
    descriptionP: `Marca: Rise Mode<br>
    Modelo: Fonte Rise Mode Atomic 500W
    Especificações:
    
    Part Number: RM-FE-03-AT<br>
    Potência: 500W<br>
    Eficiência: 82%<br>
    PFC: Ativo<br>
    Entrada de CA certificada CE<br>
    Tamanho do ventilador: 120mm<br>
    Cor do LED: Branco
    Conectores:
    
    Conector 24-pin: 1x<br>
    Conector 4+4-pin: 1x<br>
    Conector Floppy: 1x<br>
    Conector 4-Pin: 3x<br>
    Conector PCIe 6+2-pin: 2x<br>
    Conector SATA: 5x
    EAN: 6015033513551`,

    descriptionE: `Brand: Rise Mode<br>
    Model: Rise Mode Atomic 500W
    Specifications:
    
    Part number: RM-FE-03-AT<br>
    Power: 500W<br>
    Efficiency: 82%<br>
    PFC: Active<br>
    AC Input: CE certified<br>
    Fan Size: 120mm<br>
    LED Color: White
    Connectors:
    
    24-pin Connector: 1x<br>
    4+4-pin Connector: 1x<br>
    Floppy Connector: 1x<br>
    4-Pin Connector: 3x<br>
    PCIe 6+2-pin Connector: 2x<br>
    SATA Connector: 5x
    EAN: 6015033513551`,
      
    productId: 'atomic-500w-led',
    banner: '',
    youtubeUrl: '',
    images: [Atomic500_01, Atomic500_02, Atomic500_03],
    pageURL: 'atomic-500w-led',
  },
  {
    id: 3,
    name: 'Atomic 600w Led WHITE',
    category: 'Fonte',
    descriptionP: `Marca: Rise Mode<br>
    Modelo: Fonte Rise Mode Atomic 600W
    Especificações:
    
    Part Number: RM-FE-04-AT<br>
    Potência: 600W<br>
    Eficiência: 82%<br>
    PFC: Ativo<br>
    Entrada de CA certificada CE<br>
    Tamanho do ventilador: 120mm<br>
    Cor do LED: Branco
    Conectores:
    
    Conector 24-pin: 1x<br>
    Conector 4+4-pin: 1x<br>
    Conector Floppy: 1x<br>
    Conector 4-Pin: 3x<br>
    Conector PCIe 6+2-pin: 2x<br>
    Conector SATA: 5x
    EAN: 6015033513568`,

    descriptionE: `Brand: Rise Mode<br>
    Model: Rise Mode Atomic 600W Power Supply<br>
    Specifications:<br>
    
    Part Number: RM-FE-04-AT<br>
    Power: 600W<br>
    Efficiency: 82%<br>
    PFC: Active<br>
    AC Input, CE certified<br>
    Fan Size: 120mm<br>
    LED Color: White<br>
    Connectors:<br>
    
    24-pin Connector: 1x<br>
    4+4-pin Connector: 1x<br>
    Floppy Connector: 1x<br>
    4-Pin Connector: 3x<br>
    PCIe 6+2-pin Connector: 2x<br>
    SATA Connector: 5x<br>
    EAN: 6015033513568<br>`,
      
    productId: 'atomic-600w-led',
    banner: '',
    youtubeUrl: '',
    images: [Atomic600_01, Atomic600_02, Atomic600_03],
    pageURL: 'atomic-600w-led',
  },
  {
    id: 4,
    name: 'Zeus 500w',
    category: 'Fonte',
    descriptionP: `Marca: Rise Mode<br>
    Modelo: Fonte Rise Mode Zeus 500W<br>
    Part Number: RM-PSU-01-BZ-500<br>
    Especificações:<br>
    
    Potência: 500W<br>
    Certificação: 80 Plus Bronze<br>
    Eficiência: 82-85%<br>
    PFC: Ativo<br>
    Tamanho do ventilador: 120mm<br>
    Conectores:<br>
    
    Conector 24 pinos: 1x<br>
    Conector 4+4 pinos: 1x<br>
    Conector Molex de 4 pinos: 2x<br>
    Conector PCIE 6+2 pinos: 2x<br>
    Conector SATA: 5x<br>
    Informações adicionais:<br>
    
    Proteção contra Sobretensão (OVP) para proteção contra sobretensão<br>
    Proteção contra Subtensão (UVP) para proteção contra subtensão<br>
    Proteção contra Sobrecarga de Energia (OPP) para proteção contra sobrecarga de energia<br>
    Proteção contra Sobrecorrente (OCP) para proteção contra sobrecorrente no trilho +12V<br>
    Proteção contra Excesso de Temperatura (OTP) para proteção contra temperatura excessiva<br>
    Proteção contra Curto-Circuito (SCP) para proteção contra curtos-circuitos<br>
    Proteção contra Surto Elétrico (SP) para proteção contra surtos elétricos<br>
    Proteção contra Corrente de Irrupção (ICP) para proteção contra corrente de irrupção<br>
    Operação sem Carga (NLO) para operação sem carga<br>`,

    descriptionE: `Brand: Rise Mode<br>
    Model: Rise Mode Zeus 500W<br>
    Part Number: RM-PSU-01-BZ-500<br>
    Specifications:<br>
    
    Power: 500W<br>
    Certification: 80 Plus Bronze<br>
    Efficiency: 82-85%<br>
    PFC: Active<br>
    Fan Size: 120mm<br>
    Connectors:<br>
    
    24-pin Connector: 1x<br>
    Conector 4+4p: 1x<br>
    Conector 4-Pin Molex: 2x<br>
    Conector PCIE 6+2p: 2x<br>
    Conector SATA: 5x<br>
    Additional Information:<br>
    
    Over Voltage Protection (OVP) for protection against overvoltage<br>
    Under Voltage Protection (UVP) for protection against undervoltage<br>
    Over Power Protection (OPP) for protection against power overload<br>
    Over Current Protection (OCP) for protection against overcurrent on the +12V rail<br>
    Over Temperature Protection (OTP) for protection against excessive temperature<br>
    Short-Circuit Protection (SCP) for protection against short circuits<br>
    Surge Protection (SP) for protection against electrical surges<br>
    Inrush Current Protection (ICP) for protection against inrush current<br>
    No-Load Operation (NLO) for operation without load<br>`,
      
    productId: 'zeus-500w',
    banner: ZeusBanner,
    youtubeUrl: '',
    images: [Zeus500_01],
    pageURL: 'zeus-500w',
  },
  {
    id: 5,
    name: 'Zeus 650w',
    category: 'Fonte',
    descriptionP: `Marca: Rise Mode<br>
    Modelo: Fonte Rise Mode Zeus 650W<br>
    Part Number: RM-PSU-01-BZ-650<br>
    Especificações:<br>
    
    Potência: 650W<br>
    Certificação: 80 Plus Bronze<br>
    Eficiência: 82-85%<br>
    PFC: Ativo<br>
    Tamanho do ventilador: 120mm<br>
    Conectores:<br>
    
    Conector 24 pinos: 1x<br>
    Conector 4+4 pinos: 1x<br>
    Conector Molex de 4 pinos: 3x<br>
    Conector PCIE 6+2 pinos: 2x<br>
    Conector SATA: 6x<br>
    Informações adicionais:<br>
    
    Proteção contra Sobretensão (OVP) para proteção contra sobretensão<br>
    Proteção contra Subtensão (UVP) para proteção contra subtensão<br>
    Proteção contra Sobrecarga de Energia (OPP) para proteção contra sobrecarga de energia<br>
    Proteção contra Sobrecorrente (OCP) para proteção contra sobrecorrente no trilho +12V<br>
    Proteção contra Excesso de Temperatura (OTP) para proteção contra temperatura excessiva<br>
    Proteção contra Curto-Circuito (SCP) para proteção contra curtos-circuitos<br>
    Proteção contra Surto Elétrico (SP) para proteção contra surtos elétricos<br>
    Proteção contra Corrente de Irrupção (ICP) para proteção contra corrente de irrupção<br>
    Operação sem Carga (NLO) para operação sem carga<br>`,

    descriptionE: `Brand: Rise Mode<br>
    Model: Rise Mode Zeus 650W<br>
    Part Number: RM-PSU-01-BZ-650<br>
    Specifications:<br>
    
    Power: 650W<br>
    Certification: 80 Plus Bronze<br>
    Efficiency: 82-85%<br>
    PFC: Active<br>
    Fan Size: 120mm<br>
    Connectors:<br>
    
    24-pin Connector: 1x<br>
    4+4-pin Connector: 1x<br>
    4-Pin Molex Connector: 3x<br>
    PCIE 6+2-pin Connector: 2x<br>
    SATA Connector: 6x<br>
    Additional Information:<br>
    
    Over Voltage Protection (OVP) for protection against overvoltage<br>
    Under Voltage Protection (UVP) for protection against undervoltage<br>
    Over Power Protection (OPP) for protection against power overload<br>
    Over Current Protection (OCP) for protection against overcurrent on the +12V rail<br>
    Over Temperature Protection (OTP) for protection against excessive temperature<br>
    Short-Circuit Protection (SCP) for protection against short circuits<br>
    Surge Protection (SP) for protection against electrical surges<br>
    Inrush Current Protection (ICP) for protection against inrush current<br>
    No-Load Operation (NLO) for operation without load<br>`,
      
    productId: 'zeus-650w',
    banner: ZeusBanner,
    youtubeUrl: '',
    images: [Zeus650_01],
    pageURL: 'zeus-650w',
  },
  {
    id: 6,
    name: 'Zeus 850w',
    category: 'Fonte',
    descriptionP: `Marca: Rise Mode<br>
    Modelo: Fonte Rise Mode Zeus 850W<br>
    Part Number: RM-PSU-01-GO-850<br>
    Especificações:<br>
    
    Potência: 850W<br>
    Certificação: 80 Plus Gold<br>
    Eficiência: 87-89%<br>
    PFC: Ativo<br>
    Tamanho do ventilador: 135mm<br>
    Padrão: ATX12V v3.0, EPS 2.92<br>
    Cabos: Totalmente modular<br>
    Conectores:<br>
    
    Conector 24 pinos: 1x<br>
    Conector 4+4 pinos: 2x<br>
    Conector 4-Pin Molex: 4x<br>
    Conector PCIE 6+2 pinos: 4x<br>
    Conector SATA: 12x<br>
    Conector PCIE 12+4 pinos (PCIE 5.0): 1x<br>
    Informações adicionais:<br>
    
    Proteção contra sobretensão (OVP) para proteção contra sobrecarga de tensão<br>
    Proteção contra subtensão (UVP) para proteção contra queda de tensão<br>
    Proteção contra sobrecarga de energia (OPP) para proteção contra sobrecarga de energia<br>
    Proteção contra sobrecorrente (OCP) para proteção contra sobrecorrente na linha +12V<br>
    Proteção contra excesso de temperatura (OTP) para proteção contra temperatura excessiva<br>
    Proteção contra curto-circuito (SCP) para proteção contra curto-circuito<br>
    Proteção contra surto elétrico (SP) para proteção contra surtos elétricos<br>
    Proteção contra corrente de irrupção (ICP) para proteção contra corrente de irrupção<br>
    Operação sem carga (NLO) para operação sem carga<br>`,

    descriptionE: `Brand: Rise Mode<br>
    Model: Rise Mode Zeus 850W<br>
    Part Number: RM-PSU-01-GO-850<br>
    Specifications:<br>
    
    Power: 850W<br>
    Certification: 80 Plus Gold<br>
    Efficiency: 87-89%<br>
    PFC: Active<br>
    Fan Size: 135mm<br>
    Standard: ATX12V v3.0, EPS 2.92<br>
    Cables: Full Modular<br>
    Connectors:<br>
    
    24-pin Connector: 1x<br>
    4+4-pin Connector: 2x<br>
    4-Pin Molex Connector: 4x<br>
    PCIE 6+2-pin Connector: 4x<br>
    SATA Connector: 12x<br>
    PCIE 12+4-pin Connector (Pcie 5.0): 1x<br>
    Additional Information:<br>
    
    Over Voltage Protection (OVP) for protection against overvoltage<br>
    Under Voltage Protection (UVP) for protection against undervoltage<br>
    Over Power Protection (OPP) for protection against power overload<br>
    Over Current Protection (OCP) for protection against overcurrent on the +12V rail<br>
    Over Temperature Protection (OTP) for protection against excessive temperature<br>
    Short-Circuit Protection (SCP) for protection against short circuits<br>
    Surge Protection (SP) for protection against electrical surges<br>
    Inrush Current Protection (ICP) for protection against inrush current<br>
    No-Load Operation (NLO) for operation without load<br>`,
      
    productId: 'zeus-850w',
    banner: ZeusBanner,
    youtubeUrl: '',
    images: [Zeus850_01],
    pageURL: 'zeus-850w',
  },
];

export default products;
