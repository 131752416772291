import X5Glass_01 from '../../../img/Produtos/gabinete/x5-glass-black/1.jpg';
import X5Glass_02 from '../../../img/Produtos/gabinete/x5-glass-black/2.jpg';
import X5Glass_03 from '../../../img/Produtos/gabinete/x5-glass-black/3.jpg';
import X5Glass_04 from '../../../img/Produtos/gabinete/x5-glass-black/4.jpg';
import X5Glass_05 from '../../../img/Produtos/gabinete/x5-glass-black/5.jpg';
import X5Glass_06 from '../../../img/Produtos/gabinete/x5-glass-black/6.jpg';
import X5Glass_Banner from '../../../img/Produtos/gabinete/x5-glass-black/banner.jpg';

import X4Glass_01 from '../../../img/Produtos/gabinete/x4-glass-black/1.jpg';
import X4Glass_02 from '../../../img/Produtos/gabinete/x4-glass-black/2.jpg';
import X4Glass_03 from '../../../img/Produtos/gabinete/x4-glass-black/3.jpg';
import X4Glass_04 from '../../../img/Produtos/gabinete/x4-glass-black/4.jpg';
import X4Glass_05 from '../../../img/Produtos/gabinete/x4-glass-black/5.jpg';
import X4Glass_06 from '../../../img/Produtos/gabinete/x4-glass-black/6.jpg';
import X4Glass_Banner from '../../../img/Produtos/gabinete/x4-glass-black/banner.jpg';

import GalaxyGlass_01 from '../../../img/Produtos/gabinete/galaxy-glass-sound-black/1.jpg';
import GalaxyGlass_02 from '../../../img/Produtos/gabinete/galaxy-glass-sound-black/2.jpg';
import GalaxyGlass_03 from '../../../img/Produtos/gabinete/galaxy-glass-sound-black/3.jpg';
import GalaxyGlass_04 from '../../../img/Produtos/gabinete/galaxy-glass-sound-black/4.jpg';
import GalaxyGlass_Banner from '../../../img/Produtos/gabinete/galaxy-glass-sound-black/banner.jpg';

import GalaxyGlassW_01 from '../../../img/Produtos/gabinete/galaxy-glass-sound-white/1.jpg';
import GalaxyGlassW_02 from '../../../img/Produtos/gabinete/galaxy-glass-sound-white/2.jpg';
import GalaxyGlassW_03 from '../../../img/Produtos/gabinete/galaxy-glass-sound-white/3.jpg';
import GalaxyGlassW_04 from '../../../img/Produtos/gabinete/galaxy-glass-sound-white/4.jpg';
import GalaxyGlassW_Banner from '../../../img/Produtos/gabinete/galaxy-glass-sound-white/banner.jpg';

import GalaxyGlassP_01 from '../../../img/Produtos/gabinete/galaxy-glass-sound-pink/1.jpg';
import GalaxyGlassP_02 from '../../../img/Produtos/gabinete/galaxy-glass-sound-pink/2.jpg';
import GalaxyGlassP_03 from '../../../img/Produtos/gabinete/galaxy-glass-sound-pink/3.jpg';
import GalaxyGlassP_04 from '../../../img/Produtos/gabinete/galaxy-glass-sound-pink/4.jpg';
import GalaxyGlassP_Banner from '../../../img/Produtos/gabinete/galaxy-glass-sound-pink/banner.jpg';

import GalaxyGlassBlack_01 from '../../../img/Produtos/gabinete/galaxy-glass-black/1.jpg';
import GalaxyGlassBlack_02 from '../../../img/Produtos/gabinete/galaxy-glass-black/2.jpg';
import GalaxyGlassBlack_03 from '../../../img/Produtos/gabinete/galaxy-glass-black/3.jpg';
import GalaxyGlassBlack_04 from '../../../img/Produtos/gabinete/galaxy-glass-black/4.jpg';
import GalaxyGlassBlack_Banner from '../../../img/Produtos/gabinete/galaxy-glass-black/banner.jpg';

import GalaxyGlassWhite_01 from '../../../img/Produtos/gabinete/galaxy-glass-white/1.jpg';
import GalaxyGlassWhite_02 from '../../../img/Produtos/gabinete/galaxy-glass-white/2.jpg';
import GalaxyGlassWhite_03 from '../../../img/Produtos/gabinete/galaxy-glass-white/3.jpg';
import GalaxyGlassWhite_04 from '../../../img/Produtos/gabinete/galaxy-glass-white/4.jpg';
import GalaxyGlassWhite_Banner from '../../../img/Produtos/gabinete/galaxy-glass-white/banner.jpg';

import GalaxyGlassPink_01 from '../../../img/Produtos/gabinete/galaxy-glass-pink/1.jpg';
import GalaxyGlassPink_02 from '../../../img/Produtos/gabinete/galaxy-glass-pink/2.jpg';
import GalaxyGlassPink_03 from '../../../img/Produtos/gabinete/galaxy-glass-pink/3.jpg';
import GalaxyGlassPink_04 from '../../../img/Produtos/gabinete/galaxy-glass-pink/4.jpg';
import GalaxyGlassPink_Banner from '../../../img/Produtos/gabinete/galaxy-glass-pink/banner.jpg';

import GalaxyStandard_01 from '../../../img/Produtos/gabinete/galaxy-glass-standard-black/1.jpg';
import GalaxyStandard_02 from '../../../img/Produtos/gabinete/galaxy-glass-standard-black/2.jpg';
import GalaxyStandard_03 from '../../../img/Produtos/gabinete/galaxy-glass-standard-black/3.jpg';
import GalaxyStandard_Banner from '../../../img/Produtos/gabinete/galaxy-glass-standard-black/banner.jpg';

import GalaxyStandardW_01 from '../../../img/Produtos/gabinete/galaxy-glass-standard-white/1.jpg';
import GalaxyStandardW_02 from '../../../img/Produtos/gabinete/galaxy-glass-standard-white/2.jpg';
import GalaxyStandardW_03 from '../../../img/Produtos/gabinete/galaxy-glass-standard-white/3.jpg';
import GalaxyStandardW_Banner from '../../../img/Produtos/gabinete/galaxy-glass-standard-white/banner.jpg';

import GalaxyGlassMB_01 from '../../../img/Produtos/gabinete/galaxy-glass-m-black/1.jpg';
import GalaxyGlassMB_02 from '../../../img/Produtos/gabinete/galaxy-glass-m-black/2.jpg';
import GalaxyGlassMB_03 from '../../../img/Produtos/gabinete/galaxy-glass-m-black/3.jpg';
import GalaxyGlassMB_04 from '../../../img/Produtos/gabinete/galaxy-glass-m-black/4.jpg';
import GalaxyGlassMB_Banner from '../../../img/Produtos/gabinete/galaxy-glass-m-black/banner.jpg';

import GalaxyGlassMW_01 from '../../../img/Produtos/gabinete/galaxy-glass-m-white/1.jpg';
import GalaxyGlassMW_02 from '../../../img/Produtos/gabinete/galaxy-glass-m-white/2.jpg';
import GalaxyGlassMW_03 from '../../../img/Produtos/gabinete/galaxy-glass-m-white/3.jpg';
import GalaxyGlassMW_04 from '../../../img/Produtos/gabinete/galaxy-glass-m-white/4.jpg';
import GalaxyGlassMW_Banner from '../../../img/Produtos/gabinete/galaxy-glass-m-white/banner.jpg';

import frostW_01 from '../../../img/Produtos/gabinete/glass-06x-frost-white/1.jpg';
import frostW_02 from '../../../img/Produtos/gabinete/glass-06x-frost-white/2.jpg';
import frostW_03 from '../../../img/Produtos/gabinete/glass-06x-frost-white/3.jpg';
import frostW_04 from '../../../img/Produtos/gabinete/glass-06x-frost-white/4.jpg';
import frostW_Banner from '../../../img/Produtos/gabinete/glass-06x-frost-white/banner.jpg';

import frostB_01 from '../../../img/Produtos/gabinete/glass-06x-black/1.jpg';
import frostB_02 from '../../../img/Produtos/gabinete/glass-06x-black/2.jpg';
import frostB_03 from '../../../img/Produtos/gabinete/glass-06x-black/3.jpg';
import frostB_04 from '../../../img/Produtos/gabinete/glass-06x-black/4.jpg';
import frostB_Banner from '../../../img/Produtos/gabinete/glass-06x-black/banner.jpg';

import Z01_01 from '../../../img/Produtos/gabinete/z01-black/1.jpg';
import Z01_02 from '../../../img/Produtos/gabinete/z01-black/2.jpg';
import Z01_03 from '../../../img/Produtos/gabinete/z01-black/3.jpg';
import Z01_Banner from '../../../img/Produtos/gabinete/z01-black/banner.jpg';

import Z02_01 from '../../../img/Produtos/gabinete/z02-black/1.jpg';
import Z02_02 from '../../../img/Produtos/gabinete/z02-black/2.jpg';
import Z02_03 from '../../../img/Produtos/gabinete/z02-black/3.jpg';
import Z02_Banner from '../../../img/Produtos/gabinete/z02-black/banner.jpg';

import Z03_01 from '../../../img/Produtos/gabinete/z03-black/1.jpg';
import Z03_02 from '../../../img/Produtos/gabinete/z03-black/2.jpg';
import Z03_03 from '../../../img/Produtos/gabinete/z03-black/3.jpg';
import Z03_Banner from '../../../img/Produtos/gabinete/z03-black/banner.jpg';

import office01_01 from '../../../img/Produtos/gabinete/office-01/1.jpg';
import office01_02 from '../../../img/Produtos/gabinete/office-01/2.jpg';
import office01_03 from '../../../img/Produtos/gabinete/office-01/3.jpg';
import office01_04 from '../../../img/Produtos/gabinete/office-01/4.jpg';
import office01_Banner from '../../../img/Produtos/gabinete/office-01/banner.jpg';

import GLASS06XBLACK6FANSARGB_01 from '../../../img/Produtos/gabinete/RM-CA-06XB-ARGB/1.jpg';
import GLASS06XBLACK6FANSARGB_02 from '../../../img/Produtos/gabinete/RM-CA-06XB-ARGB/2.jpg';
import GLASS06XBLACK6FANSARGB_03 from '../../../img/Produtos/gabinete/RM-CA-06XB-ARGB/3.jpg';
import GLASS06XBLACK6FANSARGB_04 from '../../../img/Produtos/gabinete/RM-CA-06XB-ARGB/4.jpg';
import GLASS06XBLACK6FANSARGB_05 from '../../../img/Produtos/gabinete/RM-CA-06XB-ARGB/5.jpg';
import GLASS06XBLACK6FANSARGB_06 from '../../../img/Produtos/gabinete/RM-CA-06XB-ARGB/6.jpg';
import GLASS06XBLACK6FANSARGB_07 from '../../../img/Produtos/gabinete/RM-CA-06XB-ARGB/7.jpg';
import GLASS06XBLACK6FANSARGB_08 from '../../../img/Produtos/gabinete/RM-CA-06XB-ARGB/8.jpg';

import GLASS06XWHITEK6FANSARGB_01 from '../../../img/Produtos/gabinete/RM-CA-06XW-ARGB/1.jpg';
import GLASS06XWHITEK6FANSARGB_02 from '../../../img/Produtos/gabinete/RM-CA-06XW-ARGB/2.jpg';
import GLASS06XWHITEK6FANSARGB_03 from '../../../img/Produtos/gabinete/RM-CA-06XW-ARGB/3.jpg';
import GLASS06XWHITEK6FANSARGB_04 from '../../../img/Produtos/gabinete/RM-CA-06XW-ARGB/4.jpg';
import GLASS06XWHITEK6FANSARGB_05 from '../../../img/Produtos/gabinete/RM-CA-06XW-ARGB/5.jpg';
import GLASS06XWHITEK6FANSARGB_06 from '../../../img/Produtos/gabinete/RM-CA-06XW-ARGB/6.jpg';
import GLASS06XWHITEK6FANSARGB_07 from '../../../img/Produtos/gabinete/RM-CA-06XW-ARGB/7.jpg';

import GLASSSTANDARDBLACK10FANS_01 from '../../../img/Produtos/gabinete/RM-GA-GGSB-ARGB/1.jpg';
import GLASSSTANDARDBLACK10FANS_02 from '../../../img/Produtos/gabinete/RM-GA-GGSB-ARGB/2.png';
import GLASSSTANDARDBLACK10FANS_03 from '../../../img/Produtos/gabinete/RM-GA-GGSB-ARGB/3.jpg';
import GLASSSTANDARDBLACK10FANS_04 from '../../../img/Produtos/gabinete/RM-GA-GGSB-ARGB/4.jpg';
import GLASSSTANDARDBLACK10FANS_05 from '../../../img/Produtos/gabinete/RM-GA-GGSB-ARGB/5.jpg';
import GLASSSTANDARDBLACK10FANS_06 from '../../../img/Produtos/gabinete/RM-GA-GGSB-ARGB/6.jpg';


import GLASSSTANDARDWHITE10FANS_01 from '../../../img/Produtos/gabinete/RM-GA-GGSW-ARGB/1.jpg';
import GLASSSTANDARDWHITE10FANS_02 from '../../../img/Produtos/gabinete/RM-GA-GGSW-ARGB/2.png';
import GLASSSTANDARDWHITE10FANS_03 from '../../../img/Produtos/gabinete/RM-GA-GGSW-ARGB/3.jpg';
import GLASSSTANDARDWHITE10FANS_04 from '../../../img/Produtos/gabinete/RM-GA-GGSW-ARGB/4.jpg';
import GLASSSTANDARDWHITE10FANS_05 from '../../../img/Produtos/gabinete/RM-GA-GGSW-ARGB/5.jpg';
import GLASSSTANDARDWHITE10FANS_06 from '../../../img/Produtos/gabinete/RM-GA-GGSW-ARGB/6.jpg';
import GLASSSTANDARDWHITE10FANS_07 from '../../../img/Produtos/gabinete/RM-GA-GGSW-ARGB/7.jpg';

import WAVEWHITE3FANSARGB01 from '../../../img/Produtos/gabinete/RM-WA-BW-ARGB/1.jpg';
import WAVEWHITE3FANSARGB02 from '../../../img/Produtos/gabinete/RM-WA-BW-ARGB/2.jpg';
import WAVEWHITE3FANSARGB03 from '../../../img/Produtos/gabinete/RM-WA-BW-ARGB/3.png';
import WAVEWHITE3FANSARGB04 from '../../../img/Produtos/gabinete/RM-WA-BW-ARGB/4.jpg';
import WAVEWHITE3FANSARGB05 from '../../../img/Produtos/gabinete/RM-WA-BW-ARGB/5.jpg';
import WAVEWHITE3FANSARGB06 from '../../../img/Produtos/gabinete/RM-WA-BW-ARGB/6.jpg';

import WAVEBLACKFANSARGB01 from '../../../img/Produtos/gabinete/RM-WA-FB-ARGB/1.jpg';
import WAVEBLACKFANSARGB02 from '../../../img/Produtos/gabinete/RM-WA-FB-ARGB/02.jpg';
import WAVEBLACKFANSARGB03 from '../../../img/Produtos/gabinete/RM-WA-FB-ARGB/03.jpg';
import WAVEBLACKFANSARGB04 from '../../../img/Produtos/gabinete/RM-WA-FB-ARGB/04.jpg';
import WAVEBLACKFANSARGB05 from '../../../img/Produtos/gabinete/RM-WA-FB-ARGB/05.jpg';
import WAVEBLACKFANSARGB06 from '../../../img/Produtos/gabinete/RM-WA-FB-ARGB/06.jpg';


const products = [
  {
    id: 3,
    name: 'GALAXY GLASS SOUND BLACK',
    category: 'gabinete',
    descriptionP: "GABINETE RISE MODE GALAXY GLASS SOUND BLACK – SEM FANS<br>Marca: Rise Mode<br>Modelo: Gabinete Rise Mode GALAXY GLASS SOUND BLACK – SEM FANS<br><br>ESPECIFICAÇÕES:<br>Part Number: RM-GA-GGS-FB<br>Cor: Preto<br><br>- Dimensões (L x W x H): L 417mm x W 280mm x H 427mm<br>- Fans: Suporte para 10 fans (fans não inclusos)<br>* Produto não acompanha fans, imagem ilustrativa<br>- Led Frontal: ARGB com suporte a led rítmico (pisca no ritmo da música)<br>- Baias: 3.5” HDD (x2) | 2.5” SSD (x2)<br>- Slots de Expansão: 7<br>- Placa-Mãe: ATX / M-ATX / ITX<br>- Frontal I/O: x2 USB 2.0 | x1 USB 3.0<br>HD Áudio Entrada e Saída<br>- Fontes de Alimentação: ATX (não inclusa)<br><br>Informações adicionais:<br>- Lateral Vidro Temperado<br>- Frontal em Vidro Temperado<br>- Suporte a Placa de Vídeo até 400MM<br>- Suporte Air cooler até 160mm<br><br>Ean: 6015033519577",

    descriptionE: "CASE RISE MODE GALAXY GLASS SOUND BLACK - NO FANS<br>Brand: Rise Mode<br>Model: Case Rise Mode GALAXY GLASS SOUND BLACK - NO FANS<br><br>SPECIFICATIONS:<br>Part Number: RM-GA-GGS-FB<br>Color: Black<br><br>- Dimensions (L x W x H): L 417mm x W 280mm x H 427mm<br>- Fans: Support for 10 fans (fans not included)<br>* Product does not come with fans, illustrative image<br>- Front LED: ARGB with support for rhythmical LED (flashes to the rhythm of music)<br>- Bays: 3.5 HDD (x2) | 2.5 SSD (x2)<br>- Expansion Slots: 7<br>- Motherboard: ATX / M-ATX / ITX<br>- Front I/O: x2 USB 2.0 | x1 USB 3.0<br>HD Audio Input and Output<br>- Power Supply: ATX (not included)<br><br>Additional Information:<br>- Tempered Glass Side Panel<br>- Tempered Glass Front Panel<br>- Support for Graphics Card up to 400mm<br>- Support for Air Cooler up to 160mm<br><br>EAN: 6015033519577",
      
    productId: 'galaxy-glass-sound-black',
    banner: GalaxyGlass_Banner,
    youtubeUrl: 'gslHXMi0xUs',
    images: [GalaxyGlass_01, GalaxyGlass_02, GalaxyGlass_03, GalaxyGlass_04],
    pageURL: 'galaxy-glass-sound-black',
  },
  {
    id: 4,
    name: 'GALAXY GLASS SOUND WHITE',
    category: 'gabinete',
    descriptionP:  "GABINETE RISE MODE GALAXY GLASS SOUND WHITE – SEM FANS<br>Marca: Rise Mode<br>Modelo: Gabinete Rise Mode GALAXY GLASS SOUND WHITE – SEM FANS<br><br>ESPECIFICAÇÕES:<br>Part Number: RM-GA-GGS-FW<br>Cor: Branco<br><br>- Dimensões (L x W x H): L 417mm x W 280mm x H 427mm<br>- Fans: Suporte para 10 fans (fans não inclusos)<br>* Produto não acompanha fans, imagem ilustrativa<br>- Led Frontal: ARGB com suporte a led rítmico (pisca no ritmo da música)<br>- Baias: 3.5” HDD (x2) | 2.5” SSD (x2)<br>- Slots de Expansão: 7<br>- Placa-Mãe: ATX / M-ATX / ITX<br>- Frontal I/O: x2 USB 2.0 | x1 USB 3.0<br>HD Áudio Entrada e Saída<br>- Fontes de Alimentação: ATX (não inclusa)<br><br>Informações adicionais:<br>- Lateral Vidro Temperado<br>- Frontal em Vidro Temperado<br>- Suporte a Placa de Vídeo até 400MM<br>- Suporte Air cooler até 160mm<br><br>Ean: 6015033519584",

    descriptionE: "CASE RISE MODE GALAXY GLASS SOUND WHITE - NO FANS<br>Brand: Rise Mode<br>Model: Case Rise Mode GALAXY GLASS SOUND WHITE - NO FANS<br><br>SPECIFICATIONS:<br>Part Number: RM-GA-GGS-FW<br>Color: White<br><br>- Dimensions (L x W x H): L 417mm x W 280mm x H 427mm<br>- Fans: Support for 10 fans (fans not included)<br>* Product does not come with fans, illustrative image<br>- Front LED: ARGB with support for rhythmical LED (flashes to the rhythm of music)<br>- Bays: 3.5 HDD (x2) | 2.5 SSD (x2)<br>- Expansion Slots: 7<br>- Motherboard: ATX / M-ATX / ITX<br>- Front I/O: x2 USB 2.0 | x1 USB 3.0<br>HD Audio Input and Output<br>- Power Supply: ATX (not included)<br><br>Additional Information:<br>- Tempered Glass Side Panel<br>- Tempered Glass Front Panel<br>- Support for Graphics Card up to 400mm<br>- Support for Air Cooler up to 160mm<br><br>EAN: 6015033519584",
      
    productId: 'galaxy-glass-sound-white',
    banner: GalaxyGlassW_Banner,
    youtubeUrl: 'gslHXMi0xUs',
    images: [GalaxyGlassW_01, GalaxyGlassW_02, GalaxyGlassW_03, GalaxyGlassW_04],
    pageURL: 'galaxy-glass-sound-white',
  },
  {
    id: 5,
    name: 'GALAXY GLASS SOUND PINK',
    category: 'gabinete',
    descriptionP:  "GABINETE RISE MODE GALAXY GLASS SOUND PINK – SEM FANS<br>Marca: Rise Mode<br>Modelo: Gabinete Rise Mode GALAXY GLASS SOUND PINK – SEM FANS<br><br>ESPECIFICAÇÕES:<br>Part Number: RM-GA-GGS-PK<br>Cor: Rosa<br><br>- Dimensões (L x W x H): L 417mm x W 280mm x H 427mm<br>- Fans: Suporte para 10 fans (fans não inclusos)<br>* Produto não acompanha fans, imagem ilustrativa<br>- Led Frontal: ARGB com suporte a led rítmico (pisca no ritmo da música)<br>- Baias: 3.5” HDD (x2) | 2.5” SSD (x2)<br>- Slots de Expansão: 7<br>- Placa-Mãe: ATX / M-ATX / ITX<br>- Frontal I/O: x2 USB 2.0 | x1 USB 3.0<br>HD Áudio Entrada e Saída<br>- Fontes de Alimentação: ATX (não inclusa)<br><br>Informações adicionais:<br>- Lateral Vidro Temperado<br>- Frontal em Vidro Temperado<br>- Suporte a Placa de Vídeo até 400MM<br>- Suporte Air cooler até 160mm<br><br>EAN: 6015033519591",

    descriptionE:  "CASE RISE MODE GALAXY GLASS SOUND PINK - NO FANS<br>Brand: Rise Mode<br>Model: Case Rise Mode GALAXY GLASS SOUND PINK - NO FANS<br><br>SPECIFICATIONS:<br>Part Number: RM-GA-GGS-PK<br>Color: Pink<br><br>- Dimensions (L x W x H): L 417mm x W 280mm x H 427mm<br>- Fans: Support for 10 fans (fans not included)<br>* Product does not come with fans, illustrative image<br>- Front LED: ARGB with support for rhythmical LED (flashes to the rhythm of music)<br>- Bays: 3.5 HDD (x2) | 2.5 SSD (x2)<br>- Expansion Slots: 7<br>- Motherboard: ATX / M-ATX / ITX<br>- Front I/O: x2 USB 2.0 | x1 USB 3.0<br>HD Audio Input and Output<br>- Power Supply: ATX (not included)<br><br>Additional Information:<br>- Tempered Glass Side Panel<br>- Tempered Glass Front Panel<br>- Support for Graphics Card up to 400mm<br>- Support for Air Cooler up to 160mm<br><br>EAN: 6015033519591",
      
    productId: 'galaxy-glass-sound-pink',
    banner: GalaxyGlassP_Banner,
    youtubeUrl: 'gslHXMi0xUs',
    images: [GalaxyGlassP_01, GalaxyGlassP_02, GalaxyGlassP_03, GalaxyGlassP_04],
    pageURL: 'galaxy-glass-sound-pink',
  },
  {
    id: 6,
    name: 'GALAXY GLASS BLACK',
    category: 'gabinete',
    descriptionP:  "GABINETE RISE MODE GALAXY GLASS BLACK – SEM FANS<br>Marca: Rise Mode<br>Modelo: Gabinete Rise Mode GALAXY BLACK – SEM FANS<br><br>ESPECIFICAÇÕES:<br>Part Number: RM-GA-GG-FB<br>Cor: Preto<br><br>- Dimensões (L x W x H): L 440mm x W 280mm x H 427mm<br>- Fans: Suporte para 10 fans (fans não inclusos)<br>* Produto não acompanha fans, imagem ilustrativa<br>- Baias: 3.5” HDD (x2) | 2.5” SSD (x2)<br>- Slots de Expansão: 7<br>- Placa-Mãe: ATX / M-ATX / ITX<br>- Frontal I/O: x2 USB 2.0 | x1 USB 3.0<br>HD Áudio Entrada e Saída<br>- Fontes de Alimentação: ATX (não inclusa)<br><br>Informações adicionais:<br>- Lateral Vidro Temperado<br>- Frontal em Vidro Temperado<br>- Suporte a Placa de Vídeo até 400MM<br>- Suporte Air cooler até 160mm<br><br>Ean: 6015033521501",

    descriptionE:  "CASE RISE MODE GALAXY GLASS BLACK – NO FANS<br>Brand: Rise Mode<br>Model: Case Rise Mode GALAXY BLACK – NO FANS<br><br>SPECIFICATIONS:<br>Part Number: RM-GA-GG-FB<br>Color: Black<br><br>- Dimensions (L x W x H): L 440mm x W 280mm x H 427mm<br>- Fans: Support for 10 fans (fans not included)<br>* Product does not come with fans, illustrative image<br>- Bays: 3.5 HDD (x2) | 2.5 SSD (x2)<br>- Expansion Slots: 7<br>- Motherboard: ATX / M-ATX / ITX<br>- Front I/O: x2 USB 2.0 | x1 USB 3.0<br>HD Audio Input and Output<br>- Power Supply: ATX (not included)<br><br>Additional Information:<br>- Tempered Glass Side Panel<br>- Tempered Glass Front Panel<br>- Support for Graphics Card up to 400mm<br>- Support for Air Cooler up to 160mm<br><br>EAN: 6015033521501",
      
    productId: 'galaxy-glass-black',
    banner: GalaxyGlassBlack_Banner,
    youtubeUrl: 'jJWVqomvk8s',
    images: [GalaxyGlassBlack_01, GalaxyGlassBlack_02, GalaxyGlassBlack_03, GalaxyGlassBlack_04],
    pageURL: 'galaxy-glass-black',
  },
  {
    id: 7,
    name: 'GALAXY GLASS WHITE',
    category: 'gabinete',
    descriptionP:  "GABINETE RISE MODE GALAXY Glass Branco - SEM FANS<br>Marca: Rise Mode<br>Modelo: Gabinete Rise Mode GALAXY Glass Branco - SEM FANS<br><br>ESPECIFICAÇÕES:<br>Part Number: RM-GA-GG-FW<br>Cor: Branco<br><br>- Dimensões (L x W x H): L 440mm x W 280mm x H 427mm<br>- Fans: Suporte para 10 fans (fans não inclusos)<br>* Produto não acompanha fans, imagem ilustrativa<br>- Baias: 3.5” HDD (x2) | 2.5” SSD (x2)<br>- Slots de Expansão: 7<br>- Placa-Mãe: ATX / M-ATX<br>- Frontal I/O: x2 USB 2.0 | x1 USB 3.0<br>HD Áudio Entrada e Saída<br>- Fontes de Alimentação: ATX (não inclusa)<br><br>Informações adicionais:<br>- Lateral Vidro Temperado<br>- Frontal em Vidro Temperado<br>- Suporte a Placa de Vídeo até 400MM<br>- Suporte Air cooler até 160mm<br><br>EAN: 6015033520597",

    descriptionE:  "CASE RISE MODE GALAXY Glass White - NO FANS<br>Brand: Rise Mode<br>Model: Case Rise Mode GALAXY Glass White - NO FANS<br><br>SPECIFICATIONS:<br>Part Number: RM-GA-GG-FW<br>Color: White<br><br>- Dimensions (L x W x H): L 440mm x W 280mm x H 427mm<br>- Fans: Support for 10 fans (fans not included)<br>* Product does not come with fans, illustrative image<br>- Bays: 3.5 HDD (x2) | 2.5 SSD (x2)<br>- Expansion Slots: 7<br>- Motherboard: ATX / M-ATX<br>- Front I/O: x2 USB 2.0 | x1 USB 3.0<br>HD Audio Input and Output<br>- Power Supply: ATX (not included)<br><br>Additional Information:<br>- Tempered Glass Side Panel<br>- Tempered Glass Front Panel<br>- Support for Graphics Card up to 400mm<br>- Support for Air Cooler up to 160mm<br><br>EAN: 6015033520597",
      
    productId: 'galaxy-glass-white',
    banner: GalaxyGlassWhite_Banner,
    youtubeUrl: 'jJWVqomvk8s',
    images: [GalaxyGlassWhite_01, GalaxyGlassWhite_02, GalaxyGlassWhite_03, GalaxyGlassWhite_04],
    pageURL: 'galaxy-glass-white',
  },
  {
    id: 8,
    name: 'GALAXY GLASS PINK',
    category: 'gabinete',
    descriptionP:  "GABINETE RISE MODE GALAXY Glass Pink - SEM FANS<br>Marca: Rise Mode<br>Modelo: Gabinete Rise Mode GALAXY Glass Pink - SEM FANS<br><br>ESPECIFICAÇÕES:<br>Part Number: RM-GA-GG-FW<br>Cor: Branco<br><br>- Dimensões (L x W x H): L 440mm x W 280mm x H 427mm<br>- Fans: Suporte para 10 fans (fans não inclusos)<br>* Produto não acompanha fans, imagem ilustrativa<br>- Baias: 3.5” HDD (x2) | 2.5” SSD (x2)<br>- Slots de Expansão: 7<br>- Placa-Mãe: ATX / M-ATX<br>- Frontal I/O: x2 USB 2.0 | x1 USB 3.0<br>HD Áudio Entrada e Saída<br>- Fontes de Alimentação: ATX (não inclusa)<br><br>Informações adicionais:<br>- Lateral Vidro Temperado<br>- Frontal em Vidro Temperado<br>- Suporte a Placa de Vídeo até 400MM<br>- Suporte Air cooler até 160mm<br><br>EAN: 6015033520597",

    descriptionE:  "CASE RISE MODE GALAXY Glass Pink - NO FANS<br>Brand: Rise Mode<br>Model: Case Rise Mode GALAXY Glass Pink - NO FANS<br><br>SPECIFICATIONS:<br>Part Number: RM-GA-GG-FW<br>Color: White<br><br>- Dimensions (L x W x H): L 440mm x W 280mm x H 427mm<br>- Fans: Support for 10 fans (fans not included)<br>* Product does not come with fans, illustrative image<br>- Bays: 3.5 HDD (x2) | 2.5 SSD (x2)<br>- Expansion Slots: 7<br>- Motherboard: ATX / M-ATX<br>- Front I/O: x2 USB 2.0 | x1 USB 3.0<br>HD Audio Input and Output<br>- Power Supply: ATX (not included)<br><br>Additional Information:<br>- Tempered Glass Side Panel<br>- Tempered Glass Front Panel<br>- Support for Graphics Card up to 400mm<br>- Support for Air Cooler up to 160mm<br><br>EAN: 6015033520597",
      
    productId: 'galaxy-glass-pink',
    banner: GalaxyGlassPink_Banner,
    youtubeUrl: 'jJWVqomvk8s',
    images: [GalaxyGlassPink_01, GalaxyGlassPink_02, GalaxyGlassPink_03, GalaxyGlassPink_04],
    pageURL: 'galaxy-glass-pink',
  },
  {
    id: 9,
    name: 'galaxy glass standard black',
    category: 'gabinete',
    descriptionP:   "GABINETE RISE MODE GALAXY GLASS STANDARD BLACK – SEM FANS<br>Marca: Rise Mode<br>Modelo: Gabinete Rise Mode GALAXY GLASS STANDARD BLACK – SEM FANS<br><br>ESPECIFICAÇÕES:<br>Part Number: RM-GA-GGST-FB<br>Cor: Preto<br><br>- Dimensões (L x W x H): L 420mm x W 272mm x H 399mm<br>- Fans: Suporte para 10 fans (fans não inclusos)<br>* Produto não acompanha fans, imagem ilustrativa<br>- Baias: 3.5” HDD (x1) | 2.5” SSD (x2)<br>- Slots de Expansão: 7<br>- Placa-Mãe: ATX / M-ATX / ITX<br>- Frontal I/O: x2 USB 2.0 | x1 USB 3.0<br>HD Áudio Entrada e Saída<br>- Fontes de Alimentação: ATX (não inclusa)<br><br>Informações adicionais:<br>- Lateral Vidro Temperado<br>- Frontal em Vidro Temperado<br>- Suporte a Placa de Vídeo até 360MM<br>- Suporte Air cooler até 158mm<br><br>EAN: 6015033520504",

    descriptionE:  "CASE RISE MODE GALAXY GLASS STANDARD BLACK – NO FANS<br>Brand: Rise Mode<br>Model: Case Rise Mode GALAXY GLASS STANDARD BLACK – NO FANS<br><br>SPECIFICATIONS:<br>Part Number: RM-GA-GGST-FB<br>Color: Black<br><br>- Dimensions (L x W x H): L 420mm x W 272mm x H 399mm<br>- Fans: Support for 10 fans (fans not included)<br>* Product does not come with fans, illustrative image<br>- Bays: 3.5 HDD (x1) | 2.5 SSD (x2)<br>- Expansion Slots: 7<br>- Motherboard: ATX / M-ATX / ITX<br>- Front I/O: x2 USB 2.0 | x1 USB 3.0<br>HD Audio Input and Output<br>- Power Supply: ATX (not included)<br><br>Additional Information:<br>- Tempered Glass Side Panel<br>- Tempered Glass Front Panel<br>- Support for Graphics Card up to 360mm<br>- Support for Air Cooler up to 158mm<br><br>EAN: 6015033520504",
      
    productId: 'galaxy-glass-standard-black',
    banner: GalaxyStandard_Banner,
    youtubeUrl: 'BTq5509dFJc',
    images: [GalaxyStandard_01, GalaxyStandard_02, GalaxyStandard_03],
    pageURL: 'galaxy-glass-standard-black',
  },
  {
    id: 10,
    name: 'galaxy glass standard white',
    category: 'gabinete',
    descriptionP: "GABINETE RISE MODE GALAXY GLASS STANDARD WHITE – SEM FANS<br>Marca: Rise Mode<br>Modelo: Gabinete Rise Mode GALAXY GLASS STANDARD WHITE – SEM FANS<br><br>ESPECIFICAÇÕES:<br>Part Number: RM-GA-GGST-FW<br>Cor: Branco<br><br>- Dimensões (L x W x H): L 420mm x W 272mm x H 399mm<br>- Fans: Suporte para 10 fans (fans não inclusos)<br>* Produto não acompanha fans, imagem ilustrativa<br>- Baias: 3.5” HDD (x1) | 2.5” SSD (x2)<br>- Slots de Expansão: 7<br>- Placa-Mãe: ATX / M-ATX / ITX<br>- Frontal I/O: x2 USB 2.0 | x1 USB 3.0<br>HD Áudio Entrada e Saída<br>- Fontes de Alimentação: ATX (não inclusa)<br><br>Informações adicionais:<br>- Lateral Vidro Temperado<br>- Frontal em Vidro Temperado<br>- Suporte a Placa de Vídeo até 360MM<br>- Suporte Air cooler até 158mm<br><br>EAN: 6015033497486",

    descriptionE: "CASE RISE MODE GALAXY GLASS STANDARD WHITE – NO FANS<br>Brand: Rise Mode<br>Model: Case Rise Mode GALAXY GLASS STANDARD WHITE – NO FANS<br><br>SPECIFICATIONS:<br>Part Number: RM-GA-GGST-FW<br>Color: White<br><br>- Dimensions (L x W x H): L 420mm x W 272mm x H 399mm<br>- Fans: Support for 10 fans (fans not included)<br>* Product does not come with fans, illustrative image<br>- Bays: 3.5 HDD (x1) | 2.5 SSD (x2)<br>- Expansion Slots: 7<br>- Motherboard: ATX / M-ATX / ITX<br>- Front I/O: x2 USB 2.0 | x1 USB 3.0<br>HD Audio Input and Output<br>- Power Supply: ATX (not included)<br><br>Additional Information:<br>- Tempered Glass Side Panel<br>- Tempered Glass Front Panel<br>- Support for Graphics Card up to 360mm<br>- Support for Air Cooler up to 158mm<br><br>EAN: 6015033497486",
      
    productId: 'galaxy-glass-standard-white',
    banner: GalaxyStandardW_Banner,
    youtubeUrl: 'BTq5509dFJc',
    images: [GalaxyStandardW_01, GalaxyStandardW_02, GalaxyStandardW_03],
    pageURL: 'galaxy-glass-standard-white',
  },
  {
    id: 11,
    name: 'GALAXY GLASS M BLACK',
    category: 'gabinete',
    descriptionP: "GABINETE RISE MODE GALAXY GLASS M BLACK – SEM FANS<br>Modelo: Gabinete Rise Mode GALAXY GLASS M BLACK – SEM FANS<br><br>ESPECIFICAÇÕES:<br>Part Number: RM-GA-GGM-FB<br>Cor: Preto<br><br>- Dimensões (L x W x H): L 415mm x W 280mm x H 377mm<br>- Fans: Suporte para 9 fans (fans não inclusos)<br>* Produto não acompanha fans, imagem ilustrativa<br>- Baias: 3.5” HDD (x2) | 2.5” SSD (x3)<br>- Slots de Expansão: 4<br>- Placa-Mãe: M-ATX / ITX<br>- Frontal I/O: x1 USB 2.0 | x1 USB 3.0<br>HD Áudio Entrada e Saída<br>- Fontes de Alimentação: ATX (não inclusa)<br><br>Informações adicionais:<br>- Lateral Vidro Temperado<br>- Frontal em Vidro Temperado<br>- Suporte a Placa de Vídeo até 400MM<br>- Suporte Air cooler até 157mm<br><br>EAN: 6015033520528",

    descriptionE:  "CASE RISE MODE GALAXY GLASS M BLACK – NO FANS<br>Model: Case Rise Mode GALAXY GLASS M BLACK – NO FANS<br><br>SPECIFICATIONS:<br>Part Number: RM-GA-GGM-FB<br>Color: Black<br><br>- Dimensions (L x W x H): L 415mm x W 280mm x H 377mm<br>- Fans: Support for 9 fans (fans not included)<br>* Product does not come with fans, illustrative image<br>- Bays: 3.5 HDD (x2) | 2.5 SSD (x3)<br>- Expansion Slots: 4<br>- Motherboard: M-ATX / ITX<br>- Front I/O: x1 USB 2.0 | x1 USB 3.0<br>HD Audio Input and Output<br>- Power Supply: ATX (not included)<br><br>Additional Information:<br>- Tempered Glass Side Panel<br>- Tempered Glass Front Panel<br>- Support for Graphics Card up to 400mm<br>- Support for Air Cooler up to 157mm<br><br>EAN: 6015033520528",
      
    productId: 'galaxy-glass-m-black',
    banner: GalaxyGlassMB_Banner,
    youtubeUrl: '',
    images: [GalaxyGlassMB_01, GalaxyGlassMB_02, GalaxyGlassMB_03, GalaxyGlassMB_04],
    pageURL: 'galaxy-glass-m-black',
  },
  {
    id: 12,
    name: 'GALAXY GLASS M WHITE',
    category: 'gabinete',
    descriptionP: "GABINETE RISE MODE GALAXY GLASS M WHITE – SEM FANS<br>Modelo: Gabinete Rise Mode GALAXY GLASS M WHITE – SEM FANS<br><br>ESPECIFICAÇÕES:<br>Part Number: RM-GA-GGM-FW<br>Cor: Branco<br><br>- Dimensões (L x W x H): L 415mm x W 280mm x H 377mm<br>- Fans: Suporte para 9 fans (fans não inclusos)<br>* Produto não acompanha fans, imagem ilustrativa<br>- Baias: 3.5” HDD (x2) | 2.5” SSD (x3)<br>- Slots de Expansão: 4<br>- Placa-Mãe: M-ATX / ITX<br>- Frontal I/O: x1 USB 2.0 | x1 USB 3.0<br>HD Áudio Entrada e Saída<br>- Fontes de Alimentação: ATX (não inclusa)<br><br>Informações adicionais:<br>- Lateral Vidro Temperado<br>- Frontal em Vidro Temperado<br>- Suporte a Placa de Vídeo até 400MM<br>- Suporte Air cooler até 157mm<br><br>EAN: 6015033524540",

    descriptionE:  "CASE RISE MODE GALAXY GLASS M WHITE – NO FANS<br>Model: Case Rise Mode GALAXY GLASS M WHITE – NO FANS<br><br>SPECIFICATIONS:<br>Part Number: RM-GA-GGM-FW<br>Color: White<br><br>- Dimensions (L x W x H): L 415mm x W 280mm x H 377mm<br>- Fans: Support for 9 fans (fans not included)<br>* Product does not come with fans, illustrative image<br>- Bays: 3.5 HDD (x2) | 2.5 SSD (x3)<br>- Expansion Slots: 4<br>- Motherboard: M-ATX / ITX<br>- Front I/O: x1 USB 2.0 | x1 USB 3.0<br>HD Audio Input and Output<br>- Power Supply: ATX (not included)<br><br>Additional Information:<br>- Tempered Glass Side Panel<br>- Tempered Glass Front Panel<br>- Support for Graphics Card up to 400mm<br>- Support for Air Cooler up to 157mm<br><br>EAN: 6015033524540",
      
    productId: 'galaxy-glass-m-white',
    banner: GalaxyGlassMW_Banner,
    youtubeUrl: '',
    images: [GalaxyGlassMW_01, GalaxyGlassMW_02, GalaxyGlassMW_03, GalaxyGlassMW_04],
    pageURL: 'galaxy-glass-m-white',
  },
  {
    id: 1,
    name: 'X5 GLASS BLACK',
    category: 'gabinete',
    descriptionP: "PROTEGE SEU HEADSET COM ESTILO!<br><br>" +
    "A LINHA DE SUPORTE HEADSET RISE MODE GAMER além de proteger seu headset para os momentos que você não está usando, dá um estilo único e arrojado para o ambiente do seu PC.<br><br>" +
    "Projetado para suportar e guardar qualquer Headset ou fone de ouvido, o suporte é produzido com dupla camada de acrílico da mais alta qualidade, proporcionando robustez e durabilidade.<br><br>" +
    "Por ser um suporte com uma base firme e regular, ele se torna estável na mesa, evitando a queda do fone caso aconteça algum impacto. Além disso, evita que seu headset seja riscado quando fica solto na mesa. E, é claro, conta sempre com o acabamento profissional cortado a laser que só a RISE MODE oferece.<br><br>" +
    "Especificações: Marca: RISE MODE / Modelo: SUPORTE HEADSET RISE MODE GAMER - VENON V3 / Part number: RM-VN-05-BR / Material: ACRÍLICO / Acabamento: CORTADO A LASER / Suporta: 1 HEADSET / Dimensões do produto: 26,8CM X 15,5CM X 14CM / Dimensões da caixa: 28CM X 15,5CM X 14CM / Peso: 185 GRAMAS (BRUTO SEM EMBALAGEM) / Peso com caixa: 314 GRAMAS (BRUTO COM EMBALAGEM) / EAN: 7895099518825" ,
      
    descriptionE:
    "PROTECT YOUR HEADSET WITH STYLE!<br><br>" +
    "The RISE MODE GAMER HEADSET SUPPORT LINE not only protects your headset when you're not using it but also adds a unique and bold style to your PC environment.<br><br>" +
    "Designed to support and store any headset or headphones, the support is made with a double layer of high-quality acrylic, providing robustness and durability.<br><br>" +
    "Being a support with a firm and regular base, it remains stable on the desk, preventing the headset from falling in case of any impact. It also avoids scratching your headset when it's left loose on the desk, and of course, it always features the professional laser-cut finish that only RISE MODE offers.<br><br>" +
    "Specifications: Brand: RISE MODE / Model: RISE MODE GAMER HEADSET SUPPORT - VENON V3 / Part number: RM-VN-05-BR / Material: ACRYLIC / Finish: LASER-CUT / Supports: 1 HEADSET / Product dimensions: 26.8CM X 15.5CM X 14CM / Box dimensions: 28CM X 15.5CM X 14CM / Weight: 185 GRAMS (GROSS WEIGHT WITHOUT PACKAGING) / Weight with box: 314 GRAMS (GROSS WEIGHT WITH PACKAGING) / EAN: 7895099518825",
  
    productId: 'x5-glass-black',
    banner: X5Glass_Banner,
    youtubeUrl: '',
    images: [X5Glass_01, X5Glass_02, X5Glass_03, X5Glass_04, X5Glass_05, X5Glass_06],
    pageURL: 'x5-glass-black',
  },
  {
    id: 2,
    name: 'X4 GLASS BLACK',
    category: 'gabinete',
    descriptionP: "GABINETE RISE MODE X4 GLASS BLACK – SEM FANS<br>Marca: Rise Mode<br>Modelo: Gabinete Rise Mode X4 GLASS BLACK – SEM FANS<br><br>ESPECIFICAÇÕES:<br>Part Number: RM-X4-04-FB<br>Cor: Preto<br><br>- Dimensões (L x W x H): L 378mm x W 190mm x H 447mm<br>- Fans: Suporte para 6 fans (fans não inclusos)<br>* Produto não acompanha fans, imagem ilustrativa<br>- Baias: 3.5” HDD (x2) | 2.5” SSD (x2)<br>- Slots de Expansão: 7<br>- Placa-Mãe: ATX / M-ATX<br>- Frontal I/O: x2 USB 2.0 | x1 USB 3.0<br>HD Áudio Entrada e Saída<br>- Fontes de Alimentação: ATX (não inclusa)<br><br>Informações adicionais:<br>- Lateral Vidro Fumê<br>- Barra de led RGB<br><br>Ean: 6015033515500",
      
    descriptionE: "CASE RISE MODE X4 GLASS BLACK – NO FANS<br>Brand: Rise Mode<br>Model: Case Rise Mode X4 GLASS BLACK – NO FANS<br><br>SPECIFICATIONS:<br>Part Number: RM-X4-04-FB<br>Color: Black<br><br>- Dimensions (L x W x H): L 378mm x W 190mm x H 447mm<br>- Fans: Support for 6 fans (fans not included)<br>* Product does not come with fans, illustrative image<br>- Bays: 3.5 HDD (x2) | 2.5 SSD (x2)<br>- Expansion Slots: 7<br>- Motherboard: ATX / M-ATX<br>- Front I/O: x2 USB 2.0 | x1 USB 3.0<br>HD Audio Input and Output<br>- Power Supply: ATX (not included)<br><br>Additional Information:<br>- Smoked Glass Side Panel<br>- RGB LED Strip<br><br>EAN: 6015033515500",
  
    productId: 'x4-glass-black',
    banner: X4Glass_Banner,
    youtubeUrl: '',
    images: [X4Glass_01, X4Glass_02, X4Glass_03, X4Glass_04, X4Glass_05, X4Glass_06],
    pageURL: 'x4-glass-black',
  },
  {
    id: 13,
    name: 'GLASS 06X FROST white',
    category: 'gabinete',
    descriptionP: "GABINETE RISE MODE GLASS 06X FROST BRANCO – SEM FANS<br>Marca: Rise Mode<br>Modelo: Gabinete Rise Mode GLASS 06X FROST BRANCO – SEM FANS<br><br>ESPECIFICAÇÕES:<br>Part Number: RM-WT-06X-FW<br>Cor: Branco<br><br>- Dimensões (L x W x H): L 365mm x W 200mm x H 455mm<br>- Fans: Suporte para 6 fans (fans não inclusos)<br>* Produto não acompanha fans, imagem ilustrativa<br>- Baias: 3.5” HDD (x2) | 2.5” SSD (x3)<br>- Slots de Expansão: 7<br>- Placa-Mãe: ATX / M-ATX / ITX<br>- Frontal I/O: x1 USB 2.0 | x1 USB 3.0<br>HD Áudio Entrada e Saída<br>- Fontes de Alimentação: ATX (não inclusa)<br><br>Informações adicionais:<br>- Lateral Vidro Fumê<br>- Frontal em Vidro Temperado<br><br>EAN: 6015033520580",

    descriptionE:  "CASE RISE MODE GLASS 06X FROST WHITE – NO FANS<br>Brand: Rise Mode<br>Model: Case Rise Mode GLASS 06X FROST WHITE – NO FANS<br><br>SPECIFICATIONS:<br>Part Number: RM-WT-06X-FW<br>Color: White<br><br>- Dimensions (L x W x H): L 365mm x W 200mm x H 455mm<br>- Fans: Support for 6 fans (fans not included)<br>* Product does not come with fans, illustrative image<br>- Bays: 3.5 HDD (x2) | 2.5 SSD (x3)<br>- Expansion Slots: 7<br>- Motherboard: ATX / M-ATX / ITX<br>- Front I/O: x1 USB 2.0 | x1 USB 3.0<br>HD Audio Input and Output<br>- Power Supply: ATX (not included)<br><br>Additional Information:<br>- Smoked Glass Side Panel<br>- Tempered Glass Front Panel<br><br>EAN: 6015033520580",
      
    productId: 'glass-06x-frost-white',
    banner: frostW_Banner,
    youtubeUrl: '',
    images: [frostW_01, frostW_02, frostW_03,frostW_04],
    pageURL: 'glass-06x-frost-white',
  },
  {
    id: 14,
    name: 'GLASS 06X BLACK',
    category: 'gabinete',
    descriptionP:  "GABINETE RISE MODE GLASS 06X BLACK – SEM FANS<br>Marca: Rise Mode<br>Modelo: Gabinete Rise Mode GLASS 06X BLACK – SEM FANS<br><br>ESPECIFICAÇÕES:<br>Part Number: RM-CA-06X-FB<br>Cor: Preto<br><br>- Dimensões (L x W x H): L 365mm x W 200mm x H 455mm<br>- Fans: Suporte para 6 fans (fans não inclusos)<br>* Produto não acompanha fans, imagem ilustrativa<br>- Baias: 3.5” HDD (x2) | 2.5” SSD (x3)<br>- Slots de Expansão: 7<br>- Placa-Mãe: ATX / M-ATX / ITX<br>- Frontal I/O: x1 USB 2.0 | x1 USB 3.0<br>HD Áudio Entrada e Saída<br>- Fontes de Alimentação: ATX (não inclusa)<br><br>Informações adicionais:<br>- Lateral Vidro Fumê<br>- Frontal em Vidro Temperado<br><br>EAN: 6015033531524",

    descriptionE:  "CASE RISE MODE GLASS 06X BLACK – NO FANS<br>Brand: Rise Mode<br>Model: Case Rise Mode GLASS 06X BLACK – NO FANS<br><br>SPECIFICATIONS:<br>Part Number: RM-CA-06X-FB<br>Color: Black<br><br>- Dimensions (L x W x H): L 365mm x W 200mm x H 455mm<br>- Fans: Support for 6 fans (fans not included)<br>* Product does not come with fans, illustrative image<br>- Bays: 3.5 HDD (x2) | 2.5 SSD (x3)<br>- Expansion Slots: 7<br>- Motherboard: ATX / M-ATX / ITX<br>- Front I/O: x1 USB 2.0 | x1 USB 3.0<br>HD Audio Input and Output<br>- Power Supply: ATX (not included)<br><br>Additional Information:<br>- Smoked Glass Side Panel<br>- Tempered Glass Front Panel<br><br>EAN: 6015033531524",
      
    productId: 'glass-06x-black',
    banner: frostB_Banner,
    youtubeUrl: '',
    images: [frostB_01, frostB_02, frostB_03,frostB_04],
    pageURL: 'glass-06x-black',
  },
  {
    id: 15,
    name: 'Z01 BLACK',
    category: 'gabinete',
    descriptionP: "GABINETE RISE MODE Z01 BLACK – SEM FANS<br>Marca: Rise Mode<br>Modelo: Gabinete Rise Mode Z01 BLACK – SEM FANS<br><br>ESPECIFICAÇÕES:<br>- Part Number: RM-Z01-01-FB<br>- Cor: Preto<br>- Dimensões (L x W x H): L 380mm x W 180mm x H 440mm<br>- Fans: Suporte para 8 fans (fans não inclusos)<br>* Produto não acompanha fans, imagem ilustrativa<br>- Baias: 3.5” HDD (x1) | 2.5” SSD (x2)<br>- Slots de Expansão: 7<br>- Placa-Mãe: ATX / M-ATX / ITX<br>- Frontal I/O: x2 USB 2.0 | x1 USB 3.0<br>HD Áudio Entrada e Saída<br>- Fontes de Alimentação: ATX (não inclusa)<br><br>Informações adicionais:<br>- Lateral Vidro Fumê<br>- Barra de led RGB 12v para placa-mãe<br><br>EAN: 6015033726760",

    descriptionE: "CASE RISE MODE Z01 BLACK – NO FANS<br>Brand: Rise Mode<br>Model: Case Rise Mode Z01 BLACK – NO FANS<br><br>SPECIFICATIONS:<br>- Part Number: RM-Z01-01-FB<br>- Color: Black<br>- Dimensions (L x W x H): L 380mm x W 180mm x H 440mm<br>- Fans: Support for 8 fans (fans not included)<br>* Product does not come with fans, illustrative image<br>- Bays: 3.5 HDD (x1) | 2.5 SSD (x2)<br>- Expansion Slots: 7<br>- Motherboard: ATX / M-ATX / ITX<br>- Front I/O: x2 USB 2.0 | x1 USB 3.0<br>HD Audio Input and Output<br>- Power Supply: ATX (not included)<br><br>Additional Information:<br>- Smoked Glass Side Panel<br>- RGB LED Bar 12v for motherboard<br><br>EAN: 6015033726760",
      
    productId: 'z01-black',
    banner: Z01_Banner,
    youtubeUrl: '',
    images: [Z01_01, Z01_02, Z01_03],
    pageURL: 'z01-black',
  },
  {
    id: 16,
    name: 'Z02 BLACK',
    category: 'gabinete',
    descriptionP: "GABINETE RISE MODE Z02 BLACK – SEM FANS<br>Marca: Rise Mode<br>Modelo: Gabinete Rise Mode Z02 BLACK – SEM FANS<br><br>ESPECIFICAÇÕES:<br>- Part Number: RM-Z02-02-FB<br>- Cor: Preto<br>- Dimensões (L x W x H): L 380mm x W 180mm x H 440mm<br>- Fans: Suporte para 8 fans (fans não inclusos)<br>* Produto não acompanha fans, imagem ilustrativa<br>- Baias: 3.5” HDD (x1) | 2.5” SSD (x2)<br>- Slots de Expansão: 7<br>- Placa-Mãe: ATX / M-ATX<br>- Frontal I/O: x1 USB 2.0 | x1 USB 3.0<br>HD Áudio Entrada e Saída<br>- Fontes de Alimentação: ATX (não inclusa)<br><br>Informações adicionais:<br>- Lateral Vidro Fumê<br>- Barra de led RGB<br><br>EAN: 6015033726777",

    descriptionE: "CASE RISE MODE Z02 BLACK – NO FANS<br>Brand: Rise Mode<br>Model: Case Rise Mode Z02 BLACK – NO FANS<br><br>SPECIFICATIONS:<br>- Part Number: RM-Z02-02-FB<br>- Color: Black<br>- Dimensions (L x W x H): L 380mm x W 180mm x H 440mm<br>- Fans: Support for 8 fans (fans not included)<br>* Product does not come with fans, illustrative image<br>- Bays: 3.5 HDD (x1) | 2.5 SSD (x2)<br>- Expansion Slots: 7<br>- Motherboard: ATX / M-ATX<br>- Front I/O: x1 USB 2.0 | x1 USB 3.0<br>HD Audio Input and Output<br>- Power Supply: ATX (not included)<br><br>Additional Information:<br>- Smoked Glass Side Panel<br>- RGB LED Bar<br><br>EAN: 6015033726777",
      
    productId: 'z02-black',
    banner: Z02_Banner,
    youtubeUrl: '',
    images: [Z02_01, Z02_02, Z02_03],
    pageURL: 'z02-black',
  },
  {
    id: 21,
    name: 'Z03 BLACK',
    category: 'gabinete',
    descriptionP:  "GABINETE RISE MODE Z03 BLACK – SEM FANS<br>Marca: Rise Mode<br>Modelo: Gabinete Rise Mode Z03 BLACK – SEM FANS<br><br>ESPECIFICAÇÕES:<br>- Part Number: RM-Z03-03-FB<br>- Cor: Preto<br>- Dimensões (L x W x H): L 378mm x W 190mm x H 447mm<br>- Fans: Suporte para 8 fans (fans não inclusos)<br>* Produto não acompanha fans, imagem ilustrativa<br>- Baias: 3.5” HDD (x2) | 2.5” SSD (x2)<br>- Slots de Expansão: 7<br>- Placa-Mãe: ATX / M-ATX<br>- Frontal I/O: x2 USB 2.0 | x1 USB 3.0<br>HD Áudio Entrada e Saída<br>- Fontes de Alimentação: ATX (não inclusa)<br><br>Informações adicionais:<br>- Lateral Vidro Fumê<br>- Barra de led RGB<br><br>Ean: 6015033497417",

    descriptionE:  "CASE RISE MODE Z03 BLACK - No Fans<br>Brand: Rise Mode<br>Model: Case Rise Mode Z03 BLACK - No Fans<br><br>SPECIFICATIONS:<br>- Part Number: RM-Z03-03-FB<br>- Color: Black<br>- Dimensions (L x W x H): L 378mm x W 190mm x H 447mm<br>- Fans: Support for 8 fans (fans not included)<br>* Product does not include fans, illustrative image<br>- Bays: 3.5 HDD (x2) | 2.5 SSD (x2)<br>- Expansion Slots: 7<br>- Motherboard: ATX / M-ATX<br>- Front I/O: x2 USB 2.0 | x1 USB 3.0<br>HD Audio Input and Output<br>- Power Supply: ATX (not included)<br><br>Additional Information:<br>- Smoked Glass Side Panel<br>- RGB LED Strip<br><br>EAN: 6015033497417",
      
    productId: 'z03-black',
    banner: Z03_Banner,
    youtubeUrl: '',
    images: [Z03_01, Z03_02, Z03_03],
    pageURL: 'z03-black',
  },
  {
    id: 20,
    name: 'OFFICE 01',
    category: 'gabinete',
    descriptionP: "GABINETE RISE MODE OFFICE 01 – SEM FANS<br>Marca: Rise Mode<br>Modelo: Gabinete Rise Mode OFFICE 01 – SEM FANS<br><br>ESPECIFICAÇÕES:<br>- Part Number: RM-GA-OF-FB<br>- Cor: Preto<br>- Dimensões (L x W x H): L 315mm x W 170mm x H 350mm<br>- Fans: Suporte para 4 fans (fans não inclusos)<br>* Produto não acompanha fans, imagem ilustrativa<br>- Baias: 3.5” HDD (x2) | 2.5” SSD (x3)<br>- Slots de Expansão: 4<br>- Placa-Mãe: M-ATX<br>- Frontal I/O: x2 USB 2.0 | x1 USB 3.0<br>HD Áudio Entrada e Saída<br>- Fontes de Alimentação: ATX (não inclusa)<br><br>Ean: 6015033521525",

    descriptionE: "CASE RISE MODE OFFICE 01 - No Fans<br>Brand: Rise Mode<br>Model: Case Rise Mode OFFICE 01 - No Fans<br><br>SPECIFICATIONS:<br>- Part Number: RM-GA-OF-FB<br>- Color: Black<br>- Dimensions (L x W x H): L 315mm x W 170mm x H 350mm<br>- Fans: Support for 4 fans (fans not included)<br>* Product does not include fans, illustrative image<br>- Bays: 3.5 HDD (x2) | 2.5 SSD (x3)<br>- Expansion Slots: 4<br>- Motherboard: M-ATX<br>- Front I/O: x2 USB 2.0 | x1 USB 3.0<br>HD Audio Input and Output<br>- Power Supply: ATX (not included)<br><br>EAN: 6015033521525",
      
    productId: 'office-01',
    banner: office01_Banner,
    youtubeUrl: '',
    images: [office01_01, office01_02, office01_03, office01_04],
    pageURL: 'office-01',
  },
  {
    id: 27,
    name: 'GLASS 06X BLACK – 6 FANS ARGB',
    category: 'gabinete',
    descriptionP: "ESPECIFICAÇÕES:<br/>Part Number: RM-CA-06XB-ARGB<br/>Cor: Preto<br/>Dimensões (L x W x H): L 365mm x W 200mm x H 455mm<br/>Fans: Suporte para 6 fans (6 fans ARGB inclusos)<br/>Baias: 3.5” HDD (x2) | 2.5” SSD (x3)<br/>Slots de Expansão: 7<br/>Placa-Mãe: ATX / M-ATX / ITX<br/>Frontal I/O: x1 USB 2.0 | x1 USB 3.0<br/>HD Áudio Entrada e Saída<br/>Fontes de Alimentação: ATX (não inclusa)<br/>Informações adicionais:<br/>- Lateral Vidro Fumê<br/>- Frontal em Vidro Temperado<br/>- 6 Fans Galaxy ARGB Inclusos<br/>EAN: 7894984390737<br/>",

    descriptionE: "SPECIFICATIONS:<br/>Part Number: RM-CA-06XB-ARGB<br/>Color: Black<br/>Dimensions (L x W x H): L 365mm x W 200mm x H 455mm<br/>Fans: Support for 6 fans (6 ARGB fans included)<br/>Bays: 3.5” HDD (x2) | 2.5” SSD (x3)<br/>Expansion Slots: 7<br/>Motherboard: ATX / M-ATX / ITX<br/>Front I/O: x1 USB 2.0 | x1 USB 3.0<br/>HD Audio Input and Output<br/>Power Supplies: ATX (not included)<br/>Additional Information:<br/>- Smoked Glass Side<br/>- Tempered Glass Front<br/>- 6 Galaxy ARGB Fans Included<br/>EAN: 7894984390737<br/>",
      
    productId: 'glass-06x-black-6-fans-argb',
    banner: '',
    youtubeUrl: '',
    images: [GLASS06XBLACK6FANSARGB_01, GLASS06XBLACK6FANSARGB_02, GLASS06XBLACK6FANSARGB_03, GLASS06XBLACK6FANSARGB_04, GLASS06XBLACK6FANSARGB_05, GLASS06XBLACK6FANSARGB_06, GLASS06XBLACK6FANSARGB_07, GLASS06XBLACK6FANSARGB_08],
    pageURL: 'glass-06x-black-6-fans-argb',
  },
  {
    id: 22,
    name: 'GLASS 06X WHITE – 6 FANS ARGB',
    category: 'gabinete',
    descriptionP: "ESPECIFICAÇÕES:<br/>Part Number: RM-CA-06XW-ARGB<br/>Cor: Preto<br/>Dimensões (L x W x H): L 365mm x W 200mm x H 455mm<br/>Fans: Suporte para 6 fans (6 fans ARGB inclusos)<br/>Baias: 3.5” HDD (x2) | 2.5” SSD (x3)<br/>Slots de Expansão: 7<br/>Placa-Mãe: ATX / M-ATX / ITX<br/>Frontal I/O: x1 USB 2.0 | x1 USB 3.0<br/>HD Áudio Entrada e Saída<br/>Fontes de Alimentação: ATX (não inclusa)<br/>Informações adicionais:<br/>- Lateral Vidro Fumê<br/>- Frontal em Vidro Temperado<br/>- 6 Fans ARGB Inclusos<br/>",

    descriptionE: "SPECIFICATIONS:<br/>Part Number: RM-CA-06XW-ARGB<br/>Color: Black<br/>Dimensions (L x W x H): L 365mm x W 200mm x H 455mm<br/>Fans: Support for 6 fans (6 ARGB fans included)<br/>Bays: 3.5” HDD (x2) | 2.5” SSD (x3)<br/>Expansion Slots: 7<br/>Motherboard: ATX / M-ATX / ITX<br/>Front I/O: x1 USB 2.0 | x1 USB 3.0<br/>HD Audio Input and Output<br/>Power Supplies: ATX (not included)<br/>Additional Information:<br/>- Smoked Glass Side<br/>- Tempered Glass Front<br/>- 6 ARGB Fans Included<br/>",
      
    productId: 'glass-06x-white-6-fans-argb',
    banner: '',
    youtubeUrl: '',
    images: [GLASS06XWHITEK6FANSARGB_01, GLASS06XWHITEK6FANSARGB_02, GLASS06XWHITEK6FANSARGB_03, GLASS06XWHITEK6FANSARGB_04, GLASS06XWHITEK6FANSARGB_05, GLASS06XWHITEK6FANSARGB_06, GLASS06XWHITEK6FANSARGB_07],
    pageURL: 'glass-06x-white-6-fans-argb',
  },
  {
    id: 23,
    name: 'GALAXY GLASS STANDARD BLACK – 10 FANS ARGB',
    category: 'gabinete',
    descriptionP: "ESPECIFICAÇÕES:<br/>Part Number: RM-GA-GGSB-ARGB<br/>Cor: Preto<br/>Dimensões (L x W x H): L 420mm x W 272mm x H 399mm<br/>Fans: Suporte para 10 fans (Fans ARGB Já Inclusos)<br/>LED: ARGB<br/>Baias: 3.5” HDD (x1) | 2.5” SSD (x2)<br/>Slots de Expansão: 7<br/>Placa-Mãe: ATX / M-ATX / ITX<br/>Frontal I/O: x2 USB 2.0 | x1 USB 3.0<br/>HD Áudio Entrada e Saída<br/>Fontes de Alimentação: ATX (não inclusa)<br/>Informações adicionais:<br/>- Lateral Vidro Temperado<br/>- Frontal em Vidro Temperado<br/>- Suporte a Placa de Vídeo até 360MM<br/>- Suporte Air cooler até 158mm<br/>",

    descriptionE: "SPECIFICATIONS:<br/>Part Number: RM-GA-GGSB-ARGB<br/>Color: Black<br/>Dimensions (L x W x H): L 420mm x W 272mm x H 399mm<br/>Fans: Support for 10 fans (ARGB Fans Included)<br/>LED: ARGB<br/>Bays: 3.5” HDD (x1) | 2.5” SSD (x2)<br/>Expansion Slots: 7<br/>Motherboard: ATX / M-ATX / ITX<br/>Front I/O: x2 USB 2.0 | x1 USB 3.0<br/>HD Audio Input and Output<br/>Power Supplies: ATX (not included)<br/>Additional Information:<br/>- Tempered Glass Side<br/>- Tempered Glass Front<br/>- Supports Graphics Card up to 360MM<br/>- Supports Air Cooler up to 158mm<br/>",
      
    productId: 'galaxy-glass-standard-black-10-fans-argb',
    banner: '',
    youtubeUrl: '',
    images: [GLASSSTANDARDBLACK10FANS_01, GLASSSTANDARDBLACK10FANS_02, GLASSSTANDARDBLACK10FANS_03, GLASSSTANDARDBLACK10FANS_04, GLASSSTANDARDBLACK10FANS_05, GLASSSTANDARDBLACK10FANS_06],
    pageURL: 'galaxy-glass-standard-black-10-fans-argb',
  },
  {
    id: 24,
    name: 'GALAXY GLASS STANDARD WHITE – 10 FANS ARGB',
    category: 'gabinete',
    descriptionP: "ESPECIFICAÇÕES:<br/>Part Number: RM-GA-GGSW-ARGB<br/>Cor: Branco<br/>Dimensões (L x W x H): L 420mm x W 272mm x H 399mm<br/>Fans: Suporte para 10 fans (Fans ARGB Já Inclusos)<br/>LED: ARGB<br/>Baias: 3.5” HDD (x1) | 2.5” SSD (x2)<br/>Slots de Expansão: 7<br/>Placa-Mãe: ATX / M-ATX / ITX<br/>Frontal I/O: x2 USB 2.0 | x1 USB 3.0<br/>HD Áudio Entrada e Saída<br/>Fontes de Alimentação: ATX (não inclusa)<br/>Informações adicionais:<br/>- Lateral Vidro Temperado<br/>- Frontal em Vidro Temperado<br/>- Suporte a Placa de Vídeo até 360MM<br/>- Suporte Air cooler até 158mm<br/>",

    descriptionE: "SPECIFICATIONS:<br/>Part Number: RM-GA-GGSW-ARGB<br/>Color: White<br/>Dimensions (L x W x H): L 420mm x W 272mm x H 399mm<br/>Fans: Support for 10 fans (ARGB Fans Included)<br/>LED: ARGB<br/>Bays: 3.5” HDD (x1) | 2.5” SSD (x2)<br/>Expansion Slots: 7<br/>Motherboard: ATX / M-ATX / ITX<br/>Front I/O: x2 USB 2.0 | x1 USB 3.0<br/>HD Audio Input and Output<br/>Power Supplies: ATX (not included)<br/>Additional Information:<br/>- Tempered Glass Side<br/>- Tempered Glass Front<br/>- Supports Graphics Card up to 360MM<br/>- Supports Air Cooler up to 158mm<br/>",
      
    productId: 'galaxy-glass-standard-white-10-fans-argb',
    banner: '',
    youtubeUrl: '',
    images: [GLASSSTANDARDWHITE10FANS_01, GLASSSTANDARDWHITE10FANS_02, GLASSSTANDARDWHITE10FANS_03, GLASSSTANDARDWHITE10FANS_04, GLASSSTANDARDWHITE10FANS_06, GLASSSTANDARDWHITE10FANS_07, GLASSSTANDARDWHITE10FANS_05],
    pageURL: 'galaxy-glass-standard-white-10-fans-argb',
  },
  {
    id: 25,
    name: 'WAVE WHITE –  3 FANS ARGB',
    category: 'gabinete',
    descriptionP: "ESPECIFICAÇÕES:<br/>Part Number: RM-WA-BW-ARGB<br/>Cor: Branco<br/>Dimensões (L x W x H): L 360mm x W 200mm x H 456mm<br/>Fans: Suporte para 6 fans (3 fans ARGB inclusos)<br/>Baias: 3.5” HDD (x2) | 2.5” SSD (x3)<br/>Slots de Expansão: 7<br/>Placa-Mãe: ATX / M-ATX / ITX<br/>Frontal I/O: x1 USB 2.0 | x1 USB 3.0<br/>HD Áudio Entrada e Saída<br/>Fontes de Alimentação: ATX (não inclusa)",

    descriptionE: "SPECIFICATIONS:<br/>Part Number: RM-WA-BW-ARGB<br/>Color: White<br/>Dimensions (L x W x H): L 360mm x W 200mm x H 456mm<br/>Fans: Support for 6 fans (3 ARGB Fans included)<br/>Bays: 3.5” HDD (x2) | 2.5” SSD (x3)<br/>Expansion Slots: 7<br/>Motherboard: ATX / M-ATX / ITX<br/>Front I/O: x1 USB 2.0 | x1 USB 3.0<br/>HD Audio Input and Output<br/>Power Supplies: ATX (not included)",
      
    productId: 'wave-white-3-fans-argb',
    banner: '',
    youtubeUrl: '',
    images: [WAVEWHITE3FANSARGB01, WAVEWHITE3FANSARGB02, WAVEWHITE3FANSARGB03, WAVEWHITE3FANSARGB04, WAVEWHITE3FANSARGB05, WAVEWHITE3FANSARGB06],
    pageURL: 'wave-white-3-fans-argb',
  },
  {
    id: 26,
    name: 'WAVE BLACK –  3 FANS ARGB',
    category: 'gabinete',
    descriptionP: "ESPECIFICAÇÕES:<br/>Part Number: RM-WA-FB-ARGB<br/>Cor: Preto<br/>Dimensões (L x W x H): L 360mm x W 200mm x H 456mm<br/>Fans: Suporte para 6 fans (3 fans ARGB inclusos)<br/>Baias: 3.5” HDD (x2) | 2.5” SSD (x3)<br/>Slots de Expansão: 7<br/>Placa-Mãe: ATX / M-ATX / ITX<br/>Frontal I/O: x1 USB 2.0 | x1 USB 3.0<br/>HD Áudio Entrada e Saída<br/>Fontes de Alimentação: ATX (não inclusa)",

    descriptionE: "SPECIFICATIONS:<br/>Part Number: RM-WA-FB-ARGB<br/>Color: Black<br/>Dimensions (L x W x H): L 360mm x W 200mm x H 456mm<br/>Fans: Support for 6 fans (3 ARGB Fans included)<br/>Bays: 3.5” HDD (x2) | 2.5” SSD (x3)<br/>Expansion Slots: 7<br/>Motherboard: ATX / M-ATX / ITX<br/>Front I/O: x1 USB 2.0 | x1 USB 3.0<br/>HD Audio Input and Output<br/>Power Supplies: ATX (not included)",
      
    productId: 'wave-black-3-fans-argb',
    banner: '',
    youtubeUrl: '',
    images: [WAVEBLACKFANSARGB01, WAVEBLACKFANSARGB02, WAVEBLACKFANSARGB03, WAVEBLACKFANSARGB04, WAVEBLACKFANSARGB05, WAVEBLACKFANSARGB06],
    pageURL: 'wave-black-3-fans-argb',
  },
];

export default products;
