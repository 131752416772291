import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/storage';
import { motion } from 'framer-motion'; 


// Configurar o Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBliTfTPbSU4caIdCdMh_Xs5f_-95ISq28",
  authDomain: "produtos-list.firebaseapp.com",
  databaseURL: "https://produtos-list-default-rtdb.firebaseio.com",
  projectId: "produtos-list",
  storageBucket: "produtos-list.appspot.com",
  messagingSenderId: "308827988350",
  appId: "1:308827988350:web:a635c2b1508599bfff132f"
};

firebase.initializeApp(firebaseConfig);


const TitleDaPage = styled.h1`
    background: #161616;
    color: #4491FA;
    text-align: center;
    padding: 2rem;
`;

const SuccessMessage = styled.div`
  /* Estilos para a mensagem de sucesso */
  background-color: #4caf50 !important;
  color: #fff;
  padding: 10px;
  margin-top: 40px;
  font-size: 1.3rem;
  width: 300px;
  margin: 0 auto;
  padding: 1rem;
  border-radius: 4px;
  display: block;
  text-align: center;
`;


const DivisUnico = styled.div`
  display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    flex-direction: column;
    margin: 10px;
`;

// Estilos usando Styled Components
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 1200px;
  height: auto;
  padding: 1rem;
  background: #161616;

  @media screen and (max-width: 1000px) {
    width: 100%;
    flex-direction: column;
  }

`;

const TextArea = styled.textarea`
  padding: 10px;
  margin-top: 5px;
  width: 350px;
  height: 80px;
  background: #343333;
  border-radius: 4px;
  border: 1px solid #8D8D8D;
  font-family: 'Inter', sans-serif;
`;

const Input = styled.input`
  margin: 5px;
  width: 350px;
  height: 40px;
  border-radius: 4px;
  padding: 10px;
  background: #343333;
  border: 1px solid #8d8d8d;
  font-size: 1.2rem;
  margin-bottom: 10px;
`;

const InputC = styled.input`
  margin: 5px;
  width: 350px;
  height: 40px;
  border-radius: 4px;
  padding: 10px;
  background: #343333;
  border: 1px solid #8d8d8d;
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-family: 'Inter';
`;

const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: black;
  border-radius: 8px;
  border: 1px solid white;
  padding: 20px;
  margin: 10px;
  width: 300px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;

  #salvar{
    color: white;
    border: none;
    width: 150px;
    height: 50px;
    background: #ff0000;
    cursor: pointer;
    font-size: 1rem;
    font-family: 'inter';
    font-weight: 800;
    border-radius: 4px;

    :hover{
      filter: brightness(0.8);
    }

    &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
  }

  img{
    width: 130px;
    margin-bottom: 6px;
    background: none;
  }

  h3{
    text-align: center;
    background: none;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
`;

const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; 
  width: 1000px;
  height: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #161616;
  padding: 1rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
  overflow-y: scroll;

  h2{
    font-size: 3rem;
    background: none;
  }

  #cancelar{
    color: white;
    border: 1px solid white;
    width: 150px;
    height: 50px;
    background:none;
    cursor: pointer;
    font-size: 1rem;
    font-family: 'inter';
    font-weight: 800;
    border-radius: 4px;
    margin: 1rem;

    :hover{
      filter: brightness(0.8);
    }
  }

  #salvar{
    color: white;
    border: none;
    width: 150px;
    height: 50px;
    background: #ff0000;
    cursor: pointer;
    font-size: 1rem;
    font-family: 'inter';
    font-weight: 800;
    border-radius: 4px;

    :hover{
      filter: brightness(0.8);
    }

    &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
  }

  div{
    background: none;
  }
`;

const Label = styled.label`
  margin: 4px;
  font-size: 1.5rem;
  background: none;
`;

const ImageInput = styled.input`
background: none;
cursor: pointer;
background: red;
font-size: 1rem;
width: 350px;
cursor: pointer;

`;

const Button = styled.button`
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: none;
`;

// Componente principal
const App = () => {
  const [isSending, setIsSending] = useState(false);
  const [data, setData] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [pageURL, setPageURL] = useState('');
  const [headerTitle, setHeaderTitle] = useState('');
  const [headerDescription, setHeaderDescription] = useState('');
  const [headerBannerURL, setHeaderBannerURL] = useState(null);
  const [cardImageURL, setCardImageURL] = useState('');
  const [additionalImageURL, setAdditionalImageURL] = useState(null);
  const [cardTitle, setCardTitle] = useState('');
  const [cardDescription, setCardDescription] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);


  const uploadImageToFirebase = async (file) => {
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(file.name);
    await fileRef.put(file);
    const downloadURL = await fileRef.getDownloadURL();
    return downloadURL;
  };

  const handleAdditionalImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageURL = await uploadImageToFirebase(file);
      setAdditionalImageURL(imageURL);
    }
  };
  
  const handleHeaderBannerChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageURL = await uploadImageToFirebase(file);
      setHeaderBannerURL(imageURL);
    }
  };
  
  const handleCardImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageURL = await uploadImageToFirebase(file);
      setCardImageURL(imageURL);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const snapshot = await firebase.database().ref('products').once('value');
      const dataSnapshot = snapshot.val();
      if (dataSnapshot) {
        const newData = Object.entries(dataSnapshot)
          .filter(([key, value]) => value.hasOwnProperty('pageURL'))
          .map(([key, value]) => ({
            id: key,
            ...value,
          }));
        setData(newData);

        // Atribuir valores do primeiro item ao estado dos inputs
        const firstItem = newData[0];
        if (firstItem) {
          setPageURL(firstItem.pageURL);
          setHeaderTitle(firstItem.headerTitle);
          setHeaderDescription(firstItem.headerDescription);
          setHeaderBannerURL(firstItem.headerBannerURL);
          setCardTitle(firstItem.cardTitle);
          setCardDescription(firstItem.cardDescription);
          setCardImageURL(firstItem.cardImageURL);
          setAdditionalImageURL(firstItem.additionalImageURL);
        }
      }
    };

    fetchData();
  }, []);
  
  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  const handleModalCancel = () => {
    setSelectedCard(null);
  };

  const handleModalSave = () => {
    setIsSending(true);
    if (selectedCard) {
      const updatedData = data.map((card) => {
        if (card.id === selectedCard.id) {
          return {
            ...card,
            pageURL,
            headerTitle,
            headerDescription,
            headerBannerURL,
            cardTitle,
            cardDescription,
            cardImageURL,
            additionalImageURL,
          };
        }
        return card;
      });
  
      // Verificar se algum campo de imagem está como undefined
      if (
        typeof cardImageURL === "undefined" ||
        typeof headerBannerURL === "undefined" ||
        typeof additionalImageURL === "undefined"
      ) {
        console.log("Um dos campos de imagem está com valor undefined");
        return;
      }
  
      // Salvar os dados atualizados no Firebase Realtime Database
      firebase
        .database()
        .ref(`products/${selectedCard.id}`)
        .set(updatedData.find((card) => card.id === selectedCard.id));
        setData(updatedData);
        setShowSuccessMessage(true); // Exibir mensagem de sucesso

        setTimeout(() => {
          setShowSuccessMessage(false); // Ocultar mensagem de sucesso após 3 segundos
        }, 3000);
        setTimeout(() => {
          setIsSending(false);
        }, 2000);
      }
  };

  return (
    <motion.div
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.3 }}
    >
    <TitleDaPage>Editar Páginas</TitleDaPage>
    <Container>
      {data.map((card) => (
        <DivisUnico>
          <Card key={card.id} onClick={() => handleCardClick(card)}>
            <img src={card.additionalImageURL} />
            <h3>{card.cardTitle}</h3> 
            <button id="salvar">
              Editar
            </button>
          </Card>
        </DivisUnico>
      ))}
      {selectedCard && (
        <ModalOverlay>
          <ModalContent>
            <h2>Editar Página</h2>
            <Label>THUMBNAIL:</Label>
            <ImageInput
              type="file"
              id="additionalImageInput"
              onChange={handleAdditionalImageChange}
            />
            <Label>NOME DA CATEGORIA:</Label>
            <InputC
              type="text"
              value={pageURL}
              onChange={(e) => setPageURL(e.target.value)}
            />
            <Label >BANNER principal:</Label > 
            <ImageInput
              type="file"
              id="headerBannerInput"
              onChange={handleHeaderBannerChange}
            />
            <Label >NOME DO PRODUTO:</Label > 
            <Input
              type="text"
              value={headerTitle}
              onChange={(e) => setHeaderTitle(e.target.value)}
            />
            <Label >DESCRIÇÃO:</Label > 
            <TextArea
               value={headerDescription}
               onChange={(e) => setHeaderDescription(e.target.value)}
            />
            <Label >BANNER:</Label> 
            <ImageInput
              id="cardImageInput"
              type="file"
              onChange={handleCardImageChange}
            />  
            <Label >TITULO:</Label> 
            <Input
              type="text"
              value={cardTitle}
              onChange={(e) => setCardTitle(e.target.value)}
            />
            <Label >DESCRIÇÃO:</Label > 
            <TextArea
               value={cardDescription}
               onChange={(e) => setCardDescription(e.target.value)}
            />
            <div>
              <button id="cancelar" onClick={handleModalCancel}>Cancel</button>
              <button id="salvar" onClick={handleModalSave} disabled={isSending}>Salvar</button>
              {showSuccessMessage && (
            <SuccessMessage>A página foi salva com sucesso!</SuccessMessage>
             )}
            </div>
          </ModalContent>
        </ModalOverlay>
      )}
    </Container>
    </motion.div>
  );
};

export default App;
