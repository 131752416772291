import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import { Navigation } from 'swiper';
import image1 from '../../img/carrosel/water_cooler.png';
import image2 from '../../img/carrosel/memoria.png';
import image3 from '../../img/carrosel/gabinete.png';
import image4 from '../../img/carrosel/suporte_headset.png';
import image5 from '../../img/carrosel/cadeira_gamer.png';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  h1 {
    text-align: center;
    font-size: 2rem;
    font-family: 'Bebas neue';
    margin: 1rem;
  }
`;

const StyledCarousel = styled(Swiper)`
  width: 1000px;
  position: relative;

  .swiper-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 500ms;
    margin: 0.8rem;

    :hover{
      scale: calc(1.1);
    }
  }

  .swiper-slide img {
    width: 100%;
    object-fit: contain;
  }

  p {
    text-align: center;
    width: 100%;
    background-color: var(--global-cinza);
    font-size: 1.3rem;
    cursor: pointer;
    padding: 0.5rem;
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: #ffffff;
    font-size: 24px;
    background-color: transparent;
    transition: background-color 0.5s;

    &:hover {
      color: #4491fa;
    }
  }

  a {
    text-decoration: none;
  }

  p:hover {
    background-color: var(--global-azul);
  }
`;

const Carousel = () => {

  const { t } = useTranslation();  


  return (
    <>
      <Container>
        <h1 alt="Principais Categorias">{t('title.carrosel')}</h1>
      </Container>
      <StyledCarousel
        spaceBetween={10}
        slidesPerView={4}
        centeredSlides={true}
        navigation
        keyboard={{ enabled: true }}
        modules={[Navigation]}
        initialSlide={1} // Definindo o terceiro slide (índice 2) como inicial
      >
        <SwiperSlide>
          <div>
            <a href="/watercooler">
              <img src={image1} alt="Water Cooler" title="Water Cooler" />
              <p alt="Title">{t('card.carrosel.1')}</p>
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <a href="/memoria">
              <img src={image2} alt="Memória Ram" title="Memória RAM" />
              <p alt="Title">{t('card.carrosel.2')}</p>
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <a href="/gabinete"  >
              <img src={image3} alt="Gabinete" title="Gabinete"/ >
              <p alt="Title">{t('card.carrosel.3')}</p>
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <a href="/suporteheadset">
              <img src={image4} alt="Suporte Headset" title="Suporte Headset" />
              <p alt="Title">{t('card.carrosel.4')}</p>
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <a href="/cadeiragamer">
              <img src={image5} alt="Cadeira Gamer" title="Cadeira Gamer" />
              <p alt="Title">{t('card.carrosel.5')}</p>
            </a>
          </div>
        </SwiperSlide>
      </StyledCarousel>
    </>
  );
};

export default Carousel;
