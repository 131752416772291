import React, { useState, useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';
import Loading from '../../Loading/Loading';
import { motion } from 'framer-motion';
import Banner01 from '../../../img/banners_produtos/m2.jpg';
import Foto01 from '../../../img/banners_produtos/suporteheadset/foto_m2.jpg';
import UsaFlag from '../../../img/icon/usaIcon.png';
import KabumIcon from '../../../img/icon/kabumIcon.png';
import Lojarise from '../../../img/icon/riseIcon.png';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import products from './DataSuporteHeadset';

const Link = styled.a`
  text-decoration: none;
  background: none;
`;

const Titlebuy = styled.h1`
  font-size: 3.5rem;
  text-align: center;
`;

const Botoes = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem ;
  
  a{
    padding: 10px;
    margin: 8px;
    width: 200px;
    height: 50px;
    background: #4491FA;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: center;
    gap: 10px;
    
    :hover{
    filter: brightness(0.8);
    }
  }

  img{
    width: 30px;
    height: 30px;
    background: none;
  }

  p{
    font-size: 1.5rem;
    text-align: center;
    background: none;
  }
`;

const Video = styled.video`
  width: 100%;
  max-width: 1500px;
  pointer-events: none; /* Impede a interação com o vídeo */
`;  

const Imagem = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  margin-top: 2rem;
  padding: 1rem;
  @media screen and (max-width: 1031px) {
      flex-direction: column;
      margin-top: 8rem;
  }

  img{
    width: 100%;
    max-width: 500px;
    margin-right: 2rem;
  }

  div{
    margin-top: 3rem;

  }

  h1{
    width: 460px;
    color: white;
    text-align: left;
    font-size: 3rem;
    line-height: 1;
    margin-bottom: 0.3rem;
    @media screen and (max-width: 1031px) {
      width: 300px;
      font-size: 2rem;
  }
  }

  h2{
    width: 450px;
    font-family: "Inter";
    color: #c3c3c3;
    font-size: 1.3rem;
    text-align: left;
    @media screen and (max-width: 1031px) {
      width: 300px;
      font-size: 1rem;
  }
  }

  button{
    margin-top: 5rem;
    margin-bottom: 1rem;
    width: 200px;
    height: 50px;
    background: #4491FA;
    border: none;
    border-radius: 4px;
    font-size: 1.5rem;
    cursor: pointer;

    :hover{
    filter: brightness(0.8);
    }

    @media screen and (max-width: 1031px) {
      width: 150px;
      font-size: 1.4rem;
      height: 50px;
      margin-top: 2rem;
  }
  }
`;

const Division = styled.div`
  background: linear-gradient(to right, #ff20cd, #1294ff,#58ffb4,#1294ff,#ff20cd);
	background-size: 400% 400%;
	animation: gradient 6s ease infinite;
	height: 8px;

	@keyframes gradient {
		0% {
			background-position: 0% 50%;
		}
		50% {
			background-position: 100% 50%;
		}
		100% {
			background-position: 0% 50%;
		}
	}
`;

const TituloModal = styled.h1`
    background: none;
    color: white;
    text-align: center;
`;

const BannerContainer = styled.div`
  position: relative;
  text-align: center;
  margin-bottom: 10px;

  img{
    width: 100%;
    max-width: 1920px;
  }

  div{
    position: absolute;
    top: 14%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: none;

    @media screen and (max-width: 1000px) {
      top: 150% !important;
  }  

  @media screen and (max-width: 1024px) {
      top: 120%;
  }

  @media screen and (min-width: 1234px) {
      top: 14%;
  }
  }

`;

const BannerTitle = styled.h1`
  color: white;
  font-size: 40px;
  background: none;
  margin-bottom: 4px;
  @media screen and (max-width: 1031px) {
    font-size: 25px;
  }
`;

const BannerText = styled.p`
  color: #c3c3c3;
  font-size: 18px;
  background: none;
  font-family: "Inter";

  @media screen and (max-width: 1031px) {
    font-size: 13px;
  }
`;

const Galeria = styled.div`
  overflow-y: hidden;
  text-align: center;

  @media screen and (max-width: 1031px) {
    margin: 2rem 1rem 1rem 1rem;
  }

  h1 {
    font-size: 2rem;
  }

  div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    
    @media (max-width: 1000px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    }
  }
`;

const Card = styled.div`
  border: 1px solid #c3c3c3;
  border-radius: 4px;
  padding: 1rem;
  margin: 1rem;
  transition: 200ms;
  width: 300px;
  height: 330px;
  text-align: center;
  display: flex;
  flex-direction: column;
  background: white;
  cursor: pointer;
  overflow: hidden;

  :hover {
    transform: scale(1.1);
  }

`;

const CardImage = styled.img`
  display: block;
  width: 200px;
  height: 200px;
  margin: 0 auto;
`;

const CardTitle = styled.h2`
  margin-top: 10px;
  color: black;
  font-size: 1.5rem;
  background: none;
`;

const CardDescription = styled.p`
  padding: 1rem;
  color: #c3c3c3;
  font-size: 1rem;
  background: none;
  text-align: center;
  font-family: "Inter";
  @media screen and (max-width: 1031px) {
    font-size: 0.6rem;
    width: 300px;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  z-index: 1;
  background-color: black;
  padding: 1rem;
  border-radius: 4px;
  width: 100%;
  max-width: 1200px;
  height: 800px;
  position: relative;
  overflow-y: auto;
`;

const ControleAll = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

const Conteudo = styled.div`
  width: 800px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background: white;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 8px;

  @media screen and (max-width: 1000px) {
      width: 420px;
  }

  img{
    width: 350px;
    margin: 4px;

    @media screen and (max-width: 1000px) {
      width: 150px;
      margin: 4px;
  }
  }
`;

const ModalCloseButton = styled.button`
  position: absolute;
  top: 2%;
  right: 2%;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  font-family: "Inter";
  font-weight: 800;

  :hover {
    color: #c3c3c3;
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
`;

const BannerProduto = styled.img`
  width: 100%;
  max-width: 797px;
  margin: 1rem;
`;

const SimpleComponent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [modalUrl, setModalUrl] = useState('');
  const { id } = useParams();
  const [productsData, setProductsData] = useState([]);

  useEffect(() => {
    // Simulando uma chamada assíncrona para buscar os dados dos produtos
    setTimeout(() => {
      setProductsData(products);
      setIsLoading(false);
    }, 2000);
  }, []);

    useEffect(() => {
      if (id) {
        openModal(id);
      }
    }, [id]);
  
    const navigate = useNavigate();
        
    const openModal = (product) => {
    // Gerando a URL com o nome do produto
    const modalUrl = `${product.productId}`;

    setSelectedProduct(product);
    };
  
    const closeModal = () => {
      setSelectedProduct(null);
    };
  
    const handleOverlayClick = (event) => {
      if (event.target === event.currentTarget) {
        closeModal();
      }
    };

  const WithLoading = ({ isLoading, children }) => {
    return (
      <>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {children}
          </>
        )}
      </>
    );
  };

  const { t } = useTranslation();

  return (
        <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1}}
        transition={{ duration: 1 }}
        >
          <Division></Division>
          <BannerContainer>
            <div>
              <BannerTitle alt="Title Banner">SSD M.2 PCIE</BannerTitle>
              <BannerText alt="Desc Banner">
              {t('description.m2')}
              </BannerText>
            </div>
            <img src={Banner01} alt="SSD M.2" title="SSD M.2"/>
          </BannerContainer>
          <Imagem>
            <img src={Foto01} alt="SSD M.2" title="SSD M.2"/>
            <div>
              <h1 alt="Title">SSD M.2 PCIE</h1>
              <h2 alt="Desc">{t('description.cardbox.m2')}</h2>
            </div>
          </Imagem>
          <Titlebuy alt="Comprar">{t('button.buy')}</Titlebuy>
          <Botoes alt="Comprar">
                <a target='_blank' href="https://www.amazon.com/s?i=merchant-items&me=A2Z190E8OU3Q34&marketplaceID=ATVPDKIKX0DER&qid=1682472974&ref=sr_pg_2">
                <img src={UsaFlag} />  
                <p>RISE USA</p>
                </a>

                <a target='_blank' href="https://www.lojarisemode.com.br/loja/busca.php?loja=612435&palavra_busca=SSD+M.2">
                <img src={Lojarise} />  
                <p>LOJA RISE</p>
                </a>

                <a target='_blank' href="https://www.kabum.com.br/busca/SSD-Rise-Mode">
                <img src={KabumIcon} />  
                <p>KABUM</p>
                </a>
              </Botoes>
          <WithLoading isLoading={isLoading}>
          <Galeria>
            <div>
                {products.map((product) => (
                  <Link href={`/${product.productId}`}>
                    <Card key={product.id} onClick={() => openModal(product)}>
                      <CardImage src={product.images[0]} alt={product.name} title={product.name} />
                      <CardTitle>{product.name}</CardTitle>
                    </Card>
                  </Link>
                ))}
              </div>
          </Galeria>
         </WithLoading>
         </motion.div>
  );
};

export default SimpleComponent;
