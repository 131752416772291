import React from 'react';
import AddProduct from './Addproduct';
import EditProduct from './EditProduct.js';
import RemoveProduct from './Removeproduct';
import Uploadbanners from './Uploadbanners';
import RemovePage from './Removepage';
import EditPage from './EditPage';
import CriarPage from './CriarPage';
import styled from 'styled-components';
import { motion } from 'framer-motion'; 
import Logo from '../../img/icon/logo_admin.png';
import IconFile from '../../img/icon/icon_file.png';
import IconImage from '../../img/icon/icon_image.png';
import IconPen from '../../img/icon/icon_Pen.png';
import IconPlus from '../../img/icon/icon_plus.png';
import IconTrash from '../../img/icon/icon_Trash.png';
import IconTrashPage from '../../img/icon/icon_Trash_Page.png';
import IconPenPage from '../../img/icon/icon_Pen_Page.png';
import IconExit from '../../img/icon/icon_exit.png';
import { Link } from 'react-router-dom';

const Perfil = styled.div`
  padding: 2rem;
  background: none;


  h1{
    margin-top: 10px;
    text-align: center;
    background: none;
    font-family: 'Inter';
    font-weight: bold;
    font-size: 1.8rem;
  }

  p{
    margin-top: 5px;
    text-align: center;
    background: none;
    font-family: 'Inter';
    font-size: 1.2rem;
    color: #c3c3c3;
  }

  img{
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 100px;
    background: none;
  }
`;

const Img = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

const ImgSair = styled.img`
  width: 25px;
  height: auto;
  margin-right: 10px;
`;

const Container = styled.div`
  margin: 0 auto;
  padding: 20px;
  text-align: left;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1000px) {
      flex-direction: column;
  }

  a{
    background: none;
    text-decoration: none;
  }

  ul {
    padding: 1rem;
    border-radius: 1rem 0rem 0rem 1rem;
    width:300px;
    height: 100vh;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    background: #101010;

    @media screen and (max-width: 1000px) {
      width:100%;
      justify-content: center;
  } 
  }
`;

const TitleList = styled.li`
  display: inline;
  margin: 1rem;
  font-size: 1.4rem;
  font-family: 'Bebas Neue';
  background: none;
  color: #c3c3c3;
`;

const List = styled.li`
  display: flex;
  
  margin: 1rem;
  cursor: pointer;
  font-size: 1.2rem;
  font-family: 'Inter';
  background: none;

  :hover,
  &.active {
    color: #4491fa;
  }
  `;


const Sair = styled.li`
  display: flex;
  margin-top: 4rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  font-size: 1.2rem;
  font-family: 'Inter';
  background: none;

  :hover,
  &.active {
    color: #4491fa;
  }
`;

const LoginForm = styled.form`
    margin: 0 auto;
    padding: 3rem;
    background: #101010;
    max-width: 600px;
    width: 100%;
    border-radius: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2{
    font-size: 2rem;
    background: none;
    margin-bottom: 0.5rem;
  }

  input{
    height: 45px;
    width: 300px;
    background: #343333;
    border: 1px solid #8D8D8D;
    border-radius: 4px;
    color: #fff;
    padding: 1rem;
    font-size: 1rem;
    font-family: 'Inter';
    }

    button{
      margin-top: 4px;
      padding: 10px;
      background-color: #4491FA;
      color: #fff;
      border: none;
      width: 300px;
      border-radius: 4px;
      cursor: pointer;
      height: 50px;
      font-size: 1.5rem;
      opacity: ${props => (props.disabled ? 0.5 : 1)};
      cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
      margin-bottom: 1rem;

      :hover{
        filter: brightness(0.8);
  }
    }
`;

const Admin = () => {
  const [showAddProduct, setShowAddProduct] = React.useState(true);
  const [showEditProduct, setShowEditProduct] = React.useState(false);
  const [showRemoveProduct, setShowRemoveProduct] = React.useState(false);
  const [showUploadBanners, setShowUploadBanners] = React.useState(false);
  const [showCriarpage, setShowCriarpage] = React.useState(false);
  const [showRemovePage, setShowRemovePage] = React.useState(false);
  const [showEditPage, setShowEditPage] = React.useState(false);
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');

  const handleAddProductClick = () => {
    setShowAddProduct(true);
    setShowRemoveProduct(false);
    setShowUploadBanners(false);
    setShowEditProduct(false);
    setShowCriarpage(false);
    setShowRemovePage(false);
    setShowEditPage(false);
  };

  const handleEditProductClick = () => {
    setShowEditProduct(true);
    setShowAddProduct(false);
    setShowRemoveProduct(false);
    setShowUploadBanners(false);
    setShowCriarpage(false);
    setShowRemovePage(false);
    setShowEditPage(false);
  };

  const handleRemoveProductClick = () => {
    setShowAddProduct(false);
    setShowRemoveProduct(true);
    setShowUploadBanners(false);
    setShowEditProduct(false);
    setShowCriarpage(false);
    setShowRemovePage(false);
    setShowEditPage(false);
  };

  const handleCriarPageClick = () => {
    setShowAddProduct(false);
    setShowRemoveProduct(false);
    setShowUploadBanners(false);
    setShowEditProduct(false);
    setShowCriarpage(true);
    setShowRemovePage(false);
    setShowEditPage(false);
  };

  const handleBannersClick = () => {
    setShowAddProduct(false);
    setShowRemoveProduct(false);
    setShowUploadBanners(true);
    setShowEditProduct(false);
    setShowCriarpage(false);
    setShowRemovePage(false);
    setShowEditPage(false);
  };

  const handleRemovePageClick = () => {
    setShowAddProduct(false);
    setShowRemoveProduct(false);
    setShowUploadBanners(false);
    setShowEditProduct(false);
    setShowCriarpage(false);
    setShowRemovePage(true);
    setShowEditPage(false);
  };

  const handleEditPageClick = () => {
    setShowAddProduct(false);
    setShowRemoveProduct(false);
    setShowUploadBanners(false);
    setShowEditProduct(false);
    setShowCriarpage(false);
    setShowRemovePage(false);
    setShowEditPage(true);
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (username === 'Risemode' && password === 'S3iya10@') {
      setLoggedIn(true);
      setErrorMessage('');
    } else {
      setLoggedIn(false);
      setErrorMessage('Nome de usuário ou senha incorretos');
    }

    setUsername('');
    setPassword('');
  };

  if (!loggedIn) {
    return (
      <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.3 }}
      >
        <Container>
        <LoginForm onSubmit={handleSubmit}>
          <h2>Login</h2>
          <input
            type="text"
            placeholder="Digite o nome de usuário"
            value={username}
            onChange={handleUsernameChange}
          />
          <br />
          <input
            type="password"
            placeholder="Digite a senha"
            value={password}
            onChange={handlePasswordChange}
          />
          <br />
          <button type="submit">Entrar</button>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </LoginForm>
      </Container>
      </motion.div>
    );
  }

  return (
    <motion.div
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.3 }}
    >
    <Container>
      
      <ul>
      <Perfil>
        <img src={Logo}/>
        <h1>Rise Mode</h1>
        <p>@risemode</p>
      </Perfil>
        <TitleList
        >
          ADMIN
        </TitleList>
        <List
          onClick={handleAddProductClick}
          className={showAddProduct ? 'active' : ''}
        >
          <Img src={IconPlus}/>
          Novo produto
        </List>
        
        <List
          onClick={handleEditProductClick}
          className={showEditProduct ? 'active' : ''}
        >
          <Img src={IconPen}/>
          Editar Produtos
        </List>

        <List
          onClick={handleRemoveProductClick}
          className={showRemoveProduct ? 'active' : ''}
        >
          <Img src={IconTrash}/>
          Remover Produtos
        </List>

        <List
          onClick={handleCriarPageClick}
          className={showCriarpage ? 'active' : ''}
        >
          <Img src={IconFile}/>
          Nova Página
        </List>

        <List
          onClick={handleEditPageClick}
          className={showEditPage ? 'active' : ''}
        >
          <Img src={IconPenPage}/>
          Editar Páginas
        </List>

        <List
          onClick={handleRemovePageClick}
          className={showRemovePage ? 'active' : ''}
        >
          <Img src={IconTrashPage}/>
          Remover Páginas
        </List>

        <a href='/admin'>
          <Sair
          >
            <ImgSair src={IconExit}/>
            Sair
          </Sair>
        </a>
      </ul>
      
      {showAddProduct && <AddProduct />}
      {showEditProduct && <EditProduct />}
      {showRemoveProduct && <RemoveProduct />}
      {showCriarpage && <CriarPage />}
      {showEditPage && <EditPage />}
      {showRemovePage && <RemovePage />}
    </Container>
    </motion.div>
  );
};

export default Admin;
