import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import UsaFlag from '../../../img/icon/usaIcon.png';
import KabumIcon from '../../../img/icon/kabumIcon.png';
import Lojarise from '../../../img/icon/riseIcon.png';

const Titlebuy = styled.h1`
  font-size: 3.5rem;
  text-align: center;
`;

const Botoes = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem ;

  a {
    padding: 10px;
    margin: 8px;
    width: 200px;
    height: 50px;
    background: #4491FA;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: center;
    gap: 10px;

    :hover {
      filter: brightness(0.8);
    }
  }

  img {
    width: 30px;
    height: 30px;
    background: none;
  }

  p {
    font-size: 1.5rem;
    text-align: center;
    background: none;
  }
`;



const BotaoComprar = () => {
  const { t } = useTranslation();
  return(
            <div>
              <Titlebuy>{t('button.buy')}</Titlebuy>
              <Botoes>
                <a target='_blank' href="https://www.amazon.com/s?i=merchant-items&me=A2Z190E8OU3Q34&marketplaceID=ATVPDKIKX0DER&qid=1682472974&ref=sr_pg_2">
                  <img src={UsaFlag} />
                  <p>RISE USA</p>
                </a>
  
                <a target='_blank' href="https://www.lojarisemode.com.br/">
                  <img src={Lojarise} />
                  <p>LOJA RISE</p>
                </a>
  
                <a target='_blank' href="https://www.kabum.com.br/busca/Rise-mode">
                  <img src={KabumIcon} />
                  <p>KABUM</p>
                </a>
              </Botoes>
        </div>
        )
  };
  
  export default BotaoComprar;