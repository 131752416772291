
import Cover1 from '../../../img/Produtos/cover/1.jpg';
import Cover2 from '../../../img/Produtos/cover/2.jpg';
import Cover3 from '../../../img/Produtos/cover/3.jpg';
import Cover4 from '../../../img/Produtos/cover/4.jpg';
import Cover5 from '../../../img/Produtos/cover/5.jpg';
import Cover6 from '../../../img/Produtos/cover/6.jpg';
import Cover7 from '../../../img/Produtos/cover/7.jpg';
import Cover8 from '../../../img/Produtos/cover/8.jpg';
import Cover9 from '../../../img/Produtos/cover/9.jpg';
import Cover10 from '../../../img/Produtos/cover/10.jpg';
import Cover11 from '../../../img/Produtos/cover/11.jpg';
import Cover12 from '../../../img/Produtos/cover/12.jpg';
import Cover13 from '../../../img/Produtos/cover/13.jpg';
import Cover14 from '../../../img/Produtos/cover/14.jpg';



const products = [
  {
    id: 1,
    name: 'COVER PSU RS1(RGB MOLEX)',
    category: 'coverpsu',
    descriptionP: `COVER PARA FONTE RISE MODE RS1(RGB MOLEX) - BLACK RM-CF-01-RS<br><br>

    COVER DE FONTE RS1 PRETO COM LEDS<br><br>
    TIRA DE LED RISE MODE PARA GABINETE 50CM RGB<br><br>
    EXTENSOR PARA CABO RGB - 4 PINOS 50CM<br><br>
    UM CONTROLE MOLEX PARA CONTROLAR OS LEDS RGB<br><br>
    Descrição:<br><br>
    DÊ OUTRA CARA PARA SEU SETUP!<br><br>
    Pensando no público entusiasta a Rise Mode desenvolveu sua linha de Cover Psu, projetado nas dimensões do Padrão ATX, garantindo uma ampla compatibilidade com os principais gabinetes do mercado, com uma vasta possibilidade de personalização, da cor do LED ao Design.<br><br>
    Nossos Covers não requerem a utilização de ferramentas para a instalação, proporcionando facilidade para quem procura um visual inovador ao seu Setup. Iluminadas através de uma tira de LED de fácil instalação e conectadas direto a fonte de alimentação.<br><br>
    
    Especificações:<br><br>
    
    Marca: RISE<br><br>
    Modelo: COVER RISE MODE RS1(RGB MOLEX) - BLACK RM-CF-01-RS<br><br>
    Part Number: RM-CF-01-RS<br><br>
    Cor: PRETO<br><br>
    Compatibilidade: GABINETES ATX (ESPAÇO MINÍMO 27 CM),<br><br>
    Conexão Molex ou Conexão 4 pin motherboard<br><br>
    Dimensões da Caixa: 28,3CM X 19CM X 9CM<br><br>
    Peso: 410 GRAMAS (BRUTO SEM EMBALAGEM)<br><br>
    Peso com Caixa: 540 GRAMAS (BRUTO COM EMBALAGEM)
    `,

    descriptionE: `COVER FOR RISE MODE RS1 POWER SUPPLY (RGB MOLEX) - BLACK RM-CF-01-RS<br><br>

    RS1 POWER SUPPLY COVER IN BLACK WITH LEDS<br><br>
    RISE MODE 50CM RGB LED STRIP FOR CABINET<br><br>
    RGB CABLE EXTENDER - 4-PIN 50CM<br><br>
    ONE MOLEX CONTROLLER TO CONTROL RGB LEDS<br><br>
    Description:<br><br>
    GIVE YOUR SETUP A NEW LOOK!<br><br>
    With enthusiasts in mind, Rise Mode has developed its line of PSU Covers designed in the dimensions of the ATX Standard, ensuring broad compatibility with the leading cabinets in the market, offering a wide range of customization options from LED color to design.<br><br>
    Our Covers do not require the use of tools for installation, providing ease for those seeking an innovative look for their setup. They are illuminated through an easily installable LED strip, directly connected to the power supply.<br><br>
    
    Specifications:<br><br>
    
    Brand: RISE<br><br>
    Model: COVER RISE MODE RS1 (RGB MOLEX) - BLACK RM-CF-01-RS<br><br>
    Part Number: RM-CF-01-RS<br><br>
    Color: BLACK<br><br>
    Compatibility: ATX CABINETS (MINIMUM SPACE 27 CM)<br><br>
    Molex Connection or 4-pin motherboard connection<br><br>
    Box Dimensions: 28.3CM X 19CM X 9CM<br><br>
    Weight: 410 GRAMS (GROSS WEIGHT WITHOUT PACKAGING)<br><br>
    Weight with Box: 540 GRAMS (GROSS WEIGHT WITH PACKAGING)
    `,
      
    productId: 'cover-psu-rs1-rgb',
    banner: '',
    youtubeUrl: '',
    images: [Cover1],
    pageURL: 'cover-psu-rs1-rgb',
  },
  {
    id: 2,
    name: 'COVER PSU RS1 WHITE',
    category: 'coverpsu',
    descriptionP: `COVER PARA FONTE RISE MODE RS1(RGB MOLEX) - BRANCO RM-CF-01-RS<br><br>

    COVER DE FONTE RS1 BRANCA COM LEDS<br><br>
    EXTENSOR PARA CABO RGB - 4 PINOS 50CM<br><br>
    UM CONTROLE MOLEX PARA CONTROLAR OS LEDS RGB<br><br>
    Descrição:<br><br>
    DÊ OUTRA CARA PARA SEU SETUP!<br><br>
    Pensando no público entusiasta, a Rise Mode desenvolveu sua linha de Covers para Fonte, projetados nas dimensões do Padrão ATX, garantindo ampla compatibilidade com os principais gabinetes do mercado, com uma vasta possibilidade de personalização, desde a cor do LED até o design.<br><br>
    Nossos Covers não requerem o uso de ferramentas para a instalação, proporcionando facilidade para aqueles que procuram um visual inovador para seu setup. São iluminados por meio de uma tira de LED de fácil instalação, conectada diretamente à fonte de alimentação.<br><br>
    
    Especificações:<br><br>
    
    Marca: RISE<br><br>
    Modelo: COVER RISE MODE RS1 (RGB MOLEX) - BRANCO RM-CF-01-RS<br><br>
    Número da Peça: RM-CF-02-RS<br><br>
    Cor: BRANCO<br><br>
    Compatibilidade: Gabinetes ATX (espaço mínimo de 27 cm)<br><br>
    Conexão Molex ou Conexão 4 pinos na placa-mãe<br><br>
    Dimensões da Caixa: 28,3CM X 19CM X 9CM<br><br>
    Peso: 410 GRAMAS (PESO BRUTO SEM EMBALAGEM)<br><br>
    Peso com Caixa: 540 GRAMAS (PESO BRUTO COM EMBALAGEM)
    `,

    descriptionE: `COVER FOR RISE MODE RS1 POWER SUPPLY (RGB MOLEX) - WHITE RM-CF-01-RS<br><br>

    RS1 WHITE POWER SUPPLY COVER WITH LEDS<br><br>
    RGB CABLE EXTENDER - 4-PIN 50CM<br><br>
    ONE MOLEX CONTROLLER TO CONTROL RGB LEDS<br><br>
    Description:<br><br>
    GIVE YOUR SETUP A NEW LOOK!<br><br>
    With enthusiasts in mind, Rise Mode has developed its line of PSU Covers designed in the dimensions of the ATX Standard, ensuring broad compatibility with the leading cabinets in the market, offering a wide range of customization options from LED color to design.<br><br>
    Our Covers do not require the use of tools for installation, providing ease for those seeking an innovative look for their setup. They are illuminated through an easily installable LED strip, directly connected to the power supply.<br><br>
    
    Specifications:<br><br>
    
    Brand: RISE<br><br>
    Model: COVER RISE MODE RS1 (RGB MOLEX) - WHITE RM-CF-01-RS<br><br>
    Part Number: RM-CF-02-RS<br><br>
    Color: WHITE<br><br>
    Compatibility: ATX CABINETS (MINIMUM SPACE 27 CM)<br><br>
    Molex Connection or 4-pin motherboard connection<br><br>
    Box Dimensions: 28.3CM X 19CM X 9CM<br><br>
    Weight: 410 GRAMS (GROSS WEIGHT WITHOUT PACKAGING)<br><br>
    Weight with Box: 540 GRAMS (GROSS WEIGHT WITH PACKAGING)
    `,
      
    productId: 'cover-psu-rs1-white',
    banner: '',
    youtubeUrl: '',
    images: [Cover2],
    pageURL: 'cover-psu-rs1-white',
  },
  {
    id: 3,
    name: 'DRAGON RGB MOLEX CR BLACK',
    category: 'coverpsu',
    descriptionP: `Cover para Fonte Rise Mode DRAGON RGB MOLEX CR PRETA<br><br>
    --------------------------------------------------------<br><br>
    DÊ OUTRA CARA PARA SEU SETUP!<br><br>
    COVER PARA FONTE RISE MODE DRAGON RGB MOLEX (LIGADO DIRETO NA FONTE).<br><br>
    --------------------------------------------------------<br><br>
    Especificações:<br><br>
    
    Marca: Rise Mode<br><br>
    Modelo: DRAGON RGB MOLEX CR PRETA<br><br>
    Part Number: RM-CF-01-DR<br><br>
    Cor: PRETO<br><br>
    Iluminação: RGB<br><br>
    Alimentação: DIRETO FONTE (MOLEX)<br><br>
    Compatibilidade RISE: UMA OU MAIS TIRAS DE LED RISE RGB MOTHERBOARD (RM-TL-02-RGB).<br><br>
    Compatibilidade: FONTES COM CONECTOR MOLEX<br><br>
    Compatibilidade: GABINETES ATX (ESPAÇO MINÍMO 27 CM)<br><br>
    Dimensões da Caixa: 28,3CM X 19CM X 9CM<br><br>
    Peso: 410 GRAMAS (BRUTO SEM EMBALAGEM)<br><br>
    Peso com Caixa: 540 GRAMAS (BRUTO COM EMBALAGEM)<br><br>
    EAN: 7895099201345
    `,

    descriptionE: `Cover for Rise Mode DRAGON RGB MOLEX CR Power Supply - BLACK<br><br>
    --------------------------------------------------------<br><br>
    GIVE YOUR SETUP A NEW LOOK!<br><br>
    COVER FOR RISE MODE DRAGON RGB MOLEX POWER SUPPLY (DIRECTLY CONNECTED TO THE PSU).<br><br>
    --------------------------------------------------------<br><br>
    Specifications:<br><br>
    
    Brand: Rise Mode<br><br>
    Model: DRAGON RGB MOLEX CR BLACK<br><br>
    Part Number: RM-CF-01-DR<br><br>
    Color: BLACK<br><br>
    Lighting: RGB<br><br>
    Power: DIRECT PSU CONNECTION (MOLEX)<br><br>
    RISE Compatibility: ONE OR MORE RISE RGB LED STRIPS FOR MOTHERBOARD (RM-TL-02-RGB).<br><br>
    Compatibility: PSU WITH MOLEX CONNECTOR<br><br>
    Compatibility: ATX CABINETS (MINIMUM SPACE 27 CM)<br><br>
    Box Dimensions: 28.3CM X 19CM X 9CM<br><br>
    Weight: 410 GRAMS (GROSS WEIGHT WITHOUT PACKAGING)<br><br>
    Weight with Box: 540 GRAMS (GROSS WEIGHT WITH PACKAGING)<br><br>
    EAN: 7895099201345
    `,
      
    productId: 'dragon-rgb-molex-cr-black',
    banner: '',
    youtubeUrl: '',
    images: [Cover3],
    pageURL: 'dragon-rgb-molex-cr-black',
  },
  {
    id: 3,
    name: 'DRAGON RGB MOLEX CR BLACK',
    category: 'coverpsu',
    descriptionP: `Cover para Fonte Rise Mode DRAGON RGB MOLEX CR PRETA<br><br>
    --------------------------------------------------------<br><br>
    DÊ OUTRA CARA PARA SEU SETUP!<br><br>
    COVER PARA FONTE RISE MODE DRAGON RGB MOLEX (LIGADO DIRETO NA FONTE).<br><br>
    --------------------------------------------------------<br><br>
    Especificações:<br><br>
    
    Marca: Rise Mode<br><br>
    Modelo: DRAGON RGB MOLEX CR PRETA<br><br>
    Part Number: RM-CF-01-DR<br><br>
    Cor: PRETO<br><br>
    Iluminação: RGB<br><br>
    Alimentação: DIRETO FONTE (MOLEX)<br><br>
    Compatibilidade RISE: UMA OU MAIS TIRAS DE LED RISE RGB MOTHERBOARD (RM-TL-02-RGB).<br><br>
    Compatibilidade: FONTES COM CONECTOR MOLEX<br><br>
    Compatibilidade: GABINETES ATX (ESPAÇO MINÍMO 27 CM)<br><br>
    Dimensões da Caixa: 28,3CM X 19CM X 9CM<br><br>
    Peso: 410 GRAMAS (BRUTO SEM EMBALAGEM)<br><br>
    Peso com Caixa: 540 GRAMAS (BRUTO COM EMBALAGEM)<br><br>
    EAN: 7895099201345
    `,

    descriptionE: `Cover for Rise Mode DRAGON RGB MOLEX CR Power Supply - BLACK<br><br>
    --------------------------------------------------------<br><br>
    GIVE YOUR SETUP A NEW LOOK!<br><br>
    COVER FOR RISE MODE DRAGON RGB MOLEX POWER SUPPLY (DIRECTLY CONNECTED TO THE PSU).<br><br>
    --------------------------------------------------------<br><br>
    Specifications:<br><br>
    
    Brand: Rise Mode<br><br>
    Model: DRAGON RGB MOLEX CR BLACK<br><br>
    Part Number: RM-CF-01-DR<br><br>
    Color: BLACK<br><br>
    Lighting: RGB<br><br>
    Power: DIRECT PSU CONNECTION (MOLEX)<br><br>
    RISE Compatibility: ONE OR MORE RISE RGB LED STRIPS FOR MOTHERBOARD (RM-TL-02-RGB).<br><br>
    Compatibility: PSU WITH MOLEX CONNECTOR<br><br>
    Compatibility: ATX CABINETS (MINIMUM SPACE 27 CM)<br><br>
    Box Dimensions: 28.3CM X 19CM X 9CM<br><br>
    Weight: 410 GRAMS (GROSS WEIGHT WITHOUT PACKAGING)<br><br>
    Weight with Box: 540 GRAMS (GROSS WEIGHT WITH PACKAGING)<br><br>
    EAN: 7895099201345
    `,
      
    productId: 'dragon-rgb-molex-cr-black',
    banner: '',
    youtubeUrl: '',
    images: [Cover3],
    pageURL: 'dragon-rgb-molex-cr-black',
  },
  {
    id: 4,
    name: 'DRAGON RGB MOTHERBOARD BLACK',
    category: 'coverpsu',
    descriptionP: `Cover para Fonte Rise Mode DRAGON RGB MOTHERBOARD PRETA<br><br>
    --------------------------------------------------------<br><br>
    DÊ OUTRA CARA PARA SEU SETUP!<br><br>
    COVER PARA FONTE RISE MODE DRAGON RGB MOTHERBOARD (LIGADO DIRETO NO CONECTOR RGB DA PLACA MÃE).<br><br>
    --------------------------------------------------------<br><br>
    Especificações:<br><br>
    
    Marca: Rise Mode<br><br>
    Modelo: DRAGON RGB MOTHERBOARD PRETA<br><br>
    Part Number: RM-CF-02-DR<br><br>
    Cor: PRETO<br><br>
    Iluminação: RGB<br><br>
    Alimentação: Direto na placa mãe (RGB Header)<br><br>
    Compatibilidade RISE: UMA OU MAIS TIRAS DE LED RISE RGB MOTHERBOARD (RM-TL-02-RGB).<br><br>
    Compatibilidade: PLACAS MÃE COM FUNÇÃO RGB E CONECTOR RGB (RGB HEADER)<br><br>
    Compatibilidade: GABINETES ATX (ESPAÇO MINÍMO 27 CM)<br><br>
    Dimensões da Caixa: 28,3CM X 19CM X 9CM<br><br>
    Peso: 410 GRAMAS (BRUTO SEM EMBALAGEM)<br><br>
    Peso com Caixa: 540 GRAMAS (BRUTO COM EMBALAGEM)<br><br>
    EAN: 7895099201352
    `,

    descriptionE: `
    `,
      
    productId: 'dragon-rgb-motherboard-black',
    banner: '',
    youtubeUrl: '',
    images: [Cover4],
    pageURL: 'dragon-rgb-motherboard-black',
  },
  {
    id: 5,
    name: 'DRAGON RGB MOLEX CR WHITE',
    category: 'coverpsu',
    descriptionP: `
    Cover para Fonte Rise Mode DRAGON RGB MOLEX CR BRANCA<br><br>
    --------------------------------------------------------<br><br>
    DÊ OUTRA CARA PARA SEU SETUP!<br><br>
    COVER PARA FONTE RISE MODE DRAGON RGB MOLEX CR (LIGADO DIRETO NA FONTE).<br><br>
    --------------------------------------------------------<br><br>
    Especificações:<br><br>
    
    Marca: Rise Mode<br><br>
    Modelo: DRAGON RGB MOLEX CR BRANCA<br><br>
    Part Number: RM-CF-05-DR<br><br>
    Cor: BRANCO<br><br>
    Iluminação: RGB<br><br>
    Alimentação: DIRETO FONTE (MOLEX)<br><br>
    Compatibilidade RISE: UMA OU MAIS TIRAS DE LED RISE RGB MOTHERBOARD (RM-TL-02-RGB).<br><br>
    Compatibilidade: FONTES COM CONECTOR MOLEX<br><br>
    Compatibilidade: GABINETES ATX (ESPAÇO MINÍMO 27 CM)<br><br>
    Dimensões da Caixa: 28,3CM X 19CM X 9CM<br><br>
    Peso: 410 GRAMAS (BRUTO SEM EMBALAGEM)<br><br>
    Peso com Caixa: 540 GRAMAS (BRUTO COM EMBALAGEM)<br><br>
    EAN: 7895099201420
    `,

    descriptionE: `
    `,
      
    productId: 'dragon-rgb-molex-cr-white',
    banner: '',
    youtubeUrl: '',
    images: [Cover5],
    pageURL: 'dragon-rgb-molex-cr-white',
  },
  {
    id: 6,
    name: 'DRAGON RGB MOTHERBOARD WHITE',
    category: 'coverpsu',
    descriptionP: `
        Cover para Fonte Rise Mode DRAGON RGB MOTHERBOARD BRANCA<br><br>
        --------------------------------------------------------<br><br>
        DÊ OUTRA CARA PARA SEU SETUP!<br><br>
        COVER PARA FONTE RISE MODE DRAGON RGB MOTHERBOARD (LIGADO DIRETO NO CONECTOR RGB DA PLACA MÃE).<br><br>
        --------------------------------------------------------<br><br>
        Especificações:<br><br>

        Marca: Rise Mode<br><br>
        Modelo: DRAGON RGB MOTHERBOARD BRANCA<br><br>
        Part Number: RM-CF-06-DR<br><br>
        Cor: BRANCO<br><br>
        Iluminação: RGB<br><br>
        Alimentação: Direto na placa mãe (RGB Header)<br><br>
        Compatibilidade RISE: UMA OU MAIS TIRAS DE LED RISE RGB MOTHERBOARD (RM-TL-02-RGB).<br><br>
        Compatibilidade: PLACAS MÃE COM FUNÇÃO RGB E CONECTOR RGB (RGB HEADER)<br><br>
        Compatibilidade: GABINETES ATX (ESPAÇO MINÍMO 27 CM)<br><br>
        Dimensões da Caixa: 28,3CM X 19CM X 9CM<br><br>
        Peso: 410 GRAMAS (BRUTO SEM EMBALAGEM)<br><br>
        Peso com Caixa: 540 GRAMAS (BRUTO COM EMBALAGEM)<br><br>
        EAN: 7895099201437
    `,

    descriptionE: `
    `,
      
    productId: 'dragon-rgb-motherboard-white',
    banner: '',
    youtubeUrl: '',
    images: [Cover6],
    pageURL: 'dragon-rgb-motherboard-white',
  },
  {
    id: 7,
    name: 'DRAGON BLACK',
    category: 'coverpsu',
    descriptionP: `
    Cover para Fonte Rise Mode DRAGON PRETA<br><br>
    --------------------------------------------------------<br><br>
    DÊ OUTRA CARA PARA SEU SETUP!<br><br>
    Pensando no público entusiasta, a Rise Mode desenvolveu sua linha de Cover Psu, projetada nas dimensões do Padrão ATX, garantindo ampla compatibilidade com os principais gabinetes do mercado, com uma vasta possibilidade de personalização, desde a cor do LED até o design.<br><br>
    Nossos Covers não requerem a utilização de ferramentas para a instalação, proporcionando facilidade para quem procura um visual inovador para seu Setup. São iluminadas através de uma tira de LED de fácil instalação e conectadas diretamente à fonte de alimentação.<br><br>
    --------------------------------------------------------<br><br>
    Especificações:<br><br>
    
    Marca: Rise Mode<br><br>
    Modelo: DRAGON PRETA<br><br>
    Part Number: RM-CF-03-DR<br><br>
    Cor: PRETO<br><br>
    Compatibilidade: GABINETES ATX (ESPAÇO MÍNIMO 27 CM)<br><br>
    Dimensões da Caixa: 28,3CM X 19CM X 9CM<br><br>
    Peso: 410 GRAMAS (BRUTO SEM EMBALAGEM)<br><br>
    Peso com Caixa: 540 GRAMAS (BRUTO COM EMBALAGEM)<br><br>
    EAN: 7895099201321
    `,

    descriptionE: `
    `,
      
    productId: 'dragon-black',
    banner: '',
    youtubeUrl: '',
    images: [Cover7],
    pageURL: 'dragon-black',
  },
  {
    id: 8,
    name: 'DRAGON WHITE',
    category: 'coverpsu',
    descriptionP: `
    Cover para Fonte Rise Mode DRAGON BRANCA<br><br>
    --------------------------------------------------------<br><br>
    DÊ OUTRA CARA PARA SEU SETUP!<br><br>
    Pensando no público entusiasta, a Rise Mode desenvolveu sua linha de Cover Psu, projetada nas dimensões do Padrão ATX, garantindo ampla compatibilidade com os principais gabinetes do mercado, com uma vasta possibilidade de personalização, desde a cor do LED até o design.<br><br>
    Nossos Covers não requerem a utilização de ferramentas para a instalação, proporcionando facilidade para quem procura um visual inovador para seu Setup. São iluminadas através de uma tira de LED de fácil instalação e conectadas diretamente à fonte de alimentação.<br><br>
    --------------------------------------------------------<br><br>
    Especificações:<br><br>

    Marca: Rise Mode<br><br>
    Modelo: DRAGON BRANCA<br><br>
    Part Number: RM-CF-04-DR<br><br>
    Cor: BRANCO<br><br>
    Compatibilidade: GABINETES ATX (ESPAÇO MÍNIMO 27 CM)<br><br>
    Dimensões da Caixa: 28,3CM X 19CM X 9CM<br><br>
    Peso: 410 GRAMAS (BRUTO SEM EMBALAGEM)<br><br>
    Peso com Caixa: 540 GRAMAS (BRUTO COM EMBALAGEM)<br><br>
    EAN: 7895099201338
    `,

    descriptionE: `
    `,
      
    productId: 'dragon-white',
    banner: '',
    youtubeUrl: '',
    images: [Cover8],
    pageURL: 'dragon-white',
  },
  {
    id: 9,
    name: 'ALIEN RGB CONTROLE MOLEX BLACK',
    category: 'coverpsu',
    descriptionP: `
    Cover para Fonte Rise Mode ALIEN RGB CONTROLE MOLEX PRETA<br><br>
    --------------------------------------------------------<br><br>
    DÊ OUTRA CARA PARA SEU SETUP!<br><br>
    COVER PARA FONTE RISE MODE ALIEN RGB MOLEX (LIGADO DIRETO NA FONTE).<br><br>
    --------------------------------------------------------<br><br>
    Especificações:<br><br>

    Marca: Rise Mode<br><br>
    Modelo: ALIEN RGB CONTROLE MOLEX PRETA<br><br>
    Part Number: RM-CF-03-AL<br><br>
    Cor: PRETO<br><br>
    Iluminação: RGB<br><br>
    Alimentação: DIRETO FONTE (MOLEX)<br><br>
    Compatibilidade RISE: UMA OU MAIS TIRAS DE LED RISE RGB MOTHERBOARD (RM-TL-02-RGB).<br><br>
    Compatibilidade: FONTES COM CONECTOR MOLEX<br><br>
    Compatibilidade: GABINETES ATX (ESPAÇO MÍNIMO 27 CM)<br><br>
    Dimensões da Caixa: 28,3CM X 19CM X 9CM<br><br>
    Peso: 410 GRAMAS (BRUTO SEM EMBALAGEM)<br><br>
    Peso com Caixa: 540 GRAMAS (BRUTO COM EMBALAGEM)<br><br>
    EAN: 7895099201765
    `,

    descriptionE: `
    `,
      
    productId: 'alien-rgb-controle-molex-black',
    banner: '',
    youtubeUrl: '',
    images: [Cover9],
    pageURL: 'alien-rgb-controle-molex-black',
  },
  {
    id: 10,
    name: 'ALIEN RGB MOTHERBOARD BLACK',
    category: 'coverpsu',
    descriptionP: `
    Cover para Fonte Rise Mode ALIEN RGB MOTHERBOARD PRETA<br><br>
    --------------------------------------------------------<br><br>
    DÊ OUTRA CARA PARA SEU SETUP!<br><br>
    COVER PARA FONTE RISE MODE ALIEN RGB MOTHERBOARD (LIGADO DIRETO NO CONECTOR RGB DA PLACA MÃE).<br><br>
    --------------------------------------------------------<br><br>
    Especificações:<br><br>

    Marca: Rise Mode<br><br>
    Modelo: ALIEN RGB MOTHERBOARD PRETA<br><br>
    Part Number: RM-CF-04-AL<br><br>
    Cor: PRETO<br><br>
    Iluminação: RGB<br><br>
    Alimentação: Direto na placa mãe (RGB Header)<br><br>
    Compatibilidade RISE: UMA OU MAIS TIRAS DE LED RISE RGB MOTHERBOARD (RM-TL-02-RGB).<br><br>
    Compatibilidade: PLACAS MÃE COM FUNÇÃO RGB E CONECTOR RGB (RGB HEADER)<br><br>
    Compatibilidade: GABINETES ATX (ESPAÇO MÍNIMO 27 CM)<br><br>
    Dimensões da Caixa: 28,3CM X 19CM X 9CM<br><br>
    Peso: 410 GRAMAS (BRUTO SEM EMBALAGEM)<br><br>
    Peso com Caixa: 540 GRAMAS (BRUTO COM EMBALAGEM)<br><br>
    EAN: 7895099201772
    `,

    descriptionE: `
    `,
      
    productId: 'alien-rgb-motherboard-black',
    banner: '',
    youtubeUrl: '',
    images: [Cover10],
    pageURL: 'alien-rgb-motherboard-black',
  },
  {
    id: 11,
    name: 'ALIEN RGB MOTHERBOARD BLACK',
    category: 'coverpsu',
    descriptionP: `
    Cover para Fonte Rise Mode ALIEN RGB MOTHERBOARD PRETA<br><br>
    --------------------------------------------------------<br><br>
    DÊ OUTRA CARA PARA SEU SETUP!<br><br>
    COVER PARA FONTE RISE MODE ALIEN RGB MOTHERBOARD (LIGADO DIRETO NO CONECTOR RGB DA PLACA MÃE).<br><br>
    --------------------------------------------------------<br><br>
    Especificações:<br><br>
    
    Marca: Rise Mode<br><br>
    Modelo: ALIEN RGB MOTHERBOARD PRETA<br><br>
    Part Number: RM-CF-04-AL<br><br>
    Cor: PRETO<br><br>
    Iluminação: RGB<br><br>
    Alimentação: Direto na placa mãe (RGB Header)<br><br>
    Compatibilidade RISE: UMA OU MAIS TIRAS DE LED RISE RGB MOTHERBOARD (RM-TL-02-RGB).<br><br>
    Compatibilidade: PLACAS MÃE COM FUNÇÃO RGB E CONECTOR RGB (RGB HEADER)<br><br>
    Compatibilidade: GABINETES ATX (ESPAÇO MÍNIMO 27 CM)<br><br>
    Dimensões da Caixa: 28,3CM X 19CM X 9CM<br><br>
    Peso: 410 GRAMAS (BRUTO SEM EMBALAGEM)<br><br>
    Peso com Caixa: 540 GRAMAS (BRUTO COM EMBALAGEM)<br><br>
    EAN: 7895099201772
    `,

    descriptionE: `
    `,
      
    productId: 'alien-rgb-molex-cr-white',
    banner: '',
    youtubeUrl: '',
    images: [Cover11],
    pageURL: 'alien-rgb-molex-cr-white',
  },
  {
    id: 12,
    name: 'ALIEN RGB MOLEX CR WHITE',
    category: 'coverpsu',
    descriptionP: `
    Cover para Fonte Rise Mode ALIEN RGB MOLEX CR BRANCA<br><br>
    --------------------------------------------------------<br><br>
    DÊ OUTRA CARA PARA SEU SETUP!<br><br>
    COVER PARA FONTE RISE MODE ALIEN RGB MOLEX CR (LIGADO DIRETO NA FONTE).<br><br>
    --------------------------------------------------------<br><br>
    Especificações:<br><br>

    Marca: Rise Mode<br><br>
    Modelo: ALIEN RGB MOLEX CR BRANCA<br><br>
    Part Number: RM-CF-01-AL<br><br>
    Cor: BRANCO<br><br>
    Iluminação: RGB<br><br>
    Alimentação: DIRETO FONTE (MOLEX)<br><br>
    Compatibilidade RISE: UMA OU MAIS TIRAS DE LED RISE RGB MOTHERBOARD (RM-TL-02-RGB).<br><br>
    Compatibilidade: FONTES COM CONECTOR MOLEX<br><br>
    Compatibilidade: GABINETES ATX (ESPAÇO MÍNIMO 27 CM)<br><br>
    Dimensões da Caixa: 28,3CM X 19CM X 9CM<br><br>
    Peso: 410 GRAMAS (BRUTO SEM EMBALAGEM)<br><br>
    Peso com Caixa: 540 GRAMAS (BRUTO COM EMBALAGEM)<br><br>
    EAN: 7895099201741
    `,

    descriptionE: `
    `,
      
    productId: 'alien-rgb-motherboard-white',
    banner: '',
    youtubeUrl: '',
    images: [Cover12],
    pageURL: 'alien-rgb-motherboard-white',
  },
  {
    id: 13,
    name: 'RGB Controle (Molex) - Motherboard BLACK',
    category: 'coverpsu',
    descriptionP: `
    Cover para Fonte Rise Mode RGB Controle (Molex) - Motherboard PRETO<br><br>
    --------------------------------------------------------<br><br>
    DÊ OUTRA CARA PARA SEU SETUP!<br><br>
    --------------------------------------------------------<br><br>
    Especificações:<br><br>

    Marca: Rise Mode<br><br>
    Modelo: RGB Controle (Molex) - Motherboard PRETO<br><br>
    Part Number: RM-CF-03-RS<br><br>
    Cor: PRETO<br><br>
    Iluminação: RGB<br><br>
    Alimentação: Direto Fonte (MOLEX)<br><br>
    Direto na placa mãe: (RGB Header)<br><br>
    Compatibilidade: UMA OU MAIS TIRAS DE LED RISE RGB MOTHERBOARD (RM-TL-02-RGB).<br><br>
    Compatibilidade: FONTES COM CONECTOR MOLEX<br><br>
    Compatibilidade: PLACAS MÃE COM FUNÇÃO RGB E CONECTOR RGB (RGB HEADER)<br><br>
    Compatibilidade: GABINETES ATX (ESPAÇO MÍNIMO 27 CM)<br><br>
    Dimensões da Caixa: 28,3CM X 19CM X 9CM<br><br>
    Peso: 410 GRAMAS (BRUTO SEM EMBALAGEM)<br><br>
    Peso com Caixa: 540 GRAMAS (BRUTO COM EMBALAGEM)<br><br>
    --------------------------------------------------------<br><br>
    CONTEÚDO DENTRO DA CAIXA:<br><br>
    1 COVER RISE MODE COM LED<br><br>
    1 TIRA LED PARA GABINETE RM-TL-03-RGB<br><br>
    1 EXTENSOR PARA 4 PIN RGB RM-EX-01-RGB<br><br>
    1 CONTROLE MOLEX PARA CONTROLAR OS LEDS RGB<br><br>
    `,

    descriptionE: `
    `,
      
    productId: 'rgb-controle-molex-motherboard-black',
    banner: '',
    youtubeUrl: '',
    images: [Cover13],
    pageURL: 'rgb-controle-molex-motherboard-black',
  },
  {
    id: 14,
    name: 'RS1(RGB MOLEX) - WHITE',
    category: 'coverpsu',
    descriptionP: `
    Cover para Fonte Rise Mode RS1(RGB MOLEX) - BRANCO RM-CF-01-RS<br><br>

    COVER DE FONTE RS1 BRANCA COM LEDS<br><br>
    EXTENSOR PARA CABO RGB - 4 PINOS 50CM<br><br>
    UM CONTROLE MOLEX PARA CONTROLAR OS LEDS RGB<br><br>
    Descrição:<br><br>
    DÊ OUTRA CARA PARA SEU SETUP!<br><br>
    Pensando no público entusiasta a Rise Mode desenvolveu sua linha de Cover Psu, projetado nas dimensões do Padrão ATX, garantindo uma ampla compatibilidade com os principais gabinetes do mercado, com uma vasta possibilidade de personalização, da cor do LED ao Design.<br><br>
    Nossos Covers não requerem a utilização de ferramentas para a instalação, proporcionando facilidade para quem procura um visual inovador ao seu Setup. Iluminadas através de uma tira de LED de fácil instalação e conectadas direto a fonte de alimentação.<br><br>
    Especificações:<br><br>
    Marca: RISE<br><br>
    Modelo: COVER RISE MODE RS1(RGB MOLEX) - BLACK RM-CF-01-RS<br><br>
    Part Number: RM-CF-02-RS<br><br>
    Cor: BRANCO<br><br>
    Compatibilidade: GABINETES ATX (ESPAÇO MÍNIMO 27 CM)<br><br>
    Conexão Molex ou Conexão 4 pin motherboard<br><br>
    Dimensões da Caixa: 28,3CM X 19CM X 9CM<br><br>
    Peso: 410 GRAMAS (BRUTO SEM EMBALAGEM)<br><br>
    Peso com Caixa: 540 GRAMAS (BRUTO COM EMBALAGEM)
    `,

    descriptionE: `
    `,
      
    productId: 'rs1-rgb-molex-white',
    banner: '',
    youtubeUrl: '',
    images: [Cover14],
    pageURL: 'rs1-rgb-molex-white',
  },
];

export default products;
