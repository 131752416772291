import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import Modal from 'react-modal';
import { motion } from 'framer-motion'; 

const firebaseConfig = {
    apiKey: "AIzaSyBliTfTPbSU4caIdCdMh_Xs5f_-95ISq28",
    authDomain: "produtos-list.firebaseapp.com",
    databaseURL: "https://produtos-list-default-rtdb.firebaseio.com",
    projectId: "produtos-list",
    storageBucket: "produtos-list.appspot.com",
    messagingSenderId: "308827988350",
    appId: "1:308827988350:web:a635c2b1508599bfff132f"
  };
  
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }

const Container = styled.div`
  max-width: 1200px;
  border-radius: 0rem 1rem 1rem 0rem;
  margin: 0 auto;
  padding: 20px;
  background: #161616;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;

  input[type=file] {
  width: 350px;
  max-width: 100%;
  color: #444;
  padding: 5px;
  background: #fff;
  border-radius: 10px;
}

  input[type=file]::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #4491FA;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background .2s ease-in-out;
  }

  input[type=file]::file-selector-button:hover {
    filter: brightness(0.9);
  }

  h1{
    background: none;
    color: #4491FA;
    margin-bottom: 0.5rem;
  }
`;

const SearchInput = styled.input`
  width: 100%;
  max-width: 600px;
  padding: 10px;
  margin-bottom: 20px;
  background: #343333;
  border: 1px solid #8d8d8d;
  border-radius: 4px;
  height: 50px;
  font-size: 1.2rem;
`;

const ImageInput = styled.input`
background: none;
margin: 5px;
cursor: pointer;
background: red;
font-size: 20px;
width: 350px;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  background: none;
`;

const Thumbnail = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 10px 0 0 10px;
  background: none;
`;

const ImagesWrapper = styled.div`
  display: flex;
  background: none;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 1000px;
`;

const EditButton = styled.button`
  padding: 5px 10px;
  background-color: #4491fa;
  border: none;
  color: #fff;
  cursor: pointer;
  margin-right: 10px;
  height: 100%;
  border-radius: 0 10px 10px 0;
  font-size: 1.4rem;
`;

const Controle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
`;

const ProductList = styled.div`
  width: 100%;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  background: none;
`;

const ProductItem = styled.li`
  display: flex;
  align-items: center;
  margin: 0.8rem;
  padding: 1rem;
  border-radius: 4px;
  background: #101010;
  border: 1px solid #8d8d8d;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: 300ms;

    :hover{
      background: #343333;
    }

    
`;

const ProductThumbnail = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 4px;
  
  
  @media screen and (max-width: 1300px) {
    width: 30px;
    height: 30px;
  }
  
`;

const ProductInfo = styled.div`
  flex: 1;
  background: none;
  
`;

const ProductName = styled.span`
  display: flex;
  align-items: center;
  background: none;
  font-size: 1.5rem;

  
  span{
    font-size: 2rem;
    background: none;
  }
`;

const ProductCategory = styled.span`
  color: #ff0000;
  background: none;
`;

const DeleteButton = styled.button`
  background-color: #ff0000;
  color: #fff;
  border: none;
  padding: 12px 20px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 1rem;
  font-family: 'inter';
  font-weight: 800;
  

  :hover{
    filter: brightness(0.8);
  }

  @media screen and (max-width: 1300px) {
    padding: 5px 10px;
    font-size: 0.8rem;
  }
`;

const SuccessMessage = styled.div`
  /* Estilos para a mensagem de sucesso */
  background-color: #4caf50;
  color: #fff;
  padding: 10px;
  margin-top: 40px;
  font-size: 1.3rem;
  width: 300px;
  margin: 0 auto;
  padding: 1rem;
  border-radius: 4px;
  display: block;
  text-align: center;
`;

const CustomModal = styled(Modal)`
  width: 1000px;
  height: 800px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #161616;
  padding: 80px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
  overflow-y: scroll;
`;

const Overlay = styled.div`
  /* Estilos para o overlay */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;


const ModalTitle = styled.h2`
  /* Estilos para o título do modal */
  margin-bottom: 4px;
  background: none;
  font-size: 2.5rem;
`;

const ModalContent = styled.div`
  margin-bottom: 20px;
  background: none;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 1rem;

  label{
    padding-left: 0.4rem;
    text-align: left;
    background: none;
    font-size: 1.5rem;
    margin-top: 6px;
  }

  p{
    font: 1rem;
    background: none;
    color: #c3c3c3;
    font-family: 'Inter';
  }
`;


const NameInput = styled.input`
  margin: 5px;
  width: 350px;
  height: 40px;
  border-radius: 4px;
  padding: 10px;
  background: #343333;
  border: 1px solid #8d8d8d;
  font-size: 1.2rem;
  margin-bottom: 10px;
`;

const Category= styled.input`
  margin: 5px;
  width: 350px;
  height: 40px;
  border-radius: 4px;
  padding: 10px;
  background: #343333;
  border: 1px solid #8d8d8d;
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-family: 'Inter';
`;

const DescriptionInput = styled.textarea`
  margin: 5px;
  padding: 10px;
  width: 350px;
  height: 100px;
  border-radius: 4px;
  background: #343333;
  border: 1px solid #8d8d8d;
  font-size: 1.2rem;
  margin-bottom: 10px;
`;

const BannerInput = styled.input`
  margin: 5px;
  cursor: pointer;
  background: red;
  font-size: 20px;
  width: 350px;
`;

const YoutubeUrlInput = styled.input`
  margin: 5px;
  padding: 10px;
  width: 350px;
  height: 40px;
  border-radius: 4px;
  background: #343333;
  border: 1px solid #8d8d8d;
  color: #ffffff;
  font-size: 1.2rem;
`;

const ModalActions = styled.div`
  /* Estilos para as ações do modal */
  display: flex;
  justify-content: center;
  background: none;
  margin-bottom: 10px;

  button {
    margin-left: 10px;
  }

  #cancelar{
    color: white;
    border: 1px solid white;
    width: 150px;
    height: 50px;
    background:none;
    cursor: pointer;
    font-size: 1rem;
    font-family: 'inter';
    font-weight: 800;
    border-radius: 4px;

    :hover{
      filter: brightness(0.8);
    }
  }

  #salvar{
    color: white;
    border: none;
    width: 150px;
    height: 50px;
    background: #ff0000;
    cursor: pointer;
    font-size: 1rem;
    font-family: 'inter';
    font-weight: 800;
    border-radius: 4px;

    :hover{
      filter: brightness(0.8);
    }

    &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
  }
`;

const DeleteImage = styled.button`
  top: 5px;
  right: 5px;
  padding: 5px;
  background-color: red;
  color: white;
  font-size: 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 12px;
  font-size: 1.4rem;

  :hover{
      filter: brightness(0.8);
    }
`;

const database = firebase.database();

Modal.setAppElement('#root'); // Define o elemento raiz do React Modal

const App = () => {
    const [isSending, setIsSending] = useState(false);
    const [products, setProducts] = useState([]);
    const [editedProduct, setEditedProduct] = useState({
      images: [],
      name: '',
      description: '',
      category: '',
      banner: '',
      youtubeUrl: ''
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const snapshot = await database.ref('products').once('value');
          const data = snapshot.val();
          if (data) {
            const productsArray = Object.keys(data).map((key) => ({
              id: key,
              ...data[key],
            }));
            setProducts(productsArray);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }, []);
  
    const handleOpenModal = (productId) => {
      const product = products.find((product) => product.id === productId);
      setEditedProduct(product);
      setIsModalOpen(true);
    };
  
    const handleCloseModal = () => {
      setIsModalOpen(false);
    };
  
    const handleSearch = (event) => {
      setSearchTerm(event.target.value);
    };
  
    const handleImageInputChange = (event) => {
      const { name, files } = event.target;
  
      if (name === 'images') {
        const file = files[0];
        const reader = new FileReader();
  
        reader.onload = (e) => {
          const fileURL = e.target.result;
  
          if (editedProduct.currentImageIndex !== undefined) {
            const updatedImages = [...editedProduct.images];
            updatedImages[editedProduct.currentImageIndex] = fileURL;
  
            setEditedProduct((prevState) => ({
              ...prevState,
              images: updatedImages,
              currentImageIndex: undefined, // Limpar o índice da imagem atualmente selecionada
            }));
          } else {
            setEditedProduct((prevState) => ({
              ...prevState,
              images: [...prevState.images, fileURL],
            }));
          }
        };
  
        reader.readAsDataURL(file);
      }
    };
    
    const handleEditImage = (index) => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';
      
        fileInput.onchange = (event) => {
          const file = event.target.files[0];
          const reader = new FileReader();
          
          reader.onload = (e) => {
            const fileURL = e.target.result;
      
            const updatedImages = [...editedProduct.images];
            updatedImages[index] = fileURL;
      
            setEditedProduct((prevState) => ({
              ...prevState,
              images: updatedImages,
            }));
      
            // Realizar o upload da imagem para o Firebase aqui
            // Você precisará usar a biblioteca ou SDK do Firebase para fazer o upload
      
            // Exemplo usando o Firebase Storage:
            const storageRef = firebase.storage().ref();
            const imageRef = storageRef.child(`images/${file.name}`);
      
            imageRef
              .put(file)
              .then((snapshot) => {
                // Obter a URL da imagem no Firebase
                return snapshot.ref.getDownloadURL();
              })
              .then((downloadURL) => {
                // Atualizar a URL da imagem no estado do produto editado
                const updatedImages = [...editedProduct.images];
                updatedImages[index] = downloadURL;
      
                setEditedProduct((prevState) => ({
                  ...prevState,
                  images: updatedImages,
                }));
      
                // Opcional: exibir uma mensagem de sucesso após o upload
                console.log('Upload de imagem realizado com sucesso!');
              })
              .catch((error) => {
                console.error('Erro ao fazer upload da imagem:', error);
              });
          };
      
          reader.readAsDataURL(file);
        };
      
        fileInput.click();
      };
      

    const handleBannerInputChange = (event) => {
      const { files } = event.target;
      const file = files[0];
      const reader = new FileReader();
  
      reader.onload = (e) => {
        const fileURL = e.target.result;
  
        setEditedProduct((prevState) => ({
          ...prevState,
          banner: fileURL,
        }));
      };
  
      reader.readAsDataURL(file);
    };
  
    const handleInputChange = (event) => {
      const { name, type, value } = event.target;
  
      if (type === 'file') {
        if (name === 'images') {
          handleImageInputChange(event);
        } else if (name === 'banner') {
          handleBannerInputChange(event);
        }
      } else {
        setEditedProduct((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    };

    const reloader = async () => {
        try {
          const snapshot = await database.ref('products').once('value');
          const data = snapshot.val();
          if (data) {
            const productsArray = Object.keys(data).map((key) => ({
              id: key,
              ...data[key],
            }));
            setProducts(productsArray);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      const handleSaveProduct = () => {
        setIsSending(true); // Define o estado de "envio" para true ao iniciar o envio
    
        database
          .ref(`products/${editedProduct.id}`)
          .update(editedProduct)
          .then(() => {
            setShowSuccessMessage(true);
            setTimeout(() => {
              setShowSuccessMessage(false);
            }, 2000);
    
            reloader(); // Chamar a função fetchData para atualizar a lista de produtos
          })
          .catch((error) => {
            console.error('Error saving product:', error);
          })
          .finally(() => {
            // Reverter o estado de "envio" após 2 segundos usando setTimeout
            setTimeout(() => {
              setIsSending(false);
            }, 2000);
          });
      };
      

      const handleDeleteImage = (index) => {
        const updatedImages = [...editedProduct.images];
        updatedImages.splice(index, 1);
        setEditedProduct((prevState) => ({
          ...prevState,
          images: updatedImages,
        }));
      
        // Deletar a imagem do Firebase aqui
        // Você precisará usar a biblioteca ou SDK do Firebase para fazer a exclusão
      
        // Exemplo usando o Firebase Storage:
        const storageRef = firebase.storage().ref();
        const imageRef = storageRef.child(`images/${editedProduct.images[index]}`);
      
        imageRef
          .delete()
          .then(() => {
            console.log('Imagem excluída com sucesso!');
          })
          .catch((error) => {
            console.error('Erro ao excluir imagem:', error);
          });
      };
      
  
  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.3 }}
    >
      <Container>
        <h1>Editar Produtos</h1>
        <SearchInput
          type="text"
          placeholder="Pesquisar produto"
          value={searchTerm}
          onChange={handleSearch}
        />
        <Controle>
        <ProductList>
          {filteredProducts.map((product) => (
            <ProductItem key={product.id}>
              {product.images && product.images.length > 0 && (
                <ProductThumbnail
                  src={product.images[0]}
                  alt="Product Thumbnail"
                />
              )}
              <ProductInfo>
                <ProductName>
                {product.name}
                </ProductName>
                <ProductCategory>
                  Categoria: {product.category}
                </ProductCategory>
              </ProductInfo>
              <DeleteButton onClick={() => handleOpenModal(product.id)}>
                Abrir
              </DeleteButton>
            </ProductItem>
          ))}
        </ProductList>
        </Controle>        
        <CustomModal
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          contentLabel="Confirmar edição"
          overlayClassName="modal-overlay"
        >
          <ModalTitle>Editar Produto</ModalTitle>
          <ModalContent>
          <ImagesWrapper>
            {editedProduct.images &&
                editedProduct.images.map((image, index) => (
                <ImageContainer key={index}>
                    <Thumbnail src={image} alt={`Thumbnail ${index + 1}`} />
                    <EditButton onClick={() => handleEditImage(index)}>Editar</EditButton>
                    <DeleteImage onClick={() => handleDeleteImage(index)}>
                      Excluir
                    </DeleteImage>
                </ImageContainer>
                ))}
            </ImagesWrapper>
            <label>Imagem:</label>
            <ImageInput
              className="custom-file-input"   
              type="file"
              name="images"
              id="fileInput"
              onChange={handleInputChange}
            />
            <label>Nome:</label>
            <NameInput
              type="text"
              name="name"
              placeholder="Nome"
              value={editedProduct.name}
              onChange={handleInputChange}
            />
            <label>Descrição:</label>
            <DescriptionInput
              type="text"
              name="description"
              placeholder="Descrição"
              value={editedProduct.description}
              onChange={handleInputChange}
            />
            <label>Categoria:</label>
            <Category
              type="text"
              name="category"
              placeholder="Categoria"
              value={editedProduct.category}
              onChange={handleInputChange}
            />
            <label>Banner:</label>
            <BannerInput
              className="custom-file-input" 
              type="file"
              name="banner"
              id="bannerInput"
              onChange={handleInputChange}
            />
            <label>Youtube:</label>
            <YoutubeUrlInput
              type="text"
              name="youtubeUrl"
              placeholder="YouTube ID DO VIDEO"
              value={editedProduct.youtubeUrl}
              onChange={handleInputChange}
            />
          </ModalContent>
          <ModalActions>
            <button id="cancelar" onClick={handleCloseModal}>
              Cancelar
            </button>
            <button id="salvar" onClick={handleSaveProduct} disabled={isSending}>
              Salvar
            </button>
          </ModalActions>
            {showSuccessMessage && (
            <SuccessMessage>Produto foi salvo com sucesso!</SuccessMessage>
             )}
        </CustomModal>
      </Container>
    </motion.div>
  );
};

export default App;