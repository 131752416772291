import NaoFume_01 from '../../../img/Produtos/mousepad/nao-fume-ram/1.jpg';
import NaoFume_02 from '../../../img/Produtos/mousepad/nao-fume-ram/2.jpg';
import NaoFume_03 from '../../../img/Produtos/mousepad/nao-fume-ram/3.jpg';

import GalaxyRGB_01 from '../../../img/Produtos/mousepad/galaxy-rgb-mousepad/1.jpg';
import GalaxyRGB_02 from '../../../img/Produtos/mousepad/galaxy-rgb-mousepad/2.jpg';
import GalaxyRGB_03 from '../../../img/Produtos/mousepad/galaxy-rgb-mousepad/3.jpg';

import GamingEsqueleto_01 from '../../../img/Produtos/mousepad/gaming-esqueleto-mousepad/1.jpg';
import GamingEsqueleto_02 from '../../../img/Produtos/mousepad/gaming-esqueleto-mousepad/2.jpg';
import GamingEsqueleto_03 from '../../../img/Produtos/mousepad/gaming-esqueleto-mousepad/3.jpg';

import GamingNinja_01 from '../../../img/Produtos/mousepad/gaming-ninja-mousepad/1.jpg';
import GamingNinja_02 from '../../../img/Produtos/mousepad/gaming-ninja-mousepad/2.jpg';
import GamingNinja_03 from '../../../img/Produtos/mousepad/gaming-ninja-mousepad/3.jpg';

import GamingPlanetas_01 from '../../../img/Produtos/mousepad/gaming-planetas-mousepad/1.jpg';
import GamingPlanetas_02 from '../../../img/Produtos/mousepad/gaming-planetas-mousepad/2.jpg';
import GamingPlanetas_03 from '../../../img/Produtos/mousepad/gaming-planetas-mousepad/3.jpg';

import GamingFloresta_01 from '../../../img/Produtos/mousepad/gaming-floresta-mousepad/1.jpg';
import GamingFloresta_02 from '../../../img/Produtos/mousepad/gaming-floresta-mousepad/2.jpg';
import GamingFloresta_03 from '../../../img/Produtos/mousepad/gaming-floresta-mousepad/3.jpg';

import GamingNeon_01 from '../../../img/Produtos/mousepad/gaming-neon-mousepad/1.jpg';
import GamingNeon_02 from '../../../img/Produtos/mousepad/gaming-neon-mousepad/2.jpg';
import GamingNeon_03 from '../../../img/Produtos/mousepad/gaming-neon-mousepad/3.jpg';

import GamingAk47_01 from '../../../img/Produtos/mousepad/gaming-ak47-mousepad/1.jpg';
import GamingAk47_02 from '../../../img/Produtos/mousepad/gaming-ak47-mousepad/2.jpg';

import GamingBlack_01 from '../../../img/Produtos/mousepad/gaming-black-mode/1.jpg';
import GamingBlack_02 from '../../../img/Produtos/mousepad/gaming-black-mode/2.jpg';
import GamingBlack_03 from '../../../img/Produtos/mousepad/gaming-black-mode/3.jpg';

import GamingHacker_01 from '../../../img/Produtos/mousepad/gaming-hacker-mousepad/1.jpg';
import GamingHacker_02 from '../../../img/Produtos/mousepad/gaming-hacker-mousepad/2.jpg';
import GamingHacker_03 from '../../../img/Produtos/mousepad/gaming-hacker-mousepad/3.jpg';

import GamingScorpion_01 from '../../../img/Produtos/mousepad/gaming-scorpion-mousepad/1.jpg';
import GamingScorpion_02 from '../../../img/Produtos/mousepad/gaming-scorpion-mousepad/2.jpg';
import GamingScorpion_03 from '../../../img/Produtos/mousepad/gaming-scorpion-mousepad/3.jpg';

import GamingScorpionR_01 from '../../../img/Produtos/mousepad/gaming-scorpion-red-mousepad/1.jpg';
import GamingScorpionR_02 from '../../../img/Produtos/mousepad/gaming-scorpion-red-mousepad/2.jpg';
import GamingScorpionR_03 from '../../../img/Produtos/mousepad/gaming-scorpion-red-mousepad/3.jpg';

import GamingDesert_01 from '../../../img/Produtos/mousepad/gaming-desert-mousepad/1.jpg';
import GamingDesert_02 from '../../../img/Produtos/mousepad/gaming-desert-mousepad/2.jpg';
import GamingDesert_03 from '../../../img/Produtos/mousepad/gaming-desert-mousepad/3.jpg';

import GamingAKR_01 from '../../../img/Produtos/mousepad/gaming-ak47-red-mousepad/1.jpg';
import GamingAKR_02 from '../../../img/Produtos/mousepad/gaming-ak47-red-mousepad/2.jpg';
import GamingAKR_03 from '../../../img/Produtos/mousepad/gaming-ak47-red-mousepad/3.jpg';

import GamingSniper_01 from '../../../img/Produtos/mousepad/gaming-sniper-mousepad/1.jpg';
import GamingSniper_02 from '../../../img/Produtos/mousepad/gaming-sniper-mousepad/2.jpg';
import GamingSniper_03 from '../../../img/Produtos/mousepad/gaming-sniper-mousepad/3.jpg';

import GamingSniperG_01 from '../../../img/Produtos/mousepad/gaming-sniper-grey/1.jpg';
import GamingSniperG_02 from '../../../img/Produtos/mousepad/gaming-sniper-grey/2.jpg';

import GamingSnake_01 from '../../../img/Produtos/mousepad/gaming-snake-mousepad/1.jpg';
import GamingSnake_02 from '../../../img/Produtos/mousepad/gaming-snake-mousepad/2.jpg';
import GamingSnake_03 from '../../../img/Produtos/mousepad/gaming-snake-mousepad/3.jpg';


const products = [
  {
    id: 1,
    name: 'NÃO FUME RAM',
    category: 'mousepad',
    descriptionP: `MOUSEPAD DE ALTA PERFORMANCE! RISE PENSANDO SEMPRE EM SUA MELHOR JOGADA<br>
    Os Mouse Pads Rise Gaming oferecem tudo que um Gamer procura. Com base em borracha antiderrapante e uma superfície em Fibertek, eles proporcionam movimentos rápidos e tiros precisos. Sua superfície resistente à água contribui para a limpeza e durabilidade do mousepad.
    
    Com impressão de alta qualidade e disponíveis em diversos modelos, você encontrará o mousepad perfeito que combina com sua configuração e adiciona personalidade ao seu espaço gamer.`,


    descriptionE: `HIGH-PERFORMANCE MOUSEPAD! RISE ALWAYS THINKING ABOUT YOUR BEST MOVE

    The Rise Gaming Mouse Pads offer everything a gamer looks for. With a non-slip rubber base and a Fibertek surface, they provide fast and precise movements. The water-resistant surface contributes to easy cleaning and durability.
    
    With high-quality printing and a variety of designs, you will find the perfect mousepad that matches your setup and adds personality to your gaming space.`,
      
    productId: 'nao-fume-ram',
    banner: '',
    youtubeUrl: '',
    images: [NaoFume_01, NaoFume_02, NaoFume_03],
    pageURL: 'nao-fume-ram',
  },
  {
    id: 2,
    name: 'Galaxy RGB',
    category: 'mousepad',
    descriptionP: `Características:<br>

    Marca: Rise Mode<br>
    Modelo: Mouse pad Rise Mode Galaxy RGB – 900x300mm<br>
    Especificações:<br>
    
    Part Number: RM-MP-07-RGB<br>
    Base: Rubber (Emborrachada)<br>
    Superfície: Fibertek Resistente a água<br>
    Cor do Led: RGB<br>
    Dimensões do produto: 90cm x 30cm x 0,3m<br>
    Informações adicionais:<br>
    
    Troca de cores pelo botão do controlador no mousepad<br>
    Tamanho estendido para comportar perfeitamente<br>
    teclado e mouse sobre o mousepad<br>
    Proporciona movimentos rápidos e precisos<br>
    Otimizado para mouses de alto DPI<br>
    EAN: 7895099519341<br>`,

    descriptionE: `Features:<br>

    Brand: Rise Mode<br>
    Model: Rise Mode Galaxy RGB Mouse Pad - 900x300mm<br>
    Specifications:<br>
    
    Part Number: RM-MP-07-RGB<br>
    Base: Rubber (Non-slip)<br>
    Surface: Water-resistant Fibertek<br>
    LED Color: RGB<br>
    Product Dimensions: 90cm x 30cm x 0.3cm<br>
    Additional Information:<br>
    
    Color change controlled by the button on the mouse pad<br>
    Extended size to comfortably accommodate<br>
    keyboard and mouse on the mouse pad<br>
    Enables quick and precise movements<br>
    Optimized for high DPI mice<br>
    EAN: 7895099519341<br>`,
      
    productId: 'galaxy-rgb-mousepad',
    banner: '',
    youtubeUrl: '',
    images: [GalaxyRGB_01, GalaxyRGB_02, GalaxyRGB_03],
    pageURL: 'galaxy-rgb-mousepad',
  },
  {
    id: 3,
    name: 'GAMING ESQUELETO',
    category: 'mousepad',
    descriptionP: `MOUSEPAD DE ALTA PERFORMANCE! RISE PENSANDO SEMPRE EM SUA MELHOR JOGADA<br>

    Os Mouse Pads Rise Gaming oferecem tudo o que um jogador procura, com uma base de borracha antiderrapante e uma superfície em Fibertek. Seu estilo Speed na superfície proporciona movimentos rápidos e tiros precisos, enquanto sua superfície resistente à água contribui para a limpeza e durabilidade do mousepad.
    
    Impressão de alta qualidade com diversos modelos, permitindo que você encontre um que combine com sua configuração e adicione personalidade ao seu espaço gamer.
    
    Mouse Pads flexíveis e resistentes, sua flexibilidade e tecnologia permitem o transporte fácil.
    
    Espero que isso atenda às suas necessidades!`,

    descriptionE: `The Rise Gaming Mouse Pads offer everything a Gamer needs, with a non-slip rubber base and a Fibertek surface. Its Speed style surface allows for fast and precise movements, while its water-resistant feature contributes to surface cleanliness and durability.

    High-quality printing with various designs, allowing you to find one that matches your setup and adds personality to your gaming space.
    
    Flexible and durable mouse pads, their flexibility and technology enable easy transport.
    
    I hope this meets your requirements!`,
      
    productId: 'gaming-esqueleto-mousepad',
    banner: '',
    youtubeUrl: '',
    images: [GamingEsqueleto_01, GamingEsqueleto_02, GamingEsqueleto_03],
    pageURL: 'gaming-esqueleto-mousepad',
  },
  {
    id: 4,
    name: 'GAMING NINJA',
    category: 'mousepad',
    descriptionP: `MOUSEPAD DE ALTA PERFORMANCE! RISE PENSANDO SEMPRE EM SUA MELHOR JOGADA<br>

    Os Mouse Pads Rise Gaming oferecem tudo o que um jogador procura, com uma base de borracha antiderrapante e uma superfície em Fibertek. Seu estilo Speed na superfície proporciona movimentos rápidos e tiros precisos, enquanto sua superfície resistente à água contribui para a limpeza e durabilidade do mousepad.
    
    Impressão de alta qualidade com diversos modelos, permitindo que você encontre um que combine com sua configuração e adicione personalidade ao seu espaço gamer.
    
    Mouse Pads flexíveis e resistentes, sua flexibilidade e tecnologia permitem o transporte fácil.
    
    Espero que isso atenda às suas necessidades!`,

    descriptionE: `The Rise Gaming Mouse Pads offer everything a Gamer needs, with a non-slip rubber base and a Fibertek surface. Its Speed style surface allows for fast and precise movements, while its water-resistant feature contributes to surface cleanliness and durability.

    High-quality printing with various designs, allowing you to find one that matches your setup and adds personality to your gaming space.
    
    Flexible and durable mouse pads, their flexibility and technology enable easy transport.
    
    I hope this meets your requirements!`,
      
    productId: 'gaming-ninja-mousepad',
    banner: '',
    youtubeUrl: '',
    images: [GamingNinja_01, GamingNinja_02, GamingNinja_03],
    pageURL: 'gaming-ninja-mousepad',
  },
  {
    id: 5,
    name: 'GAMING PLANETAS',
    category: 'mousepad',
    descriptionP: `MOUSEPAD DE ALTA PERFORMANCE! RISE PENSANDO SEMPRE EM SUA MELHOR JOGADA<br>

    Os Mouse Pads Rise Gaming oferecem tudo o que um jogador procura, com uma base de borracha antiderrapante e uma superfície em Fibertek. Seu estilo Speed na superfície proporciona movimentos rápidos e tiros precisos, enquanto sua superfície resistente à água contribui para a limpeza e durabilidade do mousepad.
    
    Impressão de alta qualidade com diversos modelos, permitindo que você encontre um que combine com sua configuração e adicione personalidade ao seu espaço gamer.
    
    Mouse Pads flexíveis e resistentes, sua flexibilidade e tecnologia permitem o transporte fácil.
    
    Espero que isso atenda às suas necessidades!`,

    descriptionE: `The Rise Gaming Mouse Pads offer everything a Gamer needs, with a non-slip rubber base and a Fibertek surface. Its Speed style surface allows for fast and precise movements, while its water-resistant feature contributes to surface cleanliness and durability.

    High-quality printing with various designs, allowing you to find one that matches your setup and adds personality to your gaming space.
    
    Flexible and durable mouse pads, their flexibility and technology enable easy transport.
    
    I hope this meets your requirements!`,
      
    productId: 'gaming-planetas-mousepad',
    banner: '',
    youtubeUrl: '',
    images: [GamingPlanetas_01, GamingPlanetas_02, GamingPlanetas_03],
    pageURL: 'gaming-planetas-mousepad',
  },
  {
    id: 6,
    name: 'GAMING FLORESTA',
    category: 'mousepad',
    descriptionP: `MOUSEPAD DE ALTA PERFORMANCE! RISE PENSANDO SEMPRE EM SUA MELHOR JOGADA<br>

    Os Mouse Pads Rise Gaming oferecem tudo o que um jogador procura, com uma base de borracha antiderrapante e uma superfície em Fibertek. Seu estilo Speed na superfície proporciona movimentos rápidos e tiros precisos, enquanto sua superfície resistente à água contribui para a limpeza e durabilidade do mousepad.
    
    Impressão de alta qualidade com diversos modelos, permitindo que você encontre um que combine com sua configuração e adicione personalidade ao seu espaço gamer.
    
    Mouse Pads flexíveis e resistentes, sua flexibilidade e tecnologia permitem o transporte fácil.
    
    Espero que isso atenda às suas necessidades!`,

    descriptionE: `The Rise Gaming Mouse Pads offer everything a Gamer needs, with a non-slip rubber base and a Fibertek surface. Its Speed style surface allows for fast and precise movements, while its water-resistant feature contributes to surface cleanliness and durability.

    High-quality printing with various designs, allowing you to find one that matches your setup and adds personality to your gaming space.
    
    Flexible and durable mouse pads, their flexibility and technology enable easy transport.
    
    I hope this meets your requirements!`,
      
    productId: 'gaming-floresta-mousepad',
    banner: '',
    youtubeUrl: '',
    images: [GamingFloresta_01, GamingFloresta_02, GamingFloresta_03],
    pageURL: 'gaming-floresta-mousepad',
  },
  {
    id: 7,
    name: 'GAMING NEON',
    category: 'mousepad',
    descriptionP: `MOUSEPAD DE ALTA PERFORMANCE! RISE PENSANDO SEMPRE EM SUA MELHOR JOGADA<br>

    Os Mouse Pads Rise Gaming oferecem tudo o que um jogador procura, com uma base de borracha antiderrapante e uma superfície em Fibertek. Seu estilo Speed na superfície proporciona movimentos rápidos e tiros precisos, enquanto sua superfície resistente à água contribui para a limpeza e durabilidade do mousepad.
    
    Impressão de alta qualidade com diversos modelos, permitindo que você encontre um que combine com sua configuração e adicione personalidade ao seu espaço gamer.
    
    Mouse Pads flexíveis e resistentes, sua flexibilidade e tecnologia permitem o transporte fácil.
    
    Espero que isso atenda às suas necessidades!`,

    descriptionE: `The Rise Gaming Mouse Pads offer everything a Gamer needs, with a non-slip rubber base and a Fibertek surface. Its Speed style surface allows for fast and precise movements, while its water-resistant feature contributes to surface cleanliness and durability.

    High-quality printing with various designs, allowing you to find one that matches your setup and adds personality to your gaming space.
    
    Flexible and durable mouse pads, their flexibility and technology enable easy transport.
    
    I hope this meets your requirements!`,
      
    productId: 'gaming-neon-mousepad',
    banner: '',
    youtubeUrl: '',
    images: [GamingNeon_01, GamingNeon_02, GamingNeon_03],
    pageURL: 'gaming-neon-mousepad',
  },
  {
    id: 8,
    name: 'GAMING AK47',
    category: 'mousepad',
    descriptionP: `MOUSEPAD DE ALTA PERFORMANCE! RISE PENSANDO SEMPRE EM SUA MELHOR JOGADA<br>

    Os Mouse Pads Rise Gaming oferecem tudo o que um jogador procura, com uma base de borracha antiderrapante e uma superfície em Fibertek. Seu estilo Speed na superfície proporciona movimentos rápidos e tiros precisos, enquanto sua superfície resistente à água contribui para a limpeza e durabilidade do mousepad.
    
    Impressão de alta qualidade com diversos modelos, permitindo que você encontre um que combine com sua configuração e adicione personalidade ao seu espaço gamer.
    
    Mouse Pads flexíveis e resistentes, sua flexibilidade e tecnologia permitem o transporte fácil.
    
    Espero que isso atenda às suas necessidades!`,

    descriptionE: `The Rise Gaming Mouse Pads offer everything a Gamer needs, with a non-slip rubber base and a Fibertek surface. Its Speed style surface allows for fast and precise movements, while its water-resistant feature contributes to surface cleanliness and durability.

    High-quality printing with various designs, allowing you to find one that matches your setup and adds personality to your gaming space.
    
    Flexible and durable mouse pads, their flexibility and technology enable easy transport.
    
    I hope this meets your requirements!`,
      
    productId: 'gaming-ak47-mousepad',
    banner: '',
    youtubeUrl: '',
    images: [GamingAk47_01, GamingAk47_02],
    pageURL: 'gaming-ak47-mousepad',
  },
  {
    id: 9,
    name: 'GAMING BLACK MODE',
    category: 'mousepad',
    descriptionP: `MOUSEPAD DE ALTA PERFORMANCE! RISE PENSANDO SEMPRE EM SUA MELHOR JOGADA<br>

    Os Mouse Pads Rise Gaming oferecem tudo o que um jogador procura, com uma base de borracha antiderrapante e uma superfície em Fibertek. Seu estilo Speed na superfície proporciona movimentos rápidos e tiros precisos, enquanto sua superfície resistente à água contribui para a limpeza e durabilidade do mousepad.
    
    Impressão de alta qualidade com diversos modelos, permitindo que você encontre um que combine com sua configuração e adicione personalidade ao seu espaço gamer.
    
    Mouse Pads flexíveis e resistentes, sua flexibilidade e tecnologia permitem o transporte fácil.
    
    Espero que isso atenda às suas necessidades!`,

    descriptionE: `The Rise Gaming Mouse Pads offer everything a Gamer needs, with a non-slip rubber base and a Fibertek surface. Its Speed style surface allows for fast and precise movements, while its water-resistant feature contributes to surface cleanliness and durability.

    High-quality printing with various designs, allowing you to find one that matches your setup and adds personality to your gaming space.
    
    Flexible and durable mouse pads, their flexibility and technology enable easy transport.
    
    I hope this meets your requirements!`,
      
    productId: 'gaming-black-mode',
    banner: '',
    youtubeUrl: '',
    images: [GamingBlack_01, GamingBlack_02, GamingBlack_03],
    pageURL: 'gaming-black-mode',
  },
  {
    id: 10,
    name: 'GAMING HACKER',
    category: 'mousepad',
    descriptionP: `MOUSEPAD DE ALTA PERFORMANCE! RISE PENSANDO SEMPRE EM SUA MELHOR JOGADA<br>

    Os Mouse Pads Rise Gaming oferecem tudo o que um jogador procura, com uma base de borracha antiderrapante e uma superfície em Fibertek. Seu estilo Speed na superfície proporciona movimentos rápidos e tiros precisos, enquanto sua superfície resistente à água contribui para a limpeza e durabilidade do mousepad.
    
    Impressão de alta qualidade com diversos modelos, permitindo que você encontre um que combine com sua configuração e adicione personalidade ao seu espaço gamer.
    
    Mouse Pads flexíveis e resistentes, sua flexibilidade e tecnologia permitem o transporte fácil.
    
    Espero que isso atenda às suas necessidades!`,

    descriptionE: `The Rise Gaming Mouse Pads offer everything a Gamer needs, with a non-slip rubber base and a Fibertek surface. Its Speed style surface allows for fast and precise movements, while its water-resistant feature contributes to surface cleanliness and durability.

    High-quality printing with various designs, allowing you to find one that matches your setup and adds personality to your gaming space.
    
    Flexible and durable mouse pads, their flexibility and technology enable easy transport.
    
    I hope this meets your requirements!`,
      
    productId: 'gaming-hacker-mousepad',
    banner: '',
    youtubeUrl: '',
    images: [GamingHacker_01, GamingHacker_02, GamingHacker_03],
    pageURL: 'gaming-hacker-mousepad',
  },
  {
    id: 11,
    name: 'GAMING SCORPION',
    category: 'mousepad',
    descriptionP: `MOUSEPAD DE ALTA PERFORMANCE! RISE PENSANDO SEMPRE EM SUA MELHOR JOGADA<br>

    Os Mouse Pads Rise Gaming oferecem tudo o que um jogador procura, com uma base de borracha antiderrapante e uma superfície em Fibertek. Seu estilo Speed na superfície proporciona movimentos rápidos e tiros precisos, enquanto sua superfície resistente à água contribui para a limpeza e durabilidade do mousepad.
    
    Impressão de alta qualidade com diversos modelos, permitindo que você encontre um que combine com sua configuração e adicione personalidade ao seu espaço gamer.
    
    Mouse Pads flexíveis e resistentes, sua flexibilidade e tecnologia permitem o transporte fácil.
    
    Espero que isso atenda às suas necessidades!`,

    descriptionE: `The Rise Gaming Mouse Pads offer everything a Gamer needs, with a non-slip rubber base and a Fibertek surface. Its Speed style surface allows for fast and precise movements, while its water-resistant feature contributes to surface cleanliness and durability.

    High-quality printing with various designs, allowing you to find one that matches your setup and adds personality to your gaming space.
    
    Flexible and durable mouse pads, their flexibility and technology enable easy transport.
    
    I hope this meets your requirements!`,
      
    productId: 'gaming-scorpion-mousepad',
    banner: '',
    youtubeUrl: '',
    images: [GamingScorpion_01, GamingScorpion_02, GamingScorpion_03],
    pageURL: 'gaming-scorpion-mousepad',
  },
  {
    id: 12,
    name: 'GAMING SCORPION RED',
    category: 'mousepad',
    descriptionP: `MOUSEPAD DE ALTA PERFORMANCE! RISE PENSANDO SEMPRE EM SUA MELHOR JOGADA<br>

    Os Mouse Pads Rise Gaming oferecem tudo o que um jogador procura, com uma base de borracha antiderrapante e uma superfície em Fibertek. Seu estilo Speed na superfície proporciona movimentos rápidos e tiros precisos, enquanto sua superfície resistente à água contribui para a limpeza e durabilidade do mousepad.
    
    Impressão de alta qualidade com diversos modelos, permitindo que você encontre um que combine com sua configuração e adicione personalidade ao seu espaço gamer.
    
    Mouse Pads flexíveis e resistentes, sua flexibilidade e tecnologia permitem o transporte fácil.
    
    Espero que isso atenda às suas necessidades!`,

    descriptionE: `The Rise Gaming Mouse Pads offer everything a Gamer needs, with a non-slip rubber base and a Fibertek surface. Its Speed style surface allows for fast and precise movements, while its water-resistant feature contributes to surface cleanliness and durability.

    High-quality printing with various designs, allowing you to find one that matches your setup and adds personality to your gaming space.
    
    Flexible and durable mouse pads, their flexibility and technology enable easy transport.
    
    I hope this meets your requirements!`,
      
    productId: 'gaming-scorpion-red-mousepad',
    banner: '',
    youtubeUrl: '',
    images: [GamingScorpionR_01, GamingScorpionR_02, GamingScorpionR_03],
    pageURL: 'gaming-scorpion-red-mousepad',
  },
  {
    id: 13,
    name: 'GAMING DESERT',
    category: 'mousepad',
    descriptionP: `MOUSEPAD DE ALTA PERFORMANCE! RISE PENSANDO SEMPRE EM SUA MELHOR JOGADA<br>

    Os Mouse Pads Rise Gaming oferecem tudo o que um jogador procura, com uma base de borracha antiderrapante e uma superfície em Fibertek. Seu estilo Speed na superfície proporciona movimentos rápidos e tiros precisos, enquanto sua superfície resistente à água contribui para a limpeza e durabilidade do mousepad.
    
    Impressão de alta qualidade com diversos modelos, permitindo que você encontre um que combine com sua configuração e adicione personalidade ao seu espaço gamer.
    
    Mouse Pads flexíveis e resistentes, sua flexibilidade e tecnologia permitem o transporte fácil.
    
    Espero que isso atenda às suas necessidades!`,

    descriptionE: `The Rise Gaming Mouse Pads offer everything a Gamer needs, with a non-slip rubber base and a Fibertek surface. Its Speed style surface allows for fast and precise movements, while its water-resistant feature contributes to surface cleanliness and durability.

    High-quality printing with various designs, allowing you to find one that matches your setup and adds personality to your gaming space.
    
    Flexible and durable mouse pads, their flexibility and technology enable easy transport.
    
    I hope this meets your requirements!`,
      
    productId: 'gaming-desert-mousepad',
    banner: '',
    youtubeUrl: '',
    images: [GamingDesert_01, GamingDesert_02, GamingDesert_03],
    pageURL: 'gaming-desert-mousepad',
  },
  {
    id: 14,
    name: 'GAMING AK47 RED',
    category: 'mousepad',
    descriptionP: `MOUSEPAD DE ALTA PERFORMANCE! RISE PENSANDO SEMPRE EM SUA MELHOR JOGADA<br>

    Os Mouse Pads Rise Gaming oferecem tudo o que um jogador procura, com uma base de borracha antiderrapante e uma superfície em Fibertek. Seu estilo Speed na superfície proporciona movimentos rápidos e tiros precisos, enquanto sua superfície resistente à água contribui para a limpeza e durabilidade do mousepad.
    
    Impressão de alta qualidade com diversos modelos, permitindo que você encontre um que combine com sua configuração e adicione personalidade ao seu espaço gamer.
    
    Mouse Pads flexíveis e resistentes, sua flexibilidade e tecnologia permitem o transporte fácil.
    
    Espero que isso atenda às suas necessidades!`,

    descriptionE: `The Rise Gaming Mouse Pads offer everything a Gamer needs, with a non-slip rubber base and a Fibertek surface. Its Speed style surface allows for fast and precise movements, while its water-resistant feature contributes to surface cleanliness and durability.

    High-quality printing with various designs, allowing you to find one that matches your setup and adds personality to your gaming space.
    
    Flexible and durable mouse pads, their flexibility and technology enable easy transport.
    
    I hope this meets your requirements!`,
      
    productId: 'gaming-ak47-red-mousepad',
    banner: '',
    youtubeUrl: '',
    images: [GamingAKR_01, GamingAKR_02, GamingAKR_03],
    pageURL: 'gaming-ak47-red-mousepad',
  },
  {
    id: 15,
    name: 'GAMING SNIPER',
    category: 'mousepad',
    descriptionP: `MOUSEPAD DE ALTA PERFORMANCE! RISE PENSANDO SEMPRE EM SUA MELHOR JOGADA<br>

    Os Mouse Pads Rise Gaming oferecem tudo o que um jogador procura, com uma base de borracha antiderrapante e uma superfície em Fibertek. Seu estilo Speed na superfície proporciona movimentos rápidos e tiros precisos, enquanto sua superfície resistente à água contribui para a limpeza e durabilidade do mousepad.
    
    Impressão de alta qualidade com diversos modelos, permitindo que você encontre um que combine com sua configuração e adicione personalidade ao seu espaço gamer.
    
    Mouse Pads flexíveis e resistentes, sua flexibilidade e tecnologia permitem o transporte fácil.
    
    Espero que isso atenda às suas necessidades!`,

    descriptionE: `The Rise Gaming Mouse Pads offer everything a Gamer needs, with a non-slip rubber base and a Fibertek surface. Its Speed style surface allows for fast and precise movements, while its water-resistant feature contributes to surface cleanliness and durability.

    High-quality printing with various designs, allowing you to find one that matches your setup and adds personality to your gaming space.
    
    Flexible and durable mouse pads, their flexibility and technology enable easy transport.
    
    I hope this meets your requirements!`,
      
    productId: 'gaming-sniper-mousepad',
    banner: '',
    youtubeUrl: '',
    images: [GamingSniper_01, GamingSniper_02, GamingSniper_03],
    pageURL: 'gaming-sniper-mousepad',
  },
  {
    id: 16,
    name: 'GAMING SNIPER GREY',
    category: 'mousepad',
    descriptionP: `MOUSEPAD DE ALTA PERFORMANCE! RISE PENSANDO SEMPRE EM SUA MELHOR JOGADA<br>

    Os Mouse Pads Rise Gaming oferecem tudo o que um jogador procura, com uma base de borracha antiderrapante e uma superfície em Fibertek. Seu estilo Speed na superfície proporciona movimentos rápidos e tiros precisos, enquanto sua superfície resistente à água contribui para a limpeza e durabilidade do mousepad.
    
    Impressão de alta qualidade com diversos modelos, permitindo que você encontre um que combine com sua configuração e adicione personalidade ao seu espaço gamer.
    
    Mouse Pads flexíveis e resistentes, sua flexibilidade e tecnologia permitem o transporte fácil.
    
    Espero que isso atenda às suas necessidades!`,

    descriptionE: `The Rise Gaming Mouse Pads offer everything a Gamer needs, with a non-slip rubber base and a Fibertek surface. Its Speed style surface allows for fast and precise movements, while its water-resistant feature contributes to surface cleanliness and durability.

    High-quality printing with various designs, allowing you to find one that matches your setup and adds personality to your gaming space.
    
    Flexible and durable mouse pads, their flexibility and technology enable easy transport.
    
    I hope this meets your requirements!`,
      
    productId: 'gaming-sniper-grey',
    banner: '',
    youtubeUrl: '',
    images: [GamingSniperG_01, GamingSniperG_02],
    pageURL: 'gaming-sniper-grey',
  },
  {
    id: 17,
    name: 'GAMING SNAKE',
    category: 'mousepad',
    descriptionP: `MOUSEPAD DE ALTA PERFORMANCE! RISE PENSANDO SEMPRE EM SUA MELHOR JOGADA<br>

    Os Mouse Pads Rise Gaming oferecem tudo o que um jogador procura, com uma base de borracha antiderrapante e uma superfície em Fibertek. Seu estilo Speed na superfície proporciona movimentos rápidos e tiros precisos, enquanto sua superfície resistente à água contribui para a limpeza e durabilidade do mousepad.
    
    Impressão de alta qualidade com diversos modelos, permitindo que você encontre um que combine com sua configuração e adicione personalidade ao seu espaço gamer.
    
    Mouse Pads flexíveis e resistentes, sua flexibilidade e tecnologia permitem o transporte fácil.
    
    Espero que isso atenda às suas necessidades!`,

    descriptionE: `The Rise Gaming Mouse Pads offer everything a Gamer needs, with a non-slip rubber base and a Fibertek surface. Its Speed style surface allows for fast and precise movements, while its water-resistant feature contributes to surface cleanliness and durability.

    High-quality printing with various designs, allowing you to find one that matches your setup and adds personality to your gaming space.
    
    Flexible and durable mouse pads, their flexibility and technology enable easy transport.
    
    I hope this meets your requirements!`,
      
    productId: 'gaming-snake-mousepad',
    banner: '',
    youtubeUrl: '',
    images: [GamingSnake_01, GamingSnake_02, GamingSnake_03 ],
    pageURL: 'gaming-snake-mousepad',
  },
];

export default products;
