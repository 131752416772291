import React, { useState, useEffect} from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import Loading from '../../../Loading/Loading';
import YouTube from 'react-youtube';
import { useTranslation } from 'react-i18next';
import BotaoComprar from '../../BotaoComprar/BotaoComprar';
import Venon01 from '../../../../img/Produtos/Suporte_headset/Venon/venon.jpg';
import VenonBanner from '../../../../img/Produtos/Suporte_headset/Venon/venon_banner.jpg';
import products from '../DataSuporteHeadset';

const Paragraph = styled.p`
    padding: 0.2rem;
    color: #c3c3c3;
    font-size: 1rem;
    background: none;
    font-family: "Inter";
    @media screen and (max-width: 1031px) {
    font-size: 0.6rem;
    width: 100%;
    letter-spacing: 1px !important;
  }
`;

const ControleDesc = styled.div`
  display: block;
  margin: 0 auto;
  width: 400px;
`;

const TituloDesc = styled.h2`
  width: 100%;
  margin-top: 1rem;
  background: none;
`;

const Principal = styled.div`
  background-color: black;
  padding: 1rem;
  border-radius: 4px;
  width: 100%;
  max-width: 1200px;

  position: relative;
  margin: 0 auto;
`;

const ControleAll = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Conteudo = styled.div`
  width: 800px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background: white;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 8px;

  @media screen and (max-width: 1000px) {
    width: 420px;
  }

  img {
    width: 350px;
    margin: 4px;

    @media screen and (max-width: 1000px) {
      width: 150px;
      margin: 4px;
    }
  }
`;

const TituloModal = styled.h1`
  background: none;
  color: white;
  text-align: center;
`;

const BannerProduto = styled.img`
  width: 100%;
  max-width: 800px;
  margin: 1rem;
  border-radius: 10px;
`;

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
`;

const CarouselContainer = styled.div`
  width: 50%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  padding: 1rem;
  background: white;
  border-radius: 1rem;
  border: none;

  @media screen and (max-width: 1000px) {
    width: 100% ;
  }
`;

const CarouselWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
  border: none;
  background: none;
`;

const CarouselItem = styled.img`
  width: 100%;
  height: auto;
  border: none;
  background: none;
`;

const NavButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #4491FA;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: white;
  width: 40px;
  border-radius: 4px;
  margin: 8px;

  &:hover{
    filter: brightness(0.8);
  }
`;

const PrevButton = styled(NavButton)`
  left: 0;
`;

const NextButton = styled(NavButton)`
  right: 0;
`;

const ProductPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [productsData, setProductsData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const images = [
    'url_da_imagem_1.jpg',
    'url_da_imagem_2.jpg',
    'url_da_imagem_3.jpg',
  ];

  const Carousel = ({ images, currentIndex, prevSlide, nextSlide }) => (
    <CarouselContainer>
      <CarouselWrapper style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {images.map((image, index) => (
          <CarouselItem key={index} src={image} alt={`slide-${index}`} />
        ))}
      </CarouselWrapper>
      <PrevButton onClick={prevSlide}>{'<'}</PrevButton>
      <NextButton onClick={nextSlide}>{'>'}</NextButton>
    </CarouselContainer>
  );

  useEffect(() => {
    // Simulando uma chamada assíncrona para buscar os dados dos produtos
    setTimeout(() => {
      setProductsData(products);
      setIsLoading(false);
    }, 2000);
  }, []);
  
  const WithLoading = ({ isLoading, children }) => {
    return <>{isLoading ? <Loading /> : <>{children}</>}</>;
  };
  
  const { t } = useTranslation();
  
  return (
    <WithLoading isLoading={isLoading}>
       {products
      .filter((product) => product.id === 16) // Filtra apenas o produto com ID 1
      .map((product) => (
        <Principal>
          <VideoContainer>
            {product.youtubeUrl ? (
              <YouTube videoId={product.youtubeUrl} id="youtube-container" />
            ) : (
              <div></div>
            )}
          </VideoContainer>
          <BotaoComprar/>
          <ControleAll>
           
          <Carousel
              images={product.images.slice(0, 6)}
              currentIndex={currentIndex}
              prevSlide={prevSlide}
              nextSlide={nextSlide}
            />

            <BannerProduto src={product.banner} />
            <TituloModal>{product.name}</TituloModal>
          </ControleAll>
          <ControleDesc>
              <Paragraph dangerouslySetInnerHTML={{ __html: t('z02-black') }} />          
          </ControleDesc> 
        </Principal>
       ))}
    </WithLoading>
  );
  };
  
  export default ProductPage;
  
