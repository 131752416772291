import WinterBlackAR_01 from '../../../img/Produtos/cooler/winter-black-argb/1.jpg';
import WinterBlackAR_02 from '../../../img/Produtos/cooler/winter-black-argb/2.jpg';
import WinterBlackAR_03 from '../../../img/Produtos/cooler/winter-black-argb/3.jpg';
import WinterBlackAR_04 from '../../../img/Produtos/cooler/winter-black-argb/4.jpg';
import WinterBlackAR_Banner from '../../../img/Produtos/cooler/winter-black-argb/banner.jpg';


import G800RGB_01 from '../../../img/Produtos/cooler/g800-rgb/1.jpg';
import G800RGB_02 from '../../../img/Produtos/cooler/g800-rgb/2.jpg';
import G800RGB_03 from '../../../img/Produtos/cooler/g800-rgb/3.jpg';
import G800RGB_04 from '../../../img/Produtos/cooler/g800-rgb/4.jpg';
import G800RGB_Banner from '../../../img/Produtos/cooler/g800-rgb/banner.jpg';


import g700_01 from '../../../img/Produtos/cooler/g700/1.jpg';
import g700_02 from '../../../img/Produtos/cooler/g700/2.jpg';
import g700_03 from '../../../img/Produtos/cooler/g700/3.jpg';
import g700_04 from '../../../img/Produtos/cooler/g700/4.jpg';
import g700_Banner from '../../../img/Produtos/cooler/g700/banner.jpg';

import Z4White_01 from '../../../img/Produtos/cooler/z4-white/1.jpg';
import Z4White_02 from '../../../img/Produtos/cooler/z4-white/2.jpg';
import Z4White_03 from '../../../img/Produtos/cooler/z4-white/3.jpg';
import Z4White_04 from '../../../img/Produtos/cooler/z4-white/4.jpg';
import Z4White_Banner from '../../../img/Produtos/cooler/z4-white/banner.jpg';

import Z4Rgb_01 from '../../../img/Produtos/cooler/z4-rgb/1.jpg';
import Z4Rgb_02 from '../../../img/Produtos/cooler/z4-rgb/2.jpg';
import Z4Rgb_03 from '../../../img/Produtos/cooler/z4-rgb/3.jpg';
import Z4Rgb_04 from '../../../img/Produtos/cooler/z4-rgb/4.jpg';
import Z4Rgb_Banner from '../../../img/Produtos/cooler/z4-rgb/banner.jpg';

import X1Cooler_01 from '../../../img/Produtos/cooler/x1-cooler/1.jpg';
import X1Cooler_02 from '../../../img/Produtos/cooler/x1-cooler/2.jpg';
import X1Cooler_03 from '../../../img/Produtos/cooler/x1-cooler/3.jpg';
import X1Cooler_04 from '../../../img/Produtos/cooler/x1-cooler/4.jpg';
import X1Cooler_Banner from '../../../img/Produtos/cooler/x1-cooler/banner.jpg';

import X5WCooler2_01 from '../../../img/Produtos/cooler/x5-white/1.jpg';
import X5WCooler2_02 from '../../../img/Produtos/cooler/x5-white/2.jpg';
import X5WCooler2_03 from '../../../img/Produtos/cooler/x5-white/3.png';
import X5WCooler2_04 from '../../../img/Produtos/cooler/x5-white/4.png';


import X2Cooler_01 from '../../../img/Produtos/cooler/x2-cooler/1.jpg';
import X2Cooler_02 from '../../../img/Produtos/cooler/x2-cooler/2.jpg';
import X2Cooler_03 from '../../../img/Produtos/cooler/x2-cooler/3.jpg';
import X2Cooler_04 from '../../../img/Produtos/cooler/x2-cooler/4.jpg';
import X2Cooler_Banner from '../../../img/Produtos/cooler/x2-cooler/banner.jpg';

import X3Cooler_01 from '../../../img/Produtos/cooler/x3-cooler/1.jpg';
import X3Cooler_02 from '../../../img/Produtos/cooler/x3-cooler/2.jpg';
import X3Cooler_03 from '../../../img/Produtos/cooler/x3-cooler/3.jpg';
import X3Cooler_04 from '../../../img/Produtos/cooler/x3-cooler/4.jpg';
import X3Cooler_Banner from '../../../img/Produtos/cooler/x3-cooler/banner.jpg';

import X4Cooler_01 from '../../../img/Produtos/cooler/x4-cooler/1.jpg';
import X4Cooler_02 from '../../../img/Produtos/cooler/x4-cooler/2.jpg';
import X4Cooler_03 from '../../../img/Produtos/cooler/x4-cooler/3.jpg';
import X4Cooler_04 from '../../../img/Produtos/cooler/x4-cooler/4.jpg';
import X4Cooler_05 from '../../../img/Produtos/cooler/x4-cooler/5.jpg';
import X4Cooler_06 from '../../../img/Produtos/cooler/x4-cooler/6.jpg';
import X4Cooler_Banner from '../../../img/Produtos/cooler/x4-cooler/banner.jpg';


import X4RGBCooler_01 from '../../../img/Produtos/cooler/x4-rgb-cooler/1.jpg';
import X4RGBCooler_02 from '../../../img/Produtos/cooler/x4-rgb-cooler/2.jpg';
import X4RGBCooler_03 from '../../../img/Produtos/cooler/x4-rgb-cooler/3.jpg';
import X4RGBCooler_04 from '../../../img/Produtos/cooler/x4-rgb-cooler/4.jpg';
import X4RGBCooler_Banner from '../../../img/Produtos/cooler/x4-rgb-cooler/banner.jpg';

import X5Cooler_01 from '../../../img/Produtos/cooler/x5-blue-led-cooler/1.jpg';
import X5Cooler_02 from '../../../img/Produtos/cooler/x5-blue-led-cooler/2.jpg';
import X5Cooler_03 from '../../../img/Produtos/cooler/x5-blue-led-cooler/3.jpg';
import X5Cooler_04 from '../../../img/Produtos/cooler/x5-blue-led-cooler/4.jpg';
import X5Cooler_Banner from '../../../img/Produtos/cooler/x5-blue-led-cooler/banner.jpg';

import X5WCooler_01 from '../../../img/Produtos/cooler/x5-white-cooler/1.jpg';
import X5WCooler_02 from '../../../img/Produtos/cooler/x5-white-cooler/2.jpg';
import X5WCooler_03 from '../../../img/Produtos/cooler/x5-white-cooler/3.jpg';
import X5WCooler_04 from '../../../img/Produtos/cooler/x5-white-cooler/4.jpg';
import X5WCooler_Banner from '../../../img/Produtos/cooler/x5-white-cooler/banner.jpg';

import G200Cooler_01 from '../../../img/Produtos/cooler/g200-cooler/1.jpg';
import G200Cooler_02 from '../../../img/Produtos/cooler/g200-cooler/2.jpg';
import G200Cooler_03 from '../../../img/Produtos/cooler/g200-cooler/3.jpg';
import G200Cooler_04 from '../../../img/Produtos/cooler/g200-cooler/4.jpg';
import G200Cooler_Banner from '../../../img/Produtos/cooler/g200-cooler/banner.jpg';

import Z1Cooler_01 from '../../../img/Produtos/cooler/z1-cooler/1.jpg';
import Z1Cooler_02 from '../../../img/Produtos/cooler/z1-cooler/2.jpg';
import Z1Cooler_03 from '../../../img/Produtos/cooler/z1-cooler/3.jpg';
import Z1Cooler_Banner from '../../../img/Produtos/cooler/z1-cooler/banner.jpg';

import Z2Cooler_01 from '../../../img/Produtos/cooler/z2-cooler/1.jpg';
import Z2Cooler_02 from '../../../img/Produtos/cooler/z2-cooler/2.jpg';
import Z2Cooler_03 from '../../../img/Produtos/cooler/z2-cooler/3.jpg';
import Z2Cooler_04 from '../../../img/Produtos/cooler/z2-cooler/4.jpg';
import Z2Cooler_Banner from '../../../img/Produtos/cooler/z2-cooler/banner.jpg';

import Z3Cooler_01 from '../../../img/Produtos/cooler/z3-cooler/1.jpg';
import Z3Cooler_02 from '../../../img/Produtos/cooler/z3-cooler/2.jpg';
import Z3Cooler_03 from '../../../img/Produtos/cooler/z3-cooler/3.jpg';
import Z3Cooler_04 from '../../../img/Produtos/cooler/z3-cooler/4.jpg';
import Z3Cooler_Banner from '../../../img/Produtos/cooler/z3-cooler/banner.jpg';

import Z5Cooler_01 from '../../../img/Produtos/cooler/z5/1.jpg';
import Z5Cooler_02 from '../../../img/Produtos/cooler/z5/2.jpg';
import Z5Cooler_03 from '../../../img/Produtos/cooler/z5/3.jpg';
import Z5Cooler_04 from '../../../img/Produtos/cooler/z5/4.jpg';
import Z5Cooler_05 from '../../../img/Produtos/cooler/z5/5.jpg';
import Z5Cooler_06 from '../../../img/Produtos/cooler/z5/6.jpg';
import Z5_Banner from '../../../img/Produtos/cooler/z5/banner.png';

const products = [
  {
    id: 1,
    name: 'Winter Black Argb',
    category: 'cooler',
    descriptionP: `Air Cooler Gamer Rise Mode Winter Black ARGB<br>
    TDP: 200W<br>
    Part Number: RM-ACW-01-ARGB<br>
    Intel: 775/1150/1151/1155/1156/1366/1200<br>
    AMD: AM4/AM2/AM2+/AM3/AM3+/FM1/FM2<br>
    Tamanho do Fan: 120 x 120 x 25 mm x2<br>
    Velocidade do Fan: 1500 RPM<br>
    Cor do Led: ARGB 5v 3p Digital Motherboard<br>
    Conector: 4pin PWM<br>
    Tipo de rolamento: Rolamento hidráulico<br>
    Tensão: 12v<br>
    Ruído do Fan: 26±3dB(A)<br>
    Fluxo de ar: 60 CFM ± 10%<br>
    Heat Pipe: 6<br>
    Tamanho: 130 x 100 x 160mm<br>
    Vida útil do Fan: 40000 Horas<br>
    Peso: 780g<br>
    Informações adicionais:<br>
    
    Compatível com placas mãe argb padrão 3p 5v<br>
    Efeito rainbow de iluminação<br>
    Alto Brilho de Iluminação<br>
    Vários efeitos e cores ajustáveis<br>
    Leds Endereçáveis<br>
    Ean: 6015033513575<br>`,

    descriptionE: `Air Cooler Gamer Rise Mode Winter Black ARGB<br>
    TDP: 200W<br>
    Part Number: RM-ACW-01-ARGB<br>
    Intel: 775/1150/1151/1155/1156/1366/1200<br>
    AMD: AM4/AM2/AM2+/AM3/AM3+/FM1/FM2<br>
    Fan Size: 120 x 120 x 25 mm x2<br>
    Fan Speed: 1500 RPM<br>
    LED Color: ARGB 5v 3p Digital Motherboard<br>
    Connector: 4pin PWM<br>
    Bearing Type: Hydraulic Bearing<br>
    Voltage: 12V<br>
    Fan Noise: 26±3dB(A)<br>
    Airflow: 60 CFM ± 10%<br>
    Heat Pipes: 6<br>
    Dimensions: 130 x 100 x 160mm<br>
    Fan Lifespan: 40000 Hours<br>
    Weight: 780g<br>
    Additional Information:<br>
    
    Compatible with standard 3p 5v ARGB motherboards<br>
    Rainbow lighting effect<br>
    High brightness illumination<br>
    Various adjustable effects and colors<br>
    Addressable LEDs<br>
    EAN: 6015033513575<br>`,
      
    productId: 'winter-black-argb',
    banner: WinterBlackAR_Banner,
    youtubeUrl: '',
    images: [WinterBlackAR_01, WinterBlackAR_02, WinterBlackAR_03, WinterBlackAR_04],
    pageURL: 'winter-black-argb',
  },
  {
    id: 2,
    name: 'G800 rgb',
    category: 'cooler',
    descriptionP: `
    Air Cooler Gamer Rise Mode G800 RGB<br>
    TDP: 130W<br>
    Part Number: RM-AC-O8-RGB<br>
    Socket:<br>
    Intel: 775/1150/1151/1155/1156/1366<br>
    AMD: AM4/AM2/AM2+/AM3/AM3+/FM1/FM2<br>
    Tamanho do Fan: 2x 90x90x25mm<br>
    Velocidade do Fan: 800-2100 RPM<br>
    Cor do Led: RGB Rainbow<br>
    Conector: 4pin PWM<br>
    Tipo de rolamento: Rolamento hidráulico<br>
    Tensão: 12V<br>
    Ruído do Fan: 18dB(A)<br>
    Fluxo de ar: 55 CFM (MAX)<br>
    Heat Pipe: 6<br>
    Vida útil do Fan: 30000 Horas<br>
    Informações adicionais:<br>
    
    Leds padrão rainbow colorido<br>
    Os leds são coloridos e não podem ser controlados<br>
    EAN: 6015033510550<br>`,

    descriptionE: `Air Cooler Gamer Rise Mode G800 RGB<br>
    TDP: 130W<br>
    Part Number: RM-AC-O8-RGB<br>
    Socket:<br>
    Intel: 775/1150/1151/1155/1156/1366<br>
    AMD: AM4/AM2/AM2+/AM3/AM3+/FM1/FM2<br>
    Fan Size: 2x 90x90x25mm<br>
    Fan Speed: 800-2100 RPM<br>
    LED Color: RGB Rainbow<br>
    Connector: 4pin PWM<br>
    Bearing Type: Hydraulic Bearing<br>
    Voltage: 12V<br>
    Fan Noise: 18dB(A)<br>
    Airflow: 55 CFM (MAX)<br>
    Heat Pipes: 6<br>
    Fan Lifespan: 30000 Hours<br>
    Additional Information:<br>
    
    Standard rainbow colored LEDs<br>
    The LEDs are colored and cannot be controlled<br>
    EAN: 6015033510550<br>`,
      
    productId: 'g800-rgb',
    banner: G800RGB_Banner,
    youtubeUrl: '',
    images: [G800RGB_01, G800RGB_02, G800RGB_03, G800RGB_04],
    pageURL: 'g800-rgb',
  },
  {
    id: 3,
    name: 'G700',
    category: 'cooler',
    descriptionP: `Air Cooler Gamer Rise Mode G700<br>
    Especificações<br>
    TDP: 130W<br>
    Part Number: RM-AC-O7-FB<br>
    Socket:<br>
    Intel: 775/1150/1151/1155/1156/1366<br>
    AMD: AM4/AM2/AM2+/AM3/AM3+/FM1/FM2<br>
    Tamanho do Fan: 2x 90x90x25mm<br>
    Velocidade do Fan: 800-2100 RPM<br>
    Cor do Led: Azul<br>
    Conector: 4pin PWM<br>
    Tipo de rolamento: Rolamento hidráulico<br>
    Tensão: 12V<br>
    Ruído do Fan: 18dB(A)<br>
    Fluxo de ar: 55 CFM (MAX)<br>
    Heat Pipe: 4<br>
    Vida útil do Fan: 30000 Horas<br>
    EAN: 6015033510574<br>`,

    descriptionE: `Air Cooler Gamer Rise Mode G700<br>
    Specifications:<br>
    TDP: 130W<br>
    Part Number: RM-AC-O7-FB<br>
    Socket:<br>
    Intel: 775/1150/1151/1155/1156/1366<br>
    AMD: AM4/AM2/AM2+/AM3/AM3+/FM1/FM2<br>
    Fan Size: 2x 90x90x25mm<br>
    Fan Speed: 800-2100 RPM<br>
    LED Color: Blue<br>
    Connector: 4pin PWM<br>
    Bearing Type: Hydraulic Bearing<br>
    Voltage: 12V<br>
    Fan Noise: 18dB(A)<br>
    Airflow: 55 CFM (MAX)<br>
    Heat Pipe: 4<br>
    Fan Lifespan: 30000 Hours<br>
    EAN: 6015033510574<br>`,
      
    productId: 'g700',
    banner: g700_Banner,
    youtubeUrl: '',
    images: [g700_01, g700_02, g700_03, g700_04],
    pageURL: 'g700',
  },
  {
    id: 4,
    name: 'Z4 White',
    category: 'cooler',
    descriptionP: `Air Cooler Gamer Rise Mode Z4 White<br>
    TDP: 100W<br>
    Part Number: RM-ACZ-Z4-BW<br>
    Socket:<br>
    Intel: 775/1150/1151/1155/1156/1366<br>
    AMD: AM4/AM2/AM2+/AM3/AM3+/FM1/FM2<br>
    Tamanho do Fan: 120x120x25mm<br>
    Velocidade do Fan: 800-1400 RPM<br>
    Conector: 4pin PWM<br>
    Tipo de rolamento: Rolamento hidráulico<br>
    Tensão: 12V<br>
    Ruído do Fan: 18dB(A)<br>
    Fluxo de ar: 45 CFM (MAX)<br>
    Heat Pipe: 2<br>
    Vida útil do Fan: 30000 Horas<br>
    EAN: 6015033510529<br>`,

    descriptionE: `Air Cooler Gamer Rise Mode Z4 White<br>
    TDP: 100W<br>
    Part Number: RM-ACZ-Z4-BW<br>
    Socket:<br>
    Intel: 775/1150/1151/1155/1156/1366<br>
    AMD: AM4/AM2/AM2+/AM3/AM3+/FM1/FM2<br>
    Fan Size: 120x120x25mm<br>
    Fan Speed: 800-1400 RPM<br>
    Connector: 4pin PWM<br>
    Bearing Type: Hydraulic Bearing<br>
    Voltage: 12V<br>
    Fan Noise: 18dB(A)<br>
    Airflow: 45 CFM (MAX)<br>
    Heat Pipe: 2<br>
    Fan Lifespan: 30000 Hours<br>
    EAN: 6015033510529<br>`,
      
    productId: 'z4-white',
    banner: Z4White_Banner,
    youtubeUrl: '',
    images: [Z4White_01, Z4White_02, Z4White_03, Z4White_04],
    pageURL: 'z4-white',
  },
  {
    id: 5,
    name: 'Z4 RGB',
    category: 'cooler',
    descriptionP: `Air Cooler Gamer Rise Mode Z4 RGB<br>
    TDP: 100W<br>
    Part Number: RM-ACZ-Z4-RGB<br>
    Socket:<br>
    Intel: 775/1150/1151/1155/1156/1366<br>
    AMD: AM4/AM2/AM2+/AM3/AM3+/FM1/FM2<br>
    Tamanho do Fan: 120x120x25mm<br>
    Velocidade do Fan: 800-1400 RPM<br>
    Cor do Led: RGB Rainbow<br>
    Conector: 4pin PWM<br>
    Tipo de rolamento: Rolamento hidráulico<br>
    Tensão: 12V<br>
    Ruído do Fan: 18dB(A)<br>
    Fluxo de ar: 45 CFM (MAX)<br>
    Heat Pipe: 2<br>
    Vida útil do Fan: 30000 Horas<br>
    Informações adicionais:<br>
    
    Leds padrão rainbow colorido<br>
    Os leds são coloridos não podem ser controlados<br>
    EAN: 6015033510567<br>`,

    descriptionE: `Air Cooler Gamer Rise Mode Z4 RGB<br>
    TDP: 100W<br>
    Part Number: RM-ACZ-Z4-RGB<br>
    Socket:<br>
    Intel: 775/1150/1151/1155/1156/1366<br>
    AMD: AM4/AM2/AM2+/AM3/AM3+/FM1/FM2<br>
    Fan Size: 120x120x25mm<br>
    Fan Speed: 800-1400 RPM<br>
    LED Color: RGB Rainbow<br>
    Connector: 4pin PWM<br>
    Bearing Type: Hydraulic Bearing<br>
    Voltage: 12V<br>
    Fan Noise: 18dB(A)<br>
    Airflow: 45 CFM (MAX)<br>
    Heat Pipes: 2<br>
    Fan Lifespan: 30000 Hours<br>
    Additional Information:<br>
    
    Standard rainbow color LEDs<br>
    The LEDs are colored and cannot be controlled<br>
    EAN: 6015033510567<br>`,
      
    productId: 'z4-rgb',
    banner: Z4Rgb_Banner,
    youtubeUrl: '',
    images: [Z4Rgb_01, Z4Rgb_02, Z4Rgb_03, Z4Rgb_04],
    pageURL: 'z4-rgb',
  },
  {
    id: 6,
    name: 'X1',
    category: 'cooler',
    descriptionP: `Air Cooler Gamer Rise Mode X1<br>
    TDP: 95W<br>
    Part Number: RM-ACX-01-BB<br>
    Tamanho do Produto: 112x112x50mm<br>
    Socket: Intel-LGA1156/1155/1150/1151/1200<br>
    Tamanho do fan: 90x90x25mm<br>
    Velocidade do fan: 2000RPM<br>
    Conexão do Fan: 3p<br>
    Tipo de rolamento: Rolamento hidráulico<br>
    Tensão: 12V<br>
    Ruído do fan: 18dB(A)<br>
    Fluxo de ar: 55.6CFM (MAX)<br>
    Vida útil do fan: 30000 Horas<br>
    EAN: 6015033727767<br>`,

    descriptionE: `Air Cooler Gamer Rise Mode X1<br>
    TDP: 95W<br>
    Part Number: RM-ACX-01-BB<br>
    Product Size: 112x112x50mm<br>
    Socket: Intel-LGA1156/1155/1150/1151/1200<br>
    Fan Size: 90x90x25mm<br>
    Fan Speed: 2000 RPM<br>
    Fan Connection: 3p<br>
    Bearing Type: Hydraulic Bearing<br>
    Voltage: 12V<br>
    Fan Noise: 18dB(A)<br>
    Airflow: 55.6 CFM (MAX)<br>
    Fan Lifespan: 30000 Hours<br>
    EAN: 6015033727767<br>`,
      
    productId: 'x1-cooler',
    banner: X1Cooler_Banner,
    youtubeUrl: '',
    images: [X1Cooler_01, X1Cooler_02, X1Cooler_03, X1Cooler_04],
    pageURL: 'x1-cooler',
  },
  {
    id: 11,
    name: 'X2',
    category: 'cooler',
    descriptionP: `Air Cooler Gamer Rise Mode X2<br>
    TDP: 95W<br>
    Part Number: RM-ACX-02-BB<br>
    Tamanho do Produto: 112x112x50mm<br>
    Socket: Intel-LGA1156/1155/1150/1151/1200<br>
    Tamanho do fan: 90x90x25mm<br>
    Velocidade do fan: 2000RPM<br>
    Conexão Fan: 3p<br>
    Tipo de rolamento: Rolamento hidráulico<br>
    Tensão: 12v<br>
    Ruído do fan: 18dB(A)<br>
    Fluxo de ar: 55.6CFM (MAX)<br>
    Vida útil do fan: 30000 Horas<br>
    EAN: 6015033727767<br>`,

    descriptionE: `    Air Cooler Gamer Rise Mode X2<br>
    TDP: 95W<br>
    Part Number: RM-ACX-02-BB<br>
    Product Size: 112x112x50mm<br>
    Socket: Intel-LGA1156/1155/1150/1151/1200<br>
    Fan Size: 90x90x25mm<br>
    Fan Speed: 2000RPM<br>
    Fan Connector: 3p<br>
    Bearing Type: Hydraulic Bearing<br>
    Voltage: 12V<br>
    Fan Noise: 18dB(A)<br>
    Airflow: 55.6CFM (MAX)<br>
    Fan Lifespan: 30000 Hours<br>
    EAN: 6015033727767<br>`,
      
    productId: 'x2-cooler',
    banner: X2Cooler_Banner,
    youtubeUrl: '',
    images: [X2Cooler_01, X2Cooler_02, X2Cooler_03, X2Cooler_04],
    pageURL: 'x2-cooler',
  },
  {
    id: 7,
    name: 'X3',
    category: 'cooler',
    descriptionP: `Air Cooler Gamer Rise Mode X3 RGB<br>
    TDP: 115W<br>
    Part Number: RM-ACX-03-RGB<br>
    Tamanho do Produto: 125x125x68mm<br>
    Socket: Intel-LGA1156/1155/1151/1150<br>
    Tamanho do fan: 120x120x25mm<br>
    Velocidade do fan: 1400RPM<br>
    Conexão do Fan: 3p<br>
    Tipo de rolamento: Rolamento hidráulico<br>
    Tensão: 12V<br>
    Ruído do fan: 18dB(A)<br>
    Fluxo de ar: 55.6CFM (MAX)<br>
    Vida útil do fan: 30000 Horas<br>
    
    Leds padrão rainbow colorido<br>
    Os leds são coloridos não podem ser controlados<br>
    EAN: 6015033499411<br>`,

    descriptionE: `Air Cooler Gamer Rise Mode X3 RGB<br>
    TDP: 115W<br>
    Part Number: RM-ACX-03-RGB<br>
    Product Size: 125x125x68mm<br>
    Socket: Intel-LGA1156/1155/1151/1150<br>
    Fan Size: 120x120x25mm<br>
    Fan Speed: 1400RPM<br>
    Fan Connection: 3p<br>
    Bearing Type: Hydraulic Bearing<br>
    Voltage: 12V<br>
    Fan Noise: 18dB(A)<br>
    Airflow: 55.6CFM (MAX)<br>
    Fan Lifespan: 30000 Hours<br>
    
    Standard rainbow colored LEDs<br>
    The LEDs are colored and cannot be controlled<br>
    EAN: 6015033499411<br>`,
      
    productId: 'x3-cooler',
    banner: X3Cooler_Banner,
    youtubeUrl: '',
    images: [X3Cooler_01, X3Cooler_02, X3Cooler_03, X3Cooler_04],
    pageURL: 'x3-cooler',
  },
  {
    id: 17,
    name: 'X4',
    category: 'cooler',
    descriptionP: `Air Cooler Gamer Rise Mode X4<br>
    TDP: 115w<br>
    Part Number: RM-ACX-04-BG<br>
    Tamanho do Produto: 95x95x65mm<br>
    Socket: Intel-LGA1156/1155/1151/1150/1200<br>
    Tamanho do fan: 90x90x25mm<br>
    Velocidade do fan: 2200RPM <br>
    Conexão Fan: 3p<br>
    Tipo de rolamento: Rolamento hidráulico <br>
    Tensão: 12v <br>
    Ruído do fan: 18dB(A) <br>
    Fluxo de ar: 55.6CFM (MAX) <br>
    Vida útil do fan: 30000 Horas<br>
    EAN: 6015033499428<br>`,

    descriptionE: `Air Cooler Gamer Rise Mode X4<br>
    TDP: 115w<br>
    Part Number: RM-ACX-04-BG<br>
    Product Size: 95x95x65mm<br>
    Socket: Intel-LGA1156/1155/1151/1150/1200<br>
    Fan Size: 90x90x25mm<br>
    Fan Speed: 2200RPM<br>
    Fan Connection: 3-pin<br>
    Bearing Type: Hydraulic Bearing<br>
    Voltage: 12v<br>
    Fan Noise: 18dB(A)<br>
    Airflow: 55.6CFM (MAX)<br>
    Fan Lifespan: 30000 Hours<br>
    EAN: 6015033499428<br>`,
      
    productId: 'x4-cooler',
    banner: X4Cooler_Banner,
    youtubeUrl: '',
    images: [X4Cooler_01, X4Cooler_02, X4Cooler_03, X4Cooler_04, X4Cooler_05, X4Cooler_06],
    pageURL: 'x4-cooler',
  },
  {
    id: 8,
    name: 'X4 RGB',
    category: 'cooler',
    descriptionP: `Air Cooler Gamer Rise Mode X4 RGB<br>
    TDP: 115W<br>
    Part Number: RM-ACX-04-RGB<br>
    Tamanho do Produto: 95x95x65mm<br>
    Socket: Intel-LGA1156/1155/1150/1151/1200<br>
    Tamanho do fan: 90x90x25mm<br>
    Velocidade do fan: 2200RPM<br>
    Conexão Fan: 4p<br>
    Cor do Led: Rainbow<br>
    Tipo de rolamento: Rolamento hidráulico<br>
    Tensão: 12v<br>
    Ruído do fan: 18dB(A)<br>
    Fluxo de ar: 55.6CFM (MAX)<br>
    Vida útil do fan: 30000 Horas<br>
    Informações adicionais:<br>
    
    Leds padrão rainbow colorido<br>
    Os leds são coloridos e não podem ser controlados<br>
    EAN: 6015033499435<br>`,

    descriptionE: `Air Cooler Gamer Rise Mode X4 RGB<br>
    TDP: 115W<br>
    Part Number: RM-ACX-04-RGB<br>
    Product Size: 95x95x65mm<br>
    Socket: Intel-LGA1156/1155/1150/1151/1200<br>
    Fan Size: 90x90x25mm<br>
    Fan Speed: 2200RPM<br>
    Fan Connection: 4p<br>
    LED Color: Rainbow<br>
    Bearing Type: Hydraulic Bearing<br>
    Voltage: 12V<br>
    Fan Noise: 18dB(A)<br>
    Airflow: 55.6CFM (MAX)<br>
    Fan Lifespan: 30000 Hours<br>
    
    Standard rainbow colored LEDs<br>
    The LEDs are colored and cannot be controlled<br>
    EAN: 6015033499435<br>`,
      
    productId: 'x4-rgb-cooler',
    banner: X4RGBCooler_Banner,
    youtubeUrl: '',
    images: [X4RGBCooler_01, X4RGBCooler_02, X4RGBCooler_03, X4RGBCooler_04],
    pageURL: 'x4-rgb-cooler',
  },
  {
    id: 9,
    name: 'X5 Blue Led',
    category: 'cooler',
    descriptionP: `Air Cooler Gamer Rise Mode X5 Blue Led<br>
    TDP: 130W<br>
    Part Number: RM-ACX-05-BB<br>
    Tamanho do Produto: 115x92x78mm<br>
    Socket: Intel-LGA1156/1155/1150/1151/1200<br>
    AMD Socket -FM2+/FM1/AM2/AM3+/AM3/AM4<br>
    Tamanho do fan: 120x120x25mm<br>
    Velocidade do fan: 1300RPM<br>
    Conector do Fan: 4pin<br>
    Cor do Led: Azul<br>
    Tipo de rolamento: Rolamento hidráulico<br>
    Tensão: 12v<br>
    Ruído do fan: 18dB(A)<br>
    Fluxo de ar: 55.6CFM (MAX)<br>
    Vida útil do fan: 30000 Horas<br>
    Heat Pipe: 4<br>
    EAN: 6015033499473<br>`,

    descriptionE: `Air Cooler Gamer Rise Mode X5 Blue Led<br>
    TDP: 130W<br>
    Part Number: RM-ACX-05-BB<br>
    Product Size: 115x92x78mm<br>
    Socket: Intel-LGA1156/1155/1150/1151/1200<br>
    AMD Socket - FM2+/FM1/AM2/AM3+/AM3/AM4<br>
    Fan Size: 120x120x25mm<br>
    Fan Speed: 1300RPM<br>
    Fan Connector: 4pin<br>
    LED Color: Blue<br>
    Bearing Type: Hydraulic Bearing<br>
    Voltage: 12V<br>
    Fan Noise: 18dB(A)<br>
    Airflow: 55.6CFM (MAX)<br>
    Fan Lifespan: 30000 Hours<br>
    Heat Pipe: 4<br>
    EAN: 6015033499473<br>`,
      
    productId: 'x5-blue-led-cooler',
    banner: X5Cooler_Banner,
    youtubeUrl: '',
    images: [X5Cooler_01, X5Cooler_02, X5Cooler_03, X5Cooler_04],
    pageURL: 'x5-blue-led-cooler',
  },
  {
    id: 18,
    name: 'X5 White',
    category: 'cooler',
    descriptionP: `    TDP: 130W<br>
    Part Number: RM-ACX-05-BW<br>
    Tamanho do Produto: 115x92x78mm<br>
    Socket: Intel-1156/1155/1150/1151/1200<br>
    AMD Socket -FM2+/FM1/AM2/AM3+/AM3/AM4<br>
    Tamanho do fan: 120x120x25mm<br>
    Velocidade do fan: 1300RPM<br>
    Conector do Fan: 4pin<br>
    Cor do Led: Rainbow<br>
    Tipo de rolamento: Rolamento hidráulico<br>
    Tensão: 12v<br>
    Ruído do fan: 18dB(A)<br>
    Fluxo de ar: 55.6CFM (MAX)<br>
    Vida útil do fan: 30000 Horas<br>
    Heat Pipe: 4<br>`,

    descriptionE: `TDP: 130W<br>
    Part Number: RM-ACX-05-BW<br>
    Product Size: 115x92x78mm<br>
    Socket: Intel-1156/1155/1150/1151/1200<br>
    AMD Socket - FM2+/FM1/AM2/AM3+/AM3/AM4<br>
    Fan Size: 120x120x25mm<br>
    Fan Speed: 1300RPM<br>
    Fan Connector: 4pin<br>
    LED Color: Rainbow<br>
    Bearing Type: Hydraulic Bearing<br>
    Voltage: 12V<br>
    Fan Noise: 18dB(A)<br>
    Airflow: 55.6CFM (MAX)<br>
    Fan Lifespan: 30000 Hours<br>
    Heat Pipe: 4<br>`,
      
    productId: 'x5-white-cooler',
    banner: '',
    youtubeUrl: '',
    images: [X5WCooler2_01, X5WCooler2_02, X5WCooler2_03, X5WCooler2_04],
    pageURL: 'x5-white-cooler',
  },
  {
    id: 10,
    name: 'X5 RGB',
    category: 'cooler',
    descriptionP: `
    Air Cooler Gamer Rise Mode X5 RGB<br/>
    TDP: 130w<br/>
    Part Number: RM-ACX-05-RGB<br/>
    Tamanho do Produto: 115x92x78mm<br/>
    Socket: Intel-LGA1156/1155/1151/1150/1200<br/>
    AMD Socket -FM2+/FM1/AM2/AM3+/AM3/AM4<br/>
    Tamanho do fan: 120x120x25mm<br/>
    Velocidade do fan: 1300RPM<br/>
    Conector do Fan: 4pin<br/>
    Cor do Led: Rainbow<br/>
    Tipo de rolamento: Rolamento hidráulico<br/>
    Tensão: 12v<br/>
    Ruído do fan: 18dB(A)<br/>
    Fluxo de ar: 55.6CFM (MAX)<br/>
    Vida útil do fan: 30000 Horas<br/>
    Heat Pipe: 4<br/>
    
    Informações adicionais:<br/>
    
    Leds padrão rainbow colorido<br/>
    Os leds são coloridos e não podem ser controlados<br/>
    EAN: 6015033499466`,

    descriptionE: `
    Air Cooler Gamer Rise Mode X5 RGB<br/>
    TDP: 130w<br/>
    Part Number: RM-ACX-05-RGB<br/>
    Product Size: 115x92x78mm<br/>
    Socket: Intel-LGA1156/1155/1151/1150/1200<br/>
    AMD Socket -FM2+/FM1/AM2/AM3+/AM3/AM4<br/>
    Fan Size: 120x120x25mm<br/>
    Fan Speed: 1300RPM<br/>
    Fan Connector: 4pin<br/>
    Led Color: Rainbow<br/>
    Bearing Type: Hydraulic Bearing<br/>
    Voltage: 12v<br/>
    Fan Noise: 18dB(A)<br/>
    Airflow: 55.6CFM (MAX)<br/>
    Fan Lifespan: 30000 Hours<br/>
    Heat Pipe: 4<br/>
    
    Additional Information:<br/>
    
    Standard rainbow-colored LEDs<br/>
    LEDs are colored and cannot be controlled<br/>
    EAN: 6015033499466`,
      
    productId: 'x5-rgb-cooler',
    banner: '',
    youtubeUrl: '',
    images: [X5WCooler_01, X5WCooler_02, X5WCooler_03, X5WCooler_04],
    pageURL: 'x5-rgb-cooler',
  },
  {
    id: 12,
    name: 'G200 RGB',
    category: 'cooler',
    descriptionP: `Air Cooler Gamer Rise Mode G200 RGB<br>
    TDP: 65W<br>
    Part Number: RM-AC-O2-RGB<br>
    Socket:<br>
    Intel: 775/1150/1151/1155/1156/1366<br>
    AMD: FM2/AM4/FM1/AM3+/AM3/AM2<br>
    Tamanho do Fan: 100x120x25mm<br>
    Velocidade do Fan: 800-2000 RPM<br>
    Cor do Led: Rainbow<br>
    Conector: 4pin PWM<br>
    Tipo de rolamento: Rolamento hidráulico<br>
    Tensão: 12V<br>
    Ruído do fan: 29,6dB(A)<br>
    Fluxo de ar: 34,3 CFM (MAX)<br>
    Vida útil do fan: 30000 Horas<br>
    Informações adicionais:<br>
    
    Leds padrão rainbow colorido<br>
    Os leds são coloridos não podem ser controlados<br>
    Ean: 6015033510512<br>`,

    descriptionE: `Air Cooler Gamer Rise Mode G200 RGB<br>
    TDP: 65W<br>
    Part Number: RM-AC-O2-RGB<br>
    Socket:<br>
    Intel: 775/1150/1151/1155/1156/1366<br>
    AMD: FM2/AM4/FM1/AM3+/AM3/AM2<br>
    Fan Size: 100x120x25mm<br>
    Fan Speed: 800-2000 RPM<br>
    LED Color: Rainbow<br>
    Connector: 4pin PWM<br>
    Bearing Type: Hydraulic Bearing<br>
    Voltage: 12V<br>
    Fan Noise: 29.6dB(A)<br>
    Airflow: 34.3 CFM (MAX)<br>
    Fan Lifespan: 30000 Hours<br>
    Additional Information:<br>
    
    Standard rainbow-colored LEDs<br>
    LEDs are colored and cannot be controlled<br>
    EAN: 6015033510512<br>`,
      
    productId: 'g200-cooler',
    banner: G200Cooler_Banner,
    youtubeUrl: '',
    images: [G200Cooler_01, G200Cooler_02, G200Cooler_03, G200Cooler_04],
    pageURL: 'g200-cooler',
  },
  {
    id: 13,
    name: 'Z1',
    category: 'cooler',
    descriptionP: `Air Cooler Gamer Rise Mode Z1<br>
    TDP: 65W<br>
    Part Number: RM-ACZ-01-BO<br>
    Tamanho do Produto: 118x58mm<br>
    Socket:<br>
    Intel: LGA 775/1366/1156/1155/1151/1150<br>
    AMD: FM2+/FM1/AM2/AM3+/AM3<br>
    Tamanho do fan: 90x90x25mm<br>
    Velocidade do fan: 2000 RPM<br>
    Tipo de rolamento: Rolamento hidráulico<br>
    Tensão: 12V<br>
    Conector: 3pin<br>
    Ruído do fan: 20dB(A)<br>
    Fluxo de ar: 55.6 CFM (MAX)<br>
    Vida útil do fan: 30000 Horas<br>
    EAN: 6015033496489<br>`,

    descriptionE: `Air Cooler Gamer Rise Mode Z1<br>
    TDP: 65W<br>
    Part Number: RM-ACZ-01-BO<br>
    Product Size: 118x58mm<br>
    Socket:<br>
    Intel: LGA 775/1366/1156/1155/1151/1150<br>
    AMD: FM2+/FM1/AM2/AM3+/AM3<br>
    Fan Size: 90x90x25mm<br>
    Fan Speed: 2000 RPM<br>
    Bearing Type: Hydraulic Bearing<br>
    Voltage: 12V<br>
    Connector: 3pin<br>
    Fan Noise: 20dB(A)<br>
    Airflow: 55.6 CFM (MAX)<br>
    Fan Lifespan: 30000 Hours<br>
    EAN: 6015033496489<br>`,
      
    productId: 'z1-cooler',
    banner: Z1Cooler_Banner,
    youtubeUrl: '',
    images: [Z1Cooler_01, Z1Cooler_02, Z1Cooler_03],
    pageURL: 'z1-cooler',
  },
  {
    id: 14,
    name: 'Z2',
    category: 'cooler',
    descriptionP: `Air Cooler Gamer Rise Mode Z2<br>
    Part Number: RM-ACZ-02-BO<br>
    TDP: 75W<br>
    Tamanho do Produto: 120x76x126mm<br>
    Socket:<br>
    Intel: LGA775/1366/1156/1155/1151/1150<br>
    AMD: FM2+/FM1/AM2/AM3+/AM3<br>
    Tamanho do fan: 80x80x25mm<br>
    Velocidade do fan: 2000 RPM<br>
    Conector: 3pin<br>
    Tipo de rolamento: Rolamento hidráulico<br>
    Ruído do fan: 20dB(A)<br>
    Tensão: 12V<br>
    Heat Pipe: 2 PCS<br>
    Fluxo de ar: 55.6CFM (MAX)<br>
    Vida útil do Fan: 30000 Horas<br>
    EAN: 6015033496496<br>`,

    descriptionE: `Air Cooler Gamer Rise Mode Z2<br>
    Part Number: RM-ACZ-02-BO<br>
    TDP: 75W<br>
    Product Size: 120x76x126mm<br>
    Socket:<br>
    Intel: LGA775/1366/1156/1155/1151/1150<br>
    AMD: FM2+/FM1/AM2/AM3+/AM3<br>
    Fan Size: 80x80x25mm<br>
    Fan Speed: 2000 RPM<br>
    Connector: 3pin<br>
    Bearing Type: Hydraulic Bearing<br>
    Fan Noise: 20dB(A)<br>
    Voltage: 12V<br>
    Heat Pipe: 2 PCS<br>
    Airflow: 55.6CFM (MAX)<br>
    Fan Lifespan: 30000 Hours<br>
    EAN: 6015033496496<br>`,
      
    productId: 'z2-cooler',
    banner: Z2Cooler_Banner,
    youtubeUrl: '',
    images: [Z2Cooler_01, Z2Cooler_02, Z2Cooler_03, Z2Cooler_04],
    pageURL: 'z2-cooler',
  },
  {
    id: 15,
    name: 'Z3',
    category: 'cooler',
    descriptionP: `Air Cooler Gamer Rise Mode Z3<br>
    Part Number: RM-ACZ-03-FB<br>
    Tamanho do Produto: 114x75x128mm<br>
    Socket:<br>
    Intel: LGA 775/1366/1156/1155/1151/1150<br>
    AMD: FM2+/FM1/AM2/AM3+/AM3<br>
    Tamanho do fan: 92x92x25mm<br>
    Velocidade do fan: 2200 RPM<br>
    Tipo de rolamento: Rolamento hidráulico<br>
    Ruído do fan: 20dB(A)<br>
    Tensão: 12V<br>
    TDP: 75W<br>
    Heat Pipe: 4 PCS<br>
    Fluxo de ar: 55.6CFM (MAX)<br>
    Vida útil do Fan: 30000 Horas<br>
    EAN: 6015033497400<br>`,

    descriptionE: `Air Cooler Gamer Rise Mode Z3<br>
    Part Number: RM-ACZ-03-FB<br>
    Product Size: 114x75x128mm<br>
    Socket:<br>
    Intel: LGA 775/1366/1156/1155/1151/1150<br>
    AMD: FM2+/FM1/AM2/AM3+/AM3<br>
    Fan Size: 92x92x25mm<br>
    Fan Speed: 2200 RPM<br>
    Bearing Type: Hydraulic Bearing<br>
    Fan Noise: 20dB(A)<br>
    Voltage: 12V<br>
    TDP: 75W<br>
    Heat Pipe: 4 PCS<br>
    Airflow: 55.6CFM (MAX)<br>
    Fan Lifespan: 30000 Hours<br>
    EAN: 6015033497400<br>`,
      
    productId: 'z3-cooler',
    banner: Z3Cooler_Banner,
    youtubeUrl: '',
    images: [Z3Cooler_01, Z3Cooler_02, Z3Cooler_03, Z3Cooler_04],
    pageURL: 'z3-cooler',
  },
  {
    id: 16,
    name: 'Z5 RGB',
    category: 'cooler',
    descriptionP: `
    Air Cooler Gamer Rise Mode Z5 RGB<br>
    TDP:120W<br>                                            
    Socket: <br>
    Intel: 775/1150/1151/1155/1156/1366/1200<br>
    AMD: AM2/AM2+/AM3/AM3+/AM4/FM1/FM2   <br>                            
    Tamanho do Produto: 95x68x135mm<br>
    Tamanho do Fan: 90x90x25mm <br>
    Velocidade do Fan:800-2100 RPM <br>
    Cor do Led : RGB Rainbow<br>
    Conector do Fan: 3pin <br>
    Tipo de rolamento: Rolamento hidráulico <br>
    Tensão: 12v <br>
    Ruído do Fan: 18dB(A) <br>
    Fluxo de ar: 55 CFM (MAX)<br>
    Heat Pipe: 2<br>
    Vida útil do Fan: 30000 Horas    <br>
    
    Informações adicionais:<br>
    - Leds padrão rainbow colorido<br>
    * Os leds são coloridos não podem ser controlados<br>
    Ean: 6015033499442<br>
    `,

    descriptionE: `Air Cooler Gamer Rise Mode Z5 RGB<br>
    TDP: 120W<br>
    Socket: <br>
    Intel: 775/1150/1151/1155/1156/1366/1200<br>
    AMD: AM2/AM2+/AM3/AM3+/AM4/FM1/FM2<br>
    Product Size: 95x68x135mm<br>
    Fan Size: 90x90x25mm<br>
    Fan Speed: 800-2100 RPM<br>
    LED Color: RGB Rainbow<br>
    Fan Connector: 3-pin<br>
    Bearing Type: Hydraulic Bearing<br>
    Voltage: 12v<br>
    Fan Noise: 18dB(A)<br>
    Airflow: 55 CFM (MAX)<br>
    Heat Pipes: 2<br>
    Fan Lifespan: 30000 Hours<br>

    Additional Information:<br>
    - Standard rainbow colored LEDs<br>
    * LEDs are fixed and cannot be controlled<br>
    EAN: 6015033499442<br>
    `,
          
    productId: 'z5-rgb-cooler',
    banner: Z5_Banner,
    youtubeUrl: '',
    images: [Z5Cooler_01, Z5Cooler_02, Z5Cooler_03, Z5Cooler_04, Z5Cooler_05, Z5Cooler_06],
    pageURL: 'z5-rgb-cooler',
  },
];

export default products;
