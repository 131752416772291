import Lv1_01 from '../../../img/Produtos/suporte_vga/suporte-vga-lv1/1.jpg';
import Lv1_02 from '../../../img/Produtos/suporte_vga/suporte-vga-lv1/2.jpg';

import Lv2_01 from '../../../img/Produtos/suporte_vga/suporte-vga-lv2/1.jpg';
import Lv2_02 from '../../../img/Produtos/suporte_vga/suporte-vga-lv2/2.jpg';

import Lv3_01 from '../../../img/Produtos/suporte_vga/suporte-vga-lv3/1.jpg';
import Lv3_02 from '../../../img/Produtos/suporte_vga/suporte-vga-lv3/2.jpg';

const products = [
  {
    id: 1,
    name: 'Lv1',
    category: 'suporte_vga',
    descriptionP: `marca: Rise Mode<br>suportaAté: 2 Placas de Vídeo<br>material: Acrílico<br>acabamento: Cortado a Laser<br>compatibilidade: Qualquer VGA e Gabinete ATX<br>alturaDosEncaixes: Ajustável<br>alturaDoSuporte: 26,6 cm<br>peso: 33 gramas (Peso Bruto sem Embalagem)<br>pesoComCaixa: 83 gramas (Peso Bruto com Embalagem)`,

    descriptionE: `brand: Rise Mode<br>supportsUpTo: 2 Graphics Cards<br>material: Acrylic<br>finish: Laser Cut<br>compatibility: Any VGA and ATX Case<br>fittingHeight: Adjustable<br>standHeight: 26.6 cm<br>weight: 33 grams (Gross Weight without Packaging)<br>weightWithBox: 83 grams (Gross Weight with Packaging)`,
      
    productId: 'suporte-vga-lv1',
    banner: '',
    youtubeUrl: '',
    images: [Lv1_01, Lv1_02],
    pageURL: 'suporte-vga-lv1',
  },
  {
    id: 2,
    name: 'Lv2',
    category: 'suporte_vga',
    descriptionP: `marca: Rise Mode<br>suportaAté: 2 Placas de Vídeo<br>material: Acrílico<br>acabamento: Cortado a Laser<br>compatibilidade: Qualquer VGA e Gabinete ATX<br>alturaDosEncaixes: Ajustável<br>alturaDoSuporte: 26,6 cm<br>peso: 33 gramas (Peso Bruto sem Embalagem)<br>pesoComCaixa: 83 gramas (Peso Bruto com Embalagem)`,

    descriptionE: `brand: Rise Mode<br>supportsUpTo: 2 Graphics Cards<br>material: Acrylic<br>finish: Laser Cut<br>compatibility: Any VGA and ATX Case<br>fittingHeight: Adjustable<br>standHeight: 26.6 cm<br>weight: 33 grams (Gross Weight without Packaging)<br>weightWithBox: 83 grams (Gross Weight with Packaging)`,
      
    productId: 'suporte-vga-lv2',
    banner: '',
    youtubeUrl: '',
    images: [Lv2_01, Lv2_02],
    pageURL: 'suporte-vga-lv2',
  },
  {
    id: 3,
    name: 'Lv3',
    category: 'suporte_vga',
    descriptionP: `
    Especificações:<br>
    
    Marca: Rise Mode<br>
    Suporta até: 2 Placas de Vídeo<br>
    Material: Acrílico<br>
    Acabamento: Cortado a Laser<br>
    Compatibilidade: Qualquer VGA e Gabinete ATX<br>
    Altura dos encaixes: Ajustável<br>
    Altura do suporte: 26,6 cm<br>
    Peso: 33 gramas (Bruto sem Embalagem)<br>
    Peso com caixa: 83 gramas (Bruto com Embalagem)`,

    descriptionE: `
    Specifications:<br>
    
    Brand: Rise Mode<br>
    Supports up to: 2 Graphics Cards<br>
    Material: Acrylic<br>
    Finish: Laser Cut<br>
    Compatibility: Any VGA and ATX Case<br>
    Height of Slots: Adjustable<br>
    Support Height: 26.6 cm<br>
    Weight: 33 grams (Gross Weight without Packaging)<br>
    Weight with Box: 83 grams (Gross Weight with Packaging)`,
      
    productId: 'suporte-vga-lv3',
    banner: '',
    youtubeUrl: '',
    images: [Lv3_01, Lv3_02],
    pageURL: 'suporte-vga-lv3',
  },
];

export default products;
