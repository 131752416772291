import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import Loading from '../../Loading/Loading';
import firebase from 'firebase/compat/app';
import { fetchProducts } from '../../List.js/List';
import UsaFlag from '../../../img/icon/usaIcon.png';
import KabumIcon from '../../../img/icon/kabumIcon.png';
import Lojarise from '../../../img/icon/riseIcon.png';
import ProductDescription from '../ProductDescription/ProductDescription.js';
import 'firebase/compat/database';
import YouTube from 'react-youtube';
import { useTranslation } from 'react-i18next';

const firebaseConfig = {
    apiKey: "AIzaSyBliTfTPbSU4caIdCdMh_Xs5f_-95ISq28",
    authDomain: "produtos-list.firebaseapp.com",
    databaseURL: "https://produtos-list-default-rtdb.firebaseio.com",
    projectId: "produtos-list",
    storageBucket: "produtos-list.appspot.com",
    messagingSenderId: "308827988350",
    appId: "1:308827988350:web:a635c2b1508599bfff132f"
  };

// Inicialize o app do Firebase
firebase.initializeApp(firebaseConfig);


const Titlebuy = styled.h1`
  font-size: 3.5rem;
  text-align: center;
`;

const Botoes = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem ;
  
  a{
    padding: 10px;
    margin: 8px;
    width: 200px;
    height: 50px;
    background: #4491FA;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: center;
    gap: 10px;
    
    :hover{
    filter: brightness(0.8);
    }
  }

  img{
    width: 30px;
    height: 30px;
    background: none;
  }

  p{
    font-size: 1.5rem;
    text-align: center;
    background: none;
  }
`;

const Principal = styled.div`
  background-color: black;
  padding: 1rem;
  border-radius: 4px;
  width: 100%;
  max-width: 1200px;

  position: relative;
  margin: 0 auto;
`;

const ControleAll = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Conteudo = styled.div`
  width: 800px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background: white;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 8px;

  @media screen and (max-width: 1000px) {
    width: 420px;
  }

  img {
    width: 350px;
    margin: 4px;

    @media screen and (max-width: 1000px) {
      width: 150px;
      margin: 4px;
    }
  }
`;

const TituloModal = styled.h1`
    background: none;
    color: white;
    text-align: center;
`;

const BannerProduto = styled.img`
  width: 100%;
  max-width: 800px;
  margin: 1rem;
`;

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
`;

const ProductPage = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [product, setProduct] = useState(null);
    const [productId, setProductId] = useState('');
    const [products, setProducts] = useState([]);
    const { id } = useParams();
  
    useEffect(() => {
      if (id) {
        openModal(id);
      }
    }, [id]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          // Obtenha o caminho da URL completo
          const path = window.location.pathname;
  
          // Divida o caminho da URL pelo caractere da barra "/"
          const pathSegments = path.split('/');
  
          // Obtenha o último segmento que representa o ID do produto
          const id = pathSegments[pathSegments.length - 1];
  
          // Atualize o estado do ID do produto
          setProductId(id);
  
          const allProductsData = await fetchProducts(); // Busca todos os produtos
          const productData = allProductsData.find(product => product.productId === id); // Encontra o produto com o ID correspondente
  
          if (productData) {
            setProduct({
              images: productData.images,
              banner: productData.banner,
              name: productData.name,
              description: productData.description,
              youtubeUrl: productData.youtubeUrl
            });
          }
  
          setIsLoading(false);
        } catch (error) {
          console.error(error);
          setIsLoading(false);
        }
      };
  
      fetchData();
    }, []);
    const WithLoading = ({ isLoading, children }) => {
      return <>{isLoading ? <Loading /> : <>{children}</>}</>;
    };

    const { t } = useTranslation();
  
    return (
      <WithLoading isLoading={isLoading}>
        {product && (
          <Principal>
                <VideoContainer>
                        {product.youtubeUrl ? (
                      <YouTube videoId={product.youtubeUrl} id="youtube-container" />
                    ) : (
                      <div></div>
                    )}
                      <Titlebuy>{t('button.buy')}</Titlebuy>
                      <Botoes>
                        <a target='_blank' href="https://www.amazon.com/s?i=merchant-items&me=A2Z190E8OU3Q34&marketplaceID=ATVPDKIKX0DER&qid=1682472974&ref=sr_pg_2">
                        <img src={UsaFlag} />  
                        <p>RISE USA</p>
                        </a>

                        <a target='_blank' href="http://risemode.commercesuite.com.br">
                        <img src={Lojarise} />  
                        <p>LOJA RISE</p>
                        </a>

                        <a target='_blank' href="https://www.kabum.com.br/busca/Rise-mode">
                        <img src={KabumIcon} />  
                        <p>KABUM</p>
                        </a>
                    </Botoes>
                    </VideoContainer>
            <ControleAll>
            <Conteudo>
                    {product.images.slice(0, 5).map((image, index) => (
                      <img key={index} src={image} alt={`Product ${index}`} />
                    ))}
            </Conteudo>
                <BannerProduto src={product.banner} />
                <TituloModal>{product.name}</TituloModal>
                <ProductDescription description={product.description}/>  
            </ControleAll>
          </Principal>
        )}
      </WithLoading>
    );
  };
  
  export default ProductPage;
