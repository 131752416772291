import AuraSoundS1_01 from '../../../img/Produtos/caixa_som/aura-sound-s1- rgb/foto_01.png';
import AuraSoundS1_02 from '../../../img/Produtos/caixa_som/aura-sound-s1- rgb/foto_02.png';
import AuraSoundS1_03 from '../../../img/Produtos/caixa_som/aura-sound-s1- rgb/foto_03.png';
import AuraSoundS1_04 from '../../../img/Produtos/caixa_som/aura-sound-s1- rgb/foto_04.png';

import AuraSoundS4_01 from '../../../img/Produtos/caixa_som/aura-sound-s4-rgb/foto_01.png';
import AuraSoundS4_02 from '../../../img/Produtos/caixa_som/aura-sound-s4-rgb/foto_02.png';
import AuraSoundS4_03 from '../../../img/Produtos/caixa_som/aura-sound-s4-rgb/foto_03.png';
import AuraSoundS4_04 from '../../../img/Produtos/caixa_som/aura-sound-s4-rgb/foto_04.png';

import AuraSoundS5_01 from '../../../img/Produtos/caixa_som/aura-sound-S5-rgb/foto_01.png';
import AuraSoundS5_02 from '../../../img/Produtos/caixa_som/aura-sound-S5-rgb/foto_02.png';
import AuraSoundS5_03 from '../../../img/Produtos/caixa_som/aura-sound-S5-rgb/foto_03.png';
import AuraSoundS5_04 from '../../../img/Produtos/caixa_som/aura-sound-S5-rgb/foto_04.png';

import AuraSoundS6_01 from '../../../img/Produtos/caixa_som/aura-sound-S6-RGB-bluetooth/foto_01.png';
import AuraSoundS6_02 from '../../../img/Produtos/caixa_som/aura-sound-S6-RGB-bluetooth/foto_02.png';
import AuraSoundS6_03 from '../../../img/Produtos/caixa_som/aura-sound-S6-RGB-bluetooth/foto_03.png';
import AuraSoundS6_04 from '../../../img/Produtos/caixa_som/aura-sound-S6-RGB-bluetooth/foto_04.png';

const products = [
  {
    id: 1,
    name: 'Aura Sound S1 RGB',
    category: 'caixa-som',
    descriptionP: `Caixa de Som Gamer Rise Mode Aura Sound S1 RGB<br/>Especificações<br/>Potência de saída: 3W*2<br/>Tensão de alimentação: DC USB 5V<br/>Conector: P2 3.5mm (áudio)<br/>Led: RGB Rainbow<br/>Tamanho: 150x84x85mm<br/>EAN: 7893286770254<br/> Part Number: RM-SP-01-RGB <br/>`,

    descriptionE: `Gamer Rise Mode Aura Sound S1 RGB Speaker<br/>Specifications<br/>Output Power: 3W*2<br/>Power Voltage: DC USB 5V<br/>Connector: 3.5mm P2 (audio)<br/>LED: RGB Rainbow<br/>Size: 150x84x85mm<br/>EAN: 7893286770254<br/> Part Number: RM-SP-01-RGB <br/>`,
      
    productId: 'aura-sound-s1-rgb',
    banner: '',
    youtubeUrl: '',
    images: [AuraSoundS1_01, AuraSoundS1_02, AuraSoundS1_03, AuraSoundS1_04],
    pageURL: 'aura-sound-s1-rgb',
  },
  {
    id: 2,
    name: 'Aura Sound S4 RGB',
    category: 'caixa-som',
    descriptionP: `Caixa de Som Gamer Rise Mode Aura Sound S4 RGB<br/>Especificações<br/>Potência de saída: 3W*2<br/>Tensão de alimentação: DC USB 5V<br/>Conector: P2 3.5mm (áudio)<br/>Led: RGB Rainbow<br/>Tamanho: 409x77x70mm<br/>EAN: 7894581699578<br/> Part Number: RM-SP-04-RGB <br/>`,

    descriptionE: `Gamer Rise Mode Aura Sound S4 RGB Speaker<br/>Specifications<br/>Output Power: 3W*2<br/>Power Voltage: DC USB 5V<br/>Connector: 3.5mm P2 (audio)<br/>LED: RGB Rainbow<br/>Size: 409x77x70mm<br/>EAN: 7894581699578<br/> Part Number: RM-SP-04-RGB <br/>`,
      
    productId: 'aura-sound-s4-rgb',
    banner: '',
    youtubeUrl: '',
    images: [AuraSoundS4_01, AuraSoundS4_02, AuraSoundS4_03, AuraSoundS4_04],
    pageURL: 'aura-sound-s4-rgb',
  },
  {
    id: 3,
    name: 'Aura Sound S5 RGB',
    category: 'caixa-som',
    descriptionP: `Caixa de Som Gamer Rise Mode Aura Sound S5 RGB<br/>Especificações<br/>Potência de saída: 3W*2<br/>Tensão de alimentação: DC USB 5V<br/>Conector: P2 3.5mm (áudio)<br/>Led: RGB Rainbow<br/>Tamanho: 87,5x100x154mm<br/>Informações adicionais<br/>- Conexão para Cartão de memória<br/>- Conector para fone de ouvido e microfone<br/>EAN: 7895282074879<br/> Part Number: RM-SP-05-RGB <br/>`,

    descriptionE: `Gamer Rise Mode Aura Sound S5 RGB Speaker<br/>Specifications<br/>Output Power: 3W*2<br/>Power Voltage: DC USB 5V<br/>Connector: 3.5mm P2 (audio)<br/>LED: RGB Rainbow<br/>Size: 87.5x100x154mm<br/>Additional Information<br/>- Memory Card Slot<br/>- Headphone and microphone connector<br/>EAN: 7895282074879<br/> Part Number: RM-SP-05-RGB <br/>`,
      
    productId: 'aura-sound-s5-rgb',
    banner: '',
    youtubeUrl: '',
    images: [AuraSoundS5_01, AuraSoundS5_02, AuraSoundS5_03, AuraSoundS5_04],
    pageURL: 'aura-sound-s5-rgb',
  },
  {
    id: 4,
    name: 'Aura Sound S6 RGB Bluetooth',
    category: 'caixa-som',
    descriptionP: `Caixa de Som Gamer Rise Mode Aura Sound S6 RGB Bluetooth<br/>Especificações<br/>Potência de saída: 5W*2<br/>Tensão de alimentação: DC USB 5V<br/>Conexão: Bluetooth<br/>Led: RGB Rainbow<br/>Tamanho: 377x69x65mm<br/>Informações adicionais<br/>- Conexão para Cartão de memória<br/>- Conexão USB para pen drive<br/>- Bateria 1200mAh<br/>EAN: 7894581882840<br/> Part Number: RM-SP-06-RGB <br/>`,

    descriptionE: `Gamer Rise Mode Aura Sound S6 RGB Bluetooth Speaker<br/>Specifications<br/>Output Power: 5W*2<br/>Power Voltage: DC USB 5V<br/>Connection: Bluetooth<br/>LED: RGB Rainbow<br/>Size: 377x69x65mm<br/>Additional Information<br/>- Memory Card Slot<br/>- USB port for pen drive<br/>- 1200mAh Battery<br/>EAN: 7894581882840<br/> Part Number: RM-SP-06-RGB <br/>`,
      
    productId: 'aura-sound-s6-rgb-bluetooh',
    banner: '',
    youtubeUrl: '',
    images: [AuraSoundS6_01, AuraSoundS6_02, AuraSoundS6_03, AuraSoundS6_04],
    pageURL: 'aura-sound-s6-rgb-bluetooh',
  },
];

export default products;
