import Ssd120_01 from '../../../img/Produtos/ssd/120/1.jpg';
import Ssd120_02 from '../../../img/Produtos/ssd/120/2.jpg';
import Ssd120_03 from '../../../img/Produtos/ssd/120/3.jpg';
import Ssd120_04 from '../../../img/Produtos/ssd/120/4.jpg';


import Ssd240_01 from '../../../img/Produtos/ssd/240/1.jpg';
import Ssd240_02 from '../../../img/Produtos/ssd/240/2.jpg';
import Ssd240_03 from '../../../img/Produtos/ssd/240/3.jpg';
import Ssd240_04 from '../../../img/Produtos/ssd/240/4.jpg';


import Ssd480_01 from '../../../img/Produtos/ssd/480/1.jpg';
import Ssd480_02 from '../../../img/Produtos/ssd/480/2.jpg';
import Ssd480_03 from '../../../img/Produtos/ssd/480/3.jpg';


import Ssd960_01 from '../../../img/Produtos/ssd/960/1.jpg';
import Ssd960_02 from '../../../img/Produtos/ssd/960/2.jpg';
import Ssd960_03 from '../../../img/Produtos/ssd/960/3.jpg';
import Ssd960_04 from '../../../img/Produtos/ssd/960/4.jpg';

import Ssd120_Banner from '../../../img/Produtos/ssd/banner.jpg';

const products = [
  {
    id: 1,
    name: 'Rise Mode SSD 120GB Gamer line',
    category: 'ssd',
    descriptionP: `Rise Mode SSD 120GB Gamer line <br>

    Marca: Rise Mode <br>
    
    Modelo: SSD 120GB Gamer line <br>
    
    Part Number:RM-SSD-120 <br>
    
    ESPECIFICAÇÕES: <br>
    
    - Tipo de drive: 2,5 polegadas SATA-III, 6 Gb / s, 7mm <br>
    
    - Desempenho:2,5 polegadas SATA-III, 6 Gb / s, 7mm <br>
    
    Leitura sequencial: até 535MB / s <br>
    
    Escrita sequencial: até 435MB / s <br>
    
    IOPS de leitura aleatória em blocos de 4K: até 40K <br>
    
    IOPS de gravação aleatória em blocos de 4K: até 86K <br>
    
    - Consumo de energia: Típico = 2,0 W, Inativo = 0,3 W <br>
    
    - Temperatura de operação:0ºC - 70ºC <br>
    
    - Mtbf:2.000.000 de horas <br>
     
    - Compatível com:Windows 10, Windows 8, Windows 7, <br>
    
    Windows Vista, Windows XP, Linux, Mac OS X <br>
    
    - Garantia: 3 anos <br>
    
    EAN: 7895099519396 <br>`,

    descriptionE: ``,
      
    productId: 'ssd-120gb-gamer-line',
    banner: Ssd120_Banner,
    youtubeUrl: '9ZIWOFoY4T8',
    images: [Ssd120_01, Ssd120_02, Ssd120_03, Ssd120_04],
    pageURL: 'ssd-120gb-gamer-line',
  },
  {
    id: 2,
    name: 'Rise Mode SSD 240GB Gamer line',
    category: 'ssd',
    descriptionP: `Rise Mode SSD 240GB Gamer line <br>

    Marca: Rise Mode <br>
    
    Modelo: SSD 240GB Gamer line <br>
    
    Part Number:RM-SSD-240 <br>
    
    ESPECIFICAÇÕES: <br>
    
    - Tipo de drive: 2,5 polegadas SATA-III, 6 Gb / s, 7mm <br>
    
    - Desempenho:2,5 polegadas SATA-III, 6 Gb / s, 7mm <br>
    
    Leitura sequencial: até 535MB / s <br>
    
    Escrita sequencial: até 435MB / s <br>
    
    IOPS de leitura aleatória em blocos de 4K: até 40K <br>
    
    IOPS de gravação aleatória em blocos de 4K: até 86K <br>
    
    - Consumo de energia: Típico = 2,0 W, Inativo = 0,3 W <br>
    
    - Temperatura de operação:0ºC - 70ºC <br>
    
    - Mtbf:2.000.000 de horas <br>
     
    - Compatível com:Windows 10, Windows 8, Windows 7, <br>
    
    Windows Vista, Windows XP, Linux, Mac OS X <br>
    
    - Garantia: 3 anos <br>
    
    EAN: 7895099519402 <br>`,

    descriptionE: ``,
      
    productId: 'ssd-240gb-gamer-line',
    banner: Ssd120_Banner,
    youtubeUrl: '9ZIWOFoY4T8',
    images: [Ssd240_01, Ssd240_02, Ssd240_03, Ssd240_04],
    pageURL: 'ssd-240gb-gamer-line',
  },
  {
    id: 3,
    name: 'Rise Mode SSD 480GB Gamer line',
    category: 'ssd',
    descriptionP: `Rise Mode SSD 480GB Gamer line <br>

    Marca: Rise Mode <br>
    
    Modelo: SSD 480GB Gamer line <br>
    
    Part Number:RM-SSD-480 <br>
    
    ESPECIFICAÇÕES: <br>
    
    - Tipo de drive: 2,5 polegadas SATA-III, 6 Gb / s, 7mm <br>
    
    - Desempenho:2,5 polegadas SATA-III, 6 Gb / s, 7mm <br>
    
    Leitura sequencial: até 535MB / s <br>
    
    Escrita sequencial: até 435MB / s <br>
    
    IOPS de leitura aleatória em blocos de 4K: até 40K <br>
    
    IOPS de gravação aleatória em blocos de 4K: até 86K <br>
    
    - Consumo de energia: Típico = 2,0 W, Inativo = 0,3 W <br>
    
    - Temperatura de operação:0ºC - 70ºC <br>
    
    - Mtbf:2.000.000 de horas <br>
     
    - Compatível com:Windows 10, Windows 8, Windows 7, <br>
    
    Windows Vista, Windows XP, Linux, Mac OS X <br>
    
    - Garantia: 3 anos <br>
    
    EAN: 7895099519419 <br>`,

    descriptionE: ``,
      
    productId: 'ssd-480gb-gamer-line',
    banner: Ssd120_Banner,
    youtubeUrl: '9ZIWOFoY4T8',
    images: [Ssd480_01, Ssd480_02, Ssd480_03],
    pageURL: 'ssd-480gb-gamer-line',
  },
  {
    id: 4,
    name: 'Rise Mode SSD 960GB Gamer line',
    category: 'ssd',
    descriptionP: `Rise Mode SSD 960GB Gamer line <br>

    Marca: Rise Mode <br>
    
    Modelo: SSD 960GB Gamer line <br>
    
    Part Number:RM-SSD-960 <br>
    
    ESPECIFICAÇÕES: <br>
    
    - Tipo de drive: 2,5 polegadas SATA-III, 6 Gb / s, 7mm <br>
    
    - Desempenho:2,5 polegadas SATA-III, 6 Gb / s, 7mm <br>
    
    Leitura sequencial: até 535MB / s <br>
    
    Escrita sequencial: até 435MB / s <br>
    
    IOPS de leitura aleatória em blocos de 4K: até 40K <br>
    
    IOPS de gravação aleatória em blocos de 4K: até 86K <br>
    
    - Consumo de energia: Típico = 2,0 W, Inativo = 0,3 W <br>
    
    - Temperatura de operação:0ºC - 70ºC <br>
    
    - Mtbf:2.000.000 de horas <br>
     
    - Compatível com:Windows 10, Windows 8, Windows 7, <br>
    
    Windows Vista, Windows XP, Linux, Mac OS X <br>
    
    - Garantia: 3 anos <br>
    
    EAN: 7895099519433 <br>`,

    descriptionE: ``,
      
    productId: 'ssd-960gb-gamer-line',
    banner: Ssd120_Banner,
    youtubeUrl: '9ZIWOFoY4T8',
    images: [Ssd960_01, Ssd960_02, Ssd960_03, Ssd960_04],
    pageURL: 'ssd-960gb-gamer-line',
  },
];

export default products;
