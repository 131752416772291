import PciExpress_01 from '../../../img/Produtos/pcie/1.jpg';

const products = [
  {
    id: 1,
    name: 'Extensor Pci Exprees',
    category: 'pcie',
    descriptionP: `Cabo Riser Extensor Pci Express Gamer Rise Mode<br>

    Características: <br>
    
    Marca: Rise Mode <br>
    
    Modelo: Riser Extensor Pci Express <br>
    
    Part Number: RM-EX-PV <br>
    
    Tamanho: 200mm x 140mm <br>
    
    Informações adicionais: <br>
    
    - Suporte para fixação no gabinete <br>
    
    - Cabo maleável e de fácil instalação <br>
    
    - Padrão de pcie x16 <br>
    
    EAN: 6015033512578 <br>`,

    descriptionE: ``,
      
    productId: 'extensor-pci-express',
    banner: '',
    youtubeUrl: '',
    images: [PciExpress_01],
    pageURL: 'extensor-pci-express',
  },
];

export default products;
