import { Component } from "react";
import PropTypes from "prop-types";

const canUseDOM = () => {
  if (
    typeof window === "undefined" ||
    !window.document ||
    !window.document.createElement
  ) {
    return false;
  }
  return true;
};

export default class Octadesk extends Component {
  constructor(props) {
    super(props);
    this.insertScript = this.insertScript.bind(this);
    this.onScriptLoaded = this.onScriptLoaded.bind(this);
  }

  onScriptLoaded() {
    if (typeof this.props.onLoaded === "function") {
      this.props.onLoaded();
    }
  }

  insertScript() {
    const script = document.createElement("script");

    script.async = true;
    script.innerHTML = `    (function (o, c, t, a, d, e, s, k) {
      o.octadesk = o.octadesk || {};
      o.octadesk.chatOptions = {
        subDomain: a,
        showButton: d,
        openOnMessage: e,
        hide: s
      };
      var bd = c.getElementsByTagName("body")[0];
      var sc = c.createElement("script");
      sc.async = 1;
      sc.src = t;
      bd.appendChild(sc);
    })(window, document, 'https://cdn.octadesk.com/embed.js', 'o184528-4cb',  'true', 'true', 'false');`;
    script.addEventListener("load", this.onScriptLoaded);
    document.body.appendChild(script);
  }

  componentDidMount() {
    if (canUseDOM && !window.zE) {
      const { ...other } = this.props;
      this.insertScript();
      window.zESettings = other;
    }
  }

  componentWillUnmount() {
    if (!canUseDOM || !window.zE) {
      return;
    }
    delete window.zE;
    delete window.zESettings;
  }

  render() {
    return null;
  }
}