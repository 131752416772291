import React from 'react';
import styled from 'styled-components';
import imagem2 from '../../img/rise.png';
import imagem3 from '../../img/modeon.png';
import imagem1 from '../../img/kabum.png';
import { motion } from 'framer-motion'; 
import { useTranslation } from 'react-i18next';
/* eslint-disable */


const Container = styled.div`
  text-align: center;
  background: 232323;
  padding: 1rem;

  h1{
    margin-bottom: 1rem;
    font-size: 2.5rem;
  }
`;

const Division = styled.div`
    width: 100%;
    height: 1px;
    background: #161616;
`

const Title = styled.h2`
  border-radius: 4px;
  font-size: 1.2rem;
  font-weight: 300;
  background: none;
  font-family: 'Inter';
  font-weight: 600;
  color: #d7d7d7;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const Card = styled.a`
  width: 300px;
  background: #202020;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin: 16px;
  text-decoration: none;
  background: #161616;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: transform .2s;

  :hover{
    transform: scale(1.1);
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 8px;
`;

const CardTitle = styled.h2`
  width: 100px;
  font-size: 18px;
  margin-bottom: 8px;
  border-radius: 2px;
  background: #4491FA;
`;


function App() {
  const { t } = useTranslation();
  
  return (
    <motion.div
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.3 }}
    >
    <Container>
        <h1>{t('reseller.page.title')}</h1>
      <Title>USA:</Title>
      <CardContainer>
        <Card target='_blank' href='https://www.amazon.com/s?i=merchant-items&me=A2Z190E8OU3Q34&marketplaceID=ATVPDKIKX0DER&qid=1682472974&ref=sr_pg_2'>
          <CardImage src={imagem2} alt="Card 2" />
          <CardTitle>Rise USA</CardTitle>
        </Card>
      </CardContainer>
    </Container>
    <Division></Division>
    <Container>
      <Title>Brazil:</Title>
      <CardContainer>
        <Card target='_blank' href='https://www.kabum.com.br/busca/rise-mode'>
          <CardImage src={imagem1} alt="Card 1" />
          <CardTitle>Kabum</CardTitle>
        </Card>
        <Card target='_blank' href='https://www.lojarisemode.com.br/'>
          <CardImage src={imagem2} alt="Card 2" />
          <CardTitle>Rise</CardTitle>
        </Card>
        <Card target='_blank' href='https://www.modeonshops.com.br/'>
          <CardImage src={imagem3} alt="Card 3" />
          <CardTitle>Mode On</CardTitle>
        </Card>
      </CardContainer>
    </Container>
    </motion.div>
  );
}

export default App;
