import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { motion } from 'framer-motion'; 
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

// Sua configuração do Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBliTfTPbSU4caIdCdMh_Xs5f_-95ISq28",
  authDomain: "produtos-list.firebaseapp.com",
  databaseURL: "https://produtos-list-default-rtdb.firebaseio.com",
  projectId: "produtos-list",
  storageBucket: "produtos-list.appspot.com",
  messagingSenderId: "308827988350",
  appId: "1:308827988350:web:a635c2b1508599bfff132f"
};

// Inicializa o Firebase
firebase.initializeApp(firebaseConfig);

const database = firebase.database();


const Container = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #161616;
  width: 1200px;
  height: 100vh;
  border-radius: 0rem 1rem 1rem 0rem;
  padding: 1rem;

  @media screen and (max-width: 1000px) {
      width:100%;
  }

  p {
    font-size: 1.2rem;
    color: white;
  }

  input[type=file] {
  width: 350px;
  max-width: 100%;
  color: #444;
  padding: 5px;
  background: #fff;
  border-radius: 10px;
}

  input[type=file]::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #4491FA;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background .2s ease-in-out;
  }

  input[type=file]::file-selector-button:hover {
    filter: brightness(0.9);
  }
`;

const Title = styled.h1`
  text-align: center;
  color: #4491FA;
  margin-bottom: 0.5rem;
  background: none;
`;

const Subtitle = styled.h2`
  background: none;
  text-align: center;
  color: white;
  margin-top: 1rem;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  background: none;
`;

const Thumbnail = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 10px 0 0 10px;
  background: none;
`;

const ImagesWrapper = styled.div`
  display: flex;
  background: none;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 1000px;
`;

const ImageInput = styled.input`
background: none;
`;

const EditButton = styled.button`
  padding: 5px 10px;
  background-color: #4491fa;
  border: none;
  color: #fff;
  cursor: pointer;
  margin-right: 10px;
  height: 100%;
  border-radius: 0 10px 10px 0;
  font-size: 1.4rem;
`;

const NameInput = styled.input`
  margin: 5px;
  width: 350px;
  height: 40px;
  border-radius: 4px;
  padding: 10px;
  background: #343333;
  border: 1px solid #8d8d8d;
  font-size: 1.2rem;
`;

const NameInputC = styled.input`
  margin: 5px;
  width: 350px;
  height: 40px;
  border-radius: 4px;
  padding: 10px;
  background: #343333;
  border: 1px solid #8d8d8d;
  font-size: 1.2rem;
  font-family: 'Inter';
`;

const DescriptionInput = styled.textarea`
  margin: 5px;
  padding: 10px;
  width: 350px;
  height: 100px;
  border-radius: 4px;
  background: #343333;
  border: 1px solid #8d8d8d;
  font-size: 1rem;
  font-family: 'Inter';
`;

const CategorySelect = styled.select`
  margin: 5px;
  padding: 10px;
  width: 350px;
  height: 40px;
  border-radius: 4px;
  background: #343333;
  border: 1px solid #8d8d8d;
  color: #ffffff;
  cursor: pointer;
  font-size: 1.2rem;

  option {
    font-size: 1.1rem;
    cursor: pointer;
  }
`;

const BannerInput = styled.input`
  margin: 5px;
  cursor: pointer;
`;

const YoutubeUrlInput = styled.input`
  margin: 5px;
  padding: 10px;
  width: 350px;
  height: 40px;
  border-radius: 4px;
  background: #343333;
  border: 1px solid #8d8d8d;
  color: #ffffff;
  font-size: 1.2rem;
`;


const SubmitButton = styled.button`
  width: 350px;
  margin: 10px;
  padding: 15px 60px;
  background-color: #4491fa;
  color: #ffff;
  cursor: pointer;
  border: none;
  font-size: 1.1rem;
  border-radius: 4px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  :hover {
    filter: brightness(0.8);
  }
`;

const UploadPage = () => {
  const [images, setImages] = useState([]);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const fileInputRef = useRef(null);
  const [currentEditIndex, setCurrentEditIndex] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showSizeWarning, setShowSizeWarning] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [banner, setBanner] = useState('');
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [otherCategory, setOtherCategory] = useState('');


const handleImageChange = (e) => {
  const file = e.target.files[0];
  const reader = new FileReader();

  if (!file) {
    return; // Verifica se o arquivo existe antes de continuar
  }

  if (file.size > 1024 * 1024) {
    setShowSizeWarning(true);
    setButtonDisabled(true);
    setTimeout(() => {
      setShowSizeWarning(false);
      setButtonDisabled(false);
    }, 10000);
    return;
  }

  reader.onload = (event) => {
    const imageDataUrl = event.target.result;
    const updatedImages = [...images];
    if (currentEditIndex !== null) {
      updatedImages[currentEditIndex] = imageDataUrl;
      setCurrentEditIndex(null);
    } else {
      updatedImages.push(imageDataUrl);
    }
    setImages(updatedImages);
  };

  reader.readAsDataURL(file);
};

const handleBannerChange = (e) => {
  const file = e.target.files[0];
  const reader = new FileReader();

  if (!file) {
    return; // Verifica se o arquivo existe antes de continuar
  }

  if (file.size > 1024 * 1024) {
    setShowSizeWarning(true);
    setButtonDisabled(true);
    setTimeout(() => {
      setShowSizeWarning(false);
      setButtonDisabled(false);
    }, 10000);
    return;
  }

  reader.onload = (event) => {
    const bannerDataUrl = event.target.result;
    setBanner(bannerDataUrl);
  };

  reader.readAsDataURL(file);
};

const handleSubmit = () => {
  setButtonDisabled(true);
  const totalSize = images.reduce((size, image) => size + image.size, 0);

  if (totalSize > 1024 * 1024) {
    setShowSizeWarning(true);
    setTimeout(() => {
      setShowSizeWarning(false);
      setButtonDisabled(false);
    }, 3000);
    return;
  }

  if (name === '' || description === '' || category === '') {
    // Verifica se os campos estão vazios
    setShowErrorMessage(true);
    setTimeout(() => {
      setShowErrorMessage(false);
      setButtonDisabled(false);
    }, 3000);
    return;
  }

  if (images.length === 0) {
    setImageError(true);
    setTimeout(() => {
      setImageError(false);
      setButtonDisabled(false);
    }, 3000);
    return;
  }

  const newProductRef = database.ref('products').push();
  const productId = newProductRef.key;

  const productPage = newProductRef.key;

  let categoryToSend = category;
  if (category === 'OUTRO') {
    categoryToSend = otherCategory;
  }

  newProductRef
    .set({
      productId,
      productPage,
      images,
      name,
      description,
      category: categoryToSend,
      banner,
      youtubeUrl,
    })
    .then(() => {
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
      setName('');
      setDescription('');
      setCategory('');
      setImages([]);
      setBanner('');
      setYoutubeUrl('');
    })
    .catch((error) => {
      // Resto do código para tratar erros na gravação no Realtime Database
    })
    .then(() => {
      setButtonDisabled(false);
    });
};

const handleEditImage = (index) => {
  fileInputRef.current.value = null;
  fileInputRef.current.click();
  setCurrentEditIndex(index);
};
  
const handleCategoryChange = (e) => {
  const selectedCategory = e.target.value;
  setCategory(selectedCategory);

  if (selectedCategory === 'OUTRO') {
    setShowOtherInput(true);
    setOtherCategory(category);

  } else {
    setShowOtherInput(false);
    setOtherCategory('');
  }
};


const handleOtherCategoryChange = (e) => {
  setOtherCategory(e.target.value);
};

  return (
    <motion.div
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.3 }}
    >
    <Container>
      <Title>NOVO PRODUTO</Title>
      <ImagesWrapper>
        {images.map((image, index) => (
          <ImageContainer key={index}>
            <Thumbnail src={image} alt={`Thumbnail ${index + 1}`} />
            <EditButton onClick={() => handleEditImage(index)}>Editar</EditButton>
          </ImageContainer>
        ))}
      </ImagesWrapper>
      <p>Clique no botão para adicionar uma nova imagem</p>
      <ImageInput
        className="custom-file-input" 
        id="fupload"
        type="file"
        ref={fileInputRef}
        onChange={(e) => {
          handleImageChange(e, images.length);
        }}
      />
      <Subtitle>Detalhes do produto:</Subtitle>
      <NameInput
        type="text"
        placeholder="Nome do Produto"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <DescriptionInput
        placeholder="Descrição do Produto"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <CategorySelect
        value={category} onChange={handleCategoryChange}
      >
        <option value="">Selecione uma categoria</option>
        <option value="suporteheadset">Suporte Headset</option>
        <option value="memoria">Memoria</option>
        <option value="suportevga">Suporte VGA</option>
        <option value="mousepad">Mousepad</option>
        <option value="psucover">Psu Cover</option>
        <option value="gabinete">Gabinete</option>
        <option value="watercooler">Water Cooler</option>
        <option value="aircooler">Air Cooler</option>
        <option value="fan">Fan</option>
        <option value="cadeiragamer">Cadeira Gamer</option>
        <option value="fonte">Fonte</option>
        <option value="extensor-pcie">extensor-pcie</option>
        <option value="ssdm2">Ssd M.2</option>
        <option value="ssdgamer">SSD Gamer</option>
        <option value="cartaosd">CARTÃO MICRO SD</option>
        <option value="pastatermica">PASTA TÉRMICA</option>
        <option value="teclado">Teclado</option>
        <option value="mouse">Mouse</option>
        <option value="suportecontrole">Suporte Controle</option>
        <option value="cabosleeved">CABOS SLEEVED</option>
        <option value="audiosplitter">ÁUDIO SPLITTER</option>
        <option value="hddgamer">HDD GAMER</option>
        <option value="camiseta">camiseta</option>
        <option value="suportenotebook">Suporte Notebook</option>
        <option value="placadevideo">Placa de Vídeo</option>
        <option value="OUTRO">OUTRO</option>
      </CategorySelect>
      {showOtherInput && (
        <NameInputC
          type="text"
          value={otherCategory}
          onChange={handleOtherCategoryChange}
          placeholder="Digite outra categoria"
        />
      )}
      <Subtitle>Adicionar Banner:</Subtitle>
      <BannerInput
          type="file"
          placeholder="Banner"
          onChange={handleBannerChange}
        />
      <YoutubeUrlInput
        type="text"
        placeholder="ID do Vídeo-YouTube"
        value={youtubeUrl}
        onChange={(e) => setYoutubeUrl(e.target.value)}
      />
      {showSizeWarning && <p style={{ color: 'yellow' }}>O tamanho total das imagens não pode exceder 1MB!</p>}
      {showErrorMessage && <p style={{ color: 'red' }}>Por favor, preencha todos os campos!</p>}
      {showSuccessMessage && <p style={{ color: 'green' }}>Arquivo adicionado com sucesso!</p>}
      {imageError && <p style={{ color: 'red' }}>Por favor, preencha o campo da imagem</p>}
      <SubmitButton disabled={buttonDisabled} onClick={handleSubmit}>Enviar</SubmitButton>
    </Container>
  </motion.div>
  );
};

export default UploadPage;
