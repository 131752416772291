import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import BannersMenores from './BannersMenores';
import BannerFinal from './BannerFinal';
import Carrosel from './Carrosel';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Banner01 from '../../img/banner_01_desktop.jpg';
import Banner02 from '../../img/banner_01_mobile.jpg';
import Banner03 from '../../img/banner_02_desktop.jpg';
import Banner04 from '../../img/banner_02_mobile.jpg';
import Banner05 from '../../img/banner_03_desktop.jpg';
import Banner06 from '../../img/banner_03_mobile.jpg';
import { Link } from 'react-router-dom';



const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  height: 800px;
`;

const Division = styled.div`
  background: linear-gradient(to right, #ff20cd, #1294ff,#58ffb4,#1294ff,#ff20cd);
	background-size: 400% 400%;
	animation: gradient 6s ease infinite;
	height: 8px;

	@keyframes gradient {
		0% {
			background-position: 0% 50%;
		}
		50% {
			background-position: 100% 50%;
		}
		100% {
			background-position: 0% 50%;
		}
	}
`;


const Slide = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${({ active }) => (active ? '1' : '0')};
  transition: opacity 500ms ease-in-out;
  overflow-y: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const SlideContent = styled.div`
  position: absolute;
  left: 200px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  background: none;

  @media screen and (max-width: 1300px) {
    left: 50%;
    top: 10%;
    text-align: center;
    transform: translateX(-50%);
    text-shadow: black 0.1em 0.1em 0.2em;
  }
`;

const Title = styled.h1`
  width: 530px;
  font-size: 4.4rem;
  margin-bottom: 4px;
  background: none;
  line-height: 1;

  @media screen and (max-width: 1300px) {
    width: 300px;
    font-size: 2.5rem;
  }
`;

const Description = styled.p`
  width: 400px;
  font-size: 1.4rem;
  margin-bottom: 1rem;
  background: none;
  color: #c3c3c3;
  font-family: 'Inter';

  @media screen and (max-width: 1300px) {
    width: 290px;
    font-size: 1rem;
    color: #fff;
  }
`;

const Button = styled.button`
  background-color: #4491FA;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1.8rem;
  cursor: pointer;
  border-radius: 4px;

  :hover {
    filter: brightness(0.8);
  }

  @media screen and (max-width: 1440px) {
    padding: 10px 20px;
    font-size: 1rem;
  }
`;

const Indicators = styled.ul`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
  background: none;
`;

const Indicator = styled.li`
  width: 15px;
  height: 15px;
  background-color: ${({ active }) => (active ? '#4491FA' : 'gray')};
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #4491FA;
  }
`;

const Arrow = styled.div`
  position: absolute;
  top: 50%;
  margin: 0 2rem 0 2rem;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background-color: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  font-size: 24px;
  transition: background-color 0.3s ease-in-out;
  border-radius: 4px;

  &:hover {
    filter: brightness(0.8);
  }

  ${({ direction }) => (direction === 'left' ? 'left: 10px;' : 'right: 10px;')}
`;

const images = [
  {
    id:1,
    src: Banner01,
    srcMobile: Banner02,
    href: '/watercooler',
    buttonText: 'Ver mais!',
  },
  {
    id:2,
    src: Banner03,
    srcMobile: Banner04,
    href: '/fonte',
    buttonText: 'Ver mais !',
  },
  {
    id:3,
    src: Banner05,
    srcMobile:  Banner06,
    href: '/fan',
    buttonText: 'Ver mais!',
  },
];

const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [activeHref, setActiveHref] = useState(images[activeIndex].href);


  const { t } = useTranslation();

  useEffect(() => {
    setActiveHref(images[activeIndex].href);
  }, [activeIndex]);
  

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1400);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      goToNextSlide();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [activeIndex]);

  const goToPrevSlide = () => {
    const index = (activeIndex - 1 + images.length) % images.length;
    setActiveIndex(index);
  };

  const goToNextSlide = () => {
    const index = (activeIndex + 1) % images.length;
    setActiveIndex(index);
  };

  const goToSlide = (index) => {
    setActiveIndex(index);
  };
  
  return (
    
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Division></Division> 
      <CarouselContainer>
      {images.map((image, index) => (
      <Slide key={index} active={index === activeIndex}>
        <Link to={activeHref}>
          <Image src={isMobile ? image.srcMobile : image.src} alt="Banners" title='Banners'/>
          <SlideContent>
            <Title alt="Titulo">{t('title.banner.p')}</Title>
            <Description alt="Descrição">{t('description.banner.p')}</Description>
            <Button alt="Ver mais!" title="Ver mais!">{t('button.banner.p')}</Button>
          </SlideContent>
        </Link>
      </Slide>
        ))}
        <Indicators>
          {images.map((_, index) => (
            <Indicator
              key={index}
              active={index === activeIndex}
              onClick={() => goToSlide(index)}
              alt="Indicator"
            />
          ))}
        </Indicators>
        <Arrow direction="left" onClick={goToPrevSlide} color="#4491FA" alt="Voltar" title="Voltar">
          &lt;
        </Arrow>
        <Arrow direction="right" onClick={goToNextSlide} color="#4491FA" alt="Avançar" title="Avançar">
          &gt;
        </Arrow>
      </CarouselContainer>

      <BannersMenores alt="Banners"/>
      <Carrosel alt="Cards"/>
      <BannerFinal alt="Banners Final"/>
    </motion.div>
  );
};

export default Carousel;
