import React from 'react';
import styled from 'styled-components';
import iconInstagram from '../../img/icon/instagram.png';
import iconFacebook from '../../img/icon/facebook.png';
import iconYoutube from '../../img/icon/youtube.png';
import iconWhats from '../../img/icon/whatsapp_menor.png';
import { useTranslation } from 'react-i18next';

const FooterContainer = styled.footer`
 margin: 2rem 1rem 1rem 1rem;
 z-index: 1000;
`;

const FooterRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const FooterColumn = styled.div`
  flex-basis: 25%;
  padding: 10px;
`;

const FooterHeading = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

const FooterHeading2 = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: var(--global-azul);
`;

const FooterLink = styled.a`
  display: block;
  color: #c3c3c3;
  text-decoration: none;
  margin-bottom: 5px;
  font-size: 0.9rem;
  font-family: 'Inter';
  &:hover {
    text-decoration: underline;
  }
`;

const FooterT = styled.p`
  display: block;
  color: #c3c3c3;
  text-decoration: none;
  margin-bottom: 5px;
  font-size: 0.9rem;
  font-family: 'Inter';
`;

const FooterImg = styled.a`
  width: 35px;
  height: 35px;
  display: inline;
  color: #c3c3c3;
  text-decoration: none;
  margin-bottom: 5px;
  font-family: 'Inter';
  padding-right: 0.5rem;
  &:hover {
    filter: brightness(0.8);
  }
`;

const FooterLine = styled.div`
  height: 1px;
  background-color: #161616;
  margin: 20px 0;
`;

const FooterText = styled.p`
  text-align: center;
  font-size: 0.9rem;
  color: #c3c3c3;
  font-family: 'Inter';
`;

const Footer = () => {
  const { t } = useTranslation();
  return (
    <FooterContainer>
      <FooterRow>
        <FooterColumn>
          <FooterHeading alt="Comprar">{t('footer.title.1')}</FooterHeading>
          <FooterLink href="/ondecomprar" alt="Onde Comprar" title="Onde Comprar">{t('footer.whetobuy')}</FooterLink>
        </FooterColumn>
        <FooterColumn>
          <FooterHeading alt="Rise">RISE</FooterHeading>
          <FooterLink href="/sobre" alt="Sobre" title="Sobre">{t('footer.about')}</FooterLink>
          <FooterT>{t('footer.privacy')}</FooterT >
          <FooterT >{t('footer.terms')}</FooterT >
        </FooterColumn>
        <FooterColumn>
          <FooterHeading alt="Suporte">{t('footer.title.3')}</FooterHeading>
          <FooterLink href="/ticketrma" alt="Ticket Garantia" title="Ticket Garantia">{t('footer.ticketrmaT')}</FooterLink>
          <FooterLink href="/contato" alt="Contato" title="Contato">{t('contact')}</FooterLink>
          {/*<FooterLink href="/revenda" alt="Revenda Rise" title="Revenda Rise">{t('footer.reseller')}</FooterLink>*/}
        </FooterColumn>
        <FooterColumn>
          <FooterHeading2 alt="Redes Sociais">{t('footer.title.4')}</FooterHeading2>
          <FooterImg target="_blank" href="https://www.instagram.com/rise.mode/"><img src={iconInstagram} alt='Instagram' title='Instagram'/></FooterImg>
          <FooterImg target="_blank" href="http://www.facebook.com/risemode.com.br"><img src={iconFacebook} alt='Facebook' title='Facebook'/></FooterImg>
          <FooterImg target="_blank" href="https://www.youtube.com/risemode"><img src={iconYoutube} alt='Youtube' title='Youtube'/></FooterImg>
          <FooterImg target="_blank" href="https://api.whatsapp.com/send?phone=5511930473745"><img src={iconWhats} alt='Whatsapp' title='Whatsapp'/></FooterImg>  
        </FooterColumn>
      </FooterRow>
      <FooterLine />
      <FooterText alt="Copyright Rise Mode">© 2023 RISE. Todos os direitos reservados</FooterText>
    </FooterContainer>
  );
};

export default Footer;
