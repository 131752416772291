import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

const firebaseConfig = {
    apiKey: "AIzaSyBliTfTPbSU4caIdCdMh_Xs5f_-95ISq28",
    authDomain: "produtos-list.firebaseapp.com",
    databaseURL: "https://produtos-list-default-rtdb.firebaseio.com",
    projectId: "produtos-list",
    storageBucket: "produtos-list.appspot.com",
    messagingSenderId: "308827988350",
    appId: "1:308827988350:web:a635c2b1508599bfff132f"
  };

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

firebase.database().goOffline();
firebase.database().goOnline();

export const fetchProducts = async () => {
  try {
    const snapshot = await firebase
      .database()
      .ref('products')
      .once('value');

    const productsData = snapshot.val();
    const productsArray = Object.keys(productsData).map((key) => ({
      id: key,
      ...productsData[key],
    }));

    return productsArray;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
