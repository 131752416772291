import React, { useState } from 'react';
import styled from 'styled-components';
import local from '../../img/revenda.gif';
import axios from 'axios';
import { motion } from 'framer-motion'; 
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const FormWrapper = styled.div`
  background-color: #161616;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  text-align: center;
  padding: 60px;
  border-radius: 10px 0px 0px 10px;
  height: 720px;

  @media (min-width: 1000px) {
    margin-bottom: 0;
    align-items: flex-start;
  }
`;

const Label = styled.label`
  color: #CCC6C6;
  margin-bottom: 5px;
  background: none;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  font-size: 1rem;
  font-family:'Inter', sans-serif;
`;

const Input = styled.input`
  width: 300px;
  height: 60px;
  padding: 10px;
  background: #343333;
  border-radius: 4px;
  border: 1px solid #8D8D8D;
  font-family:'Inter', sans-serif;
  margin-bottom: 5px;
`;

const Button = styled.button`
  padding: 15px 60px;
  background-color: #4491FA;
  color: #ffff;
  cursor: pointer;
  border: none;
  font-size: 1.1rem;
  border-radius: 4px;
  margin-top: 1rem;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  :hover{
    filter: brightness(0.8);
  }

`;

const Image = styled.img`
  width: 100%;
  max-width: 720px;
  border-radius: 0px 10px 10px 0px;

  @media (max-width: 1000px) {
    display: none;
  }
`;

const Title = styled.h1`
  background: none;
  margin-bottom: 0.5rem;
`;

const Newsletter = () => {
  const [nome, setNome] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [emailn, setEmailn] = useState('');
  const [representante, setRepresentante] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  
  const enviarEmail = () => {

    setButtonDisabled(true);
    if (!nome || !cnpj || !representante || !whatsapp || !emailn) {
      setShowErrorMessage(true);
      setTimeout(() => {
        setShowErrorMessage(false);
        setButtonDisabled(false);
      }, 3000);
      return;
    }

    const data = {
      nome: nome,
      cnpj: cnpj,
      representante: representante,
      whatsapp: whatsapp
    };

    const commentBody = `
    CNPJ: ${cnpj}
    representante: ${representante}
  `;

    axios
    .post('https://rmarisemode.zendesk.com/api/v2/tickets.json', {
        ticket: {
        subject: 'Revenda Rise Mode - ' + nome,
        comment: {
          body: commentBody + '\nEnviado via site: Rise Mode'
        },
        requester: {
          name: nome,
          email: emailn,
          phone: whatsapp,
        },
        tags: ['risemode_brasil', 'site', 'resale'], // Adicione as tags desejadas aqui
        type: 'task', // Adicione o tipo de ticket desejado aqui
        via: {
          channel: 'web' // Define o canal como 'web' para indicar que o ticket veio do seu site
        },
        brand_id: 16126986193819, // Adicione o brand_id desejado aqui
        group_id: 16713158837147, // Adicione o group_id desejado aqui
      }
    }, {
      auth: {
        username: 'contato@rmaesolutions.com.br/token',
        password: '0PXjBTDUesWLqgI7PmXhNrHgUye19CiJwT65Yk4Y'
      }
    })
    .then(response => {
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 5000);
      limparCampos();
    })
    .catch(error => {
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 5000);
      limparCampos();
    })
    .then(() => {
      setButtonDisabled(false);
    });
  };

  const limparCampos = () => {
    setNome('');
    setEmailn('');
    setCnpj('');
    setRepresentante('');
    setWhatsapp('');
  };

  const handleWhatsappChange = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue.replace(/[^\d()+-]/g, '');
    setWhatsapp(formattedValue);
  };

  const { t } = useTranslation();

  return (
    <motion.div
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.3 }}
    >
    <Container>
      <FormWrapper>
        <Title alt="Revenda Rise Mode">{t('title.reseller')}</Title>
        <Label alt="Nome">{t('namestore.reseller')}</Label>
        <Input type="text" placeholder="Rise" value={nome} onChange={e => setNome(e.target.value)} />
        <Label alt="E-mail">E-mail</Label>
        <Input type="email" placeholder="rise@email.com" value={emailn} onChange={e => setEmailn(e.target.value)} />
        <Label alt="CNPJ">CNPJ</Label>
        <Input type="text" placeholder="00.000.000/0001-00" value={cnpj} onChange={e => setCnpj(e.target.value)} />
        <Label alt="Representante Da Loja">{t('storerepresentative')}</Label>
        <Input type="text" placeholder="João da Silva" value={representante} onChange={e => setRepresentante(e.target.value)} />
        <Label alt="Celular">{t('cell.contact')}</Label>
        <Input placeholder="(99) 999999-99999" value={whatsapp} onChange={handleWhatsappChange} />
        {showErrorMessage && <p style={{ color: 'red' }}>{t('alert.campos')}</p>}
        {showSuccessMessage && <p style={{ color: 'green' }}>{t('sucess.contact')}</p>}
        <Button disabled={buttonDisabled} onClick={enviarEmail} alt="Enviar" title="Enviar">{t('button.contact')}</Button>
      </FormWrapper>
      <Image src={local} alt="Imagem" title="Revenda"/>
    </Container>
    </motion.div>
  );
};

export default Newsletter;
