import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import Loading from '../../Loading/Loading';
import firebase from 'firebase/compat/app';
import { fetchProducts } from '../../List.js/List';
import UsaFlag from '../../../img/icon/usaIcon.png';
import KabumIcon from '../../../img/icon/kabumIcon.png';
import Lojarise from '../../../img/icon/riseIcon.png';
import ProductDescription from '../ProductDescription/ProductDescription.js';
import 'firebase/compat/database';
import YouTube from 'react-youtube';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import CardPage from '../../Produtos/Memoria/CardPage';

const firebaseConfig = {
    apiKey: "AIzaSyBliTfTPbSU4caIdCdMh_Xs5f_-95ISq28",
    authDomain: "produtos-list.firebaseapp.com",
    databaseURL: "https://produtos-list-default-rtdb.firebaseio.com",
    projectId: "produtos-list",
    storageBucket: "produtos-list.appspot.com",
    messagingSenderId: "308827988350",
    appId: "1:308827988350:web:a635c2b1508599bfff132f"
  };

// Inicialize o app do Firebase
firebase.initializeApp(firebaseConfig);


const Titlebuy = styled.h1`
  font-size: 3.5rem;
  text-align: center;
`;


const Galeria = styled.div`
  text-align: center;

  @media screen and (max-width: 1031px) {
    margin: 2rem 1rem 1rem 1rem;
  }

  h1 {
    font-size: 2rem;
  }

  div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    
    @media (max-width: 1000px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    }
  }
`;

const Card = styled.div`
  border: 1px solid #c3c3c3;
  border-radius: 4px;
  padding: 1rem;
  margin: 1rem;
  transition: 200ms;
  width: 300px;
  height: 330px;
  text-align: center;
  display: flex;
  flex-direction: column;
  background: white;
  cursor: pointer;
  overflow: hidden;

  :hover {
    transform: scale(1.1);
  }

`;

const CardImage = styled.img`
  display: block;
  width: 200px;
  height: 200px;
  margin: 0 auto;
`;

const CardTitle = styled.h2`
  margin-top: 10px;
  color: black;
  font-size: 1.5rem;
  background: none;
`;


const Imagem = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  margin-top: 2rem;
  padding: 1rem;
  @media screen and (max-width: 1031px) {
      flex-direction: column;
      margin-top: 8rem;
  }

  img{
    width: 100%;
    max-width: 500px;
    margin-right: 2rem;
  }

  div{
    margin-top: 3rem;

  }

  h1{
    width: 460px;
    color: white;
    text-align: left;
    font-size: 3rem;
    line-height: 1;
    margin-bottom: 0.3rem;
    @media screen and (max-width: 1031px) {
      width: 300px;
      font-size: 2rem;
  }
  }

  h2{
    width: 450px;
    font-family: "Inter";
    color: #c3c3c3;
    font-size: 1.3rem;
    text-align: left;
    @media screen and (max-width: 1031px) {
      width: 300px;
      font-size: 1rem;
  }
  }

  button{
    margin-top: 5rem;
    margin-bottom: 1rem;
    width: 200px;
    height: 50px;
    background: #4491FA;
    border: none;
    border-radius: 4px;
    font-size: 1.5rem;
    cursor: pointer;

    :hover{
    filter: brightness(0.8);
    }

    @media screen and (max-width: 1031px) {
      width: 150px;
      font-size: 1.4rem;
      height: 50px;
      margin-top: 2rem;
  }
  }
`;

const Division = styled.div`
  background: linear-gradient(to right, #ff20cd, #1294ff,#58ffb4,#1294ff,#ff20cd);
	background-size: 400% 400%;
	animation: gradient 6s ease infinite;
	height: 8px;

	@keyframes gradient {
		0% {
			background-position: 0% 50%;
		}
		50% {
			background-position: 100% 50%;
		}
		100% {
			background-position: 0% 50%;
		}
	}
`;

const BannerContainer = styled.div`
  position: relative;
  text-align: center;
  margin-bottom: 10px;

  img{
    width: 100%;
    max-width: 1920px;
  }

  div{
    position: absolute;
    top: 14%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: none;

    @media screen and (max-width: 1000px) {
      top: 150% !important;
  }  

  @media screen and (max-width: 1024px) {
      top: 120%;
  }

  @media screen and (min-width: 1234px) {
      top: 14%;
  }
  }

`;

const BannerTitle = styled.h1`
  color: white;
  font-size: 40px;
  background: none;
  margin-bottom: 4px;
  @media screen and (max-width: 1031px) {
    font-size: 25px;
  }
`;

const BannerText = styled.p`
  color: #c3c3c3;
  font-size: 18px;
  background: none;
  font-family: "Inter";

  @media screen and (max-width: 1031px) {
    font-size: 13px;
  }
`;

const Botoes = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem ;
  
  a{
    padding: 10px;
    margin: 8px;
    width: 200px;
    height: 50px;
    background: #4491FA;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: center;
    gap: 10px;
    
    :hover{
    filter: brightness(0.8);
    }
  }

  img{
    width: 30px;
    height: 30px;
    background: none;
  }

  p{
    font-size: 1.5rem;
    text-align: center;
    background: none;
  }
`;

const Principal = styled.div`
  background-color: black;
  padding: 1rem;
  border-radius: 4px;
  width: 100%;
  max-width: 1200px;

  position: relative;
  margin: 0 auto;
`;

const ControleAll = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Conteudo = styled.div`
  width: 800px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background: white;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 8px;

  @media screen and (max-width: 1000px) {
    width: 420px;
  }

  img {
    width: 350px;
    margin: 4px;

    @media screen and (max-width: 1000px) {
      width: 150px;
      margin: 4px;
    }
  }
`;

const TituloModal = styled.h1`
    background: none;
    color: white;
    text-align: center;
`;

const BannerProduto = styled.img`
  width: 100%;
  max-width: 800px;
  margin: 1rem;
`;

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
`;

const ProductPage = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [product, setProduct] = useState(null);
    const [productId, setProductId] = useState('');
    const [products, setProducts] = useState([]);
    const [page, setPage] = useState([]);
    const { id } = useParams();
  
    useEffect(() => {
      if (id) {
        openModal(id);
      }
    }, [id]);
    

    useEffect(() => {
      const fetchData = async () => {
        try {
          // Obtenha o caminho da URL completo
          const path = window.location.pathname;
  
          // Divida o caminho da URL pelo caractere da barra "/"
          const pathSegments = path.split('/');
  
          // Obtenha o último segmento que representa o ID do produto
          const id = pathSegments[pathSegments.length - 1];
  
          // Atualize o estado do ID do produto
          setProductId(id);
  
          const allProductsData = await fetchProducts(); // Busca todos os produtos
          const productData = allProductsData.find(product => product.pageURL === id);
  
          if (productData) {
            setProduct({
              images: productData.images,
              banner: productData.banner,
              name: productData.name,
              description: productData.description,
              youtubeUrl: productData.youtubeUrl,
              headerTitle: productData.headerTitle,
              headerDescription: productData.headerDescription,
              headerBannerURL: productData.headerBannerURL,
              cardTitle: productData.cardTitle,
              cardDescription: productData.cardDescription,
              cardImageURL: productData.cardImageURL
            });
          }
  
          setIsLoading(false);
        } catch (error) {
          console.error(error);
          setIsLoading(false);
        }
      };
      fetchData();
    }, []);
    
    const navigate = useNavigate();
    
    const openModal = (product) => {
      // Gerando a URL com o nome do produto
      const modalUrl = `${product.productId}`;
  
       window.history.replaceState(null, '', modalUrl);
  
      setSelectedProduct(product);
    };
  
    
      const closeModal = () => {
        setSelectedProduct(null);
      };
    
      const handleOverlayClick = (event) => {
        if (event.target === event.currentTarget) {
          closeModal();
        }
      };

    const WithLoading = ({ isLoading, children }) => {
      return <>{isLoading ? <Loading /> : <>{children}</>}</>;
    };

    const { t } = useTranslation();
  
    return (
      <WithLoading isLoading={isLoading}>
        {product && (
          <Principal>
              <Division></Division>
          <BannerContainer>
            <div>
              <BannerTitle>{product.headerTitle}</BannerTitle>
              <BannerText>
              {product.headerDescription}
              </BannerText>
            </div>
            <img src={product.headerBannerURL} />
          </BannerContainer>
          <Imagem>
            <img src={product.cardImageURL} />
            <div>
              <h1>{product.cardTitle}</h1>
              <p>{product.cardDescription}</p>
            </div>
          </Imagem>
          <Botoes>
                <a target='_blank' href="https://www.amazon.com/s?i=merchant-items&me=A2Z190E8OU3Q34&marketplaceID=ATVPDKIKX0DER&qid=1682472974&ref=sr_pg_2">
                <img src={UsaFlag} />  
                <p>RISE USA</p>
                </a>

                <a target='_blank' href="http://risemode.commercesuite.com.br">
                <img src={Lojarise} />  
                <p>LOJA RISE</p>
                </a>

                <a target='_blank' href="https://www.kabum.com.br/busca/rise-mode">
                <img src={KabumIcon} />  
                <p>KABUM</p>
                </a>
          </Botoes>
          <CardPage/>
          </Principal>
        )}
      </WithLoading>
    );
  };
  
  export default ProductPage;
