import Venon01 from '../../../img/Produtos/Suporte_headset/Venon/venon.jpg';
import VenonBanner from '../../../img/Produtos/Suporte_headset/Venon/venon_banner.jpg';

import Alien01 from '../../../img/Produtos/Suporte_headset/Alien/alien_01.jpg';
import Alien02 from '../../../img/Produtos/Suporte_headset/Alien/alien_02.jpg';
import AlienBanner from '../../../img/Produtos/Suporte_headset/Alien/alien_banner.jpg';

import Z01 from '../../../img/Produtos/Suporte_headset/Z/z_01.jpg';
import Z02 from '../../../img/Produtos/Suporte_headset/Z/z_02.jpg';
import ZBanner from '../../../img/Produtos/Suporte_headset/Z/z_banner.jpg';

import VenonRGB01 from '../../../img/Produtos/Suporte_headset/Venon_RGB/VenonRGB_01.jpg';
import VenonRGB02 from '../../../img/Produtos/Suporte_headset/Venon_RGB/VenonRGB_02.jpg';
import VenonRGB_Banner from '../../../img/Produtos/Suporte_headset/Venon_RGB/banner.jpg';

const products = [
  {
    id: 1,
    name: 'VENON V3',
    category: 'suporteheadset',
    descriptionP: "PROTEGE SEU HEADSET COM ESTILO!<br><br>" +
    "A LINHA DE SUPORTE HEADSET RISE MODE GAMER além de proteger seu headset para os momentos que você não está usando, dá um estilo único e arrojado para o ambiente do seu PC.<br><br>" +
    "Projetado para suportar e guardar qualquer Headset ou fone de ouvido, o suporte é produzido com dupla camada de acrílico da mais alta qualidade, proporcionando robustez e durabilidade.<br><br>" +
    "Por ser um suporte com uma base firme e regular, ele se torna estável na mesa, evitando a queda do fone caso aconteça algum impacto. Além disso, evita que seu headset seja riscado quando fica solto na mesa. E, é claro, conta sempre com o acabamento profissional cortado a laser que só a RISE MODE oferece.<br><br>" +
    "Especificações: Marca: RISE MODE / Modelo: SUPORTE HEADSET RISE MODE GAMER - VENON V3 / Part number: RM-VN-05-BR / Material: ACRÍLICO / Acabamento: CORTADO A LASER / Suporta: 1 HEADSET / Dimensões do produto: 26,8CM X 15,5CM X 14CM / Dimensões da caixa: 28CM X 15,5CM X 14CM / Peso: 185 GRAMAS (BRUTO SEM EMBALAGEM) / Peso com caixa: 314 GRAMAS (BRUTO COM EMBALAGEM) / EAN: 7895099518825" ,
      
    descriptionE:
    "PROTECT YOUR HEADSET WITH STYLE!<br><br>" +
    "The RISE MODE GAMER HEADSET SUPPORT LINE not only protects your headset when you're not using it but also adds a unique and bold style to your PC environment.<br><br>" +
    "Designed to support and store any headset or headphones, the support is made with a double layer of high-quality acrylic, providing robustness and durability.<br><br>" +
    "Being a support with a firm and regular base, it remains stable on the desk, preventing the headset from falling in case of any impact. It also avoids scratching your headset when it's left loose on the desk, and of course, it always features the professional laser-cut finish that only RISE MODE offers.<br><br>" +
    "Specifications: Brand: RISE MODE / Model: RISE MODE GAMER HEADSET SUPPORT - VENON V3 / Part number: RM-VN-05-BR / Material: ACRYLIC / Finish: LASER-CUT / Supports: 1 HEADSET / Product dimensions: 26.8CM X 15.5CM X 14CM / Box dimensions: 28CM X 15.5CM X 14CM / Weight: 185 GRAMS (GROSS WEIGHT WITHOUT PACKAGING) / Weight with box: 314 GRAMS (GROSS WEIGHT WITH PACKAGING) / EAN: 7895099518825",
  
    productId: 'suporte-headset-venon-v3',
    banner: VenonBanner,
    youtubeUrl: 'k5AjiHKgLpc',
    images: [Venon01],
    pageURL: 'venonv3',
  },
  {
    id: 2,
    name: 'ALIEN',
    category: 'suporteheadset',
    descriptionP: 'Suporte de Headset Rise Gaming é extremamente atrativo e conta com um design Gamer, projetado para suportar e guardar qualquer Headset ou fone de ouvido após o uso, o suporte é produzido com dupla camada de acrílico da mais alta qualidade, proporcionando robustez e durabilidade. Por ser um suporte com uma base firme e regular ele se torna estável na mesa evitando a queda do fone caso aconteça algum impacto, evitando também que seu headset seja riscado quando fica solto na mesa, e é claro contando sempre com o acabamento profissional cortado a laser que so a RISE oferece. Especificações: Marca: RISE MODE / Material: ACRÍLICO / Acabamento: CORTADO A LASER / Suporta: 1 HEADSET | Informações adicionais: Camada dupla de acrílico / Base reforçada / Alta durabilidade / Design Gamer / Video: https://goo.gl/XXzRhL',
    descriptionE: "The Rise Gaming Headset Stand is highly appealing and features a gamer design. It is designed to support and store any headset or headphones after use. The stand is made with a double layer of high-quality acrylic, providing robustness and durability. With its firm and regular base, it remains stable on the desk, preventing the headset from falling in case of any impact. It also avoids scratching your headset when it's left loose on the desk. Additionally, it always comes with the professional laser-cut finish that only Rise offers.Specifications: Brand: Rise Mode / Material: Acrylic / Finish: Laser-cut / Supports: 1 Headset | Additional Information: Double layer of acrylic / Reinforced base / High durability / Gamer design / Video: https://goo.gl/XXzRhL",
    productId: 'suporte-headset-alien',
    banner: AlienBanner,
    youtubeUrl: 'v0ccZLKABt4',
    images: [Alien01, Alien02],
    pageURL: 'alien',
  },
  {
    id: 3,
    name: 'Z',
    category: 'suporteheadset',
    descriptionP: "A LINHA DE SUPORTE HEADSET RISE MODE GAMER além de proteger seu headset para os momentos que você não está usando, dá um estilo único e arrojado para o ambiente do seu PC.<br><br>" +
    "Projetado para suportar e guardar qualquer Headset ou fone de ouvido, o suporte é produzido com dupla camada de acrílico da mais alta qualidade, proporcionando robustez e durabilidade.<br><br>" +
    "Por ser um suporte com uma base firme e regular, ele se torna estável na mesa, evitando a queda do fone caso aconteça algum impacto. Além disso, evita que seu headset seja riscado quando fica solto na mesa. E, é claro, conta sempre com o acabamento profissional cortado a laser que só a RISE MODE oferece.<br><br>" +
    "Especificações: Marca: Rise Mode Gamer / Modelo: Suporte de Headset Rise Mode Z Vermelho / Part number: RM-VN-06-BR / Material: Acrílico / Acabamento: Cortado a laser / Suporta: 1 Headset / Dimensões do produto: 26,8 x 15,5 x 14 cm / Peso com caixa: 330 GRAMAS (BRUTO COM EMBALAGEM)",
    
    descriptionE: "The RISE MODE GAMER HEADSET SUPPORT LINE not only protects your headset when you're not using it but also adds a unique and bold style to your PC environment.<br><br>" +
    "Designed to support and store any headset or headphones, the support is made with a double layer of high-quality acrylic, providing robustness and durability.<br><br>" +
    "Being a support with a firm and regular base, it remains stable on the desk, preventing the headset from falling in case of any impact. Additionally, it prevents your headset from being scratched when left loose on the desk. And of course, it always features the professional laser-cut finish that only RISE MODE offers.<br><br>" +
    "Specifications: Brand: Rise Mode Gamer / Model: Red Rise Mode Z Headset Stand / Part number: RM-VN-06-BR / Material: Acrylic / Finish: Laser-cut / Supports: 1 Headset / Product dimensions: 26.8 x 15.5 x 14 cm / Weight with box: 330 GRAMS (GROSS WEIGHT WITH PACKAGING)",

    productId: 'suporte-headset-z',
    banner: ZBanner,
    youtubeUrl: 'zAfB5m6oXmg',
    images: [Z01, Z02],
    pageURL: 'suporte-headset-z',
  },
  {
    id: 4,
    name: 'Venon RGB',
    category: 'suporteheadset',
    descriptionP: "Suporte de Headset Rise Gaming é extremamente atrativo e conta com um design Gamer, projetado para suportar e guardar qualquer Headset ou fone de ouvido após o uso. O suporte é produzido com leds RGB e com dupla camada de acrílico da mais alta qualidade, proporcionando robustez e durabilidade.<br><br>" +
    "Por ser um suporte com uma base firme e regular ele se torna estável na mesa evitando a queda do fone caso aconteça algum impacto, evitando também que seu headset seja riscado quando fica solto na mesa, e é claro contando sempre com o acabamento profissional cortado a laser que só a RISE oferece.<br><br>" +
    "Especificações: Marca: RISE / Modelo: RISE MODE VENON RGB BLACK / Part number: RM-VN-03-FB / Cor do led: RGB / Material: ACRÍLICO / Acabamento: CORTADO A LASER / Suporta: 1 HEADSET / Dimensões do produto: 26,8CM X 15,5CM X 14CM / Dimensões da caixa: 28CM X 15,5CM X 14CM / Peso: 185 GRAMAS (BRUTO SEM EMBALAGEM) / Peso com caixa: 314 GRAMAS (BRUTO COM EMBALAGEM)",
    
    descriptionE: "The Rise Gaming Headset Stand is extremely attractive and features a Gamer design. It is designed to support and store any headset or headphones after use. The stand is made with RGB LEDs and a double layer of high-quality acrylic, providing robustness and durability.<br><br>" +
    "With its firm and regular base, it remains stable on the desk, preventing the headset from falling in case of any impact. It also avoids scratching your headset when it's left loose on the desk, and of course, it always features the professional laser-cut finish that only Rise offers.<br><br>" +
    "Specifications: Brand: RISE / Model: RISE MODE VENON RGB BLACK / Part number: RM-VN-03-FB / LED Color: RGB / Material: ACRYLIC / Finish: LASER-CUT / Supports: 1 HEADSET / Product dimensions: 26.8CM X 15.5CM X 14CM / Box dimensions: 28CM X 15.5CM X 14CM / Weight: 185 GRAMS (GROSS WEIGHT WITHOUT PACKAGING) / Weight with box: 314 GRAMS (GROSS WEIGHT WITH PACKAGING)",

    productId: 'suporte-headset-venonrgb',
    banner: VenonRGB_Banner,
    youtubeUrl: 'xGT7O-dBSAo',
    images: [VenonRGB01, VenonRGB02],
    pageURL: 'venonrgb',
  },
];

export default products;
