import React from 'react';
import styled from 'styled-components';
import imagem1 from '../../img/BANNER CADEIRA.png';
import imagem2 from '../../img/BANNER GABINETE.png';
import imagem3 from '../../img/BANNER WATER COOLER.png';
import imagem4 from '../../img/BANNER FONTE.png';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 4px;
`;

const ImageWrapper = styled.a`
  width: 50%;
  padding: 5px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  background: none;

  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  background: none;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.1);
  }
`;

const Button = styled.button`
  position: absolute;
  bottom: 40px;
  left: 40px;
  text-align: center;
  width: 150px;
  height: 50px;
  background-color: #4491fa;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  font-size: 1.5rem;

  :hover {
    filter: brightness(0.8);
  }

  @media screen and (max-width: 900px) {
    font-size: 1rem;
    width: 80px;
    height: 30px;
  }
`;

const CardContent = styled.div`
  pointer-events: none;
  position: absolute;
  left: 50%;
  top: 18%;
  transform: translate(-50%, -50%);
  color: #fff;
  background: none;

  @media screen and (max-width: 900px) {
    top: 19%;
  }
`;

const Title = styled.h1`
  margin-bottom: 10px;
  font-size: 3rem;
  text-align: center;
  background: none;
  @media screen and (max-width: 1350px) {
    font-size: 2rem;
    margin-bottom: 2px;
  }
`;

const Description = styled.p`
  width: 580px;
  font-size: 1.1rem;
  text-align: center;
  background: none;
  font-family: 'Inter';

  @media screen and (max-width: 1350px) {
    font-size: 0.7rem;
    width: 300px;
    font-weight: 800;
  }
`;

const BannersMenores = () => {
  const { t } = useTranslation();

  const images = [
    {
      src: imagem1,
      link: '/cadeiragamer',
      title: 'Cadeira Gamer',
      description: 'Supere seus limites com conforto e ergonomia excepcionais.',
    },
    {
      src: imagem2,
      link: '/gabinete',
      title: 'Gabinete',
      description: 'Explore os gabinetes Rise e dê vida ao seu PC com estilo e funcionalidade incomparáveis.',
    },
    {
      src: imagem3,
      link: '/watercooler',
      title: 'Water Cooler',
      description: 'Desempenho térmico e silêncio em perfeita harmonia.',
    },
    {
      src: imagem4,
      link: '/fonte',
      title: 'Fonte de Alimentação',
      description: 'A fonte de alimentação Rise Mode fornece energia estável e eficiente para alimentar seus dispositivos com confiabilidade e desempenho excepcionais.',
    },
  ];

  return (
    <Container>
      <ImageWrapper href={images[0].link} rel="noopener noreferrer">
        <Image src={images[0].src} alt="Cadeira Gamer" title="Cadeira Gamer"/>
        <CardContent>
          <Title alt="Titulo">{t("banner.title.gamerchar")}</Title>
          <Description alt="Descrição">{t("banner.description.gamerchar")}</Description>
        </CardContent>
        <Button alt="Ver mais!" title="Ver mais!" >{t('button.banner.p')}</Button>
      </ImageWrapper>

      <ImageWrapper href={images[1].link} rel="noopener noreferrer">
        <Image src={images[1].src} alt="Gabinete" title="Gabinete"/>
        <CardContent>
          <Title alt="Titulo">{t("banner.title.computercase")}</Title>
          <Description alt="Descrição">{t("banner.description.computercase")}</Description>
        </CardContent>
        <Button alt="Ver mais!" title="Ver mais!">{t('button.banner.p')}</Button>
      </ImageWrapper>

      <ImageWrapper href={images[2].link} rel="noopener noreferrer">
        <Image src={images[2].src} alt="Water Cooler" title="Water Cooler"/>
        <CardContent>
          <Title alt="Titulo">{t("banner.title.watercooler")}</Title>
          <Description alt="Descrição">{t("banner.description.watercooler")}</Description>
        </CardContent>
        <Button alt="Ver mais!" title="Ver mais!">{t('button.banner.p')}</Button>
      </ImageWrapper>

      <ImageWrapper href={images[3].link} rel="noopener noreferrer">
        <Image src={images[3].src} alt="Fonte" title="Fonte" />
        <CardContent>
          <Title alt="Titulo">{t("banner.title.powersupply")}</Title>
          <Description alt="Descrição">{t("banner.description.powersupply")}</Description>
        </CardContent>
        <Button alt="Ver mais!" title="Ver mais!">{t('button.banner.p')}</Button>
      </ImageWrapper>
    </Container>
  );
};

export default BannersMenores;
