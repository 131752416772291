import G1BlackWhite_01 from '../../../img/Produtos/teclado/g1-black-white-teclado/1.jpg';
import G1BlackWhite_02 from '../../../img/Produtos/teclado/g1-black-white-teclado/2.jpg';
import G1BlackWhite_03 from '../../../img/Produtos/teclado/g1-black-white-teclado/3.jpg';
import G1BlackWhite_banner from '../../../img/Produtos/teclado/g1-black-white-teclado/banner.png';

import G1Black_01 from '../../../img/Produtos/teclado/g1-full-black-teclado/1.jpg';
import G1Black_02 from '../../../img/Produtos/teclado/g1-full-black-teclado/2.jpg';
import G1Black_03 from '../../../img/Produtos/teclado/g1-full-black-teclado/3.jpg';
import G1Black_banner from '../../../img/Produtos/teclado/g1-full-black-teclado/banner.png';

import G1White_01 from '../../../img/Produtos/teclado/g1-full-white-teclado/1.jpg';
import G1White_02 from '../../../img/Produtos/teclado/g1-full-white-teclado/2.jpg';
import G1White_03 from '../../../img/Produtos/teclado/g1-full-white-teclado/3.jpg';
import G1White_banner from '../../../img/Produtos/teclado/g1-full-white-teclado/banner.png';


import Office01_01 from '../../../img/Produtos/teclado/office-of-01/1.jpg';
import Office01_02 from '../../../img/Produtos/teclado/office-of-01/2.jpg';
import Office01_03 from '../../../img/Produtos/teclado/office-of-01/3.jpg';
import Office01_04 from '../../../img/Produtos/teclado/office-of-01/4.jpg';
import Office01_05 from '../../../img/Produtos/teclado/office-of-01/5.jpg';
import Office01_banner from '../../../img/Produtos/teclado/office-of-01/banner.png';


import Office02_01 from '../../../img/Produtos/teclado/office-of-02/1.jpg';
import Office02_02 from '../../../img/Produtos/teclado/office-of-02/2.jpg';
import Office02_03 from '../../../img/Produtos/teclado/office-of-02/3.jpg';
import Office02_04 from '../../../img/Produtos/teclado/office-of-02/4.jpg';
import Office02_05 from '../../../img/Produtos/teclado/office-of-02/5.jpg';
import Office02_banner from '../../../img/Produtos/teclado/office-of-02/banner.png';


const products = [
  {
    id: 1,
    name: 'G1 Black and White - Led RGB Rainbow',
    category: 'Teclado',
    descriptionP: `Part Number: RM-TG-01-BW<br>
    Tipo: Com fio<br>
    Material: ABS<br>
    Conector: USB<br>
    Cor: Preto e Branco<br>
    Versão do teclado: Disponível em várias línguas<br>
    Longevidade do teclado: 10 milhões de horas<br>
    Cor do LED: RGB Rainbow<br>
    Dimensões do teclado: 43,2 x 13,1 x 3,2 cm<br>`,

    descriptionE: `Part Number: RM-TG-01-BW<br>
    Type: Wired<br>
    Material: ABS<br>
    Connector: USB<br>
    Color: Black and White<br>
    Keyboard Layout: Available in multiple languages<br>
    Keyboard Lifespan: 10 million hours<br>
    LED Color: RGB Rainbow<br>
    Keyboard Dimensions: 43.2 x 13.1 x 3.2 cm<br>`,
      
    productId: 'g1-black-white-teclado',
    banner: G1BlackWhite_banner,
    youtubeUrl: 'efnkH_HbVhs?si=7aIyQaixAQdkMZNJ',
    images: [G1BlackWhite_01, G1BlackWhite_02, G1BlackWhite_03],
    pageURL: 'g1-black-white-teclado',
  },
  {
    id: 2,
    name: 'G1 Full Black - Led RGB Rainbow',
    category: 'Teclado',
    descriptionP: `Part Number: RM-TG-01-FB<br>
    Tipo: Com fio<br>
    Material: ABS<br>
    Conector: USB<br>
    Cor: Preto<br>
    Versão do teclado: Disponível em várias línguas<br>
    Longevidade do teclado: 10 milhões de horas<br>
    Cor do Led: RGB Rainbow<br>
    Dimensões do teclado: 43,2 x 13,1 x 3,2 cm<br>`,

    descriptionE: `Part Number: RM-TG-01-FB<br>
    Type: Wired<br>
    Material: ABS<br>
    Connector: USB<br>
    Color: Black<br>
    Keyboard Layout: Available in multiple languages<br>
    Keyboard Lifespan: 10 million hours<br>
    LED Color: RGB Rainbow<br>
    Keyboard Dimensions: 43.2 x 13.1 x 3.2 cm<br>`,
      
    productId: 'g1-full-black-teclado',
    banner: G1Black_banner,
    youtubeUrl: 'UQVZVhI2Zcw?si=JuoEYi7giR_mp5tY',
    images: [G1Black_01, G1Black_02, G1Black_03],
    pageURL: 'g1-full-black-teclado',
  },
  {
    id: 3,
    name: 'G1 Full White - Led RGB Rainbow',
    category: 'Teclado',
    descriptionP: `Part Number: RM-TG-01-FW<br>
    Tipo: Com fio<br>
    Material: ABS<br>
    Conector: USB<br>
    Cor: Branco<br>
    Versão do teclado: Disponível em várias línguas<br>
    Longevidade do teclado: 10 milhões de horas<br>
    Cor do Led: RGB Rainbow<br>
    Dimensões do teclado: 43,2 x 13,1 x 3,2 cm<br>`,

    descriptionE: `Part Number: RM-TG-01-FW<br>
    Type: Wired<br>
    Material: ABS<br>
    Connector: USB<br>
    Color: White<br>
    Keyboard Version: Available in multiple languages<br>
    Keyboard Lifespan: 10 million hours<br>
    LED Color: RGB Rainbow<br>
    Keyboard Dimensions: 43.2 x 13.1 x 3.2cm<br>`,
      
    productId: 'g1-full-white-teclado',
    banner: G1White_banner,
    youtubeUrl: '3inI1ZqNUHA?si=yAGz5HlJLZSjF5lu',
    images: [G1White_01, G1White_02, G1White_03],
    pageURL: 'g1-full-white-teclado',
  },
  {
    id: 4,
    name: 'Combo Rise Mode Teclado + Mouse Office OF 01 Black',
    category: 'Teclado',
    descriptionP: `Tipo: Com fio <br>
    Material: ABS <br>
    Conector: USB  <br>
    Cor: Preto <br>
    Versão do teclado: Disponível em várias línguas <br>
    Longevidade do teclado :10 milhões de horas <br>
    Mouse Dpi:1000 <br>
    Botões do mouse: 3D <br>
    Dimensões do teclado: 44.5 x 14.6 x 2.3CM   <br>
    Dimensões do mouse:11,8 x 5,8 x 3,8CM <br>
    Part Number: RM-TM-01-FB <br>
    Peso: 610g  <br>
    `,

    descriptionE: `Type: Wired  <br>
    Material: ABS <br>
    Connector: USB <br>
    Color: Black <br>
    Keyboard Version: Available in various languages <br>
    Keyboard Longevity: 10 million hours <br>
    Mouse Dpi: 1000 <br>
    Mouse Buttons: 3D <br>
    Keyboard Dimensions: 44.5 x 14.6 x 2.3CM <br>
    Mouse Dimensions: 11.8 x 5.8 x 3.8CM <br>
    Weight: 610g <br>
    Part Number: RM-TM-01-FB  <br>`,
      
    productId: 'office-of-01-black',
    banner: Office01_banner,
    youtubeUrl: 'IJ-wNo5bkzk?si=lrkEhbA-nmlDClAg',
    images: [ Office01_02, Office01_01, Office01_03, Office01_04, Office01_05],
    pageURL: 'office-of-01-black',
  },
  {
    id: 5,
    name: 'Combo Rise Mode Teclado + Mouse Office OF 02 Black',
    category: 'Teclado',
    descriptionP: `Tipo: Com fio <br>
    Material: ABS <br>
    Conector: USB  <br>
    Cor: Preto <br>
    Versão do teclado: Disponível em várias línguas <br>
    Longevidade do teclado :10 milhões de horas <br>
    Mouse Dpi:1000 <br>
    Botões do mouse: 3D <br>
    Dimensões do teclado: 43.5 x 18 x 2.9CM   <br>
    Dimensões do mouse: 11,5 x 6,4 x 3,7CM <br>
    Peso: 760g <br>
    Part Number: RM-TM-02-FB  <br>
    `,

    descriptionE: `Type: Wired <br>
    Material: ABS <br>
    Connector: USB <br>
    Color: Black <br>
    Keyboard Version: Available in various languages <br>
    Keyboard Longevity: 10 million hours <br>
    Mouse Dpi: 1000 <br>
    Mouse Buttons: 3D <br>
    Keyboard Dimensions: 43.5 x 18 x 2.9CM <br>
    Mouse Dimensions: 11.5 x 6.4 x 3.7CM <br>
    Weight: 760g <br>
    Part Number: RM-TM-02-FB <br>`,
      
    productId: 'office-of-02-black',
    banner: Office02_banner,
    youtubeUrl: '3a9OjrBlj4s?si=a3uBFO2ru9k7sU0Z',
    images: [ Office02_02, Office02_01, Office02_03, Office02_04, Office02_05],
    pageURL: 'office-of-02-black',
  },
];

export default products;
