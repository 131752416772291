import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Ptlanguage from '../components/locales/pt.json';
import Enlanguage from '../components/locales/en.json';
import Eslanguage from '../components/locales/es.json';
import Frlanguage from '../components/locales/fr.json';
import Itlanguage from '../components/locales/it.json';
import Cnlanguage from '../components/locales/zh.json';

i18n
  .use(initReactI18next)
  .init({
    // Configurações e opções do i18next
    lng: 'en', // Define o idioma padrão
    resources: {
      en: {
         ...Enlanguage
      },
      pt: {
          ...Ptlanguage
      },
      es: {
        ...Eslanguage
     },
     fr: {
      ...Frlanguage
     },
     it: {
        ...Itlanguage
     },
     zh: {
      ...Cnlanguage
   }
    }
  });
