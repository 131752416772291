import Ssdm2_01 from '../../../img/Produtos/m2/1.png';

const products = [

  {
    id: 1,
    name: 'Ssd M.2 Pcie Diamond Series 240GB',
    category: 'ssdm2',
    descriptionP: `Ssd M.2 Pcie Diamond Series 240GB <br>
    Marca: Rise Mode<br>
    
    Modelo: SSD M.2 PCIE 240GB Diamond Series <br>
    
    Part Number:RM-M2P-240GB <br>
     
    ESPECIFICAÇÕES: <br>
    
    - Capacidade: 240GB <br>
    
    - Interface: Pcie Nvme <br>
    
    - Fator de forma: M.2 2280 <br>
    
    - NAND Flash: Intel <br>
    
    - Leitura sequencial: 2500mb/s <br>
    
    - Escrita sequencial: 1200mb/s <br>
    
    - Temperatura de operação:0ºC - 70ºC <br>
    
    - Mtbf:1.000.000 de horas <br>`,

    descriptionE: ``,
      
    productId: 'ssd-m2-diamond-series',
    banner: '',
    youtubeUrl: '',
    images: [Ssdm2_01],
    pageURL: 'ssd-m2-diamond-series',
  },
];

export default products;
