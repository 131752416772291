import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu, CloseIcon } from './css';
import Logo from '../../img/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import Brazilicon from '../../img/icon/brazilIcon.png';
import Usaicon from '../../img/icon/usaIcon.png';
import SpanishIcon from '../../img/icon/spanishIcon.png';
import FranceIcon from '../../img/icon/franceIcon.png';
import ItalyIcon from '../../img/icon/italyIcon.png';
import ChinaIcon from '../../img/icon/chinaIcon.png';
import icon from '../../img/icon/iconIdiomas.png';
import { useTranslation } from 'react-i18next';

const Button = styled.button`
  background: ${props => (props.selected ? '#232323' : '#4491FA')};
  color: ${props => (props.selected ? '#ffffff' : '#ffffff')};
  border: none;
  border-radius: 4px;
  padding: 8px;
  width: 120px;
  cursor: pointer;
  margin: 15px  20px 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  :hover {
    filter: brightness(0.9);
  }

  img{
    width: 20px;
    height: 20px;
    background: none;
  }

  p{
    font-size: 1.2rem;
    background: none;
    margin-top: 4px;
  }

  @media (max-width: 1000px) {
    margin-top: 1.5rem;
    width: 80px;
    p{
    font-size: 1rem; 
    }

    img{
      width: 15px;
      height: 15px;
    }
  }


`;

const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  margin-left: 2rem;
`;

const DropdownContent = styled.div`
  display: ${props => (props.open ? 'block' : 'none')};
  position: absolute;
  background-color:#232323;
  min-width: 160px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 1.5rem;
  top: 4rem;
  border-radius: 4px;
`;

const DropdownItem = styled.div`
  padding: 0.5rem;
  position: relative;
  background: none;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 4px;
  &:hover {
    background-color: #4491FA;
  }

  img {
    display: inline;
    width: 24px;
    height: 24px;
    background: none;
    margin-top: 4px;
  }

  p {
    position: absolute;
    display: inline;
    font-size: 1.2rem;
    background: none;
    margin-top: 5px;
  }
`;

const ImagemButton = styled.a`
  text-decoration: none !important;
`;

export default function Header() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeItem, setActiveItem] = useState('');

  const { t, i18n } = useTranslation();

  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLanguageChange = (language) => {
    localStorage.setItem('selectedLanguage', language);
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
    setDropdownOpen(false);
  };

  const handleLinkClick = () => {
    if (isMenuOpen) {
      closeMenu();
    }
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    } else {
      setSelectedLanguage('en');
      i18n.changeLanguage('en');
    }
  }, [i18n.language]);

  return (
    <div>
    <Menu>
      <Dropdown alt="Idiomas" title="Idiomas">
        <Button selected={dropdownOpen} onClick={toggleDropdown}>
          <img  src={icon} alt="Icon"/>
          <p>{selectedLanguage}</p>
        </Button>
        <DropdownContent open={dropdownOpen}>
          <DropdownItem onClick={() => handleLanguageChange('pt-br')}>
            <img src={Brazilicon} />
            <p>PT-BR</p>
          </DropdownItem>
          <DropdownItem onClick={() => handleLanguageChange('en-us')}>
            <img src={Usaicon} />
            <p>EN-US</p>
          </DropdownItem>
          <DropdownItem onClick={() => handleLanguageChange('es-es')}>
            <img src={SpanishIcon} />
            <p>ES-ES</p>
          </DropdownItem>
          <DropdownItem onClick={() => handleLanguageChange('fr-fr')}>
            <img src={FranceIcon} />
            <p>FR-FR</p>
          </DropdownItem>
          <DropdownItem onClick={() => handleLanguageChange('it-it')}>
            <img src={ItalyIcon} />
            <p>it-IT</p>
          </DropdownItem>
          <DropdownItem onClick={() => handleLanguageChange('zh-CN')}>
            <img src={ChinaIcon} />
            <p>ZH-CN</p>
          </DropdownItem>
        </DropdownContent>
      </Dropdown>
    </Menu>
    </div>
  );
}
